import React from 'react'

type SearchIconProps = {
  color: string
}

/**
 * SearchIcon icon
 * @constructor
 */
export const SearchIcon = ({ color }: SearchIconProps) => (
  <svg
    fill={color}
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4965 18.773L17.5047 15.7812C18.5075 14.5152 19.1091 12.917 19.1091 11.1795C19.1091 7.08244 15.7767 3.75 11.6796 3.75C7.58248 3.75 4.25 7.08244 4.25 11.1795C4.25 15.2765 7.58248 18.609 11.6796 18.609C13.4171 18.609 15.0153 18.0073 16.2814 17.0045L19.2731 19.9963C19.4413 20.1645 19.6631 20.25 19.8848 20.25C20.1055 20.25 20.3277 20.1659 20.4966 19.9961C20.8348 19.6586 20.8342 19.1107 20.4965 18.773ZM5.98078 11.1795C5.98078 8.03715 8.53719 5.48077 11.6796 5.48077C14.8219 5.48077 17.3784 8.03715 17.3784 11.1795C17.3784 14.3218 14.8219 16.8782 11.6796 16.8782C8.53719 16.8782 5.98078 14.3218 5.98078 11.1795Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
)
