import { Text } from '@chakra-ui/react'
import { Neutral, Sad, Smile } from 'components/atoms/Icons'
import React, { FC } from 'react'

type ShowIconType = {
  value: string | number | undefined
  changeColor?: boolean
  isShowTitle?: boolean
  label?: string
}

/**
 * @returns function that handle render icon table
 */
const ShowIcon: FC<ShowIconType> = ({
  value,
  changeColor = true,
  isShowTitle = false,
  label,
}) => {
  /**
   * handleIcon
   */
  const handleIcon = () => {
    if (!value)
      return (
        <>
          <Neutral
            _hover={{ color: changeColor ? 'blue.900' : '#D7A700' }}
            color="#D7A700"
            fontSize={24}
          />
          {isShowTitle && (
            <Text
              color={'#D7A700'}
              fontSize="14px"
              fontWeight="normal"
              letterSpacing="0em"
              lineHeight="shorter"
            >
              {label}
            </Text>
          )}
        </>
      )
    switch (value) {
      case 'neutral':
        return (
          <>
            <Neutral
              _hover={{ color: changeColor ? 'blue.900' : '#D7A700' }}
              color="#D7A700"
              fontSize={24}
            />
            {isShowTitle && (
              <Text
                color={'#D7A700'}
                fontSize="14px"
                fontWeight="normal"
                letterSpacing="0em"
                lineHeight="shorter"
              >
                {label}
              </Text>
            )}
          </>
        )
      case 'positive':
        return (
          <>
            <Smile
              _hover={{ color: changeColor ? 'blue.900' : 'green.500' }}
              color="green.500"
              fontSize={24}
            />
            {isShowTitle && (
              <Text
                color={'green.500'}
                fontSize="14px"
                fontWeight="normal"
                letterSpacing="0em"
                lineHeight="shorter"
              >
                {label}
              </Text>
            )}
          </>
        )
      case 'negative':
        return (
          <>
            <Sad
              _hover={{ color: changeColor ? 'blue.900' : 'red.700' }}
              color="red.700"
              fontSize={24}
            />
            {isShowTitle && (
              <Text
                color={'red.700'}
                fontSize="14px"
                fontWeight="normal"
                letterSpacing="0em"
                lineHeight="shorter"
              >
                {label}
              </Text>
            )}
          </>
        )
      case '-':
        return (
          <Text
            color={'#000'}
            fontSize="14px"
            fontWeight="normal"
            letterSpacing="0em"
            lineHeight="shorter"
          >
            {value}
          </Text>
        )

      default:
        return (
          <>
            <Neutral
              _hover={{ color: changeColor ? 'blue.900' : '#D7A700' }}
              color="#D7A700"
              fontSize={24}
            />
            {isShowTitle && (
              <Text
                color={'#D7A700'}
                fontSize="14px"
                fontWeight="normal"
                letterSpacing="0em"
                lineHeight="shorter"
              >
                {label}
              </Text>
            )}
          </>
        )
    }
  }
  return <>{handleIcon()}</>
}

export default ShowIcon
