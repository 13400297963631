import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { loginSuccess, userLogin } from 'context/Auth/auth.slice'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { storageServices, authServices } from 'services'

/**
 * useReAuthentication
 */
export const useReAuthentication = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const refreshToken = storageServices.getRefreshToken()
  const isAdminRole = storageServices.getRoleUser() === ROLE.ADMIN
  /**
   * reAuth
   */
  const reAuth = useCallback(
    async (role: ROLE) => {
      try {
        if (!refreshToken) return
        const res = await authServices.getAuth({
          refresh_token: refreshToken,
          role: role,
        })
        const { data, is_success } = res
        if (is_success) {
          const { access_token, refresh_token } = data
          access_token && storageServices.setAccessToken(access_token)
          refresh_token && storageServices.setRefreshToken(refresh_token)
          dispatch(loginSuccess())
          dispatch(userLogin({ user: data }))
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        const { message } = error.response.data
        if (message === 'Could not validate credentials') {
          storageServices.removeAccessToken()
          storageServices.removeRefreshToken()
          storageServices.removeRoleUser()
          if (role === ROLE.ADVISOR) {
            window.location.assign(ROUTES.LOGIN)
          } else {
            window.location.assign(ADMIN_ROUTES.LOGIN)
          }
        }
      }
    },
    [dispatch, refreshToken],
  )

  useEffect(() => {
    if (refreshToken) {
      if (isAdminRole) {
        reAuth(ROLE.ADMIN)
      } else {
        reAuth(ROLE.ADVISOR)
      }
    } else {
      setLoading(false)
    }
  }, [isAdminRole, reAuth, refreshToken])

  return {
    loading,
    reAuth,
  }
}
