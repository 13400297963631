import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Flex, Tooltip, useTheme } from '@chakra-ui/react'
import { HashTagIcon } from 'components/fromAdvisor/elements/Icons'
import styled from '@emotion/styled'

type TextTooltipProps = {
  label: string
  isSelected?: boolean
}

/**
 * TextTooltip component page.
 * @return {JSX.Element} TextTooltip component.
 */
export const TextTooltip: FC<TextTooltipProps> = ({
  label,
  isSelected = false,
}) => {
  const textRef = useRef(null)
  const theme = useTheme()
  const [isOverflown, setIsOverflown] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current
      setIsOverflown(scrollWidth > offsetWidth)
    } else {
      setIsOverflown(false)
    }
    return () => {
      setIsOverflown(false)
    }
  }, [])

  return (
    <TooltipStyled
      isDisabled={!isOverflown}
      label={label}
      placement="top-start"
    >
      <Flex alignItems="center" gap={2} overflow={'hidden'}>
        <Box h={5} maxW={5} minW={5}>
          <HashTagIcon
            fill={isSelected ? theme.colors.black[100] : theme.colors.blue[900]}
          />
        </Box>
        <LabelStyled
          ref={textRef}
          isTruncated
          as={'span'}
          selected={isSelected}
        >
          {label}
        </LabelStyled>
      </Flex>
    </TooltipStyled>
  )
}

const LabelStyled = styled(Box, {
  shouldForwardProp: props => props !== 'selected',
})<{ selected: boolean }>`
  color: ${props =>
    props.selected
      ? props.theme.colors.black[100]
      : props.theme.colors.blue[900]};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: ${props => props.theme.lineHeights.shorter};
`

const TooltipStyled = styled(Tooltip)`
  padding-left: ${props => props.theme.space[4]};
  padding-right: ${props => props.theme.space[4]};
  margin-bottom: ${props => props.theme.space[1]};
  background-color: ${props => props.theme.colors.black[800]};
  border-radius: ${props => props.theme.radii.lg};
  color: ${props => props.theme.colors.white[50]};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.semibold};
  letter-spacing: ${props => props.theme.letterSpacings.tighter};
  line-height: ${props => props.theme.lineHeights.shorter};
`
