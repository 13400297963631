import { errors, regexEmail, regexPassword } from 'constant'
import { regexKana } from 'constant/conmon'
import { yup } from 'libs/yup'

export const MESSAGE_PROFILE = {
  UPDATE_PROFILE_SUCCESS: 'アカウント情報が更新されました',
  UPDATE_PASSWORD_SUCCESS: 'パスワードの変更が完了しました',
  UPDATE_EMAIL_SUCCESS: 'メールアドレスの変更が完了しました',
}

export const initialValuesChangePass = {
  old_password: '',
  new_password: '',
  cf_password: '',
}
export const changeProfileSchema = yup.object({
  first_name: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('first_name'),
  last_name: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('last_name'),
  furigana_name_first: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('furigana_name_first')
    .matches(regexKana, 'カタカナのみ入力してください。'),
  furigana_name_last: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('furigana_name_last')
    .matches(regexKana, 'カタカナのみ入力してください。'),
  department: yup
    .string()
    .required(errors.required)
    .label('department')
    .trim()
    .max(19, errors.maxCharacters19),
  position: yup
    .string()
    .required(errors.required)
    .label('position')
    .trim()
    .max(19, errors.maxCharacters19),
})

export const changePasswordSchema = yup.object({
  old_password: yup
    .string()
    .required(errors.required)
    .label('password')
    .matches(regexPassword, errors.password)
    .trim(),
  new_password: yup
    .string()
    .notOneOf(
      [yup.ref('old_password')],
      '新しいパスワードを古いパスワードと同じにすることはできません。',
    )
    .label('new_password')
    .trim(),
  cf_password: yup
    .string()
    .required(errors.required)
    .label('cf_password')
    .matches(regexPassword, errors.password)
    .oneOf(
      [yup.ref('new_password')],
      '※パスワードと確認用パスワードが一致しません。',
    )
    .trim(),
})

export const changeEmailSchema = yup.object({
  email: yup
    .string()
    .required(errors.required)
    .max(64, '64字数以下で入力してください。')
    .matches(regexEmail, errors.email)
    .label('email')
    .trim(),
  new_email: yup
    .string()
    .required(errors.required)
    .max(64, '64字数以下で入力してください。')
    .notOneOf(
      [yup.ref('email')],
      '新しいメールアドレスを古いメールアドレスと同じにすることはできません。',
    )
    .matches(regexEmail, errors.email)
    .label('email')
    .trim(),
  cf_email: yup
    .string()
    .required(errors.required)
    .label('cf_email')
    .oneOf([yup.ref('new_email')], '※メールと確認メールが一致しません。')
    .trim(),
})
