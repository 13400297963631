import { useCallback, useEffect, useState } from 'react'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant'
import { NewsType } from 'services/newServices'
import { newServices } from 'services'
import { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { getDocument } from 'pdfjs-dist'
import 'pdfjs-dist/build/pdf.worker.entry'

export type PostProps = {}

export const usePost = (props: PostProps) => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const listNewsAiId = queryParams.get('list_news_ai_id')
  const tab = queryParams.get('tab')
  const indexTab = Number(tab) ? Number(tab) : 0
  const [typeNews, setTypeNew] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [newsDetail, setNewsDetail] = useState<{
    data: NewsType
    favorite: boolean
    isLoading: boolean
  }>({ data: {} as NewsType, favorite: false, isLoading: false })
  const [cunrentPage, setCunrentPage] = useState<number>(0)
  const [summaryContent, setSummaryContent] = useState<{
    data: string
    loading: false
    done: true
  }>({
    data: '',
    loading: false,
    done: true,
  })

  /**
   *
   */
  const reverseObj = useCallback(() => {
    const oldObj: any = newsDetail.data?.abbreviation_type
    if (!oldObj) return []
    return Object.keys(oldObj).map((obj: any) => ({
      label: oldObj[obj],
      value: obj,
    }))
  }, [newsDetail.data?.abbreviation_type])

  const formik = useFormik({
    initialValues: {
      selectCartContent: reverseObj()[1],
      page: '1',
    },
    /**
     *
     */
    onSubmit() {},
    enableReinitialize: true,
  })
  const { setFieldValue } = formik

  useEffect(() => {
    setFieldValue('selectCartContent', reverseObj()[1])
  }, [newsDetail.isLoading, reverseObj, setFieldValue])

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(navigate, `${ROUTES.POST}/${id}`, 'tab', index)
  }

  const getPageCount = async (formUrl: string) => {
    try {
      if (formUrl) {
        let blob = await fetch(formUrl).then(r => r.blob())
        const fileReader: any = new FileReader()
        fileReader.onload = async () => {
          const typedArray = new Uint8Array(fileReader.result)
          const pdf = await getDocument(typedArray).promise
          setPage(pdf.numPages)
        }
        fileReader.readAsArrayBuffer(blob)
      }
    } catch (error) {
      console.error('Error fetching page count:', error)
    }
  }

  const fetchDataNewsDetail = useCallback(async () => {
    try {
      setNewsDetail(prev => ({
        ...prev,
        isLoading: true,
      }))
      if (id) {
        let params = {
          id: id,
          ...(listNewsAiId && { list_news_ai_id: Number(listNewsAiId) }),
        }

        const [
          { data },
          {
            data: { is_favorite },
          },
        ] = await Promise.all([
          newServices.getNewDetail(params),
          newServices.getNewsFavorite(id),
        ])

        if (data === null) {
          toast({
            status: 'error',
            description:
              'ご指定のニュースが見つかりません。キーワードを再確認してもう一度お試しください。',
          })
          setNewsDetail(prev => ({
            ...prev,
            data: {} as NewsType,
            favorite: false,
            isLoading: false,
          }))
          return
        }

        if (
          isEmpty(data.news_body) &&
          isEmpty(data.company_report?.file_path)
        ) {
          setTypeNew(false)
        } else {
          setTypeNew(!isEmpty(data.company_report?.file_path))
        }
        setNewsDetail(prev => ({
          ...prev,
          data,
          favorite: is_favorite,
          isLoading: false,
        }))
        setSummaryContent(prev => ({ ...prev, data: data.abbreviation_300 }))
        dispatch(
          setBreadcrumb([
            {
              text: data.subject_company ?? '',
              link: `${ROUTES.COMPANY}/${data.company_id}`,
            },
            {
              text: data.news_title,
              link: `${ROUTES.POST_DETAIL}/${data.list_news_id}`,
            },
          ]),
        )
      }
    } catch (error) {
      setNewsDetail(prev => ({
        ...prev,
        data: {} as NewsType,
        favorite: false,
        isLoading: false,
      }))

      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, toast])

  useEffect(() => {
    fetchDataNewsDetail()
  }, [fetchDataNewsDetail])

  useEffect(() => {
    if (newsDetail.data.company_report?.file_path) {
      getPageCount(newsDetail.data.company_report.file_path)
    }
  }, [newsDetail.data.company_report?.file_path])

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure({ defaultIsOpen: true })

  const handleSetPagePdf = (value: string, type?: boolean) => {
    setFieldValue('page', value)
    if (type) {
      setCunrentPage(Number(value))
    }
  }

  return {
    ...props,
    isOpenDrawer,
    onOpenDrawer,
    newsDetail,
    id,
    setNewsDetail,
    onCloseDrawer,
    indexTab,
    handleChangeTab,
    typeNews,
    cunrentPage,
    setCunrentPage,
    page,
    setPage,
    formik,
    handleSetPagePdf,
    summaryContent,
  }
}
