import { Flex, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

type NewsBodyColumnProps = {
  content: string
}

const NewsBodyColumn: FC<NewsBodyColumnProps> = props => {
  const { content } = props
  return (
    <Flex direction="column" gap={2}>
      <Text
        color="black.100"
        fontSize="xs"
        fontWeight="normal"
        lineHeight="16.8px"
        overflow="hidden"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {content}
      </Text>
    </Flex>
  )
}

export default NewsBodyColumn
