import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Neutral icon
 * @param props
 * @constructor
 */
export const Neutral: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 25 24"
        width="25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22.793A10.793 10.793 0 1022.793 12 10.805 10.805 0 0012 1.207z"
          fill="currentColor"
        ></path>
        <path
          d="M8.876 9.901a1.509 1.509 0 100-3.017 1.509 1.509 0 000 3.017zM16.118 9.901a1.509 1.509 0 100-3.017 1.509 1.509 0 000 3.017z"
          fill="currentColor"
        ></path>
        <path
          clipRule="evenodd"
          d="M8.542 16.399c.122-.325.466-.484.768-.354 2.132.916 4.248.916 6.38 0 .303-.13.646.029.768.354.12.325-.027.694-.33.824-2.413 1.036-4.843 1.036-7.256 0-.303-.13-.45-.5-.33-.824z"
          fill="currentColor"
          fillRule="evenodd"
        ></path>
      </svg>
    </Icon>
  )
}
