import {
  Box,
  BoxProps,
  FormControl,
  FormLabel,
  forwardRef,
} from '@chakra-ui/react'
import { FieldForm } from '../CompanyRegistration/type'

export type ButtonProps = BoxProps & {
  fieldform: FieldForm[]
}
/**
 * CompanyForm component page.
 * @return {JSX.Element} CompanyForm component.
 */
export const CompanyForm = forwardRef<ButtonProps, 'div'>(
  (props, ref): React.ReactElement => {
    const { fieldform } = props
    return (
      <Box
        ref={ref}
        backgroundColor="white.100"
        border="1px solid #E8ECEF"
        borderRadius="12px"
        {...props}
      >
        {fieldform.map((it: FieldForm, index) => {
          return (
            <FormControl
              key={index}
              _last={{
                borderBottom: 'none',
              }}
              borderBottom="1px solid #E8ECEF"
              display={it.isNone ? 'none' : 'flex'}
              isRequired={it.isRequired}
            >
              <FormLabel
                borderRight="1px solid #E8ECEF"
                fontSize="14px"
                fontWeight="700"
                lineHeight="24px"
                margin="0"
                padding="8px 12px"
                width="21%"
              >
                {it.label}
              </FormLabel>
              <Box
                _first={{
                  borderTopRightRadius: '12px',
                }}
                _last={{
                  borderTopRightRadius: '12px',
                  borderBottomRightRadius: '12px',
                }}
                backgroundColor={it.isBackground}
                padding="8px 12px"
                width="79%"
              >
                {it.components}
              </Box>
            </FormControl>
          )
        })}
      </Box>
    )
  },
)
