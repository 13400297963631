import { DashboardContainer } from 'components/organisms'
import { FC } from 'react'

/**
 * Dashboard page
 * @constructor
 */
export const Dashboard: FC = () => {
  return <DashboardContainer />
}
