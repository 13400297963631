import { images } from 'constant/images'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { FC, useState } from 'react'
import { InputField } from 'components/atoms'
import _ from 'lodash'
import { Lock } from 'components/atoms/Icons'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { ADMIN_ROUTES, ROLE, ROUTES, icons, regexPassword } from 'constant'

import { Button } from 'components/fromAdvisor/elements'
import { authServices, storageServices, userAdminServices } from 'services'
import { useDispatch } from 'react-redux'
import { loginSuccess, userLogin, userLogout } from 'context/Auth/auth.slice'
import { AxiosError } from 'axios'
import { resetPasswordSchema } from './validation'

/**
 * login page
 * @constructor
 */
export const ResetPassword: FC<{ size: ROLE }> = ({ size }) => {
  const [resError, setResError] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const location = useLocation()
  const isCheckType = location.pathname.includes('reset-password')
  const roleStorage = storageServices.getRoleUser()

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: { password: '', cf_password: '' },
    validationSchema: resetPasswordSchema,

    /**
     *
     */
    async onSubmit(values: { password: string; cf_password: string }) {
      if (!token || !email) {
        return
      }
      setIsLoading(true)
      try {
        const { data } = await authServices.verifyToken({
          email: email,
          token_type: isCheckType ? 'password_recovery' : 'password_creation',
          token,
        })
        if (data.is_success === true) {
          await authServices.resetPassword(size, {
            password: values.password,
            email: email,
            token,
          })
          const res =
            size === ROLE.ADVISOR
              ? await authServices.login({
                  email,
                  password: values.password,
                  role: ROLE.ADVISOR,
                })
              : await authServices.login({
                  email,
                  password: values.password,
                  role: ROLE.ADMIN,
                })

          if (res.is_success) {
            const { user_type, access_token, refresh_token } = res.data
            access_token && storageServices.setAccessToken(access_token)
            refresh_token && storageServices.setRefreshToken(refresh_token)
            user_type && storageServices.setRoleUser(user_type)
            dispatch(loginSuccess())
            dispatch(userLogin({ user: res.data }))
            navigate(size === ROLE.ADVISOR ? ROUTES.CHAT_AI : ADMIN_ROUTES.ROOT)
          }
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        if (error instanceof AxiosError) {
          if (error.response) {
            setResError(error.response.data?.detail)
          } else {
            setResError(error.message)
          }
        }
      }
    },
  })

  const { handleSubmit, errors, setFieldValue, dirty, isValid, values } = formik

  const checkPassInvalid = !values.password
    ? false
    : values.password.match(regexPassword)

  const checkPassLength = !values.password
    ? false
    : values.password.length >= 8 && values.password.length <= 20

  if (!token || !email) {
    const redirectLink = size === ROLE.ADMIN ? ADMIN_ROUTES.LOGIN : ROUTES.LOGIN
    return <Navigate to={redirectLink} />
  }

  const handleLogout = async () => {
    if (roleStorage) {
      await userAdminServices.logout(roleStorage)
      storageServices.removeAccessToken()
      storageServices.removeRefreshToken()
      storageServices.removeRoleUser()
      dispatch(userLogout())
    }
  }

  return (
    <FormikProvider value={formik}>
      <Box
        bgImage={images.BACKGROUND_LOGIN}
        bgSize="cover"
        boxSizing="border-box"
        minH="100vh"
        padding="24px"
        position="relative"
        w="100%"
      >
        <Box
          bgColor="white.50"
          borderRadius="xl"
          h="calc(100vh - 48px)"
          maxW={647}
          ml={2}
          position="relative"
          w="100%"
        >
          <Flex
            alignItems="center"
            direction="column"
            h="100%"
            justifyContent="center"
          >
            <Image alt="Logo" mb="24px" src={images.LOGO_LOGIN} />
            {resError && (
              <Text
                color="red.700"
                fontSize="sm"
                fontWeight="medium"
                lineHeight="shorter"
                mb={10}
                mt="32px"
              >
                {resError}
              </Text>
            )}
            <Flex direction="column" gap={4} maxW={327} mb="24px" w="100%">
              <InputField
                error={Boolean(resError || errors.password)}
                errorText={errors.password}
                height="56px"
                leftIcon={
                  <Lock color="blue.900" fontSize="2xl" opacity={0.5} />
                }
                name="password"
                placeholder="パスワード"
                type="password"
                width="100%"
                onChange={e =>
                  setFieldValue('password', e.target.value.replace(/ /g, ''))
                }
                onFocus={() => setResError('')}
              />
              <Text
                alignItems="center"
                color={checkPassLength ? '#0CAF60' : '#141718'}
                display="flex"
                fontSize="14px"
                gap="8px"
              >
                {checkPassLength ? (
                  <Image src={icons.CHECK_CIRCLE_DONE} />
                ) : (
                  <Image src={icons.CHECK_CIRCLE_FALSE} />
                )}
                8文字以上20文字以下
              </Text>
              <Text
                alignItems="center"
                color={checkPassInvalid ? '#0CAF60' : '#141718'}
                display="flex"
                fontSize="14px"
                gap="8px"
              >
                {checkPassInvalid ? (
                  <Image src={icons.CHECK_CIRCLE_DONE} />
                ) : (
                  <Image src={icons.CHECK_CIRCLE_FALSE} />
                )}
                大文字英字・小文字英字・数字の3種混在
              </Text>
              <InputField
                error={Boolean(resError || errors.cf_password)}
                errorText={errors.cf_password}
                height="56px"
                leftIcon={
                  <Lock color="blue.900" fontSize="2xl" opacity={0.5} />
                }
                name="cf_password"
                placeholder="パスワード"
                type="password"
                width="100%"
                onChange={e =>
                  setFieldValue('cf_password', e.target.value.replace(/ /g, ''))
                }
                onFocus={() => setResError('')}
              />
            </Flex>
            <Button
              _hover={{ bgColor: 'blue.900' }}
              bgColor="blue.900"
              borderRadius="xl"
              color="white.50"
              fontSize="md"
              fontWeight="bold"
              height="56px"
              isDisabled={
                !_.isEmpty(errors) || !dirty || !isValid || !checkPassLength
              }
              isLoading={isLoading}
              letterSpacing="-0.02em"
              lineHeight="shorter"
              maxWidth={327}
              opacity={_.isEmpty(errors) ? 1 : 0.2}
              padding="12px 14px"
              text="ログイン"
              width="100%"
              onClick={async () => {
                await handleLogout()
                handleSubmit()
              }}
            />
          </Flex>
          <Flex
            bottom={0}
            justifyContent="space-between"
            padding={6}
            position="absolute"
            width="100%"
          >
            <Text
              color="blue.900"
              cursor={'pointer'}
              fontSize="sm"
              fontWeight="medium"
              letterSpacing="-0.01em"
              lineHeight="shorter"
              onClick={() =>
                navigate(
                  size === ROLE.ADVISOR ? ROUTES.LOGIN : ADMIN_ROUTES.LOGIN,
                )
              }
            >
              {size === ROLE.ADVISOR
                ? 'ユーザーログインはこちら'
                : '管理者ログインはこちら'}
            </Text>
            <Text
              color="gray.800"
              fontSize="sm"
              fontWeight="normal"
              letterSpacing="-0.02em"
              lineHeight="shorter"
            >
              © MILIZE Inc.
            </Text>
          </Flex>
        </Box>
        <Flex
          bottom={6}
          gap={4}
          justifyContent="flex-end"
          position="absolute"
          width="calc(100% - 48px)"
        >
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            利用規約
          </Text>
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            プライバシーポリシー
          </Text>
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            運営会社
          </Text>
        </Flex>
      </Box>
    </FormikProvider>
  )
}
