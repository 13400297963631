import {
  Checkbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { FC } from 'react'
import { TTheme } from 'theme'

export type CheckboxProps = Omit<ChakraCheckboxProps, 'colorScheme' | 'size'>

export type CheckboxFieldProps = {
  label: string
  name: string
  isInForm?: boolean
  onChange?: () => void
  value: boolean
}
/**
 * Checkbox component
 * @constructor
 */
export const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  name,
  isInForm = true,
  onChange,
  value,
}) => {
  const [field, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <StyledCheckbox
      {...(isInForm ? field : {})}
      isChecked={value}
      name={name}
      onChange={() => {
        onChange ? onChange() : setValue(field.name, !value)
      }}
    >
      {label}
    </StyledCheckbox>
  )
}

/**
 * @returns function that handle background style
 */
const bgStyle = ({
  isDisabled,
}: Pick<CheckboxProps, 'isDisabled'> & { theme: TTheme }) => {
  if (!isDisabled) {
    return css`
      &[aria-checked='true'],
      &[data-checked],
      &[data-checked] {
        background: #0084ff;
        border: none;
      }
    `
  }
  return null
}

export const StyledCheckbox = styled(Checkbox)`
  .chakra-checkbox__control {
    width: ${props => props.theme.sizes[6]};
    height: ${props => props.theme.sizes[6]};
    border-width: ${props => props.theme.sizes[0.5]};
    background-color: ${props => props.theme.colors.white[50]};
    border-color: ${props => props.theme.colors.purple[250]};
    border-radius: ${props => props.theme.radii.md};
    ${bgStyle}

    &[data-checked][data-hover] {
      background: ${props => props.theme.colors.blue[900]};
    }

    &[data-checked]:hover {
      background: ${props => props.theme.colors.blue[900]};
    }
  }
  .chakra-checkbox__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`
