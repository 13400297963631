import React, { FC, useEffect, useRef, useState } from 'react'
import { Tooltip, Text } from '@chakra-ui/react'

type TextOverflowTooltipProps = {
  value: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: string
  maxW?: string
  width?: string
  opacity?: string
  color?: string
  onClick?: () => void
  isCursor?: boolean
  minW?: string
}

/**
 * TextOverflowTooltip component page.
 * @return {JSX.Element} TextOverflowTooltip component.
 */
export const TextOverflowTooltip: FC<TextOverflowTooltipProps> = ({
  value,
  fontSize,
  lineHeight = '24px',
  maxW,
  width,
  fontWeight,
  color,
  opacity,
  onClick,
  isCursor = false,
  minW,
}) => {
  const [isOverflown, setIsOverflown] = useState(false)
  const textRef = useRef(null)
  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current
      setIsOverflown(scrollWidth > offsetWidth)
    } else {
      setIsOverflown(false)
    }
    return () => {
      setIsOverflown(false)
    }
  }, [])
  return (
    <Tooltip
      bgColor="black"
      color="white"
      isDisabled={!isOverflown}
      label={value}
      placement="top-start"
    >
      <Text
        ref={textRef}
        color={color}
        cursor={isCursor ? 'pointer' : ''}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        maxW={maxW}
        minW={minW}
        opacity={opacity}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width={width}
        onClick={onClick}
      >
        {value}
      </Text>
    </Tooltip>
  )
}
