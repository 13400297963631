import { Flex, Spinner } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Loading molecules
 * @constructor
 */
export const Loading: FC = () => {
  return (
    <Flex
      alignItems="center"
      bgColor="black.800"
      height="100vh"
      justifyContent="center"
      width="100%"
    >
      <Spinner color="white.base" size="xl" />
    </Flex>
  )
}
