import { FC } from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

/**
 * Compare icon
 * @param props
 * @constructor
 */
export const Compare: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="32"
        viewBox="4 4 36 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.623 26.377a1 1 0 001.412-1.412l-2.291-2.292h14.254c.554 0 1-.446 1-1s-.446-1-1-1H10.744l2.291-2.292a1 1 0 00-1.412-1.413l-4 4a.996.996 0 000 1.413l4 4v-.004zM25.706 11.71a.996.996 0 000-1.412l-4-4.004a1 1 0 00-1.412 1.412l2.291 2.292H8.331c-.554 0-1 .446-1 1s.446 1 1 1h14.254l-2.291 2.292a1 1 0 001.412 1.412l4-3.992z"
          fill="currentColor"
        ></path>
      </svg>
    </Icon>
  )
}
