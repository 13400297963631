import React, { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import InfoCompany from '../InfoCompany'

/**
 * Company molecules
 * @constructor
 */
export const Company: FC = () => {
  return (
    <Flex alignItems="flex-start" gap={4} w="100%">
      <InfoCompany />
    </Flex>
  )
}
