import React from 'react'
import {
  Input as InputChakra,
  InputProps as InputChakraProps,
  forwardRef,
  useTheme,
} from '@chakra-ui/react'

export type InputProps = InputChakraProps

/**
 * Input component page.
 * @return {JSX.Element} Input component.
 */
export const Input = forwardRef<InputProps, 'input'>(
  (props, ref): React.ReactElement => {
    const theme = useTheme()

    return (
      <InputChakra
        ref={ref}
        _disabled={{
          background: 'gray.300',
          border: 'none',
          boxShadow: 'none',
        }}
        _focus={{}}
        _focusVisible={{}}
        _invalid={{
          border: `${theme.space[0.5]} solid ${theme.colors.red[700]}`,
        }}
        _placeholder={{
          color: 'black.250',
        }}
        border={`${theme.space.px} solid var(--neutral-03100, ${theme.colors.purple[250]})`}
        borderRadius={'xl'}
        boxShadow={theme.boxShadows.passwordInput}
        {...props}
        color={props.color || 'black.100'}
      />
    )
  },
)
