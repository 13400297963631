import React, { FC, ReactElement } from 'react'
import { Flex, Text, useTheme } from '@chakra-ui/react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { TrackLargeIcon } from 'components/fromAdvisor/elements/Icons'

export type ModalConfirmProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  title: string
  textOK?: string
  textCancel?: string
  isKeepAll?: boolean
}

/**
 * ModalConfirm component page.
 * @return {JSX.Element} ModalConfirm component.
 */
export const ModalConfirm: FC<ModalConfirmProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  textOK = '削除する',
  textCancel = 'キャンセル',
  isKeepAll = false,
}): ReactElement => {
  const theme = useTheme()
  return (
    <Modal isShowIconClose isOpen={isOpen} maxWidth={'360px'} onClose={onClose}>
      <Flex
        alignItems={'center'}
        flexDirection={'column'}
        gap={14}
        p={'72px 40px 56px 40px'}
      >
        <Flex alignItems={'center'} flexDirection={'column'} gap={2}>
          <TrackLargeIcon />
          <Text
            color={'black.100'}
            fontSize={'xl'}
            fontWeight={'bold'}
            lineHeight={theme.lineHeights['base']}
            textAlign={'center'}
            wordBreak={isKeepAll ? 'keep-all' : 'unset'}
          >
            {title}
          </Text>
        </Flex>

        <Flex flexDirection={'column'} gap={4} w={'full'}>
          <Button
            bg={'blue.900'}
            borderColor={'blue.900'}
            color={'white.50'}
            fontSize={'md'}
            fontWeight={700}
            h={12}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            text={textOK}
            w={'full'}
            onClick={onSubmit}
          />
          <Button
            borderColor={'blue.900'}
            color={'blue.900'}
            fontSize={'md'}
            fontWeight={700}
            h={12}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            text={textCancel}
            variant="outline"
            w={'full'}
            onClick={onClose}
          />
        </Flex>
      </Flex>
    </Modal>
  )
}
