import { FC } from 'react'
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { CommonLayoutContainer } from 'components/organisms'
import {
  Dashboard,
  Login,
  PostDetail,
  ChatAI,
  AdminTopPage,
  UserListPage,
  UserRegistration,
  ConditionSetting,
  ListSetting,
  PromptRegistration,
  CompanyRegistration,
  CompanyDetail,
  MyPage,
  CompanyList,
  ProductRegistration,
  ProductDetail,
  ProductList,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  AccessLog,
  ProductLog,
  ProductLogDetail,
  ChangeLogs,
} from 'pages'
import { useSelector } from 'react-redux'
import { loggedInUserSelector } from 'context/Auth/auth.selector'
import { useReAuthentication } from 'hooks/reAuth'
import { Loading, SearchPost } from 'components/molecules'
import { CompanyDetail as CompanyDetailAdvisor } from 'pages/CompanyDetail'
import { storageServices } from 'services'
import { ExternalAuthen } from 'pages/ExternalAuthen'
import ProtectedRoute from './ProtectedRoute'

/**
 * Navigations
 * @constructor
 */
export const Navigations: FC = () => {
  const { loading } = useReAuthentication()
  const currentUser = useSelector(loggedInUserSelector)
  const isAdmin = storageServices.getRoleUser() === ROLE.ADMIN

  const router = createBrowserRouter([
    {
      path: '*',
      element: <>NotFound Page</>,
    },
    {
      path: ROUTES.ROOT,
      element: (
        <ProtectedRoute condition={currentUser} redirectTo={ROUTES.LOGIN}>
          <ProtectedRoute
            condition={!isAdmin}
            redirectTo={!isAdmin ? ROUTES.ROOT : ADMIN_ROUTES.ROOT}
          >
            <CommonLayoutContainer />
          </ProtectedRoute>
        </ProtectedRoute>
      ),
      children: [
        {
          path: ROUTES.ROOT,
          element: <Navigate to={ROUTES.DASHBOARD} />,
        },
        {
          path: ROUTES.CHAT_AI,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <ChatAI />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.PROMPT_REGISTRATION,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <PromptRegistration
                size={ROLE.ADVISOR}
                title={'Myプロンプト登録'}
              />
            </ProtectedRoute>
          ),
        },
        {
          path: `${ROUTES.PROMPT_DEATAIL}/${ROUTES.ID}`,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <PromptRegistration size={ROLE.ADVISOR} />
            </ProtectedRoute>
          ),
        },
        {
          path: `${ROUTES.PROMPT_EDIT}/${ROUTES.ID}`,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <PromptRegistration size={ROLE.ADVISOR} />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.DASHBOARD,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <Dashboard />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.MY_PAGE,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <MyPage size={ROLE.ADVISOR} />
            </ProtectedRoute>
          ),
        },
        {
          path: `${ROUTES.COMPANY}/${ROUTES.ID}`,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <CompanyDetailAdvisor />
            </ProtectedRoute>
          ),
        },
        {
          path: `${ROUTES.POST_DETAIL}/${ROUTES.ID}`,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <PostDetail />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: ROUTES.STOCK_PRICE,
        //   element: (
        //     <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
        //       <StockPriceDetail />
        //     </ProtectedRoute>
        //   ),
        // },
        {
          path: ROUTES.CARD_NEWS,
          element: (
            <ProtectedRoute condition={currentUser} redirectTo={ROUTES.ROOT}>
              <SearchPost page="card-news" perPageTable={20} />
            </ProtectedRoute>
          ),
        },
        {
          path: ROUTES.COMPANY_LIST,
          element: <CompanyList size={ROLE.ADVISOR} />,
        },
        {
          path: `${ROUTES.COMPANY_DEATAIL}/${ROUTES.ID}`,
          element: <CompanyDetail size={ROLE.ADVISOR} />,
        },
      ],
    },
    {
      path: ADMIN_ROUTES.ROOT,
      element: (
        <ProtectedRoute condition={currentUser} redirectTo={ADMIN_ROUTES.LOGIN}>
          <ProtectedRoute
            condition={isAdmin}
            redirectTo={isAdmin ? ADMIN_ROUTES.ROOT : ROUTES.ROOT}
          >
            <CommonLayoutContainer />
          </ProtectedRoute>
        </ProtectedRoute>
      ),
      children: [
        { path: ADMIN_ROUTES.ROOT, element: <AdminTopPage /> },
        { path: ADMIN_ROUTES.USER_LIST, element: <UserListPage /> },
        { path: ADMIN_ROUTES.USER_REGISTRATION, element: <UserRegistration /> },
        {
          path: `${ADMIN_ROUTES.USER_DETAIL}/${ADMIN_ROUTES.ID}`,
          element: <UserRegistration />,
        },
        {
          path: `${ADMIN_ROUTES.USER_EDIT}/${ADMIN_ROUTES.ID}`,
          element: <UserRegistration />,
        },
        {
          path: ADMIN_ROUTES.AI_SETTING,
          element: <ConditionSetting />,
        },
        { path: ADMIN_ROUTES.PROMPT_SETTINGS, element: <ListSetting /> },
        {
          path: ADMIN_ROUTES.COMPANY_REGISTRATION,
          element: <CompanyRegistration size={ROLE.ADMIN} />,
        },
        {
          path: `${ADMIN_ROUTES.COMPANY_EDIT}/${ADMIN_ROUTES.ID}`,
          element: <CompanyRegistration size={ROLE.ADMIN} />,
        },
        {
          path: `${ADMIN_ROUTES.COMPANY}/${ADMIN_ROUTES.ID}`,
          element: <CompanyDetail size={ROLE.ADMIN} />,
        },
        {
          path: ADMIN_ROUTES.COMPANY_LIST,
          element: <CompanyList size={ROLE.ADMIN} />,
        },
        {
          path: ADMIN_ROUTES.PRODUCT_REGISTRATION,
          element: <ProductRegistration />,
        },
        {
          path: `${ADMIN_ROUTES.PRODUCT_DETAIL}/${ADMIN_ROUTES.ID}`,
          element: <ProductDetail />,
        },
        {
          path: ADMIN_ROUTES.CHANGE_LOGS,
          element: <ChangeLogs />,
        },
        {
          path: ADMIN_ROUTES.PRODUCT_LIST,
          element: <ProductList />,
        },
        {
          path: ADMIN_ROUTES.MY_PAGE,
          element: <MyPage size={ROLE.ADMIN} />,
        },
        {
          path: ADMIN_ROUTES.PROMPT_REGISTRATION,
          element: (
            <PromptRegistration size={ROLE.ADMIN} title="定形プロンプト登録" />
          ),
        },
        {
          path: ADMIN_ROUTES.PROMPT_REGISTRATION_COMPANY,
          element: (
            <PromptRegistration size={ROLE.ADMIN} title="定形プロンプト登録" />
          ),
        },
        {
          path: ADMIN_ROUTES.PROMPT_REGISTRATION_NEWS,
          element: (
            <PromptRegistration size={ROLE.ADMIN} title="定形プロンプト登録" />
          ),
        },
        {
          path: `${ADMIN_ROUTES.PROMPT_DEATAIL}/${ROUTES.ID}`,
          element: <PromptRegistration size={ROLE.ADMIN} />,
        },
        {
          path: `${ADMIN_ROUTES.PROMPT_EDIT}/${ROUTES.ID}`,
          element: <PromptRegistration size={ROLE.ADMIN} />,
        },
        {
          path: ADMIN_ROUTES.ACCESS_LOG,
          element: <AccessLog />,
        },
        {
          path: ADMIN_ROUTES.PRODUCT_LOG,
          element: <ProductLog />,
        },
        {
          path: `${ADMIN_ROUTES.PRODUCT_LOG}/${ROUTES.ID}`,
          element: <ProductLogDetail />,
        },
      ],
    },
    {
      path: ROUTES.ROOT,
      element: (
        <ProtectedRoute condition={!currentUser} redirectTo={ROUTES.ROOT}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        { path: ROUTES.LOGIN, element: <Login role={ROLE.ADVISOR} /> },
        {
          path: ROUTES.FORGOT_PASSWORD,
          element: <ForgotPassword size={ROLE.ADVISOR} />,
        },
      ],
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword size={ROLE.ADVISOR} />,
    },
    {
      path: ROUTES.PASSWORD_SETTING,
      element: <ResetPassword size={ROLE.ADVISOR} />,
    },
    {
      path: ROUTES.VERIFY_EMAIL,
      element: <VerifyEmail size={ROLE.ADVISOR} />,
    },
    {
      path: ROUTES.EXTERNAL_AUTH,
      element: <ExternalAuthen />,
    },
    {
      path: ADMIN_ROUTES.ROOT,
      element: (
        <ProtectedRoute condition={!currentUser} redirectTo={ADMIN_ROUTES.ROOT}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          path: ADMIN_ROUTES.ROOT,
          element: <Navigate to={ADMIN_ROUTES.LOGIN} />,
        },
        { path: ADMIN_ROUTES.LOGIN, element: <Login role={ROLE.ADMIN} /> },
        {
          path: ADMIN_ROUTES.FORGOT_PASSWORD,
          element: <ForgotPassword size={ROLE.ADMIN} />,
        },
      ],
    },
    {
      path: ADMIN_ROUTES.RESET_PASSWORD,
      element: <ResetPassword size={ROLE.ADMIN} />,
    },
    {
      path: ADMIN_ROUTES.PASSWORD_SETTING,
      element: <ResetPassword size={ROLE.ADMIN} />,
    },
    {
      path: ADMIN_ROUTES.VERIFY_EMAIL,
      element: <VerifyEmail size={ROLE.ADMIN} />,
    },
  ])
  if (loading) {
    return <Loading />
  }

  return <RouterProvider router={router} />
}
