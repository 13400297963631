import React, { FC } from 'react'
import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import {
  ChatBotIcon,
  StarLightActive,
  StarLightInActive,
  PenSolidIcon,
  MessageIcon,
  FavouriteActiveIcon,
  FavouriteInActiveIcon,
} from 'components/fromAdvisor/elements/Icons'
import { RATING } from 'constant/conmon'
import styled from '@emotion/styled'
import ReactMarkdown from 'components/molecules/ReactMarkdown'
import { AnswerBoxProps } from './MainChat.types'
import { FavoriteSaveModal } from './FavoriteSaveModal'

/**
 * AnswerBox component page.
 * @return {JSX.Element} AnswerBox component.
 */
export const AnswerBox: FC<AnswerBoxProps> = ({
  answerHistory = '',
  onClickIconEditAnswer,
  rating = 0,
  onRatingAnswer,
  onAskMore,
  isAskMore,
  isLast,
  isOverMaxTimesChat,
  isLoadingSendAskMore,
  historyId,
  conversationId,
  onSelectHistory,
  handleDeleteFavouriteAnswer,
  favoriteId,
  conversation,
}): React.ReactElement => {
  const theme = useTheme()
  const {
    isOpen: isFavoriteModal,
    onOpen: onFavoriteModal,
    onClose: closeFavoriteModal,
  } = useDisclosure()
  const isMaxAskMore =
    conversation?.chatHistories && conversation?.chatHistories?.length < 3
  return (
    <Flex flexDirection={'column'} gap={6}>
      <Flex
        bg={'gray.600'}
        borderRadius={'xl'}
        gap={6}
        h={'full'}
        p={'24px 24px 0 24px'}
        w={'full'}
      >
        <Box>
          <ChatBotIcon />
        </Box>
        <Box w={'calc(100% - 84px)'}>
          <Box
            color={'black.100'}
            fontWeight={'normal'}
            lineHeight={'30px'}
            w={'full'}
          >
            <Box>
              <ReactMarkdown
                data={answerHistory}
                isCopyBtn={true}
                isDone={true}
              />
            </Box>
          </Box>
          <Flex
            alignItems={'center'}
            gap={4}
            justifyContent={'flex-end'}
            mb={6}
            mt={6}
          >
            {!rating && (
              <Text
                color={'gray.800'}
                fontSize={'sm'}
                fontWeight={'normal'}
                lineHeight={theme.lineHeights.shorter}
                opacity={0.75}
              >
                この回答はどうでしたか？
              </Text>
            )}
            <Flex gap={0.5}>
              {RATING.map(item => (
                <IconButton
                  key={item.id}
                  _hover={{
                    transform: 'scale(1.2)',
                  }}
                  aria-label="star ratting"
                  bg={'transparent'}
                  h={5}
                  icon={
                    item.rating <= rating ? (
                      <StarLightActive />
                    ) : (
                      <StarLightInActive />
                    )
                  }
                  minW={5}
                  p={0}
                  onClick={() => onRatingAnswer(item.rating, historyId)}
                />
              ))}
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Flex alignItems={'center'} gap={4} justifyContent={'flex-end'}>
        {!isAskMore &&
          isLast &&
          !isOverMaxTimesChat &&
          !isLoadingSendAskMore &&
          isMaxAskMore && (
            <TooltipStyled label={'続質問する'} placement={'top'} px={4} py={2}>
              <IconButton
                _hover={{
                  transform: 'scale(1.2)',
                }}
                aria-label="ask many times"
                bg={'transparent'}
                h={5}
                icon={<MessageIcon />}
                minW={5}
                p={0}
                onClick={onAskMore}
              />
            </TooltipStyled>
          )}
        <IconButton
          _hover={{
            transform: 'scale(1.2)',
          }}
          aria-label="edit answer"
          bg={'transparent'}
          h={5}
          icon={
            favoriteId ? (
              <FavouriteActiveIcon />
            ) : (
              <FavouriteInActiveIcon fill={theme.colors.blue[900]} />
            )
          }
          minW={5}
          p={0}
          onClick={() => {
            if (!favoriteId) {
              onFavoriteModal()
            } else {
              handleDeleteFavouriteAnswer(favoriteId)
            }
          }}
        />
        <TooltipStyled label={'回答を編集する'} placement={'top'} px={4} py={2}>
          <IconButton
            _hover={{
              transform: 'scale(1.2)',
            }}
            aria-label="edit answer"
            bg={'transparent'}
            h={5}
            icon={<PenSolidIcon />}
            minW={5}
            p={0}
            onClick={() => {
              onSelectHistory(historyId)
              onClickIconEditAnswer()
            }}
          />
        </TooltipStyled>
      </Flex>
      <FavoriteSaveModal
        conversationId={conversationId}
        historyId={historyId}
        isOpen={isFavoriteModal}
        onClose={closeFavoriteModal}
      />
    </Flex>
  )
}

const TooltipStyled = styled(Tooltip)`
  background-color: ${props => props.theme.colors.black[800]};
  border-radius: ${props => props.theme.radii.lg};
  color: ${props => props.theme.colors.white[50]};
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: ${props => props.theme.letterSpacings.tighter};
  line-height: ${props => props.theme.lineHeights.shorter};
`
