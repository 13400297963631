const gray = {
  base: '#A5A5A5',
  100: '#F2F2F2',
  200: '#BDBDBD',
  300: '#E6E6E6',
  400: '#E0E0E0',
  500: '#F2F2F2',
  600: '#F3F5F7',
  650: 'rgba(220, 235, 250, 0.50)',
  700: '#CCD6DC',
  800: '#6C7275',
  850: '#8AA4B5',
  900: '#EEFDF5',
  1000: '#D0D0D0',
}

const red = {
  base: '#FFF9F8',
  100: '#FEF0ED',
  200: '#FDDBD3',
  300: '#FFB3B3',
  400: '#EC0A5B',
  500: '#E54E75',
  600: '#FFE7F1',
  650: '#FF5E5E',
  700: '#F75555',
  800: '#94412F',
  900: '#7B3728',
}

const orange = {
  base: '#FFFAF0',
  100: '#FFEF99',
  200: '#FBD38D',
  300: '#FFAF66',
  350: '#F9DEC3',
  400: '#ED8936',
  500: '#DD6B20',
  600: '#C05621',
  700: '#A66B52',
  800: '#7B341E',
  900: '#652B19',
}

const yellow = {
  base: '#FFFEE2',
  100: '#FFF96A',
  200: '#F4CC64',
  300: '#FAEFC0',
  400: '#fff96a',
  500: '#F4DF86',
  600: '#FFF96A',
  700: '#E6FF4E',
  800: '#B6A149',
  900: '#91813B',
}

const green = {
  base: '#FBFFF9',
  100: '#C3FFB4',
  200: '#B9E45E',
  300: '#7ECA90',
  400: '#BAF69E',
  500: '#0CAF60',
  600: '#87C36B',
  700: '#659250',
  800: '#2F7B8B',
  900: '#22311B',
  1000: '#0FB93E',
}

const teal = {
  base: '#E6FFFA',
  100: '#CAFFF2',
  200: '#81E6D9',
  300: '#6CEDC7',
  400: '#38B2AC',
  500: '#8CE9E3',
  600: '#2C7A7B',
  700: '#285E61',
  800: '#D4D9FF',
  900: '#8D99FF',
}

const blue = {
  base: '#B1E3FF',
  100: '#E0E1FF',
  200: '#E9EAFF',
  300: '#F1F2FF',
  400: '#6B70F0',
  500: '#363BB5',
  600: '#E4E7FF',
  700: '#363BB5',
  800: 'rgba(0, 132, 255, 0.1)',
  900: '#0084FF',
}

const cyan = {
  base: '#C7DDFF',
  100: '#C4F1F9',
  200: '#5772FF',
  300: '#BDD0FF',
  400: '#A8AFEB',
  500: '#00B5D8',
  600: '#4CBBDE',
  700: '#B7FFE5',
  800: 'rgba(107, 137, 201, 0.3)',
  900: '#065666',
}

const purple = {
  base: '#B9BFFC',
  100: '#C1C8FF',
  200: '#C6C7EB',
  250: '#E8ECEF',
  300: 'rgba(0, 132, 255, 0.10)',
  350: '#B1B3EE',
  400: '#454ea533',
  500: '#898CD9',
  600: '#6B78F0',
  700: '#F9D6FF',
  800: '#BF74ED',
  900: '#322659',
}

const tertiary = {
  base: '#B9BFFC',
  100: '#C1C8FF',
  200: '#E2A5FF',
  250: '#7680E0',
  300: '#F2EEFF',
  350: '#B1B3EE',
  400: '#454ea533',
  500: '#7759b6',
  600: '#6B78F0',
  700: '#F9D6FF',
  800: '#BF74ED',
  900: '#322659',
}
const danger = {
  base: '#B9BFFC',
  100: '#C1C8FF',
  200: '#E2A5FF',
  250: '#7680E0',
  300: '#F2EEFF',
  350: '#B1B3EE',
  400: '#454ea533',
  500: '#7759b6',
  600: '#6B78F0',
  700: '#F9D6FF',
  800: '#BF74ED',
  900: '#322659',
}

const pink = {
  base: '#FFF9F8',
  100: '#E690C4',
  200: '#FCDCD5',
  300: '#FF9ED9',
  400: '#E47EBC',
  500: '#F8A28F',
  600: '#F68B73',
  700: '#D17662',
  800: '#B96856',
  900: '#945345',
  light: '#FBE7EC',
}

const white = {
  base: '#FFFFFF',
  50: '#FEFEFE',
  100: '#FAFAFA',
  150: '#F4F7F9',
  200: 'rgba(255, 255, 255, 0.2)',
  300: 'rgba(255, 255, 255, 0.3)',
  500: 'rgba(255, 255, 255, 0.5)',
  600: 'rgba(255, 255, 255, 0.6)',
  700: 'rgba(255, 255, 255, 0.7)',
  800: 'rgba(255, 255, 255, 0.8)',
}

const black = {
  base: '#333333',
  50: '#343636',
  100: '#141718',
  150: 'rgba(0, 0, 0, 0.15)',
  200: 'rgba(0, 0, 0, 0.2)',
  250: 'rgba(20, 23, 24, 0.3)',
  300: '#2F3030',
  500: 'rgba(0, 0, 0, 0.5)',
  800: '#272727',
  900: '#000000',
  1000: 'rgba(20, 23, 24, 0.04)',
}

const overlay = {
  base: 'rgba(6, 5, 24, 0.8)',
}

const gradient = {
  background: 'linear-gradient(126.07deg, #FEF3FF 11.62%, #E6F0FF 55.26%)',
  pinkPurpleGradient:
    'linear-gradient(128.77deg, #FF84A3 0%, #4EACFF 89.2%, #198df3b5 89.2%)',
  textPinkPurpleGradient: 'linear-gradient(135deg, #FFA0B8 0%, #4EACFF 100%)',
  whiteBlueGradient: 'linear-gradient(180deg, #AAADF6 0%, #6B70F0 100%)',
  whiteLightPurpleGradient:
    'linear-gradient(153.67deg, rgba(255, 255, 255, 0.4) 12.68%, rgba(228, 232, 238, 0.4) 87.63%)',
  whiteLightPurpleBlueGradient:
    'linear-gradient(153.67deg, rgba(224, 222, 255, 0.4) 12.68%, rgba(187, 190, 255, 0.4) 87.63%)',
}
export const colors = {
  transparent: 'transparent',
  overlay,
  black,
  white,
  gray,
  red,
  orange,
  yellow,
  green,
  teal,
  blue,
  cyan,
  purple,
  pink,
  tertiary,
  danger,
  gradient,
}
