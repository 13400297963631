import { FC, ReactElement } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { User } from 'context/Auth/type'
import { Logout, PenLight } from 'components/atoms/Icons'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { storageServices, userAdminServices } from 'services'
import { useDispatch } from 'react-redux'
import { userLogout } from 'context/Auth/auth.slice'
import { removeBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'

/**
 * ProfileModal component page.
 * @return {JSX.Element} ProfileModal component.
 */
export const ProfileModal: FC<{
  isOpen: boolean
  onClose: () => void
  currentUser: User | null
}> = ({ isOpen, onClose, currentUser }): ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isAdmin = storageServices.getRoleUser() === ROLE.ADMIN
  const size = storageServices.getRoleUser() ?? ''

  /**
   * logout
   */
  const handleLogout = async () => {
    try {
      await userAdminServices.logout(size)
      storageServices.removeAccessToken()
      storageServices.removeRefreshToken()
      storageServices.removeRoleUser()
      dispatch(removeBreadcrumb())
      dispatch(userLogout())
      if (pathname.split('/')[1] === ROLE.ADMIN) {
        navigate(ADMIN_ROUTES.LOGIN)
      } else {
        navigate(ROUTES.LOGIN)
      }
    } catch (error) {}
  }
  /**
   * navigate
   */
  const handleNavigate = () => {
    onClose()
    navigate(!isAdmin ? ROUTES.MY_PAGE : ADMIN_ROUTES.MY_PAGE)
  }
  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      isShowIconClose={false}
      maxWidth={'328px'}
      onClose={() => {
        onClose()
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        padding="24px 48px 32px 48px"
      >
        <Avatar
          cursor="pointer"
          mt={'12px'}
          name="Admin"
          size="xl"
          src={currentUser?.avatar_url}
        />
        <Box mt="28px" textAlign="center">
          {currentUser?.entity_name && (
            <Text fontSize="14px" letterSpacing="-0.5px" lineHeight="24px">
              {currentUser.entity_name}
            </Text>
          )}
          {currentUser?.department && (
            <Text fontSize="14px" letterSpacing="-0.5px" lineHeight="24px">
              {currentUser.department}
            </Text>
          )}
          {currentUser?.position && (
            <Text fontSize="14px" letterSpacing="-0.5px" lineHeight="24px">
              {currentUser.position}
            </Text>
          )}
        </Box>
        {currentUser?.first_name && currentUser?.last_name && (
          <Text fontSize="20px" fontWeight="700" lineHeight="32px" my="16px">
            {currentUser?.first_name + ' ' + currentUser?.last_name}
          </Text>
        )}
        {currentUser?.email && (
          <Text fontSize="14px" letterSpacing="-0.5px" lineHeight="24px">
            {currentUser.email}
          </Text>
        )}
        <Flex
          alignItems="center"
          flexDirection="column"
          gap="24px"
          justify={'center'}
          mt="24px"
        >
          <Button
            backgroundColor="white.50"
            border="1px solid #0084FF"
            borderRadius="12px"
            color="blue.900"
            fontSize="14px"
            fontWeight="bold"
            height="40px"
            leftIcon={<PenLight fontSize="20px" />}
            text="アカウント情報を変更する"
            width="225px"
            onClick={handleNavigate}
          />
          <Button
            backgroundColor="rgba(0, 132, 255, 0.10)"
            border="none"
            borderRadius="12px"
            color="blue.900"
            fontSize="14px"
            fontWeight="bold"
            height="40px"
            leftIcon={<Logout fontSize="24px" />}
            text="ログアウト"
            width="149px"
            onClick={handleLogout}
          />
        </Flex>
      </Box>
    </Modal>
  )
}
