import React, { FC } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { SettingRules } from './tabs/SettingRules'
import { AutomaticReply } from './tabs/AutomaticReply'

const DEFAULT_TAB = 0

/**
 * VariousSettings component page.
 * @return {JSX.Element} VariousSettings component.
 */
export const VariousSettings: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const tabSettings = queryParams.get('tabSettings')
  const defaultIndex = tabSettings ? Number(tabSettings) : DEFAULT_TAB

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(navigate, `${ROUTES.CHAT_AI}`, 'tabSettings', index)
  }

  return (
    <Tabs
      isLazy
      defaultIndex={defaultIndex}
      variant="unstyled"
      onChange={handleChangeTab}
    >
      <TabList bg={'white.50'} pl={6}>
        <TabVarious
          _selected={{
            fontWeight: 'bold',
            color: 'black.100',
            bg: 'gray.600',
            borderRadius: '6xl',
          }}
        >
          制約・出力条件
        </TabVarious>
        <TabVarious
          _selected={{
            fontWeight: 'bold',
            color: 'black.100',
            bg: 'gray.600',
            borderRadius: '6xl',
          }}
        >
          自動返信
        </TabVarious>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <SettingRules />
        </TabPanel>
        <TabPanel p={0}>
          <AutomaticReply />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

const TabVarious = styled(Tab)`
  color: ${props => props.theme.colors.blue[900]};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: ${props => props.theme.lineHeights.shorter};
  letter-spacing: ${props => props.theme.letterSpacings.tighter};
  height: ${props => props.theme.sizes[10]};
  width: ${props => props.theme.sizes[34]};
  margin-top: ${props => props.theme.space[6]};
`
