import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { newServices, storageServices } from 'services'
import { Option } from 'types/conmon'
import { MultiValue } from 'react-select'
import { useDisclosure } from '@chakra-ui/react'
import { DataChartMoreType, DataChartType, InfoModalType } from 'types/chart'
import {
  WordCategoryChartResponse,
  WordLesChartResponse,
  WordTimeLineChartResponse,
} from 'services/companyServices'
import { options, optionsPdf } from './constant'

export type NewsDetailWordMapProps = {
  isOpenDrawer: boolean
  companyName: string | undefined
  typeNews: boolean
  year?: number
  quarter: number
  companyId?: number
  reportType: number
}

export interface FormValues {
  analysis_menu: string
  quarters: string
  term: string
  multi_company: MultiValue<Option>
  targetYear: string
  sort_by: number
}

export const useNewsDetailWordMap = (props: NewsDetailWordMapProps) => {
  const { isOpenDrawer, companyName, typeNews, year, quarter, reportType } =
    props
  const { id } = useParams()
  const allChartRef = useRef<any>(null)
  const sizeRef = useRef<any>(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const accessToken = storageServices.getAccessToken()
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const optionsAnalysisMenu = typeNews ? optionsPdf : options

  const formik = useFormik<FormValues>({
    initialValues: {
      analysis_menu: '0',
      quarters: '',
      targetYear: '',
      term: '',
      multi_company: [],
      sort_by: 1,
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { setFieldValue, values, setValues } = formik

  const [windowWidthSize, setWindowWidthSize] = useState(0)
  const [widthChart, setWidthChart] = useState(587)
  const [reloadChart, setReloadChart] = useState(false)
  const [valueSwitch, setValueSwitch] = useState(false)
  const [multiCompanyOptions, setMultiCompanyOptions] = useState<Option[]>([])

  const [infoModal, setInfoModal] = useState<InfoModalType>({
    isShowAll: false,
    typeChart: '',
    index: null,
  })

  const [dataChart, setDataChart] = useState<DataChartType>({
    wordLesChart: {
      dataWordCloud: [],
      dataEchart: [],
      hashed_data: '',
    },
    dataListWordCloud: {
      data: [],
      hashed_data: '',
    },
    dataTimeLine: {
      data: [],
      hashed_data: '',
    },
    loading: false,
  })

  const [dataChartMore, setDataChartMore] = useState<DataChartMoreType>({
    loading: false,
    data: [],
    hashed_data: '',
  })

  const [responseDataWordCloud, setResponseDataWordCloud] = useState<{
    option1: WordLesChartResponse | null
    option2: WordCategoryChartResponse | null
    option3: WordTimeLineChartResponse[]
  }>({
    option1: null,
    option2: null,
    option3: [],
  })

  const [valueExplanation, setValueExplanation] = useState<{
    loading: boolean
    data: string
    done: boolean
  }>({ loading: false, data: '', done: true })

  const {
    isOpen: isZoom,
    onOpen: onOpenZoom,
    onClose: onCloseZoom,
  } = useDisclosure()

  const containerChartWidth = React.useMemo(() => {
    const width = isOpenDrawer
      ? (windowWidthSize - 64) / 2 - 32
      : values.analysis_menu === '0'
      ? windowWidthSize - 128
      : (windowWidthSize - 144) / 2
    return Math.floor(width)
  }, [isOpenDrawer, values.analysis_menu, windowWidthSize])

  const containerChartHeight = React.useMemo(
    () => Math.floor(containerChartWidth / 1.58),
    [containerChartWidth],
  )

  const isNotWordmap = React.useMemo(
    () =>
      !dataChart.wordLesChart.dataEchart.length &&
      !dataChart.dataListWordCloud.data.length &&
      !dataChart.dataTimeLine.data.length &&
      !dataChart.wordLesChart.dataWordCloud.length &&
      !dataChartMore.data.length,
    [
      dataChart.dataListWordCloud.data.length,
      dataChart.dataTimeLine.data.length,
      dataChart.wordLesChart.dataEchart.length,
      dataChart.wordLesChart.dataWordCloud.length,
      dataChartMore.data.length,
    ],
  )

  const fileType = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        if (!typeNews) return 'file_text'
        return 'file_path'
      case '1':
        return 'company_industry'
      case '2':
        return 'company_year'
      default:
        return 'file_path'
    }
  }, [typeNews, values.analysis_menu])

  const payloadSendWebsocket = useMemo(() => {
    let payloadMoreChart = dataChartMore.data.map(item => ({
      [item.title]: item.words_cloud,
    }))

    const result = payloadMoreChart.reduce((acc, cur) => {
      return { ...acc, ...cur }
    }, {})

    switch (values.analysis_menu) {
      case '0':
        return responseDataWordCloud.option1

      case '1':
        return {
          ...responseDataWordCloud.option2,
          words_cloud: {
            ...responseDataWordCloud.option2?.words_cloud,
            ...result,
          },
        }

      case '2':
        return responseDataWordCloud.option3

      default:
        return {}
    }
  }, [
    dataChartMore.data,
    responseDataWordCloud.option1,
    responseDataWordCloud.option2,
    responseDataWordCloud.option3,
    values.analysis_menu,
  ])

  // fetchDataChart with news is string
  const fetchDataChart = useCallback(async () => {
    if (!id) return

    setDataChart(prev => ({
      ...prev,
      loading: true,
      wordLesChart: {
        dataEchart: [],
        dataWordCloud: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
    }))

    try {
      switch (values.analysis_menu) {
        case '0':
          const { data: data1, hashed_data: hashed_data_1 } =
            await newServices.getDataChartNewsString(+id)

          setDataChart(prev => ({
            ...prev,
            wordLesChart: {
              dataWordCloud: data1?.word_cloud ?? [],
              dataEchart: data1?.les_miserable ?? [],
              hashed_data: hashed_data_1 ?? '',
            },
            dataListWordCloud: {
              data: [],
              hashed_data: '',
            },
            dataTimeLine: {
              data: [],
              hashed_data: '',
            },
            loading: false,
          }))

          setResponseDataWordCloud(prev => ({ ...prev, option1: data1 }))

          break

        case '1':
          if (!year) break

          const { data: data2, hashed_data: hashed_data_2 } =
            await newServices.getWordCategoryChart({
              news_id: +id,
              type_of_process: reportType,
              year: year,
              quarter: quarter,
            })

          const convertData2 = Object.keys(data2.words_cloud).map(key => ({
            title: key,
            words_cloud: data2.words_cloud[key],
          }))

          const arrCompany = data2.companies_ability_to_compare.map(o => ({
            label: Object.keys(o)[0],
            value: o[Object.keys(o)[0]],
          }))

          setMultiCompanyOptions(arrCompany)
          setDataChart(prev => ({
            ...prev,
            wordLesChart: {
              dataWordCloud: [],
              dataEchart: [],
              hashed_data: '',
            },
            dataListWordCloud: {
              data: convertData2,
              hashed_data: hashed_data_2 ?? '',
            },
            dataTimeLine: {
              data: [],
              hashed_data: '',
            },
            loading: false,
          }))

          setResponseDataWordCloud(prev => ({ ...prev, option2: data2 }))

          break

        case '2':
          if (!values.term) {
            setDataChart(prev => ({
              ...prev,
              loading: false,
              wordLesChart: {
                dataEchart: [],
                dataWordCloud: [],
                hashed_data: '',
              },
              dataListWordCloud: {
                data: [],
                hashed_data: '',
              },
              dataTimeLine: {
                data: [],
                hashed_data: '',
              },
            }))
            break
          }
          const { data: data3, hashed_data: hashed_data_3 } =
            await newServices.getWordCloudTimeLineChart({
              news_id: +id,
              type_of_process: reportType,
              previous_times: +values.term,
              year: year,
            })

          const convertData = data3.sort(
            (a: { index: number }, b: { index: number }) => {
              if (values.sort_by === 1) {
                return a.index - b.index
              } else {
                return b.index - a.index
              }
            },
          )

          setDataChart(prev => ({
            ...prev,
            wordLesChart: {
              dataWordCloud: [],
              dataEchart: [],
              hashed_data: '',
            },
            dataListWordCloud: {
              data: [],
              hashed_data: '',
            },
            dataTimeLine: {
              data: convertData,
              hashed_data: hashed_data_3 ?? '',
            },
            loading: false,
          }))

          setResponseDataWordCloud(prev => ({ ...prev, option3: data3 }))

          break

        default:
          break
      }
    } catch (error) {
      setDataChart(prev => ({
        ...prev,
        loading: false,
        wordLesChart: {
          dataEchart: [],
          dataWordCloud: [],
          hashed_data: '',
        },
        dataListWordCloud: {
          data: [],
          hashed_data: '',
        },
        dataTimeLine: {
          data: [],
          hashed_data: '',
        },
      }))
      console.log('error', error)
    }
  }, [
    id,
    quarter,
    reportType,
    values.analysis_menu,
    values.sort_by,
    values.term,
    year,
  ])

  const fetchMoreWordCloud = useCallback(async () => {
    if (!id || !values.multi_company || !year) return

    let listCompanySelected = values.multi_company.map(item => item.label)
    let listCompanyData = dataChartMore.data.map(item => item.title)
    let companyRemove = listCompanyData.find(
      item => !listCompanySelected.includes(item),
    )
    let companyAdd = listCompanySelected.find(
      item => !listCompanyData.includes(item),
    )
    let idCompanyAdd = values.multi_company.find(
      item => item.label === companyAdd,
    )?.value

    if (companyRemove) {
      setDataChartMore(prev => ({
        ...prev,
        loading: false,
        data: prev.data.filter(item => item.title !== companyRemove),
        hashed_data: '',
      }))
      return
    }

    if (!idCompanyAdd) return

    try {
      setDataChartMore(prev => ({
        ...prev,
        loading: true,
      }))

      const response = await newServices.getWordCloudMoreChart({
        news_id: +id,
        type_of_process: reportType,
        year: year,
        quarter: quarter,
        compare_to: +idCompanyAdd,
      })

      let newCompanyName = Object.keys(response.data)[0]

      setDataChartMore(prev => ({
        ...prev,
        data: prev.data.concat({
          title: newCompanyName,
          words_cloud: response.data[`${newCompanyName}`],
        }),
        loading: false,
        hashed_data: prev.hashed_data + response.hashed_data,
      }))
    } catch (error) {
      setDataChartMore(prev => ({
        ...prev,
        loading: false,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, quarter, reportType, values.multi_company, year])

  const handleOffSwitch = () => {
    setValueSwitch(false)
    setValueExplanation(prev => ({
      ...prev,
      loading: false,
      data: '',
      done: true,
    }))

    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
  }

  const handleResetForm = (
    e:
      | {
          label: string
          value: string | number
        }
      | undefined,
  ) => {
    if (e?.value === values.analysis_menu) return

    setValues({
      analysis_menu: String(e?.value),
      quarters: '',
      targetYear: '',
      term: '',
      multi_company: [],
      sort_by: 1,
    })
    handleResetDataChart()
    handleOffSwitch()
  }

  const handleSwitch = async () => {
    if (!companyName || isNotWordmap) return

    if (valueSwitch) {
      handleOffSwitch()
    } else {
      if (valueExplanation.loading) return

      if (values.analysis_menu === '2' && !values.term) return

      if (isNotWordmap) return

      setValueSwitch(true)
      setReloadChart(!reloadChart)
      setValueExplanation(prev => ({
        ...prev,
        loading: true,
        data: '',
        done: false,
      }))
    }
  }

  const handleResetDataChart = () => {
    setDataChart(prev => ({
      ...prev,
      wordLesChart: {
        dataEchart: [],
        dataWordCloud: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
      loading: false,
    }))
    setDataChartMore(prev => ({
      ...prev,
      data: [],
      loading: false,
      hashed_data: '',
    }))
  }

  const handleOpenZoom = (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => {
    setInfoModal(info)
    onOpenZoom()
  }

  const handleCloseZoom = () => {
    onCloseZoom()
  }

  useEffect(() => {
    if (!boxRef.current?.offsetWidth) return

    setWidthChart(boxRef.current?.offsetWidth)
  }, [isOpenDrawer])

  useEffect(() => {
    fetchMoreWordCloud()
  }, [fetchMoreWordCloud])

  useEffect(() => {
    fetchDataChart()
  }, [fetchDataChart])

  const hashedDataChart = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        return dataChart.wordLesChart.hashed_data

      case '1':
        return (
          dataChart.dataListWordCloud.hashed_data + dataChartMore.hashed_data
        )

      case '2':
        return dataChart.dataTimeLine.hashed_data

      default:
        return ''
    }
  }, [
    dataChart.dataListWordCloud.hashed_data,
    dataChart.dataTimeLine.hashed_data,
    dataChart.wordLesChart.hashed_data,
    dataChartMore.hashed_data,
    values.analysis_menu,
  ])

  useEffect(() => {
    if (!accessToken || isNotWordmap || !valueSwitch) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/list_new_file/summary_image/ws_stream`,
    )

    const handleSocketOpen = async () => {
      console.log('WS connected')
      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }
      newSocket.send(JSON.stringify(messAuthen))

      let message = {
        type: 'stream_req',
        data: {
          type_image: fileType,
          type_of_process: 4,
          company: companyName,
          hashed_data: hashedDataChart,
          data: payloadSendWebsocket,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)

      if (objMess?.done === 1) {
        setValueExplanation(prev => ({
          ...prev,
          done: true,
          data: prev.data.replace(/```/g, ''),
        }))
      } else {
        switch (objMess.type) {
          case 'stream_res':
            setValueExplanation(prev => ({
              ...prev,
              loading: false,
              data: (prev.data + objMess?.data).replace(/```html/g, ''),
            }))
            break
          default:
            break
        }
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('close', handleSocketClose)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.close()
    }
  }, [
    accessToken,
    companyName,
    fileType,
    hashedDataChart,
    isNotWordmap,
    payloadSendWebsocket,
    valueSwitch,
    values.analysis_menu,
  ])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(document.documentElement.clientWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    ...props,
    isOpenDrawer,
    formik,
    boxRef,
    optionsAnalysisMenu,
    setFieldValue,
    values,
    dataChart,
    widthChart,
    multiCompanyOptions,
    dataChartMore,
    valueSwitch,
    setValueSwitch,
    handleResetForm,
    handleSwitch,
    valueExplanation,
    handleOffSwitch,
    infoModal,
    isZoom,
    sizeRef,
    handleCloseZoom,
    handleOpenZoom,
    reloadChart,
    isNotWordmap,
    allChartRef,
    containerChartHeight,
    containerChartWidth,
  }
}
