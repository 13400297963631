import dayjs from 'dayjs'
import { ConversationResponse } from 'services/chatAIServices'
import { ConversationListConverted } from './ChatHistory.types'

/**
 * @returns function that handle convert conversation
 */
export function convertConversationListResponse(
  responses?: ConversationResponse[],
): ConversationListConverted[] | undefined {
  if (!responses?.length) return undefined

  const today = dayjs().format('YYYY-MM-DD')

  // Map histories by date
  const conversationMap = responses.reduce((map, conversation) => {
    const date = conversation.updated_at
      ? conversation.updated_at.substring(0, 10)
      : undefined
    const formattedDate = date
      ? date === today
        ? '今日'
        : dayjs(date).format('YYYY/MM/DD')
      : undefined

    if (!date || !formattedDate) return map

    const existingConversation = map.get(date)

    const conversationConverted = {
      conversationId: conversation.id,
      title: conversation.title,
      bookmarked: conversation.bookmarked,
      createTime: conversation.created_at,
      updateTime: conversation.updated_at,
    }

    if (existingConversation) {
      existingConversation.conversationDetail.push(conversationConverted)
    } else {
      map.set(date, {
        userId: conversation.user_id,
        date: formattedDate,
        conversationDetail: [conversationConverted],
      })
    }

    return map
  }, new Map<string, ConversationListConverted>())

  // Sort conversationDetail within each history by updateTime
  const sortConversationList = Array.from(conversationMap.values()).map(
    item => ({
      ...item,
      conversationDetail: item.conversationDetail.sort((a, b) => {
        const aUpdateTime = dayjs(a.updateTime)
        const bUpdateTime = dayjs(b.updateTime)

        if (aUpdateTime.isSame(bUpdateTime, 'hour')) {
          return aUpdateTime.isBefore(bUpdateTime) ? 1 : -1
        }

        return bUpdateTime.diff(aUpdateTime)
      }),
    }),
  )

  // Sort sortConversationList by date and updateTime
  return sortConversationList.sort((a, b) => {
    if (a.date === 'Today' && b.date !== 'Today') return -1
    if (a.date !== 'Today' && b.date === 'Today') return 1

    const aUpdateTime = dayjs(a.conversationDetail[0].updateTime)
    const bUpdateTime = dayjs(b.conversationDetail[0].updateTime)

    return bUpdateTime.diff(aUpdateTime)
  })
}
