import { DataBreadCrumbType } from 'components/molecules/Header/hook'
import { ADMIN_ROUTES, ROUTES } from './router'

export const OFFSET_INDEX = 1

export const SORT_OPTIONS = { ASC: 'asc', DESC: 'desc' }

export const optionsDate = [
  { label: '本日', value: '0' },
  { label: '1週間(7日間)', value: '1' },
  { label: '1カ月(30日間)', value: '2' },
]

export const RATING = Array(5)
  .fill(null)
  .map((_, index) => ({
    id: index + OFFSET_INDEX,
    rating: index + OFFSET_INDEX,
  }))

export const regexKana = /^[\u30A0-\u30FF\uFF5F-\uFF9F]*$/

export const dataBreadcrumb: DataBreadCrumbType[] = [
  {
    path: ROUTES.CHAT_AI,
    breadcrumb: [{ text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI }],
    tab: '?tab=0',
  },
  {
    path: ROUTES.DASHBOARD,
    breadcrumb: [{ text: 'ダッシュボード', link: ROUTES.DASHBOARD }],
    tab: '',
  },
  {
    path: ROUTES.MY_PAGE,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'アカウント情報', link: ROUTES.MY_PAGE },
    ],
    tab: '',
  },
  {
    path: ROUTES.CARD_NEWS,
    breadcrumb: [
      { text: 'HOME（ダッシュボード）', link: ROUTES.DASHBOARD },
      { text: '記事検索', link: ROUTES.CARD_NEWS },
    ],
    tab: '',
  },
  {
    path: ROUTES.CHAT_AI,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'プロンプト集', link: `${ROUTES.CHAT_AI}?tab=1` },
    ],
    tab: '?tab=1',
  },
  {
    path: ROUTES.CHAT_AI,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'Myプロンプト', link: `${ROUTES.CHAT_AI}?tab=2` },
    ],
    tab: '?tab=2',
  },
  {
    path: ROUTES.CHAT_AI,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'お気に入り', link: `${ROUTES.CHAT_AI}?tab=3` },
    ],
    tab: '?tab=3',
  },
  {
    path: ROUTES.PROMPT_DEATAIL,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'Myプロンプト', link: `${ROUTES.CHAT_AI}?tab=2` },
    ],
    tab: '',
    id: true,
  },
  {
    path: ROUTES.PROMPT_EDIT,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'Myプロンプト', link: `${ROUTES.CHAT_AI}?tab=2` },
    ],
    tab: '',
    id: true,
  },
  {
    path: ROUTES.CHAT_AI,
    breadcrumb: [
      { text: 'HOME（AIチャット）', link: ROUTES.CHAT_AI },
      { text: 'Myプロンプト', link: `${ROUTES.CHAT_AI}?tab=2` },
    ],
    tab: '',
  },
  {
    path: ROUTES.POST_DETAIL,
    breadcrumb: [{ text: 'HOME（ダッシュボード）', link: ROUTES.DASHBOARD }],
    tab: '',
    id: true,
  },
  {
    path: ROUTES.COMPANY,
    breadcrumb: [{ text: 'HOME（ダッシュボード）', link: ROUTES.DASHBOARD }],
    tab: '',
    id: true,
  },
  // role admin
  {
    path: ADMIN_ROUTES.ROOT,
    breadcrumb: [{ text: 'HOME', link: ADMIN_ROUTES.ROOT }],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.MY_PAGE,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'アカウント情報', link: ADMIN_ROUTES.MY_PAGE },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.USER_LIST,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'ユーザー一覧', link: ADMIN_ROUTES.USER_LIST },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.USER_REGISTRATION,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'ユーザー一覧', link: ADMIN_ROUTES.USER_LIST },
      { text: '新規ユーザー登録', link: ADMIN_ROUTES.USER_REGISTRATION },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.USER_DETAIL,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'ユーザー一覧', link: ADMIN_ROUTES.USER_LIST },
    ],
    tab: '',
    id: true,
  },
  {
    path: ADMIN_ROUTES.AI_SETTING,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'AI条件設定', link: ADMIN_ROUTES.AI_SETTING },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PROMPT_SETTINGS,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'プロンプト設定', link: ADMIN_ROUTES.PROMPT_SETTINGS },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PROMPT_REGISTRATION,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'プロンプト設定', link: ADMIN_ROUTES.PROMPT_SETTINGS },
      { text: '定形プロンプト登録', link: ADMIN_ROUTES.PROMPT_REGISTRATION },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PROMPT_REGISTRATION_COMPANY,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'プロンプト設定', link: ADMIN_ROUTES.PROMPT_SETTINGS },
      { text: '定形プロンプト登録', link: ADMIN_ROUTES.PROMPT_REGISTRATION },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PROMPT_REGISTRATION_NEWS,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'プロンプト設定', link: ADMIN_ROUTES.PROMPT_SETTINGS },
      { text: '定形プロンプト登録', link: ADMIN_ROUTES.PROMPT_REGISTRATION },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PROMPT_DEATAIL,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'プロンプト設定', link: ADMIN_ROUTES.PROMPT_SETTINGS },
    ],
    tab: '',
    id: true,
  },
  {
    path: ADMIN_ROUTES.PROMPT_EDIT,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'プロンプト設定', link: ADMIN_ROUTES.PROMPT_SETTINGS },
    ],
    tab: '',
    id: true,
  },
  {
    path: ADMIN_ROUTES.COMPANY_LIST,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '企業データ連携', link: ADMIN_ROUTES.COMPANY_LIST },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.COMPANY_REGISTRATION,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '企業データ連携', link: ADMIN_ROUTES.COMPANY_LIST },
      { text: '新規企業登録', link: ADMIN_ROUTES.COMPANY_REGISTRATION },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.COMPANY,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '企業データ連携', link: ADMIN_ROUTES.COMPANY_LIST },
    ],
    tab: '',
    id: true,
  },
  {
    path: ADMIN_ROUTES.COMPANY_EDIT,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '企業データ連携', link: ADMIN_ROUTES.COMPANY_LIST },
    ],
    tab: '',
    id: true,
  },
  {
    path: ADMIN_ROUTES.PRODUCT_LIST,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '商品設定', link: ADMIN_ROUTES.PRODUCT_LIST },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PRODUCT_REGISTRATION,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '商品設定', link: ADMIN_ROUTES.PRODUCT_LIST },
      { text: '新規商品登録', link: ADMIN_ROUTES.PRODUCT_REGISTRATION },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PRODUCT_DETAIL,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '商品設定', link: ADMIN_ROUTES.PRODUCT_LIST },
    ],
    tab: '',
    id: true,
  },
  {
    path: ADMIN_ROUTES.ACCESS_LOG,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: 'アクセスログ', link: '' },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PRODUCT_LOG,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '商品設定', link: ADMIN_ROUTES.PRODUCT_LIST },
      { text: 'アップロードログ', link: ADMIN_ROUTES.PRODUCT_LOG },
    ],
    tab: '',
  },
  {
    path: ADMIN_ROUTES.PRODUCT_LOG,
    breadcrumb: [
      { text: 'HOME', link: ADMIN_ROUTES.ROOT },
      { text: '商品設定', link: ADMIN_ROUTES.PRODUCT_LIST },
      { text: 'アップロードログ', link: ADMIN_ROUTES.PRODUCT_LOG },
    ],
    tab: '',
    id: true,
  },
]
// data word cloud
export const words = [
  {
    text: '決算短信',
    value: 12,
  },
  {
    text: 'リース割賦',
    value: 11,
  },
  {
    text: '芙蓉総合',
    value: 10,
  },
  {
    text: '総合リース',
    value: 10,
  },
  {
    text: 'リース決算',
    value: 9,
  },
  {
    text: '損益計算',
    value: 8,
  },
  {
    text: '減損損失',
    value: 7,
  },
  {
    text: '割賦ファイナンス',
    value: 6,
  },
  {
    text: '固定減損',
    value: 4,
  },
  {
    text: '負のれん',
    value: 4,
  },
  {
    text: '発生益',
    value: 4,
  },
  {
    text: '損失のれん',
    value: 4,
  },
  {
    text: '包括計算',
    value: 4,
  },
  {
    text: 'のれん発生',
    value: 4,
  },
  {
    text: '無形固定',
    value: 4,
  },
  {
    text: '信託bbt',
    value: 3,
  },
  {
    text: '損失引当',
    value: 3,
  },
  {
    text: '決算補足',
    value: 3,
  },
  {
    text: 'オペレーティングリース',
    value: 3,
  },
  {
    text: '説明資料',
    value: 3,
  },
  {
    text: 'ファイナンス売上',
    value: 3,
  },
  {
    text: '説明p',
    value: 3,
  },
  {
    text: '債務保証',
    value: 3,
  },
  {
    text: '計算p',
    value: 3,
  },
  {
    text: '補足説明',
    value: 3,
  },
  {
    text: '割賦リース',
    value: 3,
  },
  {
    text: '割賦債権',
    value: 3,
  },
  {
    text: '至売上',
    value: 3,
  },
  {
    text: '表示増減',
    value: 3,
  },
  {
    text: '実行残高',
    value: 3,
  },
  {
    text: '債権流動',
    value: 3,
  },
  {
    text: '保証損失',
    value: 3,
  },
  {
    text: '貸借対照',
    value: 2,
  },
  {
    text: '役職名',
    value: 2,
  },
  {
    text: 'コマーシャルペーパー',
    value: 2,
  },
  {
    text: '長期支払',
    value: 2,
  },
  {
    text: '調整勘定',
    value: 2,
  },
  {
    text: 'ファイナンスリース',
    value: 2,
  },
  {
    text: 'bbtq',
    value: 2,
  },
  {
    text: '売上損失',
    value: 2,
  },
  {
    text: '固定無形',
    value: 2,
  },
  {
    text: '組合運用',
    value: 2,
  },
  {
    text: '名称増減',
    value: 2,
  },
  {
    text: '流動支払',
    value: 2,
  },
  {
    text: '流動長期',
    value: 2,
  },
  {
    text: '固定賃貸',
    value: 2,
  },
  {
    text: '増減リース',
    value: 2,
  },
  {
    text: '賞与引当',
    value: 2,
  },
  {
    text: '予測説明',
    value: 2,
  },
  {
    text: 'リース賃貸',
    value: 2,
  },
  {
    text: '調整全社',
    value: 2,
  },
  {
    text: '繰延ヘッジ',
    value: 2,
  },
  {
    text: 'のれん固定',
    value: 2,
  },
  {
    text: '債権割賦',
    value: 2,
  },
  {
    text: '販売固定',
    value: 2,
  },
  {
    text: '債権リース',
    value: 2,
  },
  {
    text: '貸倒引当',
    value: 2,
  },
  {
    text: '退職調整',
    value: 2,
  },
  {
    text: 'リース債務',
    value: 2,
  },
  {
    text: 'リースオペレーティング',
    value: 2,
  },
  {
    text: 'ヘッジ損益',
    value: 2,
  },
  {
    text: 'のれん負',
    value: 2,
  },
  {
    text: 'p損益',
    value: 2,
  },
  {
    text: '財政説明',
    value: 2,
  },
  {
    text: '修正表示',
    value: 2,
  },
  {
    text: '引当流動',
    value: 2,
  },
  {
    text: '修正有無',
    value: 2,
  },
  {
    text: '増減売上',
    value: 2,
  },
  {
    text: '計算至',
    value: 2,
  },
  {
    text: '支払債務',
    value: 2,
  },
  {
    text: '計算包括',
    value: 2,
  },
  {
    text: '計算全社',
    value: 2,
  },
  {
    text: '包括差額',
    value: 2,
  },
  {
    text: '損失減損',
    value: 2,
  },
  {
    text: '損失損益',
    value: 2,
  },
  {
    text: '損失リース',
    value: 2,
  },
  {
    text: '割賦実行',
    value: 2,
  },
  {
    text: '割賦控除',
    value: 2,
  },
  {
    text: '控除表示',
    value: 2,
  },
  {
    text: '決算説明',
    value: 2,
  },
  {
    text: '勘定退職',
    value: 2,
  },
  {
    text: '残高構成',
    value: 2,
  },
  {
    text: '差異調整',
    value: 2,
  },
  {
    text: '売上振替',
    value: 2,
  },
  {
    text: '計算差額',
    value: 2,
  },
  {
    text: '損益調整',
    value: 2,
  },
  {
    text: '公表修正',
    value: 2,
  },
  {
    text: '増減残高',
    value: 2,
  },
  {
    text: '直近公表',
    value: 2,
  },
  {
    text: '引当債務',
    value: 2,
  },
  {
    text: '変更修正',
    value: 2,
  },
  {
    text: '益負',
    value: 2,
  },
  {
    text: '益芙蓉',
    value: 2,
  },
  {
    text: '実行構成',
    value: 2,
  },
  {
    text: '差額繰延',
    value: 2,
  },
  {
    text: '対照表',
    value: 2,
  },
  {
    text: '差額差異',
    value: 2,
  },
  {
    text: '流動固定',
    value: 2,
  },
  {
    text: '振替損失',
    value: 2,
  },
  {
    text: '構成増減',
    value: 2,
  },
  {
    text: '全社損益',
    value: 2,
  },
  {
    text: '全社販売',
    value: 2,
  },
  {
    text: '上場東',
    value: 1,
  },
  {
    text: 'リース引当',
    value: 1,
  },
  {
    text: 'リース貸付',
    value: 1,
  },
  {
    text: 'レビュー利用',
    value: 1,
  },
  {
    text: '一定前提',
    value: 1,
  },
  {
    text: '上場名',
    value: 1,
  },
  {
    text: 'ii至',
    value: 1,
  },
  {
    text: '予定債権',
    value: 1,
  },
  {
    text: 'ファイナンスオペレーティング',
    value: 1,
  },
  {
    text: '予定年内',
    value: 1,
  },
  {
    text: 'のれん無形',
    value: 1,
  },
  {
    text: 'tdnet同日',
    value: 1,
  },
  {
    text: 'fgl役職',
    value: 1,
  },
  {
    text: 'i至',
    value: 1,
  },
  {
    text: 'リース債権',
    value: 1,
  },
  {
    text: 'リース実行',
    value: 1,
  },
  {
    text: '引当賞与',
    value: 1,
  },
  {
    text: 'リース上場',
    value: 1,
  },
  {
    text: 'メンテナンス引当',
    value: 1,
  },
  {
    text: '引当経過',
    value: 1,
  },
  {
    text: 'p前提',
    value: 1,
  },
  {
    text: '引当除去',
    value: 1,
  },
  {
    text: '慰労引当',
    value: 1,
  },
  {
    text: '戻入支払',
    value: 1,
  },
  {
    text: '建設仮',
    value: 1,
  },
  {
    text: '有無切捨て',
    value: 1,
  },
  {
    text: '包括内訳',
    value: 1,
  },
  {
    text: '務リース',
    value: 1,
  },
  {
    text: 'ファイナンス割賦',
    value: 1,
  },
  {
    text: 'ファイナンス実行',
    value: 1,
  },
  {
    text: 'ペーパー債権',
    value: 1,
  },
  {
    text: 'ペーパー発行',
    value: 1,
  },
  {
    text: 'ポイント予測',
    value: 1,
  },
  {
    text: 'ポイント剰余',
    value: 1,
  },
  {
    text: 'p予測',
    value: 1,
  },
  {
    text: '処分損',
    value: 1,
  },
  {
    text: 'p包括',
    value: 1,
  },
  {
    text: '利息発行',
    value: 1,
  },
  {
    text: '前提p',
    value: 1,
  },
  {
    text: '前提該当',
    value: 1,
  },
  {
    text: '前提達成',
    value: 1,
  },
  {
    text: '前渡賃貸',
    value: 1,
  },
  {
    text: '剰余ポイント',
    value: 1,
  },
  {
    text: '剰余包括',
    value: 1,
  },
  {
    text: '割賦売上',
    value: 1,
  },
  {
    text: '割賦賞与',
    value: 1,
  },
  {
    text: '創立開業',
    value: 1,
  },
  {
    text: '勇輔',
    value: 1,
  },
  {
    text: '勘定社用',
    value: 1,
  },
  {
    text: '内訳包括',
    value: 1,
  },
  {
    text: '公認レビュー',
    value: 1,
  },
  {
    text: '公表変更',
    value: 1,
  },
  {
    text: '引当固定',
    value: 1,
  },
  {
    text: '利用説明',
    value: 1,
  },
  {
    text: '利息受取',
    value: 1,
  },
  {
    text: 'p実行',
    value: 1,
  },
  {
    text: '切捨て表示',
    value: 1,
  },
  {
    text: 'p残高',
    value: 1,
  },
  {
    text: 'p芙蓉',
    value: 1,
  },
  {
    text: 'p著しい',
    value: 1,
  },
  {
    text: 'p補足',
    value: 1,
  },
  {
    text: 'p説明',
    value: 1,
  },
  {
    text: 'p財政',
    value: 1,
  },
  {
    text: 'p貸借',
    value: 1,
  },
  {
    text: 'q信託',
    value: 1,
  },
  {
    text: 'q期中',
    value: 1,
  },
  {
    text: 'q決算',
    value: 1,
  },
  {
    text: 'tel提出',
    value: 1,
  },
  {
    text: '予定支払',
    value: 1,
  },
  {
    text: '予定決算',
    value: 1,
  },
  {
    text: '予定長期',
    value: 1,
  },
  {
    text: '分配売却',
    value: 1,
  },
  {
    text: '引当戻入',
    value: 1,
  },
  {
    text: '原価売上',
    value: 1,
  },
  {
    text: '引当メンテナンス',
    value: 1,
  },
  {
    text: '売上ファイナンス',
    value: 1,
  },
  {
    text: '増減実行',
    value: 1,
  },
  {
    text: '固定繰延',
    value: 1,
  },
  {
    text: '包括芙蓉',
    value: 1,
  },
  {
    text: '匿名組合',
    value: 1,
  },
  {
    text: '予約芙蓉',
    value: 1,
  },
  {
    text: '参考年間',
    value: 1,
  },
  {
    text: '取立益',
    value: 1,
  },
  {
    text: '受取利息',
    value: 1,
  },
  {
    text: '受取差益',
    value: 1,
  },
  {
    text: '合理一定',
    value: 1,
  },
  {
    text: '同日芙蓉',
    value: 1,
  },
  {
    text: '名専務',
    value: 1,
  },
  {
    text: '名氏名',
    value: 1,
  },
  {
    text: '名芙蓉',
    value: 1,
  },
  {
    text: '問合せ先',
    value: 1,
  },
  {
    text: '実行損益',
    value: 1,
  },
  {
    text: '実行名称',
    value: 1,
  },
  {
    text: '固定処分',
    value: 1,
  },
  {
    text: '実行割賦',
    value: 1,
  },
  {
    text: '売上リース',
    value: 1,
  },
  {
    text: '売上包括',
    value: 1,
  },
  {
    text: '売上原価',
    value: 1,
  },
  {
    text: '売上実行',
    value: 1,
  },
  {
    text: '売上直近',
    value: 1,
  },
  {
    text: '売上財政',
    value: 1,
  },
  {
    text: '売上販売',
    value: 1,
  },
  {
    text: '売上過去',
    value: 1,
  },
  {
    text: '売却益',
    value: 1,
  },
  {
    text: '変更以外',
    value: 1,
  },
  {
    text: '変更特定',
    value: 1,
  },
  {
    text: '変更芙蓉',
    value: 1,
  },
  {
    text: '大きく決算',
    value: 1,
  },
  {
    text: '実行p',
    value: 1,
  },
  {
    text: '実行リース',
    value: 1,
  },
  {
    text: '固定のれん',
    value: 1,
  },
  {
    text: '固定剰余',
    value: 1,
  },
  {
    text: '年間直近',
    value: 1,
  },
  {
    text: '更新売上',
    value: 1,
  },
  {
    text: '更生債権',
    value: 1,
  },
  {
    text: '最高更新',
    value: 1,
  },
  {
    text: '損失固定',
    value: 1,
  },
  {
    text: '損匿名',
    value: 1,
  },
  {
    text: '提出予定',
    value: 1,
  },
  {
    text: 'bbt導入',
    value: 1,
  },
  {
    text: '岸田勇',
    value: 1,
  },
  {
    text: '差損組合',
    value: 1,
  },
  {
    text: '差益組合',
    value: 1,
  },
  {
    text: '平均q',
    value: 1,
  },
  {
    text: '平均計算',
    value: 1,
  },
  {
    text: '年内償還',
    value: 1,
  },
  {
    text: '年内支払',
    value: 1,
  },
  {
    text: '年内返済',
    value: 1,
  },
  {
    text: '包括潜在',
    value: 1,
  },
  {
    text: '更新設定',
    value: 1,
  },
  {
    text: '普通発行',
    value: 1,
  },
  {
    text: '固定破産',
    value: 1,
  },
  {
    text: '改正変更',
    value: 1,
  },
  {
    text: '固定長期',
    value: 1,
  },
  {
    text: '寛明問合せ',
    value: 1,
  },
  {
    text: '専務氏名',
    value: 1,
  },
  {
    text: '導入信託',
    value: 1,
  },
  {
    text: '損失損',
    value: 1,
  },
  {
    text: '損失調整',
    value: 1,
  },
  {
    text: '損損失',
    value: 1,
  },
  {
    text: '損減損',
    value: 1,
  },
  {
    text: '損益分配',
    value: 1,
  },
  {
    text: '損益売上',
    value: 1,
  },
  {
    text: '支払予定',
    value: 1,
  },
  {
    text: '支払利息',
    value: 1,
  },
  {
    text: '支払務',
    value: 1,
  },
  {
    text: '支払買掛',
    value: 1,
  },
  {
    text: '支払開始',
    value: 1,
  },
  {
    text: '入金貸倒',
    value: 1,
  },
  {
    text: '短信添付',
    value: 1,
  },
  {
    text: '以外変更',
    value: 1,
  },
  {
    text: '長期コマーシャル',
    value: 1,
  },
  {
    text: '残高間接',
    value: 1,
  },
  {
    text: '過去最高',
    value: 1,
  },
  {
    text: '運用損',
    value: 1,
  },
  {
    text: '連続更新',
    value: 1,
  },
  {
    text: '目次決算',
    value: 1,
  },
  {
    text: '退職繰延',
    value: 1,
  },
  {
    text: '退職慰労',
    value: 1,
  },
  {
    text: '返済予定',
    value: 1,
  },
  {
    text: '運用益',
    value: 1,
  },
  {
    text: '流動現金',
    value: 1,
  },
  {
    text: '残高調達',
    value: 1,
  },
  {
    text: '残高売上',
    value: 1,
  },
  {
    text: '期中平均',
    value: 1,
  },
  {
    text: '未収入金',
    value: 1,
  },
  {
    text: '未払税',
    value: 1,
  },
  {
    text: '達成約束',
    value: 1,
  },
  {
    text: '決算p',
    value: 1,
  },
  {
    text: '間接調達',
    value: 1,
  },
  {
    text: '氏名織田',
    value: 1,
  },
  {
    text: '著しい該当',
    value: 1,
  },
  {
    text: '繰延貸倒',
    value: 1,
  },
  {
    text: '表p',
    value: 1,
  },
  {
    text: '調達長期',
    value: 1,
  },
  {
    text: '調達残高',
    value: 1,
  },
  {
    text: '表示改正',
    value: 1,
  },
  {
    text: '調達ポイント',
    value: 1,
  },
  {
    text: '調達コマーシャル',
    value: 1,
  },
  {
    text: '調整財政',
    value: 1,
  },
  {
    text: '表流動',
    value: 1,
  },
  {
    text: '直接調達',
    value: 1,
  },
  {
    text: '短信公認',
    value: 1,
  },
  {
    text: '資料tdnet',
    value: 1,
  },
  {
    text: '氏名岸田',
    value: 1,
  },
  {
    text: '長期債権',
    value: 1,
  },
  {
    text: '東番号',
    value: 1,
  },
  {
    text: '構成実行',
    value: 1,
  },
  {
    text: '仮勘定',
    value: 1,
  },
  {
    text: '番号fgl',
    value: 1,
  },
  {
    text: '資料入手',
    value: 1,
  },
  {
    text: '発行調達',
    value: 1,
  },
  {
    text: '益債務',
    value: 1,
  },
  {
    text: '益債権',
    value: 1,
  },
  {
    text: '益損失',
    value: 1,
  },
  {
    text: '資料作成',
    value: 1,
  },
  {
    text: '発行普通',
    value: 1,
  },
  {
    text: '現金割賦',
    value: 1,
  },
  {
    text: '説明特記',
    value: 1,
  },
  {
    text: '購入割賦',
    value: 1,
  },
  {
    text: '潜在調整',
    value: 1,
  },
  {
    text: '特記記述',
    value: 1,
  },
  {
    text: '特定異動',
    value: 1,
  },
  {
    text: '物件価額',
    value: 1,
  },
  {
    text: '無表示',
    value: 1,
  },
  {
    text: '資料目次',
    value: 1,
  },
  {
    text: '発行平均',
    value: 1,
  },
  {
    text: '長期直接',
    value: 1,
  },
  {
    text: '発行差損',
    value: 1,
  },
  {
    text: '構成残高',
    value: 1,
  },
  {
    text: '残高ファイナンス',
    value: 1,
  },
  {
    text: '残高リース',
    value: 1,
  },
  {
    text: '開業繰延',
    value: 1,
  },
  {
    text: '残高名称',
    value: 1,
  },
  {
    text: '開始予定',
    value: 1,
  },
  {
    text: '開催有無',
    value: 1,
  },
  {
    text: '残高p',
    value: 1,
  },
  {
    text: '輔tel',
    value: 1,
  },
  {
    text: '注意資料',
    value: 1,
  },
  {
    text: '添付資料',
    value: 1,
  },
  {
    text: '異動作成',
    value: 1,
  },
  {
    text: '趣旨大きく',
    value: 1,
  },
  {
    text: '異動範囲',
    value: 1,
  },
  {
    text: '資料見通し',
    value: 1,
  },
  {
    text: '財政参考',
    value: 1,
  },
  {
    text: '著しいp',
    value: 1,
  },
  {
    text: '有無無',
    value: 1,
  },
  {
    text: '控除信託',
    value: 1,
  },
  {
    text: '責任役職',
    value: 1,
  },
  {
    text: '織田寛明',
    value: 1,
  },
  {
    text: '買掛短期',
    value: 1,
  },
  {
    text: '経過リース',
    value: 1,
  },
  {
    text: '貸付債権',
    value: 1,
  },
  {
    text: '組合損益',
    value: 1,
  },
  {
    text: '約束趣旨',
    value: 1,
  },
  {
    text: '範囲変更',
    value: 1,
  },
  {
    text: '税芙蓉',
    value: 1,
  },
  {
    text: '税割賦',
    value: 1,
  },
  {
    text: '社用固定',
    value: 1,
  },
  {
    text: '破産更生',
    value: 1,
  },
  {
    text: '短期年内',
    value: 1,
  },
  {
    text: '短信貸借',
    value: 1,
  },
  {
    text: '賃貸前渡',
    value: 1,
  },
  {
    text: '有無異動',
    value: 1,
  },
  {
    text: '有無決算',
    value: 1,
  },
  {
    text: '販売受取',
    value: 1,
  },
  {
    text: '債務未払',
    value: 1,
  },
  {
    text: '作成変更',
    value: 1,
  },
  {
    text: '作成有無',
    value: 1,
  },
  {
    text: '価額リース',
    value: 1,
  },
  {
    text: '債務リース',
    value: 1,
  },
  {
    text: '債務固定',
    value: 1,
  },
  {
    text: '包括予約',
    value: 1,
  },
  {
    text: '債務年内',
    value: 1,
  },
  {
    text: '債務繰延',
    value: 1,
  },
  {
    text: '債権取立',
    value: 1,
  },
  {
    text: '債権賃貸',
    value: 1,
  },
  {
    text: '債権退職',
    value: 1,
  },
  {
    text: '償還予定',
    value: 1,
  },
  {
    text: '先責任',
    value: 1,
  },
  {
    text: '入手合理',
    value: 1,
  },
  {
    text: '入手決算',
    value: 1,
  },
  {
    text: '短信補足',
    value: 1,
  },
  {
    text: '賃貸建設',
    value: 1,
  },
  {
    text: '賃貸物件',
    value: 1,
  },
  {
    text: '相当包括',
    value: 1,
  },
  {
    text: '該当i',
    value: 1,
  },
  {
    text: '説明開催',
    value: 1,
  },
  {
    text: '設定連続',
    value: 1,
  },
  {
    text: '記述注意',
    value: 1,
  },
  {
    text: '記述入手',
    value: 1,
  },
  {
    text: '計算損益',
    value: 1,
  },
  {
    text: '計算控除',
    value: 1,
  },
  {
    text: '調整包括',
    value: 1,
  },
  {
    text: '調整相当',
    value: 1,
  },
  {
    text: '見通し記述',
    value: 1,
  },
  {
    text: '調整税',
    value: 1,
  },
  {
    text: '補足実行',
    value: 1,
  },
  {
    text: '補足p',
    value: 1,
  },
  {
    text: '表示発行',
    value: 1,
  },
  {
    text: '至包括',
    value: 1,
  },
  {
    text: '該当著しい',
    value: 1,
  },
  {
    text: '説明実行',
    value: 1,
  },
  {
    text: '説明残高',
    value: 1,
  },
  {
    text: '短信包括',
    value: 1,
  },
  {
    text: '短信ii',
    value: 1,
  },
  {
    text: '短信上場',
    value: 1,
  },
  {
    text: '短信公表',
    value: 1,
  },
  {
    text: '説明決算',
    value: 1,
  },
  {
    text: '繰延創立',
    value: 1,
  },
  {
    text: '賃貸無形',
    value: 1,
  },
  {
    text: '短信損益',
    value: 1,
  },
  {
    text: '繰延退職',
    value: 1,
  },
  {
    text: '短信決算',
    value: 1,
  },
  {
    text: '短信流動',
    value: 1,
  },
  {
    text: '賃貸未収',
    value: 1,
  },
  {
    text: '短信前提',
    value: 1,
  },
  {
    text: '賃貸購入',
    value: 1,
  },
  {
    text: '繰延芙蓉',
    value: 1,
  },
  {
    text: '除去債務',
    value: 1,
  },
]

//data Card ranking
export const dataRanking = {
  title: 'トヨタ利益率 テスラ逆転　８四半期ぶり、ＨＶ販売増',
  postContent:
    'トヨタ自動車の2023年4～6月期の売上高純利益率は12.4%と、米テスラの10.8%を上回った。値上げやハイブリッド車(HV)の生産増が影響し、電気自動車(EV)も重視している。23年の純利益は前年同期比78%増の1兆3113億円。一方、テスラの純利益は約3700億円。トヨタは過去、テスラに利益率で逆転されていたが、円安やHVの売上増が業績を押し上げた。世界のHV市場でトヨタは約6割のシェアを持ち、26年には22年比で2倍の販売を見込む。テスラは価格を下げてシェア拡大を狙っているが、時価総額でトヨタを大きく上回る。将来的には、EVの赤字問題とCO2削減と収益の両立が焦点となる見通し。トヨタは「マルチパスウェイ（全方位戦略）」を掲げ、PHV戦略の重要性が増している。',
  likePost: false,
  datePost: '2023/08/24',
  score: 98.9,
  scoreChange: 1.5,
  sentiment: true,
  relatedCompanies: 'トヨタ自動車',
  sauce: 'ニュース',
  ranking: [
    {
      title: 'シンジケートローン',
      score: 6.6,
      scoreChange: 1.2,
    },
    {
      title: 'プロジェクトファイナンス',
      score: 6.3,
      scoreChange: 1.3,
    },
    {
      title: '従来型ローン',
      score: 5.6,
      scoreChange: -0.5,
    },
    {
      title: 'コミットメント・ライン',
      score: 5.1,
      scoreChange: -2.1,
    },

    {
      title: '不動産ファイナンス',
      score: 4.5,
      scoreChange: 0,
    },
    {
      title: 'M&Aファイナンス',
      score: 3.2,
      scoreChange: 0.3,
    },
    {
      title: '不動産流動化ファイナンス',
      score: 2.1,
      scoreChange: 0,
    },
    {
      title: '私募債',
      score: 2.1,
      scoreChange: -0.1,
    },
    {
      title: '売掛債権流動化',
      score: 0.8,
      scoreChange: -0.2,
    },
  ],
}
export const formatCurrency = (amount: number) => {
  const amountValue = amount.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')
  return amountValue
}
