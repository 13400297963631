import { FC, ReactElement, useState } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react'
import { useFormik, FormikProvider } from 'formik'
import { icons, regexPassword } from 'constant'
import { ChangePasswordType } from 'services/userServices'
import { userAdminServices } from 'services'
import { InputField } from 'components/atoms'
import { AxiosError } from 'axios'
import { changePasswordSchema, initialValuesChangePass } from '../validation'

/**
 * ModalChangePassword component page.
 * @return {JSX.Element} ModalChangePassword component.
 */
export const ModalChangePassword: FC<{
  isOpen: boolean
  onClose: () => void
  size: string
}> = ({ isOpen, onClose, size }): ReactElement => {
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [resError, setResError] = useState('')
  /**
   *
   */
  const handleChangePassword = async (values: ChangePasswordType) => {
    setLoading(true)
    try {
      await userAdminServices.changePassword(size, values)
      toast({
        status: 'success',
        description: 'パスワードの変更が完了しました',
      })
      onClose()
      resetForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error instanceof AxiosError) {
        if (error.response) {
          setResError(error.response.data?.detail)
        } else {
          setResError(error.message)
        }
      }
    }
  }
  const formik = useFormik({
    initialValues: initialValuesChangePass,
    validationSchema: changePasswordSchema,

    /**
     *
     */
    async onSubmit(values: ChangePasswordType) {
      handleChangePassword(values)
    },
  })
  const { handleSubmit, errors, dirty, values, setFieldValue, resetForm } =
    formik

  const checkPassInvalid = !values.new_password
    ? false
    : values.new_password.match(regexPassword)

  const checkPassLength = !values.new_password
    ? false
    : values.new_password.length >= 8 && values.new_password.length <= 20
  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxWidth={'423px'}
      onClose={() => {
        setResError('')
        onClose()
        resetForm()
      }}
    >
      <FormikProvider value={formik}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p="32px 40px"
        >
          <Text
            fontSize="20px"
            fontWeight="700"
            lineHeight="32px"
            marginTop="6px"
            textAlign="center"
          >
            パスワード変更
          </Text>
          {resError && (
            <Text
              color="red.700"
              fontSize="sm"
              fontWeight="medium"
              lineHeight="shorter"
              mt="32px"
              px="32px"
              textAlign="center"
              whiteSpace="break-spaces"
            >
              {resError}
            </Text>
          )}
          <Flex
            flexDirection="column"
            gap="40px"
            mt={resError ? '24px' : '32px'}
            width="100%"
          >
            <InputField
              error={Boolean(errors.old_password || resError)}
              errorText={errors.old_password}
              height="48px"
              label="現在のパスワード"
              name="old_password"
              sizeInput="14px"
              sizeLabel="16px"
              type="password"
              width="100%"
              onChange={e =>
                setFieldValue('old_password', e.target.value.replace(/ /g, ''))
              }
              onFocus={() => setResError('')}
            />
            <InputField
              error={Boolean(errors.new_password || resError)}
              errorText={errors.new_password}
              height="48px"
              label="新しいパスワード"
              name="new_password"
              sizeInput="14px"
              sizeLabel="16px"
              type="password"
              width="100%"
              onChange={e =>
                setFieldValue('new_password', e.target.value.replace(/ /g, ''))
              }
              onFocus={() => setResError('')}
            />
          </Flex>
          <Flex
            flexDirection="column"
            gap="16px"
            marginBottom="32px"
            marginTop="16px"
            width="100%"
          >
            <Text
              alignItems="center"
              color={checkPassLength ? '#0CAF60' : '#141718'}
              display="flex"
              fontSize="14px"
              gap="8px"
            >
              {checkPassLength ? (
                <Image src={icons.CHECK_CIRCLE_DONE} />
              ) : (
                <Image src={icons.CHECK_CIRCLE_FALSE} />
              )}
              8文字以上20文字以下
            </Text>
            <Text
              alignItems="center"
              color={checkPassInvalid ? '#0CAF60' : '#141718'}
              display="flex"
              fontSize="14px"
              gap="8px"
            >
              {checkPassInvalid ? (
                <Image src={icons.CHECK_CIRCLE_DONE} />
              ) : (
                <Image src={icons.CHECK_CIRCLE_FALSE} />
              )}
              大文字英字・小文字英字・数字の3種混在
            </Text>
            <InputField
              error={Boolean(errors.cf_password || resError)}
              errorText={errors.cf_password}
              height="48px"
              label="新しいパスワード（確認用）"
              name="cf_password"
              sizeInput="14px"
              sizeLabel="16px"
              type="password"
              width="100%"
              onChange={e =>
                setFieldValue('cf_password', e.target.value.replace(/ /g, ''))
              }
            />
          </Flex>
          <Button
            backgroundColor="#0084FF"
            border="1px solid #0084FF"
            borderRadius="12px"
            color="white.50"
            fontWeight={'bold'}
            height="48px"
            isDisabled={
              !dirty ||
              !checkPassLength ||
              !checkPassInvalid ||
              Object.keys(errors).length > 0
            }
            isLoading={loading}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            text="変更する"
            width="100%"
            onClick={() => handleSubmit()}
          />
          <Button
            backgroundColor="#FEFEFE"
            border="1px solid #0084FF"
            borderRadius="12px"
            color="#0084FF"
            fontWeight={'bold'}
            height="48px"
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            marginTop="16px"
            text="キャンセル"
            width="100%"
            onClick={() => {
              resetForm()
              onClose()
              setResError('')
            }}
          />
        </Box>
      </FormikProvider>
    </Modal>
  )
}
