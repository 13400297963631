import React from 'react'

type HashTagIconProps = {
  fill: string
}

/**
 * HashTagIcon icon
 * @constructor
 */
export const HashTagIcon = ({ fill }: HashTagIconProps) => (
  <svg
    fill="none"
    height="20"
    viewBox="-2 -2 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.385 4.114h-3.007L13.11.852c.083-.368-.118-.74-.447-.831-.332-.093-.665.131-.747.499l-.806 3.595H6.635L7.367.853c.082-.368-.119-.74-.448-.831-.334-.093-.664.131-.746.499l-.807 3.594h-3.11c-.34 0-.615.308-.615.686 0 .379.276.686.615.686h2.802l-1.129 5.028H.615c-.34 0-.615.307-.615.685 0 .379.276.686.615.686h3.007l-.732 3.262c-.083.367.118.74.447.831.05.014.1.021.15.021.276 0 .527-.209.597-.52l.806-3.594h4.475l-.732 3.262c-.082.367.119.74.448.831.05.014.1.021.15.021.275 0 .527-.209.596-.52l.807-3.594h3.11c.34 0 .615-.307.615-.685 0-.379-.276-.686-.615-.686h-2.802l1.129-5.028h3.314c.34 0 .615-.307.615-.686 0-.378-.276-.687-.615-.687zm-5.712 6.399H5.198l1.128-5.028h4.475l-1.128 5.028z"
      fill={fill}
    ></path>
  </svg>
)
