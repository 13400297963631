import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { DataTable } from 'components/atoms'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import moment from 'moment'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { productAdminServices } from 'services'
import { PaginateResponse } from 'types/conmon'

export const ChangeLogs: FC = () => {
  const [dataAccessLog, setDataAccessLog] = useState<{
    loading: boolean
    data: {
      id: string
      action: string
      status: string
      created_at: string
      new_content: {
        product_name: string
      }
    }[]
    last_updated?: string
  }>({ loading: false, data: [] })
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const toast = useToast()
  const columnHelper = createColumnHelper<{
    id: string
    action: string
    status: string
    created_at: string
    new_content: {
      product_name: string
    }
  }>()
  const getListProductLog = useCallback(async () => {
    try {
      setDataAccessLog(prev => ({ ...prev, loading: true }))
      const params = {
        page_size: pagination.page_size,
        page: pagination.page,
        sort_by: 'updated_at:desc',
      }
      const { data, paginate } =
        await productAdminServices.getProductChangeLogs(params)
      setDataAccessLog(prev => ({
        ...prev,
        data: data,
        loading: false,
      }))
      if (paginate) {
        setPagination(paginate)
      }
    } catch (error) {
      setDataAccessLog(prev => ({
        ...prev,
        loading: false,
      }))
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [pagination.page, pagination.page_size])

  const handleChangeLogs = useCallback(
    async (id: string, status: string) => {
      try {
        await productAdminServices.updateChangeLogs(id, {
          status: status,
        })
        getListProductLog()
        toast({
          status: 'success',
          description: 'Update successfully!',
        })
      } catch (error) {
        toast({
          status: 'error',
          description: 'error',
        })
      }
    },
    [getListProductLog, toast],
  )

  useEffect(() => {
    getListProductLog()
  }, [getListProductLog])

  const columns = [
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => {
        const japanDateTime = moment.utc(info.getValue()).tz('Asia/Tokyo')
        const japanTime = japanDateTime.format('HH:mm:ss')
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={japanTime ?? '-'}
            width="100%"
          />
        )
      },
      header: 'created_at',
      size: 105,
      meta: {
        minWidthColumn: '105px',
      },
    }),
    columnHelper.accessor('action', {
      id: 'action',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue()}
        />
      ),
      header: 'action',
      size: 320,
      meta: {
        minWidthColumn: '320px',
      },
    }),
    columnHelper.accessor('new_content', {
      id: 'new_content',
      cell: info => {
        const nameProduct = info.row.original.new_content.product_name
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={nameProduct}
          />
        )
      },
      header: 'product name',
      size: 320,
      meta: {
        minWidthColumn: '320px',
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      cell: info => {
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={info.getValue()}
          />
        )
      },
      header: 'status',
      size: 118,
      meta: {
        minWidthColumn: '118px',
      },
    }),
    columnHelper.accessor('id', {
      id: 'id',
      cell: info => {
        return (
          <Flex justifyContent="center">
            {info.row.original.status === 'pendding' && (
              <Menu>
                <MenuButton
                  aria-label="Options"
                  as={IconButton}
                  icon={<HamburgerIcon />}
                />
                <MenuList>
                  <MenuItem
                    onClick={() =>
                      handleChangeLogs(info.row.original.id, 'appvored')
                    }
                  >
                    Appvored
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleChangeLogs(info.row.original.id, 'rejected')
                    }
                  >
                    Rejected
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        )
      },
      header: 'Change Status',
      size: 118,
      meta: {
        minWidthColumn: '118px',
      },
    }),
  ]

  return (
    <>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minH="calc(100vh - 88px)"
        padding="12px 24px"
        w="100%"
      >
        <Flex align={'center'} justifyContent="space-between" mb="16px">
          <Flex alignItems="center" gap="24px">
            <Text
              fontSize="24px"
              fontWeight="700"
              letterSpacing="tighter"
              lineHeight="40px"
              minW="141px"
              padding="0 8px"
            >
              ChangeLogs
            </Text>
          </Flex>
        </Flex>
        <DataTable
          bgColorTable="white.50"
          columns={columns}
          data={dataAccessLog.data}
          isPagination={true}
          isScroll={true}
          isShowAction={false}
          loadingData={dataAccessLog.loading}
          pagination={pagination}
          rangePage={[15, 30, 50]}
          setPagination={setPagination}
        />
      </Box>
    </>
  )
}
