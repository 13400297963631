import { Box, Flex, Text } from '@chakra-ui/react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { FC } from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'

type UploadProductPdfModalProps = {
  isOpen: boolean
  fileName?: string
  onSubmit: () => void
  onClose: () => void
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
}
type Product = {
  product_name: string
  product_short_name: string
  description: string
  score_target: string
  category_name: string
  url: string
}
const UploadProductPdfModal: FC<UploadProductPdfModalProps> = ({
  isOpen,
  fileName,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
}) => {
  const convertToCSV = (data: Product[]) => {
    const header = Object.keys(data[0]).join(',')
    const rows = data.map((obj: any) => Object.values(obj).join(','))
    return header + '\n' + rows.join('\n')
  }
  const downloadCSV = () => {
    const data = [
      {
        product_name: 'product_name',
        product_short_name: 'product_short_name',
        description: 'description',
        score_target: '5',
        category_name: 'category_name',
        url: 'url',
      },
    ]
    const csvData = convertToCSV(data)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'product_example.csv'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={true}
      isOpen={isOpen}
      maxWidth="510px"
      onClose={onClose}
    >
      <Box bg="#F4F7F9" borderRadius={'20px'} p={'40px'}>
        <Text
          fontSize="24px"
          fontWeight={700}
          letterSpacing="-0.48px"
          lineHeight="40px"
          textAlign="center"
          w="full"
        >
          商品一括登録
        </Text>
        <Flex flexDirection="column" gap="32px" mt="32px">
          <Flex gap="8px">
            <Text
              bg="rgba(0, 132, 255, 0.20)"
              borderRadius="50%"
              color="#0084FF"
              fontSize="16px"
              fontWeight="600"
              h="32px"
              textAlign="center"
              w="32px"
            >
              1
            </Text>
            <Text display={'flex'} fontSize="14px">
              <Text
                color={'#0084FF'}
                cursor="pointer"
                textDecoration="underline"
                onClick={downloadCSV}
              >
                インポート用CSVファイル
              </Text>
              をダウンロード
            </Text>
          </Flex>
          <Flex gap="8px">
            <Text
              bg="rgba(0, 132, 255, 0.20)"
              borderRadius="50%"
              color="#0084FF"
              fontSize="16px"
              fontWeight="600"
              h="32px"
              textAlign="center"
              w="32px"
            >
              2
            </Text>
            <Text fontSize="14px">
              ファイルを開き、登録したい商品情報を入力して保存
            </Text>
          </Flex>
          <Flex gap="8px">
            <Text
              bg="rgba(0, 132, 255, 0.20)"
              borderRadius="50%"
              color="#0084FF"
              fontSize="16px"
              fontWeight="600"
              h="32px"
              textAlign="center"
              w="32px"
            >
              3
            </Text>
            <Text fontSize="14px">下記にファイルをインポートしてください</Text>
          </Flex>
        </Flex>
        {fileName ? (
          <Flex
            alignItems="center"
            bg="#FEFEFE"
            border="1px solid #E8ECEF"
            borderRadius="12px"
            flexDirection="column"
            height="300px"
            justifyContent="center"
            mt="32px"
          >
            <Text fontStyle="14px" mb="16px" mt="11px">
              {fileName}
            </Text>
          </Flex>
        ) : (
          <Flex
            {...getRootProps({ className: 'dropzone' })}
            alignItems="center"
            bg="#FEFEFE"
            border="1px solid #E8ECEF"
            borderRadius="12px"
            flexDirection="column"
            height="300px"
            justifyContent="center"
            mt="32px"
          >
            <input {...getInputProps()} />
            <svg
              fill="none"
              height="58"
              viewBox="0 0 51 58"
              width="51"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.17 58C15.718 58 10.6399 55.8316 6.86913 51.8933C-1.08339 43.5825 -0.960097 29.9325 7.14371 21.467L23.4941 4.38143C26.1973 1.55565 29.7963 0 33.6213 0C37.4463 0 41.046 1.55565 43.7492 4.38143C49.3268 10.2115 49.3268 19.6972 43.7492 25.5273L27.3186 42.6932C24.213 45.9413 18.7928 45.9443 15.6757 42.6932C12.4702 39.3439 12.4702 33.8945 15.6757 30.5452L30.1076 15.4645C30.944 14.593 32.2999 14.59 33.1363 15.4615C33.9755 16.3301 33.9754 17.743 33.1419 18.6175L18.71 33.6982C17.1657 35.3104 17.1657 37.9339 18.71 39.5461C20.1971 41.1047 22.8 41.1018 24.2872 39.5461L40.7177 22.3802C44.6341 18.2873 44.6341 11.6273 40.7177 7.53441C36.927 3.57236 30.3191 3.57236 26.5284 7.53441L10.178 24.6201C3.84392 31.2354 3.72063 42.2857 9.90339 48.7463C12.8635 51.8398 16.8626 53.5412 21.17 53.5412C25.5858 53.5412 29.8875 51.6911 32.976 48.4637L47.3256 33.4663C48.1591 32.5947 49.5151 32.5917 50.3543 33.4633C51.1935 34.3318 51.1934 35.7447 50.3599 36.6192L36.0103 51.6167C32.1225 55.671 26.7134 58 21.17 58Z"
                fill="#0084FF"
              />
            </svg>
            <Text fontSize="14px" fontStyle="14px" mb="16px" mt="11px">
              用意したファイルをドラッグ＆ドロップしてください
            </Text>
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              height="40px"
              text="ファイル選択"
              width="156px"
            />
          </Flex>
        )}
        <Button
          _hover={{ bgColor: 'blue.900' }}
          backgroundColor="#0084FF"
          border="1px solid #0084FF"
          borderRadius="12px"
          color="#FEFEFE"
          height="48px"
          isDisabled={Boolean(!fileName)}
          mt="32px"
          text="ファイルをインポートする"
          width="100%"
          onClick={onSubmit}
        />
      </Box>
    </Modal>
  )
}

export default UploadProductPdfModal
