import { images } from 'constant/images'
import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { CheckDone, Close } from 'components/atoms/Icons'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { authServices } from 'services'
import { AxiosError } from 'axios'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { useReAuthentication } from 'hooks/reAuth'

/**
 * login page
 * @constructor
 */
export const VerifyEmail: FC<{ size: ROLE }> = ({ size }) => {
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [resError, setResError] = useState('')
  const email = searchParams.get('email')
  const navigate = useNavigate()
  const { reAuth } = useReAuthentication()

  /**
   *
   */
  const handleVerifyEmail = useCallback(async () => {
    if (!email) {
      return
    }
    try {
      setResError('')
      await authServices.verifyEmail(size, { email })
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error instanceof AxiosError) {
        if (error.response) {
          setResError(error.response.data?.detail)
        } else {
          setResError(error.message)
        }
      }
    }
  }, [email, size])

  useEffect(() => {
    handleVerifyEmail()
  }, [handleVerifyEmail])

  if (!email) {
    return (
      <Navigate to={size === ROLE.ADVISOR ? ROUTES.ROOT : ADMIN_ROUTES.ROOT} />
    )
  }
  if (loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <Box
      bgImage={images.BACKGROUND_LOGIN}
      bgSize="cover"
      boxSizing="border-box"
      minH="100vh"
      padding="24px"
      position="relative"
      w="100%"
    >
      <Box
        bgColor="white.50"
        borderRadius="xl"
        h="calc(100vh - 48px)"
        maxW={647}
        ml={2}
        position="relative"
        w="100%"
      >
        <Close
          cursor="pointer"
          fontSize="24px"
          ml="10px"
          mt="10px"
          onClick={() => {
            reAuth(size)
            navigate(size === ROLE.ADVISOR ? ROUTES.LOGIN : ADMIN_ROUTES.LOGIN)
          }}
        />
        <Flex
          alignItems="center"
          direction="column"
          h="100%"
          justifyContent="center"
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            px="40px"
          >
            {resError ? (
              <WarningTwoIcon color="red" fontSize="50px" />
            ) : (
              <CheckDone fontSize="50px" />
            )}
            <Text fontSize="18px" fontWeight="700" lineHeight="24px" mt="8px">
              {resError ? resError : 'メールアドレスの変更が完了しました'}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex
        bottom={6}
        gap={4}
        justifyContent="flex-end"
        position="absolute"
        width="calc(100% - 48px)"
      >
        <Text
          color="white.base"
          fontSize="sm"
          fontWeight="bold"
          letterSpacing="-0.02em"
          lineHeight="shorter"
        >
          利用規約
        </Text>
        <Text
          color="white.base"
          fontSize="sm"
          fontWeight="bold"
          letterSpacing="-0.02em"
          lineHeight="shorter"
        >
          プライバシーポリシー
        </Text>
        <Text
          color="white.base"
          fontSize="sm"
          fontWeight="bold"
          letterSpacing="-0.02em"
          lineHeight="shorter"
        >
          運営会社
        </Text>
      </Flex>
    </Box>
  )
}
