import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Logout icon
 * @param props
 * @constructor
 */
export const Logout: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8469 17.1282V18.1538C15.8469 20.6338 14.4806 22 12.0005 22H5.84635C3.36622 22 2 20.6338 2 18.1538V5.84615C2 3.36615 3.36622 2 5.84635 2H12.0005C14.4806 2 15.8469 3.36615 15.8469 5.84615V6.8718C15.8469 7.29641 15.5022 7.64103 15.0776 7.64103C14.6529 7.64103 14.3083 7.29641 14.3083 6.8718V5.84615C14.3083 4.22872 13.618 3.53846 12.0005 3.53846H5.84635C4.22883 3.53846 3.53854 4.22872 3.53854 5.84615V18.1538C3.53854 19.7713 4.22883 20.4615 5.84635 20.4615H12.0005C13.618 20.4615 14.3083 19.7713 14.3083 18.1538V17.1282C14.3083 16.7036 14.6529 16.359 15.0776 16.359C15.5022 16.359 15.8469 16.7036 15.8469 17.1282ZM21.9415 12.2943C22.0195 12.1067 22.0195 11.8943 21.9415 11.7067C21.9026 11.6123 21.8462 11.5272 21.7754 11.4564L18.6983 8.37949C18.3978 8.07898 17.9105 8.07898 17.61 8.37949C17.3095 8.68 17.3095 9.16719 17.61 9.4677L19.3742 11.2318H7.89773C7.4731 11.2318 7.12846 11.5764 7.12846 12.001C7.12846 12.4256 7.4731 12.7703 7.89773 12.7703H19.3742L17.61 14.5344C17.3095 14.8349 17.3095 15.3221 17.61 15.6226C17.7598 15.7723 17.9567 15.8482 18.1537 15.8482C18.3506 15.8482 18.5475 15.7733 18.6972 15.6226L21.7743 12.5457C21.8461 12.4728 21.9026 12.3877 21.9415 12.2943Z"
          fill="currentColor"
        ></path>
      </svg>
      ;
    </Icon>
  )
}
