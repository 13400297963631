/**
 * TrackLargeIcon icon
 * @constructor
 */
export const TrackLargeIcon = () => (
  <svg
    fill="none"
    height="120"
    viewBox="0 0 120 120"
    width="120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M95.6571 30.2H76.8747C76.4602 29.3749 76.1393 28.4411 75.7961 27.3984L74.8957 24.6466C73.9865 21.8722 71.4326 20 68.5533 20H51.4467C48.5674 20 46.0135 21.8722 45.1042 24.6466L44.2039 27.3984C43.8607 28.4411 43.5398 29.3749 43.1253 30.2H24.3429C22.4976 30.2 21 31.7232 21 33.6C21 35.4768 22.4976 37 24.3429 37H95.6571C97.5024 37 99 35.4768 99 33.6C99 31.7232 97.5024 30.2 95.6571 30.2Z"
      fill="#0084FF"
    />
    <path
      d="M69.5 81C67.568 81 66 79.5009 66 77.6538V55.3462C66 53.4991 67.568 52 69.5 52C71.432 52 73 53.4991 73 55.3462V77.6538C73 79.5009 71.432 81 69.5 81Z"
      fill="#0084FF"
    />
    <path
      d="M51.5 81C49.568 81 48 79.5009 48 77.6538V55.3462C48 53.4991 49.568 52 51.5 52C53.432 52 55 53.4991 55 55.3462V77.6538C55 79.5009 53.432 81 51.5 81Z"
      fill="#0084FF"
    />
    <path
      d="M92 37L88.5554 87.621C88.0569 94.6063 84.295 100 74.9583 100H46.0417C36.705 100 32.9431 94.6063 32.4446 87.621L29 37H92Z"
      fill="#0084FF"
      opacity="0.4"
    />
  </svg>
)
