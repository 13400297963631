import { extendTheme } from '@chakra-ui/react'

import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { fontSizes } from './fontSizes'
import { fontWeights } from './fontWeight'
import { letterSpacings } from './letterSpacing'
import { lineHeights } from './lineHeights'
import { radii } from './radii'
import { sizes } from './sizes'
import { space } from './space'
import { zIndices } from './zIndices'
import { boxShadows } from './boxShadows'
import { transforms } from './transforms'

const fonts = {
  heading: `"Inter", sans-serif`,
  body: `"Noto Sans JP", sans-serif`,
}

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        color: 'black.100',
      },
      a: {
        fontWeight: 'normal',
      },
    },
  },
  fonts,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  zIndices,
  space,
  sizes,
  radii,
  boxShadows,
  transforms,
})

export type TTheme = typeof theme
