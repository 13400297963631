import { Box, Text, Divider, Avatar, Flex, FormLabel } from '@chakra-ui/react'
import { PenLight } from 'components/atoms/Icons'
import { InputField } from 'components/atoms/InputField'
import { Button } from 'components/fromAdvisor/elements/Button'
import { FormikProvider } from 'formik'
import { FC } from 'react'
import { ModalChangeEmail } from './components/ModalChangeEmail'
import { ModalChangePassword } from './components/ModalChangePassword'
import { MyPageProps, useMyPage } from './hook'

/**
 * MyPage page
 * @constructor
 */
export const MyPage: FC<MyPageProps> = props => {
  const {
    size,
    currentUser,
    getRootProps,
    getInputProps,
    formik,
    disabledTop,
    errors,
    setFieldValue,
    disabledBottom,
    resetForm,
    setDisabledTop,
    isLoading,
    handleSubmit,
    handleChange,
    setDisabledBottom,
    onOpenModalChangeEmail,
    onOpenModalChangePassword,
    isOpenModalChangeEmail,
    onCloseModalChangeEmail,
    isOpenModalChangePassword,
    onCloseModalChangePassword,
    img,
  } = useMyPage(props)
  return (
    <Box
      bgColor="white.50"
      borderRadius={4}
      minHeight="calc(100vh - 88px)"
      padding="24px 32px"
      w="100%"
    >
      <Text
        fontSize="24px"
        fontWeight="700"
        letterSpacing="-0.48px"
        lineHeight="40px"
        marginBottom="16px"
        minW="158px"
      >
        アカウント情報
      </Text>
      <Divider color="#E8ECEF" />

      <Box display="flex" gap="40px" marginTop="24px">
        <Box height="fit-content" padding="32px" position="relative">
          <Avatar name={currentUser?.email} size="2xl" src={img} />
          <Flex
            {...getRootProps()}
            alignItems="center"
            bgColor="white.50"
            border="1px solid #0084FF"
            borderRadius="12px"
            bottom="16px"
            cursor="pointer"
            h="44px"
            justifyContent="center"
            position="absolute"
            right="27px"
            w="44px"
          >
            <PenLight />
          </Flex>
          <input {...getInputProps()} type="file" />
        </Box>
        <FormikProvider value={formik}>
          <Box w="100%">
            <Flex gap="24px" height="fit-content">
              <Box>
                <FormLabel
                  alignItems="center"
                  color="#141718"
                  display="flex"
                  fontSize="18px"
                  fontWeight="700"
                  gap="8px"
                  lineHeight="24px"
                  margin="0 0 8px 0"
                >
                  名前
                </FormLabel>
                <Flex gap="8px">
                  <InputField
                    isFocus
                    disabled={disabledTop}
                    error={Boolean(errors.first_name)}
                    errorText={errors.first_name}
                    height="48px"
                    name="first_name"
                    sizeLabel="18px"
                    width="167px"
                    onChange={e => {
                      setFieldValue('first_name', e.target.value)
                    }}
                  />
                  <InputField
                    isFocus
                    disabled={disabledTop}
                    error={Boolean(errors.last_name)}
                    errorText={errors.last_name}
                    height="48px"
                    name="last_name"
                    sizeLabel="18px"
                    width="167px"
                    onChange={e => {
                      setFieldValue('last_name', e.target.value)
                    }}
                  />
                </Flex>
              </Box>
              <Box>
                <FormLabel
                  alignItems="center"
                  color="#141718"
                  display="flex"
                  fontSize="18px"
                  fontWeight="700"
                  gap="8px"
                  lineHeight="24px"
                  margin="0 0 8px 0"
                >
                  フリガナ
                </FormLabel>
                <Flex gap="8px">
                  <InputField
                    isFocus
                    disabled={disabledTop}
                    error={Boolean(errors.furigana_name_first)}
                    errorText={errors.furigana_name_first}
                    height="48px"
                    name="furigana_name_first"
                    sizeLabel="18px"
                    width="167px"
                    onChange={e => {
                      setFieldValue('furigana_name_first', e.target.value)
                    }}
                  />
                  <InputField
                    isFocus
                    disabled={disabledTop}
                    error={Boolean(errors.furigana_name_last)}
                    errorText={errors.furigana_name_last}
                    height="48px"
                    name="furigana_name_last"
                    sizeLabel="18px"
                    width="167px"
                    onChange={e => {
                      setFieldValue('furigana_name_last', e.target.value)
                    }}
                  />
                </Flex>
              </Box>
              {disabledBottom && (
                <Flex gap="8px" mt="32px">
                  {disabledTop ? (
                    <Button
                      backgroundColor="#FEFEFE"
                      border="1px solid #0084FF"
                      borderRadius="12px"
                      color="#0084FF"
                      fontSize={'14px'}
                      fontWeight={'bold'}
                      height="48px"
                      leftIcon={<PenLight />}
                      letterSpacing={'-0.02px'}
                      lineHeight={'shorter'}
                      text="変更する"
                      width="116px"
                      onClick={() => setDisabledTop(e => !e)}
                    />
                  ) : (
                    <>
                      <Button
                        backgroundColor="#FEFEFE"
                        border="1px solid #0084FF"
                        borderRadius="12px"
                        color="#0084FF"
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        height="48px"
                        letterSpacing={'-0.02px'}
                        lineHeight={'shorter'}
                        text="キャンセル"
                        width="116px"
                        onClick={() => {
                          resetForm()
                          setDisabledTop(e => !e)
                        }}
                      />
                      <Button
                        backgroundColor="#0084FF"
                        border="1px solid #0084FF"
                        borderRadius="12px"
                        color="white.50"
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        height="48px"
                        isLoading={isLoading}
                        letterSpacing={'-0.02px'}
                        lineHeight={'shorter'}
                        text="登録する"
                        width="116px"
                        onClick={() => handleSubmit()}
                      />
                    </>
                  )}
                </Flex>
              )}
            </Flex>
            <Divider color="#E8ECEF" marginY="24px" />
            <Flex gap="24px" height="fit-content">
              <Flex gap="24px">
                <InputField
                  isFocus
                  disabled={disabledBottom}
                  error={Boolean(errors.department)}
                  errorText={errors.department}
                  height="48px"
                  label="部署"
                  name="department"
                  sizeLabel="18px"
                  width="343px"
                  onChange={handleChange}
                />
                <InputField
                  isFocus
                  disabled={disabledBottom}
                  error={Boolean(errors.position)}
                  errorText={errors.position}
                  height="48px"
                  label="役職"
                  name="position"
                  sizeLabel="18px"
                  width="343px"
                  onChange={handleChange}
                />
              </Flex>
              {disabledTop && (
                <Flex gap="8px" mt="32px">
                  {disabledBottom ? (
                    <Button
                      backgroundColor="#FEFEFE"
                      border="1px solid #0084FF"
                      borderRadius="12px"
                      color="#0084FF"
                      fontSize={'14px'}
                      fontWeight={'bold'}
                      height="48px"
                      leftIcon={<PenLight />}
                      letterSpacing={'-0.02px'}
                      lineHeight={'shorter'}
                      text="変更する"
                      width="116px"
                      onClick={() => setDisabledBottom(e => !e)}
                    />
                  ) : (
                    <>
                      <Button
                        backgroundColor="#FEFEFE"
                        border="1px solid #0084FF"
                        borderRadius="12px"
                        color="#0084FF"
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        height="48px"
                        letterSpacing={'-0.02px'}
                        lineHeight={'shorter'}
                        text="キャンセル"
                        width="116px"
                        onClick={() => {
                          resetForm()
                          setDisabledBottom(e => !e)
                        }}
                      />
                      <Button
                        backgroundColor="#0084FF"
                        border="1px solid #0084FF"
                        borderRadius="12px"
                        color="white.50"
                        fontSize={'14px'}
                        fontWeight={'bold'}
                        height="48px"
                        isLoading={isLoading}
                        letterSpacing={'-0.02px'}
                        lineHeight={'shorter'}
                        text="登録する"
                        width="116px"
                        onClick={() => handleSubmit()}
                      />
                    </>
                  )}
                </Flex>
              )}
            </Flex>
            <Divider color="#E8ECEF" marginY="24px" />
            <Flex gap="24px" height="fit-content">
              <InputField
                disabled
                height="48px"
                label="メールアドレス"
                name="email"
                placeholder="法人営業部"
                sizeLabel="18px"
                width="343px"
              />
              {disabledTop && disabledBottom && (
                <Flex alignItems="end">
                  <Button
                    backgroundColor="#FEFEFE"
                    border="1px solid #0084FF"
                    borderRadius="12px"
                    color="#0084FF"
                    fontSize={'14px'}
                    fontWeight={'bold'}
                    height="48px"
                    leftIcon={<PenLight />}
                    letterSpacing={'-0.02px'}
                    lineHeight={'shorter'}
                    text="変更する"
                    width="116px"
                    onClick={onOpenModalChangeEmail}
                  />
                </Flex>
              )}
            </Flex>
            <Divider color="#E8ECEF" marginY="24px" />
            <Flex gap="24px" height="fit-content">
              <InputField
                disabled
                height="48px"
                label="パスワード"
                name="password"
                placeholder="********"
                sizeLabel="18px"
                width="343px"
              />
              {disabledTop && disabledBottom && (
                <Flex alignItems="end">
                  <Button
                    backgroundColor="#FEFEFE"
                    border="1px solid #0084FF"
                    borderRadius="12px"
                    color="#0084FF"
                    fontSize={'14px'}
                    fontWeight={'bold'}
                    height="48px"
                    leftIcon={<PenLight />}
                    letterSpacing={'-0.02px'}
                    lineHeight={'shorter'}
                    text="変更する"
                    width="116px"
                    onClick={onOpenModalChangePassword}
                  />
                </Flex>
              )}
            </Flex>
          </Box>
        </FormikProvider>
      </Box>
      <ModalChangeEmail
        email={currentUser?.email}
        isOpen={isOpenModalChangeEmail}
        size={size}
        onClose={onCloseModalChangeEmail}
      />
      <ModalChangePassword
        isOpen={isOpenModalChangePassword}
        size={size}
        onClose={onCloseModalChangePassword}
      />
    </Box>
  )
}
