import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Mail icon
 * @param props
 * @constructor
 */
export const Mail: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 4H6C4 4 3 5 3 7v9c0 2 1 3 3 3h12c2 0 3-1 3-3V7c0-2-1-3-3-3zm-.06 4.606l-4.91 3.572a1.748 1.748 0 01-2.06.001L6.06 8.606a.75.75 0 11.882-1.213l4.912 3.572a.25.25 0 00.293 0l4.912-3.572a.75.75 0 11.882 1.213z"
          fill="currentColor"
        ></path>
      </svg>
    </Icon>
  )
}
