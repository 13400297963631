import React, { FC } from 'react'
import ReactWordcloud from 'react-wordcloud'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

type WordCloudProps = {
  words: { text: string; value: number }[]
  keySearch?: string
  width: number
  height: number
}
type Spiral = 'archimedean' | 'rectangular'
/**
 * WordCloud molecules
 * @constructor
 */
const WordCloud: FC<WordCloudProps> = ({ keySearch, words, width, height }) => {
  const totalArea = width * height
  const wordSpacing = 6
  const totalValue = words.reduce((sum, word) => sum + word.value, 0)

  const estimateWordArea = (
    word: { text: string; value: number },
    fontSize: number,
  ) => {
    const wordLength = word.text.length
    const estimatedWidth = wordLength * (fontSize * 0.6)
    const estimatedHeight = fontSize

    const totalWidthWithSpacing = estimatedWidth + 2 * wordSpacing
    const totalHeightWithSpacing = estimatedHeight + 2 * wordSpacing
    const totalAreaWithSpacing = totalWidthWithSpacing * totalHeightWithSpacing

    return totalAreaWithSpacing
  }

  const arrayFontSize = words.map(word => {
    const wordValueRatio = word.value / totalValue
    const targetArea = totalArea * wordValueRatio

    let fontSize = 6
    while (estimateWordArea(word, fontSize) < targetArea) {
      fontSize += 1
    }
    return fontSize
  })

  const minFontSize = Math.min(...arrayFontSize)
  const maxFontSize = Math.max(...arrayFontSize)

  const options = {
    colors: [
      '#482677',
      '#60ca60',
      '#F8E621',
      '#355E8D',
      '#32B67A',
      '#29778D',
      '#2DB081',
      '#168A87',
      '#43095D',
    ],
    enableTooltip: true,
    deterministic: true,
    fontFamily: 'NotoSansJP',
    fontStyle: 'normal',
    fontWeight: 'bold',
    rotations: 0,
    spiral: 'rectangular' as Spiral,
    fontSizes: [minFontSize, maxFontSize] as [number, number],
    padding: 1,
  }

  const callbacks = {
    getWordColor: (word: any) =>
      word.text.toLowerCase() === keySearch?.toLowerCase() && keySearch !== ''
        ? 'red'
        : 'grey',
  }

  return (
    <ReactWordcloud
      callbacks={keySearch ? callbacks : undefined}
      options={options}
      words={words}
    />
  )
}

export default React.memo(WordCloud)
