import { Box, Flex, Text } from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { DataTable, InputField } from 'components/atoms'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import 'moment-timezone'
import { FC, useCallback, useEffect, useState } from 'react'
import { accessLogServices } from 'services'
import { GetAccessLogResponse } from 'services/accessLogServices'
import { PaginateResponse } from 'types/conmon'

/**
 * AccessLog Page
 * @constructor
 */
export const AccessLog: FC = () => {
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const [search, setSearch] = useState<string>('')
  const [dataAccessLog, setDataAccessLog] = useState<{
    loading: boolean
    data: GetAccessLogResponse[]
    last_updated?: string
  }>({ loading: false, data: [] })
  const columnHelper = createColumnHelper<GetAccessLogResponse>()

  const getListAccessLog = useCallback(async () => {
    try {
      setDataAccessLog(prev => ({ ...prev, loading: true }))
      const params = {
        page_size: pagination.page_size,
        page: pagination.page,
        sort_by: 'updated_at:desc',
        search_key: search,
      }
      const { data, last_updated, paginate } =
        await accessLogServices.getListAccessLog(params)
      setDataAccessLog(prev => ({
        ...prev,
        data: data,
        loading: false,
        last_updated: last_updated,
      }))
      if (paginate) {
        setPagination(paginate)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [pagination.page, pagination.page_size, search])

  useEffect(() => {
    getListAccessLog()
  }, [getListAccessLog])

  const formik = useFormik({
    initialValues: { search_key: '' },
    /**
     *
     */
    async onSubmit() {},
  })
  const { setFieldValue } = formik

  const columns = [
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => {
        const japanDateTime = moment.utc(info.getValue()).tz('Asia/Tokyo')
        const japanDate = japanDateTime.format('YYYY/MM/DD')
        return (
          <Text fontSize="sm" fontWeight={'normal'} lineHeight="14px">
            {japanDate}
          </Text>
        )
      },
      header: '日付',
      size: 100,
      meta: {
        minWidthColumn: '100px',
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => {
        const japanDateTime = moment.utc(info.getValue()).tz('Asia/Tokyo')
        const japanTime = japanDateTime.format('HH:mm:ss')
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={japanTime ?? '-'}
            width="100%"
          />
        )
      },
      header: '時刻',
      size: 80,
      meta: {
        minWidthColumn: '80px',
      },
    }),
    columnHelper.accessor('IP', {
      id: 'IP',
      cell: info => {
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={info.getValue()}
          />
        )
      },
      header: 'IPアドレス',
      size: 118,
      meta: {
        minWidthColumn: '118px',
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: info => {
        const firstName = info.row.original.first_name
        const lastName = info.row.original.last_name
        return (
          <TextOverflowTooltip
            color="blue.900"
            fontSize="sm"
            fontWeight={'bold'}
            value={firstName + lastName}
          />
        )
      },
      header: 'ユーザー',
      size: 117,
      meta: {
        minWidthColumn: '117px',
      },
    }),
    columnHelper.accessor('department', {
      id: 'department',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue()}
        />
      ),
      header: '部署',
      size: 198,
      meta: {
        minWidthColumn: '198px',
      },
    }),
    columnHelper.accessor('activity', {
      id: 'activity',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue()}
        />
      ),
      header: '操作内容',
      size: 640,
      meta: {
        minWidthColumn: '640px',
      },
    }),
  ]

  return (
    <>
      <FormikProvider value={formik}>
        <Box
          bgColor="white.50"
          borderRadius={4}
          minH="calc(100vh - 88px)"
          padding="12px 24px"
          w="100%"
        >
          <Flex align={'center'} justifyContent="space-between" mb="16px">
            <Flex alignItems="center" gap="24px">
              <Text
                fontSize="24px"
                fontWeight="700"
                letterSpacing="tighter"
                lineHeight="40px"
                minW="141px"
                padding="0 8px"
              >
                アクセスログ
              </Text>
              <InputField
                height="40px"
                name="name"
                placeholder="ユーザー名"
                width="242px"
                onChange={e => {
                  setFieldValue('search_key', e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearch(formik.values.search_key.trim())
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }
                }}
              />
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="12px"
                minW="135px"
                opacity="0.5"
              >
                最終更新日：
                {moment(dataAccessLog?.last_updated).format('YYYY/MM/DD')}
              </Text>
            </Flex>
          </Flex>
          <DataTable
            bgColorTable="white.50"
            columns={columns}
            data={dataAccessLog.data}
            isPagination={true}
            isScroll={true}
            isShowAction={false}
            loadingData={dataAccessLog.loading}
            maxH="calc(100vh - 216px)"
            pagination={pagination}
            rangePage={[15, 30, 50]}
            setPagination={setPagination}
          />
        </Box>
      </FormikProvider>
    </>
  )
}
