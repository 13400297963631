import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * EyeOn icon
 * @param props
 * @constructor
 */
export const EyeOn: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="19"
        viewBox="0 0 24 19"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_2)">
          <path
            d="M15.6667 9.33334C15.6667 11.356 14.0227 13 12.0001 13C9.97739 13 8.33339 11.356 8.33339 9.33334C8.33339 8.868 8.42928 8.42662 8.58795 8.01595C8.84661 8.22128 9.15871 8.36263 9.51471 8.36263C10.3507 8.36263 11.0293 7.68399 11.0293 6.84799C11.0293 6.49199 10.888 6.17989 10.6827 5.92123C11.0933 5.76256 11.5347 5.66667 12.0001 5.66667C14.0227 5.66667 15.6667 7.31067 15.6667 9.33334ZM23.4561 11.3027C21.8201 14.0415 18.1453 18.6667 12.0001 18.6667C5.85472 18.6667 2.18 14.0415 0.544 11.3027C-0.181333 10.0894 -0.181333 8.57727 0.544 7.36394C2.18 4.62527 5.85472 0 12.0001 0C18.1453 0 21.8201 4.62527 23.4561 7.36394C24.1815 8.57727 24.1815 10.0894 23.4561 11.3027ZM17.6667 9.33334C17.6667 6.20933 15.1253 3.66667 12.0001 3.66667C8.87472 3.66667 6.33339 6.20933 6.33339 9.33334C6.33339 12.4573 8.87472 15 12.0001 15C15.1253 15 17.6667 12.4573 17.6667 9.33334Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect fill="white" height="18.6667" width="24" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}
