import { Box, Flex, Text } from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { DataTable } from 'components/atoms'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { ADMIN_ROUTES } from 'constant'
import moment from 'moment'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { accessLogServices } from 'services'
import { GetProductLogResponse } from 'services/accessLogServices'
import { PaginateResponse } from 'types/conmon'

export const ProductLog: FC = () => {
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const [dataAccessLog, setDataAccessLog] = useState<{
    loading: boolean
    data: GetProductLogResponse[]
    last_updated?: string
  }>({ loading: false, data: [] })
  const columnHelper = createColumnHelper<GetProductLogResponse>()
  const navigate = useNavigate()

  const handleNavigateProductDetail = (id: number) => {
    navigate(`${ADMIN_ROUTES.PRODUCT_LOG}/${id}`)
  }

  const getListProductLog = useCallback(async () => {
    try {
      setDataAccessLog(prev => ({ ...prev, loading: true }))
      const params = {
        page_size: pagination.page_size,
        page: pagination.page,
        sort_by: 'updated_at:desc',
      }
      const { data, last_updated, paginate } =
        await accessLogServices.getListProductLog(params)
      setDataAccessLog(prev => ({
        ...prev,
        data: data,
        loading: false,
        last_updated: last_updated,
      }))
      if (paginate) {
        setPagination(paginate)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [pagination.page, pagination.page_size])

  useEffect(() => {
    getListProductLog()
  }, [getListProductLog])

  const columns = [
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => {
        const japanDateTime = moment.utc(info.getValue()).tz('Asia/Tokyo')
        const japanDate = japanDateTime.format('YYYY/MM/DD')
        return (
          <Text fontSize="sm" fontWeight={'normal'} lineHeight="14px">
            {japanDate}
          </Text>
        )
      },
      header: '日付',
      size: 100,
      meta: {
        minWidthColumn: '100px',
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at_time',
      cell: info => {
        const japanDateTime = moment.utc(info.getValue()).tz('Asia/Tokyo')
        const japanTime = japanDateTime.format('HH:mm:ss')
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={japanTime ?? '-'}
            width="100%"
          />
        )
      },
      header: '時刻',
      size: 80,
      meta: {
        minWidthColumn: '80px',
      },
    }),
    columnHelper.accessor('IP', {
      id: 'IP',
      cell: info => {
        return (
          <TextOverflowTooltip
            fontSize="sm"
            fontWeight={'normal'}
            value={info.getValue()}
          />
        )
      },
      header: 'IPアドレス',
      size: 118,
      meta: {
        minWidthColumn: '118px',
      },
    }),
    columnHelper.accessor('file_name', {
      id: 'file_name',
      cell: info => {
        const id = Number(info.row.original.id)
        return (
          <TextOverflowTooltip
            isCursor
            color="blue.900"
            fontSize="sm"
            fontWeight={'bold'}
            value={info.getValue()}
            onClick={() => handleNavigateProductDetail(id)}
          />
        )
      },
      header: 'ファイル名',
      size: 117,
      meta: {
        minWidthColumn: '117px',
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue()}
        />
      ),
      header: 'ステータス',
      size: 198,
      meta: {
        minWidthColumn: '198px',
      },
    }),
    columnHelper.accessor('error_message', {
      id: 'error_message',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue()}
        />
      ),
      header: 'エラー',
      size: 640,
      meta: {
        minWidthColumn: '640px',
      },
    }),
  ]

  return (
    <>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minH="calc(100vh - 88px)"
        padding="12px 24px"
        w="100%"
      >
        <Flex align={'center'} justifyContent="space-between" mb="16px">
          <Flex alignItems="center" gap="24px">
            <Text
              fontSize="24px"
              fontWeight="700"
              letterSpacing="tighter"
              lineHeight="40px"
              minW="141px"
              padding="0 8px"
            >
              アクセスログ
            </Text>
          </Flex>
        </Flex>
        <DataTable
          bgColorTable="white.50"
          columns={columns}
          data={dataAccessLog.data}
          isPagination={true}
          isScroll={true}
          isShowAction={false}
          loadingData={dataAccessLog.loading}
          maxH="calc(100vh - 216px)"
          pagination={pagination}
          rangePage={[15, 30, 50]}
          setPagination={setPagination}
        />
      </Box>
    </>
  )
}
