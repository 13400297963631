import { isNull } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { saveBoxServices } from 'services'
import { FavoriteAnswerType } from 'services/saveBoxServices'

/**
 * @returns function that handle any function for save answer
 */
export const useSaveAnswer = (favoritePromptTab: string | null) => {
  const [dataSavedAnswer, setDataSavedAnswer] = useState<FavoriteAnswerType[]>()
  const [isFetchingSaved, setIsFetchingSaved] = useState<boolean>(false)

  /**
   * @returns function that handle fetch list answer saved
   */
  const handleFetchSavedAnswer = useCallback(async () => {
    setIsFetchingSaved(true)
    if (!isNull(favoritePromptTab) && favoritePromptTab !== '1') return
    try {
      const { data } = await saveBoxServices.getListFavoriteAnswer()
      setDataSavedAnswer(data)
    } finally {
      setIsFetchingSaved(false)
    }
  }, [favoritePromptTab])

  /**
   * @returns function that handle favorite item
   */
  const handleDeleteFavouriteAnswer = async (id: number) => {
    const newState = dataSavedAnswer?.map(answer =>
      answer.id === id
        ? { ...answer, is_favorite: !answer.is_favorite }
        : answer,
    )
    setDataSavedAnswer(newState)

    await saveBoxServices.deleteFavoriteStatus(id)
  }

  /**
   * @returns function that handle favorite item
   */
  const handleAddFavouriteAnswer = async (
    id: number,
    title: string,
    conversationId: number,
    historyId: number,
  ) => {
    const newState = dataSavedAnswer?.map(answer =>
      answer.id === id
        ? { ...answer, is_favorite: !answer.is_favorite }
        : answer,
    )
    setDataSavedAnswer(newState)
    const body = {
      title,
      ai_conversation_id: conversationId,
      ai_conversation_history_id: historyId,
    }

    await saveBoxServices.addFavoriteStatus(body)
  }

  useEffect(() => {
    handleFetchSavedAnswer()
  }, [handleFetchSavedAnswer])

  return {
    isFetchingSaved,
    dataSavedAnswer,
    handleDeleteFavouriteAnswer,
    handleAddFavouriteAnswer,
  }
}
