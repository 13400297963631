import {
  useState,
  useEffect,
  useMemo,
  MouseEvent,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'
import dayjs from 'dayjs'
import { useDisclosure } from '@chakra-ui/react'
import { chatAIServices, saveBoxServices } from 'services'
import {
  ListConversationConverted,
  ConversationConverted,
} from 'services/chatAIServices'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant/router'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { FormValueFilter } from './ChatHistory.types'
import { convertConversationListResponse } from './ChatHistory.utils'

const DEFAULT_PAGE_SIZE = 20

/**
 * @returns function that handle any function for chat history
 */
export const useChatHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const conversationId = queryParams.get('conversationId')

  const [conversationList, setConversationList] =
    useState<ListConversationConverted>({
      paginate: {
        pageSize: 20,
        page: 1,
        pageCount: 1,
        totalCount: 0,
      },
      data: [],
    })
  const [conversation, setConversation] = useState<ConversationConverted>()
  const [answerEdit, setAnswerEdit] = useState<string | undefined>('')
  const [historyId, setHistoryId] = useState<number>()
  const {
    isOpen: isOpenModalFilter,
    onClose: onCloseModalFilter,
    onOpen: onOpenModalFilter,
  } = useDisclosure()
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false)
  const [isLoadingFetchDetail, setIsLoadingFetchDetail] =
    useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(1)
  const {
    isOpen: isOpenDrawerLeft,
    onOpen: onOpenDrawerLeft,
    onClose: onCloseDrawerLeft,
  } = useDisclosure({ defaultIsOpen: true })
  const [paramsFilterHistory, setParamsFilterHistory] =
    useState<FormValueFilter>({
      freeWord: '',
      startDate: undefined,
      endDate: undefined,
      bookmark: undefined,
    })

  /**
   * @returns function that handle fetch conversation list
   */
  const handleFetchConversationList = useCallback(async () => {
    setIsLoadingEdit(true)
    try {
      const { bookmark, freeWord, startDate, endDate } = paramsFilterHistory
      const params = {
        bookmarked: bookmark,
        free_word: freeWord?.trim(),
        start_date: startDate
          ? dayjs(startDate).format('YYYY-MM-DD')
          : undefined,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
        page_size: DEFAULT_PAGE_SIZE,
        page: pageCount,
      }
      const response = await chatAIServices.getConversations(params)
      const { data, paginate } = response
      setConversationList(prev => ({
        ...prev,
        data: prev.data.concat(data),
        paginate: {
          page: paginate.page,
          pageCount: paginate.pageCount,
          pageSize: paginate.pageSize,
          totalCount: paginate.totalCount,
        },
      }))
    } finally {
      setIsLoadingEdit(false)
      onCloseModalFilter()
    }
  }, [onCloseModalFilter, pageCount, paramsFilterHistory])

  useEffect(() => {
    handleFetchConversationList()
  }, [handleFetchConversationList])

  /**
   * @returns function that handle fetch conversation detail
   */
  const handleFetchConversationDetail = useCallback(async () => {
    if (!conversationId) return
    setIsLoadingFetchDetail(true)
    try {
      const detailConversation = await chatAIServices.getConversationDetail(
        Number(conversationId),
      )
      setConversation(detailConversation)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoadingFetchDetail(false)
    }
  }, [conversationId])

  useEffect(() => {
    handleFetchConversationDetail()
  }, [handleFetchConversationDetail])

  /**
   * @returns function that handle new chat
   */
  const handleNewChat = () => {
    if (conversation) setConversation(undefined)
    navigateWithQueryParams(
      navigate,
      `${ROUTES.CHAT_AI}`,
      'conversationId',
      undefined,
    )
  }

  /**
   * @returns function that handle change bookmark
   */
  const handleChangeBookmark = async (
    event: MouseEvent<HTMLButtonElement>,
    id: number,
  ) => {
    event.stopPropagation()
    event.preventDefault()
    if (!conversationList) return
    const updateConversationList = conversationList?.data.map(conversation =>
      conversation.id === id
        ? { ...conversation, bookmarked: !conversation.bookmarked }
        : conversation,
    )
    setConversationList({
      ...conversationList,
      data: updateConversationList,
    })

    const findConversation = conversationList?.data.find(
      conversation => conversation.id === id,
    )
    if (!findConversation) return

    const body = {
      is_bookmarked: !findConversation.bookmarked,
    }

    await chatAIServices.changeBookmarkedConversation(id, body)
  }

  /**
   * @returns function that handle select history
   */
  const handleSelectHistory = (historyId?: number) => {
    setHistoryId(historyId)
    const findHistory = conversation?.chatHistories?.find(
      history => history.id === historyId,
    )
    setAnswerEdit(findHistory?.answerData)
  }

  /**
   * @returns function that handle change answer
   */
  const handleOnChangeInputEditAnswer = (newAnswer: string) =>
    setAnswerEdit(newAnswer)

  /**
   * @returns function that handle submit change answer
   */
  const handleEditAnswer = async () => {
    if (!historyId || !conversation) return

    try {
      const updatedChatHistories = conversation.chatHistories?.map(history =>
        history.id === historyId
          ? { ...history, answerData: answerEdit }
          : { ...history },
      )

      const updatedConversation: ConversationConverted = {
        ...conversation,
        chatHistories: updatedChatHistories,
      }

      setConversation(updatedConversation)

      const params = {
        answer_data: answerEdit,
      }

      await chatAIServices.editHistory(historyId, params)
      await handleFetchConversationList()
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @returns function that handle filter history
   */
  const handleFilterConversation = () => {
    handleFetchConversationList()
  }

  /**
   * @returns function that handle select conversation
   */
  const handleSelectConversation = (conversationId: number) =>
    navigateWithQueryParams(
      navigate,
      `${ROUTES.CHAT_AI}`,
      'conversationId',
      conversationId,
    )

  /**
   * @returns function that handle remove history
   */
  const handleDeleteConversation = async () => {
    if (!conversation || !conversationList) return
    const { conversationId } = conversation

    try {
      const updatedConversationList = conversationList.data.filter(
        item => item.id !== conversationId,
      )
      setConversationList({
        ...conversationList,
        data: updatedConversationList,
      })

      setConversation(undefined)
      await chatAIServices.deleteConversation(conversationId)
    } finally {
      navigateWithQueryParams(
        navigate,
        `${ROUTES.CHAT_AI}`,
        'conversationId',
        undefined,
      )
    }
  }

  /**
   * @returns function that handle rating answer
   */
  const handleRatingAnswer = async (rate: number, historyId?: number) => {
    if (!historyId || !conversation) return

    try {
      const updatedChatHistories = conversation.chatHistories?.map(history =>
        history.id === historyId
          ? { ...history, rating: rate }
          : { ...history },
      )

      const updatedConversation: ConversationConverted = {
        ...conversation,
        chatHistories: updatedChatHistories,
      }

      setConversation(updatedConversation)

      const body = {
        rating: rate,
      }

      await chatAIServices.rating(historyId, body)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @returns function that handle convert histories
   */
  const convertConversationList = useMemo(
    () => convertConversationListResponse(conversationList?.data),
    [conversationList?.data],
  )

  /**
   * @returns function that handle change page
   */
  const handleChangePageSize = () => {
    if (
      (conversationList && pageCount >= conversationList.paginate.pageCount) ||
      isLoadingEdit
    )
      return
    setPageCount(prevState => prevState + 1)
  }

  /**
   * @returns function that handle remove favorite item
   */
  const handleDeleteFavouriteAnswer = async (id: number) => {
    if (conversation) {
      const newState = conversation.chatHistories?.map(chat =>
        chat.save_answer_favorite_id === id
          ? { ...chat, is_favorite: false, save_answer_favorite_id: null }
          : chat,
      )
      setConversation({ ...conversation, chatHistories: newState })
      await saveBoxServices.deleteFavoriteStatus(id)
    }
  }

  return {
    answerEdit,
    conversation,
    conversationList: convertConversationList,
    isLoadingEdit,
    isOpenDrawerLeft,
    isOpenModalFilter,
    isLoadingFetchDetail,
    paramsFilterHistory,
    setParamsFilterHistory,
    handleNewChat,
    setConversation,
    setConversationList: setConversationList as Dispatch<
      SetStateAction<ListConversationConverted>
    >,
    handleEditAnswer,
    onOpenDrawerLeft,
    onCloseDrawerLeft,
    onOpenModalFilter,
    onCloseModalFilter,
    handleRatingAnswer,
    handleSelectHistory,
    handleChangeBookmark,
    handleChangePageSize,
    handleSelectConversation,
    handleDeleteConversation,
    handleFilterConversation,
    handleOnChangeInputEditAnswer,
    handleDeleteFavouriteAnswer,
    setPageCount,
  }
}
