import { CardRanking, Company, SearchPost } from 'components/molecules'
import { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import { AIAdviceCompany } from 'components/molecules/AIAdviceCompany'
import { useParams } from 'react-router-dom'
import WordMap from 'components/molecules/WordMap'

/**
 * CompanyDetailContainer organisms
 * @constructor
 */
export const CompanyDetailContainer: FC = () => {
  const { id } = useParams()
  return (
    <Flex direction="column" gap="16px" w="100%">
      <Company />
      <SearchPost isDetailCompany={true} page="company" />
      <Flex gap={4} h="272px" w="100%">
        <CardRanking id={id} showScoreChange={true} width="50%" />
        <AIAdviceCompany />
      </Flex>
      <WordMap />
    </Flex>
  )
}
