import { Box, Flex, Text, Center, SkeletonText, Button } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { Modal, Button as CusButton } from 'components/fromAdvisor/elements'
import { Option } from 'types/conmon'
import ReactMarkdown from '../ReactMarkdown'

type ModalTalkStoryProps = {
  onClose: () => void
  isOpen: boolean
  options: { [key: string]: Option[] }
  story: string
  stateAIAnswer: { loading: boolean; done: boolean }
  isDisableSelect: boolean
  onSendChatAi: () => void
  valueSelected: {
    industry: number | null
    story: number | null
  }
  setValueSelected: React.Dispatch<
    React.SetStateAction<{
      industry: number | null
      story: number | null
    }>
  >
  isDisableButton: boolean
}

/**
 * ModalTalkStory molecules
 * @constructor
 */
const ModalTalkStory: FC<ModalTalkStoryProps> = ({
  onClose,
  isOpen,
  options,
  story,
  stateAIAnswer,
  isDisableSelect,
  isDisableButton,
  onSendChatAi,
  setValueSelected,
  valueSelected,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isShowIconClose={false}
      maxWidth="667px"
      onClose={onClose}
    >
      <Box
        bg="white.50"
        borderRadius="20px"
        boxSizing="border-box"
        padding="24px 24px 24px 32px"
        w="100%"
      >
        <Text
          fontSize={'18px'}
          fontWeight={'bold'}
          letterSpacing={'-0.02em'}
          lineHeight={'40px'}
          minW={'fit-content'}
          px={'8px'}
        >
          トークネタ
        </Text>
        <Flex gap="16px" mb={'12px'} mt={'4px'}>
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            isInForm={false}
            minHeight="40px"
            name="selectIndustry"
            options={options.industry}
            placeholder="業界"
            sizePlaceholder="14px"
            valueOption={options.industry.find(
              it => it.value === valueSelected.industry,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== valueSelected.industry) {
                setValueSelected(prev => ({
                  ...prev,
                  industry: Number(e?.value),
                }))
              }
            }}
          />
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            isInForm={false}
            minHeight="40px"
            name="selectTalkStory"
            options={options.story}
            placeholder="トークテーマ"
            sizePlaceholder="14px"
            valueOption={options.story.find(
              it => it.value === valueSelected.story,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== valueSelected.story) {
                setValueSelected(prev => ({ ...prev, story: Number(e?.value) }))
              }
            }}
          />
          <CusButton
            bgColor={'blue.900'}
            border={'none'}
            color="white.50"
            fontSize={'16px'}
            fontWeight={'bold'}
            h={'40px'}
            isDisabled={isDisableSelect || isDisableButton}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            minW={'fit-content'}
            px={5}
            text="実行"
            onClick={onSendChatAi}
          />
        </Flex>
        <Box>
          {stateAIAnswer.loading ? (
            <SkeletonText noOfLines={6} skeletonHeight="4" spacing="4" />
          ) : Boolean(story) ? (
            <Box maxHeight={'425px'} overflow={'auto'}>
              <ReactMarkdown
                bgColor="#FEFEFE"
                data={story}
                isCopyBtn={true}
                isDone={stateAIAnswer.done}
              />
            </Box>
          ) : (
            <Flex justify={'center'} w="100%">
              <Text
                color="black.100"
                fontSize={'16px'}
                fontWeight="normal"
                id="text-element"
                lineHeight="shorter"
                maxHeight={'425px'}
                overflow={'auto'}
                textAlign="justify"
                whiteSpace={'pre-wrap'}
              >
                {'データがありません。'}
              </Text>
            </Flex>
          )}
        </Box>
        <Center mt={'24px'}>
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'white.50',
            }}
            bgColor="white.50"
            border="1px solid"
            borderColor="blue.900"
            borderRadius="12px"
            color="blue.900"
            height="48px"
            maxW="280px"
            width="100%"
            onClick={onClose}
          >
            とじる
          </Button>
        </Center>
      </Box>
    </Modal>
  )
}

export default ModalTalkStory
