import { WarningIcon } from '@chakra-ui/icons'
import { Box, Flex, useTheme } from '@chakra-ui/react'
import React, { FC, ReactNode } from 'react'

type CustomToastProps = {
  title: ReactNode
  status?: string
  description: ReactNode
}
/**
 * @returns function that handle toast
 */
const CustomToast: FC<CustomToastProps> = ({ title, status, description }) => {
  const theme = useTheme()
  return (
    <Flex
      alignItems={'center'}
      bg={
        status === 'error'
          ? theme.colors.red[500]
          : status === 'warning'
          ? theme.colors.gray[800]
          : theme.colors.green[500]
      }
      borderRadius="xl"
      color="white.base"
      h={'auto'}
      justifyContent={'center'}
      mt={10}
      paddingY={'10px'}
      px={5}
    >
      <Box fontWeight="bold">{title}</Box>
      <Box
        alignItems="center"
        display="flex"
        fontSize={'lg'}
        fontWeight={'semibold'}
        gap="4px"
        lineHeight={theme.lineHeights[6]}
      >
        {status === 'warning' && <WarningIcon />} {description}
      </Box>
    </Flex>
  )
}

export default CustomToast
