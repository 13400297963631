import React from 'react'
import { Box } from '@chakra-ui/react'
import { CustomTabs, TabsType } from 'components/fromAdvisor/elements'
import {
  ChatAIContainer,
  MyPromptTab,
  SaveBox,
} from 'components/fromAdvisor/templates'
import { DefaultPromptTab } from 'components/fromAdvisor/templates/chatAI/tabs/DefaultPromptTab'

/**
 * ChatAI page
 * @constructor
 */
export const ChatAI = () => {
  const Tabs: TabsType[] = [
    {
      id: 1,
      label: 'AIチャット相談',
      component: <ChatAIContainer />,
    },
    {
      id: 2,
      label: 'プロンプト集',
      component: <DefaultPromptTab />,
    },
    {
      id: 3,
      label: 'Myプロンプト',
      component: <MyPromptTab />,
    },
    {
      id: 4,
      // label: '保存したAI回答',
      label: 'お気に入り',
      component: <SaveBox />,
    },
  ]

  return (
    <Box borderRadius={4} overflow={'hidden'}>
      <CustomTabs tabs={Tabs} />
    </Box>
  )
}
