import React from 'react'
import {
  Text,
  Flex,
  Box,
  useTheme,
  IconButton,
  Tooltip,
  Avatar,
} from '@chakra-ui/react'
import {
  FavouriteActiveIcon,
  FavouriteInActiveIcon,
  HashTagIcon,
} from 'components/fromAdvisor/elements/Icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constant'
import { ConsultationsListContentProps } from './MyPromptTabContent.type'

/**
 * MyPromptTabItem component page.
 * @return {JSX.Element} MyPromptTabItem component.
 */
export const MyPromptTabItem = (props: ConsultationsListContentProps) => {
  const { item, index, handleCheckFavouriteContent } = props
  const theme = useTheme()
  const navigate = useNavigate()

  /**
   * @returns function that handle click item body
   */
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`${ROUTES.PROMPT_DEATAIL}/${item.id}`)
  }

  /**
   * @returns function that handle click favorite icon
   */
  const handleClickFavourite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleCheckFavouriteContent()
  }

  return (
    <Flex
      key={index}
      _hover={{
        background: 'blue.200',
      }}
      alignItems="center"
      bg={'white.50'}
      borderRadius="xl"
      cursor={'pointer'}
      gap={8}
      h={12}
      justifyContent="space-between"
      padding="12px 16px"
      onClick={handleClick}
    >
      <Flex alignItems="center" gap={1}>
        <Box h={5} minW={5}>
          <HashTagIcon fill={theme.colors.blue[900]} />
        </Box>
        <Tooltip
          bgColor="black"
          color="white"
          label={item.title}
          maxW="calc(100vw - 212px)"
          placement="top-start"
        >
          <Text
            color="blue.900"
            cursor="pointer"
            fontSize="sm"
            fontWeight="normal"
            lineHeight="shorter"
            maxW="calc(100vw - 362px)"
            overflow="hidden"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.title}
          </Text>
        </Tooltip>
      </Flex>
      <Flex alignItems="center" gap={2} justifyContent="space-between">
        <Text
          color="gray.800"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="14px"
        >
          {moment(item?.created_at).format('YYYY/MM/DD')}
        </Text>
        <Avatar bg="teal.500" size="xs" src={item.avatar_url} />
        <IconButton
          _hover={{
            transform: 'scale(1.2)',
          }}
          aria-label="favourite"
          bg="transparent"
          h={6}
          icon={
            item?.is_favorite ? (
              <FavouriteActiveIcon />
            ) : (
              <FavouriteInActiveIcon fill={theme.colors.blue[900]} />
            )
          }
          minW={6}
          onClick={handleClickFavourite}
        />
      </Flex>
    </Flex>
  )
}
