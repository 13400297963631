import {
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import React, { FC, ReactNode } from 'react'

type PopoverCustomProps = {
  children: ReactNode
  content: ReactNode | string
  isArrow?: boolean
  maxWidthPopover?: string
  isClose?: boolean
  placement?: PlacementWithLogical
}

const PopoverCustom: FC<PopoverCustomProps> = ({
  children,
  content,
  isArrow = true,
  maxWidthPopover = '266px',
  isClose = true,
  placement = 'right-start',
}) => {
  return (
    <Popover placement={placement}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        _focusVisible={{}}
        bgColor={'#272727'}
        maxW={maxWidthPopover}
        outline={'none'}
        p={'12px 16px'}
      >
        {isArrow && <PopoverArrow color={'white'} />}
        {isClose && <PopoverCloseButton />}
        {content}
      </PopoverContent>
    </Popover>
  )
}

export default PopoverCustom
