import { Text, Tooltip } from '@chakra-ui/react'
import React, { FC } from 'react'

type NormalColumnProps = { label: string }

const NormalColumn: FC<NormalColumnProps> = props => {
  const { label } = props

  return (
    <Tooltip bgColor="black" color="white" label={label} placement="top-start">
      <Text
        fontSize="sm"
        fontWeight="normal"
        lineHeight="14px"
        overflow="hidden"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {label}
      </Text>
    </Tooltip>
  )
}

export default NormalColumn
