import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
  breadcrumb: { text: string; link: string }[]
}

const initialState: InitialState = {
  breadcrumb: [],
}

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    /**
     * setBreadcrumb
     */
    setBreadcrumb(
      state: InitialState,
      action: PayloadAction<{ text: string; link: string }[]>,
    ) {
      state.breadcrumb = action.payload
    },
    /**
     * removeBreadcrumb
     */
    removeBreadcrumb(state: InitialState) {
      state.breadcrumb = []
    },
  },
})

export default breadcrumbSlice

export const { setBreadcrumb, removeBreadcrumb } = breadcrumbSlice.actions
