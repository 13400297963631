import React, { FC } from 'react'
import { Box, Flex, Image, List, ListItem } from '@chakra-ui/react'

import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { ROUTES, icons } from 'constant'
import { isEmpty } from 'lodash'
import { CompanyFavoriteProps, useCompanyFavorite } from './hook'

/**
 * CompanyFavorite molecules
 * @constructor
 */
export const CompanyFavorite: FC<CompanyFavoriteProps> = props => {
  const { companyFavorite, theme, navigate } = useCompanyFavorite(props)
  if (isEmpty(companyFavorite)) {
    return <></>
  }

  return (
    <Flex
      bgColor="white.base"
      borderRadius={4}
      gap="8px"
      minH={'48px'}
      p="12px 33px"
      width="100%"
    >
      <Box mt="3px">
        <Image src={icons.FAVORITE} />
      </Box>
      <List
        display="flex"
        gap="24px"
        overflowX="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: theme.space[2],
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.colors.black['150'],
            borderRadius: theme.radii.lg,
            border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
          },
        }}
        width="100%"
      >
        {companyFavorite.map((company, index) => (
          <ListItem
            key={index}
            color="blue.900"
            cursor="pointer"
            display="inline-block"
            fontSize="14px"
            fontWeight="normal"
            lineHeight="24px"
            maxW="84px"
            onClick={() => navigate(`${ROUTES.COMPANY}/${company.company_id}`)}
          >
            <TextOverflowTooltip isCursor value={company.company_name} />
          </ListItem>
        ))}
      </List>
    </Flex>
  )
}
