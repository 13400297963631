import { Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import { ROUTES, icons } from 'constant'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

type NewsDetailColumnProps = {
  label: string
  newsId: number
  isFavorite: boolean
  newsAiId: number
  company_id?: number
}

const NewsDetailColumn: FC<NewsDetailColumnProps> = props => {
  const { label, newsId, isFavorite, newsAiId, company_id } = props

  const urlToNews = company_id
    ? `${window.location.origin}${ROUTES.POST}/${newsId}?list_news_ai_id=${newsAiId}&&company_id=${company_id}`
    : `${window.location.origin}${ROUTES.POST}/${newsId}?list_news_ai_id=${newsAiId}`

  return (
    <Flex align={'center'} justify={'space-between'}>
      <Tooltip
        bgColor="black"
        color="white"
        label={label}
        placement="top-start"
      >
        {newsId ? (
          <Link
            style={{
              color: '#0084FF',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: '14px',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
            to={urlToNews}
          >
            {label}
          </Link>
        ) : (
          <Text
            color="blue.900"
            cursor="pointer"
            fontSize="sm"
            fontWeight="bold"
            lineHeight="14px"
            overflow="hidden"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {label}
          </Text>
        )}
      </Tooltip>
      {isFavorite && <Image src={icons.STAR} />}
    </Flex>
  )
}

export default NewsDetailColumn
