import { Flex, Skeleton, SkeletonText } from '@chakra-ui/react'
import React from 'react'

const SkeletonAI = () => {
  return (
    <Flex
      bgColor="gray.600"
      borderRadius={12}
      gap={3}
      h={'auto'}
      mb={'12px'}
      padding={3}
      w={'100%'}
    >
      <Flex w="100%">
        <Skeleton
          bg="green.500"
          borderRadius={'12px'}
          color="white"
          height="40px"
          w={'40px'}
        />
        <SkeletonText
          h={'80px'}
          ml={'12px'}
          noOfLines={3}
          skeletonHeight="4"
          spacing="4"
          w={'100%'}
        />
      </Flex>
    </Flex>
  )
}

export default SkeletonAI
