import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { Button } from 'components/fromAdvisor/elements'
import { icons } from 'constant'
import { useFormikContext } from 'formik'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { InitialValuesType } from 'components/molecules/SearchPost/hook'
import { MoreVertical } from '../Icons'
import RenderField from './RenderField'

type PopoverHeaderProps = {
  title: any
  meta: any
  handleSort: () => void
  state: {
    headerHover: string
    popoverOpen: string
  }
  setState: React.Dispatch<
    React.SetStateAction<{
      headerHover: string
      popoverOpen: string
    }>
  >
  field: string
  setPagination?: React.Dispatch<
    React.SetStateAction<{
      page_size: number
      page: number
      page_count: number
      total_count: number
    }>
  >
}
/**
 * PopoverHeader component
 */
const PopoverHeader: FC<PopoverHeaderProps> = ({
  title,
  meta,
  handleSort,
  state,
  setState,
  field,
  setPagination,
}) => {
  const { values, errors, setValues } = useFormikContext<InitialValuesType>()
  const [currentValue, setCurrentValue] = useState<InitialValuesType>(values)

  useEffect(() => {
    setCurrentValue(values)
  }, [values])

  const handleOnCancel = () => {
    setState(prev => ({ ...prev, popoverOpen: '', headerHover: '' }))
    setCurrentValue(prev => ({ ...prev, ...values }))
  }

  if (Boolean(meta.state)) {
    return <Text>{title}</Text>
  }

  return (
    <Popover
      flip={false}
      isOpen={state.popoverOpen === field}
      styleConfig={{ outline: 0 }}
      onOpen={() => setState(prev => ({ ...prev, popoverOpen: field }))}
    >
      <Flex
        align={'center'}
        flexWrap={'nowrap'}
        gap={'8px'}
        justify={'space-between'}
        minH={7}
      >
        {meta.textTooltip ? (
          <Tooltip
            aria-label="A tooltip"
            backgroundColor="#272727"
            color="#FEFEFE"
            label={meta.textTooltip as string}
            placement="top"
          >
            <Text>{title}</Text>
          </Tooltip>
        ) : (
          <Text>{title}</Text>
        )}
        {state.headerHover === field || state.popoverOpen === field ? (
          <Flex>
            {meta.isSort && (
              <Box onClick={handleSort}>
                {meta.sort_by === 'desc' ? (
                  <TriangleDownIcon />
                ) : (
                  <TriangleUpIcon />
                )}
              </Box>
            )}
            <PopoverTrigger>
              <Box>
                <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} />
              </Box>
            </PopoverTrigger>
          </Flex>
        ) : (
          <Box h={'24px'} minW={'24px'}></Box>
        )}
      </Flex>
      <PopoverContent
        bgColor={'#F4F7F9'}
        border={'none'}
        borderRadius={4}
        fontWeight={400}
        outline={0}
        p={'24px'}
        textTransform={'none'}
        w={meta.widthPopover || 316}
      >
        <RenderField
          currentValue={currentValue}
          errors={errors}
          meta={meta}
          setCurrentValue={setCurrentValue}
          title={title}
        />
        <Flex direction={'column'} gap="16px" mt={'24px'}>
          <Button
            bgColor={'blue.900'}
            border={'none'}
            color="white.50"
            fontSize={'16px'}
            fontWeight={'bold'}
            h={'48px'}
            leftIcon={<Image src={icons.GLASSES} />}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            text="絞り込み検索"
            w={'full'}
            onClick={() => {
              setValues(prev => ({
                ...prev,
                ...currentValue,
                subject_company: currentValue.subject_company.trim(),
                contact_person_name: currentValue.contact_person_name.trim(),
                news_title: currentValue.news_title.trim(),
              }))
              setPagination && setPagination(prev => ({ ...prev, page: 1 }))
              setState(prev => ({ ...prev, popoverOpen: '', headerHover: '' }))
            }}
          />
          <Button
            bgColor={'#fff'}
            border={'1px solid'}
            borderColor={'blue.900'}
            color="blue.900"
            fontSize={'16px'}
            fontWeight={'bold'}
            h={'48px'}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            text="とじる"
            w={'full'}
            onClick={handleOnCancel}
          />
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverHeader
