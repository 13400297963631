import axios from 'axios'
import { configs } from 'configs'

import { createMock } from './mock'

const instance = axios.create({
  baseURL: configs.apiURL,
  timeout: 60 * 1000,
})

/**
 * mock instance
 */
const instanceMock = axios.create({})
createMock(instanceMock)

export { instance, instanceMock }
