import React, { FC } from 'react'
import {
  Box,
  Flex,
  Text,
  useTheme,
  CircularProgress,
  Image,
} from '@chakra-ui/react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
} from '@chakra-ui/tabs'
import { useLocation } from 'react-router-dom'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { InputField, SelectField } from 'components/atoms'
import { FormikProvider, useFormik } from 'formik'
import { configs } from 'configs'
import { DefaultPromptTabContentProps } from './DefaultPromptTabContent.type'
import { DefaultPromptTabItem } from './DefaultPromptTabItem'

const DEFAULT_TAB = 0

const optionsSortBy = [{ label: '登録日', value: 'created_at' }]

/**
 * DefaultPromptTabContent component page.
 * @return {JSX.Element} DefaultPromptTabContent component.
 */
export const DefaultPromptTabContent: FC<DefaultPromptTabContentProps> = ({
  categories,
  handleOnChangeTabCategory,
  consultations,
  isLoadingFetchData,
  handleCheckFavouriteContent,
  handleSearchPrompt,
  handleSortPrompt,
  listHistorySearch,
}) => {
  const theme = useTheme()
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const defaultPromptTab = queryParams.get('defaultPromptTab')
  const defaultIndex = defaultPromptTab ? Number(defaultPromptTab) : DEFAULT_TAB

  const formik = useFormik({
    initialValues: {
      search_key: '',
      sort_by: 'created_at',
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { values, setFieldValue } = formik

  return (
    <FormikProvider value={formik}>
      <Box
        bg={'white.50'}
        borderBottomRadius={4}
        maxW={'calc(100vw - 48px)'}
        w={'full'}
      >
        <Box h={'calc(100vh - 146px)'} mx={6}>
          <Flex
            alignItems="center"
            gap="10px"
            justifyContent="space-between"
            padding="16px 0"
          >
            <InputField
              name="search_key"
              placeholder="フリーワード検索"
              width="242px"
              onChange={e => {
                formik.setFieldValue('search_key', e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSearchPrompt(formik.values.search_key, true)
                }
              }}
            />
            <Flex alignItems="center">
              <Text
                color="gray.800"
                fontSize="xs"
                fontWeight="normal"
                lineHeight="12px"
                minW="72px"
              >
                検索ワード：
              </Text>
              <Flex
                gap={2}
                maxW="calc(100vw - 420px)"
                overflowX="auto"
                sx={{
                  '&::-webkit-scrollbar': {
                    width: theme.space[2],
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: theme.colors.black['150'],
                    borderRadius: theme.radii.lg,
                    border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                  },
                }}
              >
                {listHistorySearch.data.map(({ id, search_content }) => (
                  <Text
                    key={id}
                    border="1px solid #F1F2F4"
                    borderRadius="100px"
                    color="blue.900"
                    cursor="pointer"
                    fontSize="sm"
                    fontWeight="normal"
                    h="32px"
                    lineHeight="14px"
                    minW="max-content"
                    p="7px 14px 11px 14px"
                    onClick={() => {
                      formik.setFieldValue('search_key', search_content)
                      handleSearchPrompt(search_content, false)
                    }}
                  >
                    {search_content}
                  </Text>
                ))}
              </Flex>
            </Flex>
          </Flex>
          <TabsChakra index={defaultIndex} variant="unstyled">
            <Flex
              alignItems="flex-start"
              gap={'32px'}
              justifyContent="space-between"
            >
              <TabList
                overflowX={'auto'}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: theme.space[2],
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: theme.colors.black['150'],
                    borderRadius: theme.radii.lg,
                    border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                  },
                }}
                w={'calc(100vw - 221px)'}
              >
                {categories.map((c, index) => (
                  <Tab
                    key={index}
                    _selected={{
                      color: 'black.100',
                      bg: 'gray.600',
                      borderRadius: '4px 4px 0 0',
                      fontWeight: 'semibold',
                    }}
                    color={'blue.900'}
                    fontSize={'sm'}
                    fontWeight={'normal'}
                    gap={2}
                    h={12}
                    letterSpacing={theme.letterSpacings.tighter}
                    lineHeight={theme.lineHeights.shorter}
                    minW="max-content"
                    padding="12px 32px"
                    onClick={() => handleOnChangeTabCategory(c.id, index)}
                  >
                    {defaultPromptTab && Number(defaultPromptTab) === index && (
                      <Image src={configs.apiURL + '/' + c.icons} />
                    )}

                    {c.ja_name}
                  </Tab>
                ))}
              </TabList>
              <Flex alignItems="center" gap="10px">
                <Text
                  color="black.100"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  minW="42px"
                >
                  並び順
                </Text>
                <SelectField
                  fontWeight="bold"
                  height="40px"
                  name="sort_by"
                  options={optionsSortBy}
                  valueOption={optionsSortBy.find(
                    o => o.value === values.sort_by,
                  )}
                  width="169px"
                  onChange={value => {
                    handleSortPrompt(String(value?.value))
                    setFieldValue('sort_by', value?.value)
                  }}
                />
              </Flex>
            </Flex>
            {isLoadingFetchData ? (
              <Flex
                alignItems={'center'}
                bg={'gray.600'}
                h={'calc(100vh - 295px)'}
                justifyContent={'center'}
                w={'full'}
              >
                <CircularProgress isIndeterminate color="blue.900" size={10} />
              </Flex>
            ) : (
              <TabPanels>
                {Array.from(Array(categories.length).keys()).map((c, idx) => (
                  <TabPanel key={idx} p={0}>
                    <Flex
                      bg={'gray.600'}
                      borderRadius="0 0 4px 4px"
                      flexDirection={'column'}
                      h={'calc(100vh - 295px)'}
                      overflowY={'auto'}
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: theme.space[2],
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: theme.colors.black['150'],
                          borderRadius: theme.radii.lg,
                        },
                      }}
                    >
                      <Flex flexDirection={'column'} gap="10px" padding="16px">
                        {consultations?.map((item, index) => (
                          <DefaultPromptTabItem
                            key={index}
                            handleCheckFavouriteContent={() =>
                              handleCheckFavouriteContent(item.id)
                            }
                            index={index}
                            item={item}
                          />
                        ))}
                      </Flex>
                    </Flex>
                  </TabPanel>
                ))}
              </TabPanels>
            )}
          </TabsChakra>
        </Box>
      </Box>
    </FormikProvider>
  )
}
