import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * ArrowDown icon
 * @param props
 * @constructor
 */
export const ArrowDown: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 13 8"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.499 8a.988.988 0 01-.726-.32L.301 1.863A1.14 1.14 0 01.3.32a.984.984 0 011.451 0L6.5 5.366 11.248.32a.984.984 0 011.451 0c.401.426.401 1.117 0 1.543L7.227 7.68a.998.998 0 01-.728.32z"
          fill="#0084FF"
        ></path>
      </svg>
    </Icon>
  )
}
