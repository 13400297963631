import { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { companyServices } from 'services'
import { CompanyFavoriteResponse } from 'services/companyServices'

export type CompanyFavoriteProps = {}

export const useCompanyFavorite = (props: CompanyFavoriteProps) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [companyFavorite, setCompanyFavorite] = useState<
    CompanyFavoriteResponse[]
  >([])

  const fetchCompanyFavorite = useCallback(async () => {
    try {
      const { data } = await companyServices.getCompanyFavorite({
        is_paginate: true,
      })

      setCompanyFavorite(data)
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetchCompanyFavorite()
  }, [fetchCompanyFavorite])

  return { ...props, companyFavorite, theme, navigate }
}
