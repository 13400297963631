import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { convertString } from 'components/fromAdvisor/templates/chatAI/tabs/ChatWithAI/components/MainChat/MainChat.utils'
import { askAIServices, chatAIServices, storageServices } from 'services'
import { Option } from 'types/conmon'
import { useParams } from 'react-router-dom'
import Handlebars from 'handlebars'
import { VariablesCompanyPromptType } from 'services/chatAIServices'

export type AIAdviceCompanyProps = {}
export const useAIAdviceCompany = (props: AIAdviceCompanyProps) => {
  const toast = useToast()
  const { id: companyId } = useParams()
  const accessToken = storageServices.getAccessToken()

  const [expanded, setExpanded] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [conversationId, setConversationId] = useState<number | null>(null)
  const [AIAnswer, setAIAnswer] = useState<{
    data: string
    loading: boolean
    done: boolean
  }>({
    data: '',
    loading: false,
    done: true,
  })
  const [optionPromptCompany, setOptionPromptCompany] = useState<Option[]>([])
  const [renderPrompt, setRenderPrompt] = useState('')
  const [variablePrompt, setVariablePrompt] =
    useState<VariablesCompanyPromptType>()

  const fetchOptionPropmtCompany = useCallback(async () => {
    try {
      const { data } = await askAIServices.getOptionPromptCompany()
      const convertOption = data.map(prompt => ({
        label: prompt.title,
        value: prompt.id,
      }))

      setOptionPromptCompany(convertOption)
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
      })
    }
  }, [toast])

  useEffect(() => {
    fetchOptionPropmtCompany()
  }, [fetchOptionPropmtCompany])

  const formik = useFormik<{ askAI: number | null }>({
    initialValues: {
      askAI: null,
    },
    /**
     *
     */
    onSubmit() {},
  })
  const { values, setFieldValue } = formik
  /**
   * Show all text
   */
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  /**
   * handleCloseModal
   */
  const handleCloseModal = () => {
    setExpanded(false)
  }

  const fetchContentPrompt = useCallback(async () => {
    try {
      if (!values.askAI || !companyId) return

      setAIAnswer(prev => ({ ...prev, data: '', loading: true, done: false }))

      const { data: dataPrompt } = await chatAIServices.getPromptCompanyDetail({
        prompt_id: +values.askAI,
        company_id: +companyId,
      })

      setVariablePrompt(dataPrompt.variables)

      let renderedPrompt = dataPrompt.prompt_content.trim()

      try {
        const template = Handlebars.compile(dataPrompt.prompt_content.trim())
        renderedPrompt = template(dataPrompt.variables)
      } catch (error) {
        console.log('error', error)
      }

      setRenderPrompt(renderedPrompt)
    } catch (error) {
      setAIAnswer(prev => ({ ...prev, data: '', loading: false, done: true }))
      toast({
        status: 'error',
        description: '何らかのエラーが発生しました。もう一度お試しください。',
      })
    }
  }, [companyId, toast, values.askAI])

  useEffect(() => {
    if (!accessToken || !renderPrompt) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/company/ai_advise/ws_stream`,
    )

    const handleSocketOpen = () => {
      console.log('WS connected')

      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }

      newSocket.send(JSON.stringify(messAuthen))

      const convertedString = convertString(renderPrompt || '')
      let message = {
        type: 'stream_req',
        data: {
          prompt_id: values.askAI,
          company_id: Number(companyId),
          prompt_html: `<p>${renderPrompt}</p>`,
          prompt_text: renderPrompt,
          prompt_question: convertedString ? convertedString : renderPrompt,
          current_prompt_id: null,
          ai_conversation_id: null,
          variables: variablePrompt,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)

      switch (objMess.type) {
        case 'additional_res':
          setConversationId(objMess?.data?.conversation_id)
          setAIAnswer(prev => ({
            ...prev,
            data: prev.data.replace(/```/g, ''),
            done: true,
          }))

          break

        case 'stream_res':
          setAIAnswer(prev => ({
            ...prev,
            data: (prev.data + objMess?.data).replace(/```html/g, ''),
            loading: false,
          }))
          break

        default:
          break
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('close', handleSocketClose)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.close()
    }
  }, [accessToken, companyId, renderPrompt, values.askAI, variablePrompt])

  useEffect(() => {
    fetchContentPrompt()
  }, [fetchContentPrompt])

  return {
    ...props,
    formik,
    optionPromptCompany,
    values,
    setAIAnswer,
    setConversationId,
    setFieldValue,
    AIAnswer,
    toggleExpanded,
    conversationId,
    expanded,
    setExpanded,
    setShowConfirmModal,
    handleCloseModal,
    showConfirmModal,
    setRenderPrompt,
  }
}
