import React, { useCallback, useState, MouseEvent } from 'react'
import * as Yup from 'yup'
import { ListConversationConverted } from 'services/chatAIServices'
import { FormValueFilter } from './ChatHistory.types'

type IErrors = {
  [key: string]: {
    message: string
  }
}

/**
 * @returns function that handle validate date start, end
 */
const validateStartEnd = (start: string, end: string, message: string) => {
  return Yup.date().test(end, message, function () {
    if (!this.parent[end] || !this.parent[start]) {
      return true
    }

    return new Date(this.parent[start]) <= new Date(this.parent[end])
  })
}

export const validationSchema = Yup.object().shape({
  startDate: validateStartEnd(
    'startDate',
    'endDate',
    '終了日には開始日以降の日付を指定して下さい',
  ),
  endDate: validateStartEnd(
    'startDate',
    'endDate',
    '開始日には終了日以前の日付を指定して下さい',
  ),
})

/**
 * @returns function that handle form filter history
 */
export const useFormFilterHistory = (
  onFilterConversation: (value?: FormValueFilter) => void,
  formValueFilter: FormValueFilter,
  setParamsFilterHistory: React.Dispatch<React.SetStateAction<FormValueFilter>>,
  onCloseModalFilter: () => void,
  setPageCount: React.Dispatch<React.SetStateAction<number>>,
  setConversationList: React.Dispatch<
    React.SetStateAction<ListConversationConverted>
  >,
) => {
  const [errors, setErrors] = useState<IErrors>()
  const [formValue, setFormValue] = useState<FormValueFilter>(formValueFilter)

  const handleCloseModal = () => {
    setFormValue(prev => ({ ...prev, ...formValueFilter }))
    onCloseModalFilter()
  }

  /**
   * @returns function that handle change input
   */
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked } = e.target
      setFormValue(prevState => ({
        ...prevState,
        [name]: name === 'bookmark' ? (checked ? true : undefined) : value,
      }))
    },
    [setFormValue],
  )

  /**
   * @returns function that handle change input
   */
  const handleInputDateChange = useCallback(
    (name: string, value?: Date) => {
      setFormValue(prevState => ({ ...prevState, [name]: value }))
    },
    [setFormValue],
  )

  const validation = useCallback(() => {
    setErrors(undefined)
    try {
      validationSchema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        if (error.inner) {
          const newErrors: IErrors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }
    return true
  }, [formValue])

  /**
   * @returns function that handle submit button filter
   */
  const handleSubmit = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (validation()) {
        setParamsFilterHistory(prev => ({ ...prev, ...formValue }))
        setPageCount(1)
        setConversationList(prev => ({
          ...prev,
          data: [],
          paginate: { pageSize: 20, page: 1, pageCount: 1, totalCount: 0 },
        }))
      }
    },
    [
      formValue,
      setConversationList,
      setPageCount,
      setParamsFilterHistory,
      validation,
    ],
  )

  return {
    errors,
    formValue,
    handleSubmit,
    handleInputChange,
    handleInputDateChange,
    handleCloseModal,
  }
}
