import React, { FC } from 'react'
import { Flex, Box, Text, SimpleGrid, CircularProgress } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import { FormikProvider } from 'formik'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { Button } from 'components/fromAdvisor/elements'
import WordCloud from '../WordCloud'
// import Echarts from '../Echarts'
import ChartWrapper from '../ChartWrapper'
import { optionAnalysisMenu, optionTargetDocument } from './data'
import { WordMapProps, useWordMap } from './hook'
import WordMapOptions from './WordMapOptions'
import AIExplanation from './AIExplanation'
import { ModalZoom } from './ModalZoom'

/**
 * WordMap molecules
 * @constructor
 */
const WordMap: FC<WordMapProps> = props => {
  const {
    formik,
    values,
    dataChart,
    setFieldValue,
    displaySizeChart,
    reportYearsOptions,
    reportQuartersOptions,
    setDataChart,
    termOptions,
    multiCompanyOptions,
    dataChartMore,
    handleSwitch,
    valueSwitch,
    valueExplanation,
    handleResetForm,
    handleOffSwitch,
    setYearQuaters,
    isNotWordmap,
    allChartRef,
    isZoom,
    handleOpenZoom,
    handleCloseZoom,
    infoModal,
    handleResetDataChart,
    setValues,
    sizeRef,
    containerChartHeight,
    containerChartWidth,
  } = useWordMap(props)
  return (
    <FormikProvider value={formik}>
      <Box bg="white.base" borderRadius={4} w="100%">
        <Flex
          alignItems="center"
          h={'64px'}
          justifyContent="space-between"
          px={'24px'}
          w="100%"
        >
          <Flex alignItems="center" h={'full'} w="100%">
            <Text
              color="black.100"
              display="inline-block"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
              minW="fit-content"
              px="8px"
            >
              ワードマップ
            </Text>
            <Flex align={'center'} h={'full'}>
              <Box mr={'16px'}>
                <SelectField
                  height="40px"
                  heightListOptions={'176px'}
                  isInForm={false}
                  menuPlacement="bottom"
                  name="analysis_menu"
                  options={optionAnalysisMenu}
                  placeholder=""
                  valueOption={optionAnalysisMenu.find(
                    it => it.value === values.analysis_menu,
                  )}
                  width="237px"
                  onChange={e => handleResetForm(e)}
                />
              </Box>
              <Flex
                align={'center'}
                borderLeft={'1px solid #E8ECEF'}
                gap={'4px'}
                h={'full'}
                px="12px"
              >
                <Flex align={'center'} gap={1}>
                  <SelectField
                    height="40px"
                    heightListOptions={'176px'}
                    isInForm={false}
                    menuPlacement="bottom"
                    name="targetDocument"
                    options={optionTargetDocument}
                    placeholder="資料"
                    valueOption={optionTargetDocument.find(
                      it => it.value === values.targetDocument,
                    )}
                    width="213px"
                    onChange={e => {
                      if (values.targetDocument === e?.value) return
                      setValues({
                        ...values,
                        targetDocument: String(e?.value) ?? '',
                        targetYear: '',
                        quarters: '',
                        term: '',
                        multi_company: [],
                      })
                      setYearQuaters({})
                      handleResetDataChart()
                    }}
                  />
                  <SelectField
                    height="40px"
                    heightListOptions={'176px'}
                    isInForm={false}
                    menuPlacement="bottom"
                    name="targetYear"
                    options={reportYearsOptions}
                    placeholder="年度"
                    valueOption={reportYearsOptions.find(
                      it => it.value === values.targetYear,
                    )}
                    width="125px"
                    onChange={e => {
                      if (values.targetYear === e?.value) return
                      setValues({
                        ...values,
                        targetYear: String(e?.value) ?? '',
                        quarters: '',
                        term: '',
                        multi_company: [],
                      })
                      handleResetDataChart()
                      handleOffSwitch()
                    }}
                  />
                  {!['2', '5'].includes(values.targetDocument) && (
                    <SelectField
                      height="40px"
                      heightListOptions={'176px'}
                      isInForm={false}
                      menuPlacement="bottom"
                      name="quarters"
                      options={reportQuartersOptions}
                      placeholder="四半期"
                      valueOption={reportQuartersOptions.find(
                        it => it.value === values.quarters,
                      )}
                      width="130px"
                      onChange={e => {
                        if (values.quarters === e?.value) return
                        setValues({
                          ...values,
                          quarters: String(e?.value) ?? '',
                          term: '',
                          multi_company: [],
                        })
                        handleResetDataChart()
                        handleOffSwitch()
                      }}
                    />
                  )}
                  {values.analysis_menu === '2' && (
                    <SelectField
                      height="40px"
                      heightListOptions={'176px'}
                      isInForm={false}
                      menuPlacement="bottom"
                      name="term"
                      options={termOptions}
                      placeholder="期分"
                      valueOption={termOptions.find(
                        it => it.value === values.term,
                      )}
                      width="110px"
                      onChange={e => {
                        if (values.term === e?.value) return
                        setFieldValue('term', e?.value)
                        handleOffSwitch()
                        handleResetDataChart()
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex align={'center'} gap={'6px'} minW={'fit-content'}>
            <Text
              fontSize="14px"
              fontWeight="normal"
              lineHeight="14px"
              minW="fit-content"
            >
              AI解説
            </Text>
            <Switch
              cusbgcolor={'#0084FF'}
              isChecked={valueSwitch}
              onChange={() => {
                handleSwitch()
              }}
            />
            <Button
              _hover={{
                bgColor: 'white.50',
              }}
              bgColor="white.50"
              border="solid 1px"
              borderColor="blue.900"
              borderRadius="12px"
              color="blue.900"
              fontSize="sm"
              fontWeight="bold"
              h="40px"
              isDisabled={isNotWordmap}
              lineHeight="shorter"
              padding="8px 16px"
              text="全画面表示"
              onClick={() => {
                handleOpenZoom({ isShowAll: true, typeChart: '', index: null })
              }}
            />
          </Flex>
        </Flex>
        <Flex
          bg="#F3F5F7"
          borderRadius={'0 0 20px 20px'}
          gap={4}
          h={'full'}
          minH={'164px'}
          p={'16px 24px 24px 24px'}
          w="100%"
        >
          {isNotWordmap && !dataChart.loading && (
            <Text
              color={'black.100'}
              fontSize={'16px'}
              textAlign={'center'}
              w="full"
            >
              データがありません。
            </Text>
          )}
          {dataChart.loading && (
            <Flex alignItems={'center'} justify={'center'} w={'full'}>
              <CircularProgress isIndeterminate color="blue.900" size={10} />
            </Flex>
          )}
          {values.analysis_menu === '0' && !isNotWordmap && (
            <>
              <Box width="100%">
                <Flex align={'center'} justify={'space-between'}>
                  <ChartWrapper
                    handleOpenZoom={() =>
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'wordcloud',
                        index: 0,
                      })
                    }
                    isShowButtonZoom={false}
                    label="重要なワードを見える化"
                  />
                </Flex>

                <Box
                  ref={sizeRef}
                  bgColor={'#FEFEFE'}
                  borderRadius={'12px'}
                  height={`${containerChartHeight}px`}
                  mb={'8px'}
                  width={`${containerChartWidth}px`}
                >
                  <WordCloud
                    height={containerChartHeight}
                    width={containerChartWidth}
                    words={dataChart.wordLesChart.dataWordCloud}
                  />
                </Box>
                {Boolean(values.analysis_menu === '0') && (
                  <AIExplanation
                    showContent={valueSwitch}
                    valueExplanation={valueExplanation}
                  />
                )}
              </Box>
              {/* {Boolean(!dataChart.wordLesChart.dataEchart.length) ? (
                <></>
              ) : dataChart.loading ? (
                <Box
                  bgColor={'#FEFEFE'}
                  borderRadius={'16px'}
                  h={'396px'}
                  mb="8px"
                  w={'full'}
                ></Box>
              ) : (
                <Box w="50%">
                  <Flex align={'center'} justify={'space-between'}>
                    <ChartWrapper
                      handleOpenZoom={() => {
                        handleOpenZoom({
                          isShowAll: false,
                          typeChart: 'echart',
                          index: null,
                        })
                      }}
                      label="各ワード同士の繋がり"
                    />
                  </Flex>
                  <Box
                    bgColor={'#FEFEFE'}
                    borderRadius={'12px'}
                    h={552}
                    mb="8px"
                    w={'full'}
                  >
                    <Echarts
                      ref={el => {
                        ref.current[1] = el
                      }}
                      data={dataChart.wordLesChart.dataEchart}
                      reloadChart={reloadChart}
                    />
                  </Box>
                  {Boolean(values.analysis_menu === '0') && (
                    <AIExplanation
                      content={valueExplanation.data[1]}
                      loading={valueExplanation.loading}
                      showContent={valueSwitch}
                    />
                  )}
                </Box>
              )} */}
            </>
          )}
          {values.analysis_menu !== '0' && !isNotWordmap && (
            <Flex direction={'column'} w={'100%'}>
              <AIExplanation
                showContent={valueSwitch}
                showDivider={true}
                valueExplanation={valueExplanation}
              />
              <WordMapOptions
                analysisMenuValue={values.analysis_menu}
                handleOffSwitch={handleOffSwitch}
                multiCompanyOptions={multiCompanyOptions}
                setDataChart={setDataChart}
                setFieldValue={setFieldValue}
                values={values}
              />
              <SimpleGrid
                ref={allChartRef}
                columns={displaySizeChart}
                spacing={'16px'}
                w={'100%'}
              >
                {(values.analysis_menu === '1'
                  ? dataChart.dataListWordCloud.data
                  : dataChart.dataTimeLine.data
                ).map((item, index) => (
                  <Box key={index} w={'100%'}>
                    <Flex align={'center'} justify={'space-between'}>
                      <ChartWrapper
                        handleOpenZoom={() => {
                          handleOpenZoom({
                            isShowAll: false,
                            typeChart: 'wordcloud',
                            index: index,
                          })
                        }}
                        label={item.title}
                      />
                    </Flex>
                    <Box
                      ref={sizeRef}
                      bgColor={'#FEFEFE'}
                      borderRadius={'12px'}
                      height={`${containerChartHeight}px`}
                      width={`${containerChartWidth}px`}
                    >
                      <WordCloud
                        height={containerChartHeight}
                        keySearch={values.search_key}
                        width={containerChartWidth}
                        words={item.words_cloud}
                      />
                    </Box>
                  </Box>
                ))}
                {dataChartMore.data.map((item, index) => (
                  <Box key={index} w={'100%'}>
                    <Flex align={'center'} justify={'space-between'}>
                      <ChartWrapper
                        handleOpenZoom={() => {
                          handleOpenZoom({
                            isShowAll: false,
                            typeChart: 'wordcloud',
                            index:
                              dataChart.dataListWordCloud.data.length + index,
                          })
                        }}
                        label={item.title}
                      />
                    </Flex>
                    <Box
                      bgColor={'#FEFEFE'}
                      borderRadius={'12px'}
                      height={`${containerChartHeight}px`}
                      width={`${containerChartWidth}px`}
                    >
                      <WordCloud
                        height={containerChartHeight}
                        keySearch={values.search_key}
                        width={containerChartWidth}
                        words={item.words_cloud}
                      />
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </Flex>
          )}
        </Flex>
      </Box>
      <ModalZoom
        analysisChart={values.analysis_menu}
        dataChart={dataChart}
        dataChartMore={dataChartMore}
        height={containerChartHeight}
        infoModal={infoModal}
        isOpen={isZoom}
        width={containerChartWidth}
        onClose={handleCloseZoom}
      />
    </FormikProvider>
  )
}

export default React.memo(WordMap)
