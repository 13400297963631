import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * CheckDone icon
 * @param props
 * @constructor
 */
export const CheckDone: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="22"
        viewBox="0 0 22 22"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM15.433 9.02001L10.296 14.146C10.142 14.311 9.93299 14.388 9.71299 14.388C9.50399 14.388 9.29499 14.311 9.12999 14.146L6.567 11.583C6.248 11.264 6.248 10.736 6.567 10.417C6.886 10.098 7.414 10.098 7.733 10.417L9.71299 12.397L14.267 7.85402C14.586 7.52402 15.114 7.52402 15.433 7.85402C15.752 8.17302 15.752 8.69001 15.433 9.02001Z"
          fill="#0CAF60"
        />
      </svg>
    </Icon>
  )
}
