/**
 * MessageIcon icon
 * @constructor
 */
export const MessageIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0019 2C17.5237 2 22 6.47657 22 11.9986C22 17.5207 17.5237 21.9972 12.0019 21.9972C10.5131 21.9972 9.09103 21.6639 7.82447 21.075C7.33562 20.8417 6.76908 20.8417 6.24691 21.0083L3.46936 21.9417C2.60276 22.2305 1.76948 21.3972 2.05834 20.5306L2.99169 17.7529C3.15835 17.2307 3.15828 16.664 2.92497 16.1751C2.33613 14.9085 2.00274 13.4863 2.00274 11.9975C2.00385 6.47652 6.48014 2 12.0019 2Z"
      fill="#0084FF"
    />
  </svg>
)
