import {
  Box,
  Center,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from 'components/atoms'
import { Checkbox } from 'components/fromAdvisor/elements/CheckBox'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { chatAIServices, consultationsServices } from 'services'
import { PaginateResponse } from 'types/conmon'
import { DetailPromptAdminResponse } from 'services/consultationsServices'
import { AxiosError } from 'axios'
import moment from 'moment'
import { ModalConfirm } from 'components/molecules'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES } from 'constant'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'

type ChatAIProps = {
  isTab: number
  values: { [name: string]: any }
  pagination: PaginateResponse
  setPagination: React.Dispatch<React.SetStateAction<PaginateResponse>>
  setState: Dispatch<
    SetStateAction<{ free_word: string; last_updated: string }>
  >
}

/**
 * ChatAI Page
 * @constructor
 */
export const ChatAI: FC<ChatAIProps> = ({
  values,
  pagination,
  setPagination,
  setState,
  isTab,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const toast = useToast()
  const navigate = useNavigate()
  const columnHelper = createColumnHelper<DetailPromptAdminResponse>()
  const [selectRecordId, setSelectRecordId] = useState<number[]>([])
  const [optionsCategoty, setOptionCategory] = useState<
    {
      label: string
      value: string | number
    }[]
  >()

  const [promptList, setPromptList] = useState<{
    loading: boolean
    data: DetailPromptAdminResponse[]
    last_updated?: string
  }>({ loading: false, data: [] })

  /**
   * handle click checkbox
   */
  const handleSelectRecord = (id: number) => {
    const idExist = selectRecordId.findIndex(p => p === id)
    if (idExist === -1) return setSelectRecordId(prev => [...prev, id])
    const newSelectRecordId = [...selectRecordId].filter(p => p !== id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleSelectRecordAll = () => {
    if (selectRecordId.length === promptList.data.length && selectRecordId)
      return setSelectRecordId([])
    const newSelectRecordId = promptList.data.map(p => p.id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleCancelSelected = () => {
    setSelectRecordId([])
  }

  const getListPromptAdmin = useCallback(
    async (page?: number) => {
      try {
        setPromptList(prev => ({ ...prev, loading: true }))
        setSelectRecordId([])
        const params = {
          is_tab: isTab,
          page_size: pagination.page_size,
          page: page || pagination.page,
          category_id: null,
          type: '',
          type_prompt: 'system',
          theme_id: null,
          search_key: values?.free_word ?? '',
        }
        const res = await consultationsServices.getListPromptAdmin(params)
        setPromptList(prev => ({
          ...prev,
          data: res.data,
          loading: false,
          last_updated: res.last_updated,
        }))
        if (res.paginate) {
          setPagination(res.paginate)
        }
        if (res.last_updated) {
          setState(prev => ({
            ...prev,
            last_updated: String(moment(res.last_updated).format('YYYY/MM/DD')),
          }))
        }
      } catch (error) {
        setPromptList(prev => ({ ...prev, loading: false }))
        if (error instanceof AxiosError) {
          console.log('error', error)
        }
      }
    },
    [
      isTab,
      pagination.page,
      pagination.page_size,
      setPagination,
      setState,
      values?.free_word,
    ],
  )
  /**
   * @returns function that handle open select category
   */
  const handleOpenSelectCategory = useCallback(async () => {
    try {
      const { data } = await chatAIServices.getOptionCategory()
      const options = data.map(o => ({
        label: o.ja_name,
        value: o.id,
      }))
      setOptionCategory(options)
    } catch (error) {}
  }, [])

  /**
   * handle click checkbox all
   */
  const handleDeleteMultiRecord = async () => {
    if (!selectRecordId) return
    try {
      await consultationsServices.removePromptAdmin({ ids: selectRecordId })
      setSelectRecordId([])
      getListPromptAdmin(1)
      toast({
        status: 'success',
        description: 'プロンプト削除が成功されました。',
        position: 'top',
      })
      onClose()
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
        position: 'top',
      })
    }
  }

  useEffect(() => {
    getListPromptAdmin()
    handleOpenSelectCategory()
  }, [getListPromptAdmin, handleOpenSelectCategory])

  /**
   * handle click checkbox all
   */
  const handleTogglePublicPrompt = async (id: number) => {
    const obj = promptList.data.find(item => item.id === id)
    if (!obj) return
    try {
      await consultationsServices.togglePublicPrompt(id, {
        public: !obj.public,
      })
      const newData = promptList.data.map(item =>
        item.id !== id ? { ...item } : { ...item, public: !item.public },
      )
      setPromptList(prev => ({ ...prev, data: newData }))
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
        position: 'top',
      })
    }
  }

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      size: 48,
      meta: {
        minWidthColumn: '48px',
      },
      cell: info => {
        const checked = selectRecordId.find(
          recordId => recordId === info.row.original.id,
        )
        return (
          <Center>
            <Checkbox
              isChecked={checked !== undefined ? true : false}
              onChange={() => {
                handleSelectRecord(info.row.original.id)
              }}
            />
          </Center>
        )
      },
      header: () => {
        return (
          <Center>
            <Checkbox
              isChecked={
                selectRecordId.length &&
                selectRecordId.length === promptList.data.length
                  ? true
                  : false
              }
              isDisabled={!promptList.data.length}
              onChange={handleSelectRecordAll}
            />
          </Center>
        )
      },
    }),
    columnHelper.accessor('id', {
      size: 63,
      meta: {
        minWidthColumn: '63px',
      },
      cell: info => (
        <Text
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
        >
          {Number(info.row.index) + 1}
        </Text>
      ),
      header: 'No.',
    }),
    columnHelper.accessor('title', {
      id: 'title',
      size: 400,
      meta: { colspan: 2, minWidthColumn: '400px' },
      cell: info => {
        const id = info.row.original.id
        return (
          <TextOverflowTooltip
            isCursor
            color="#0084FF"
            fontSize="sm"
            fontWeight="700"
            lineHeight="shorter"
            value={info.getValue()}
            onClick={() => navigate(`${ADMIN_ROUTES.PROMPT_DEATAIL}/${id}`)}
          />
        )
      },
      header: 'プロンプトタイトル',
    }),
    columnHelper.accessor('prompt', {
      size: 370,
      meta: { colspan: 0, minWidthColumn: '370px' },
      cell: info => (
        <TextOverflowTooltip
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
          value={info.getValue()}
        />
      ),
    }),
    columnHelper.accessor('category_id', {
      id: 'category_id',
      size: 240,
      meta: {
        minWidthColumn: '240px',
      },
      cell: info => (
        <TextOverflowTooltip
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
          value={
            optionsCategoty?.find(it => it.value === info.getValue())?.label ??
            ''
          }
        />
      ),
      header: 'カテゴリー',
    }),
    columnHelper.accessor('public', {
      id: 'public',
      size: 131,
      meta: {
        minWidthColumn: '131px',
      },
      cell: info => {
        const id = info.row.original.id
        return (
          <Flex gap="6px" justifyContent="end">
            <Text fontSize="14px">{info.getValue() ? '反映済' : '未'}</Text>
            <Switch
              defaultChecked={info.getValue()}
              onChange={() => handleTogglePublicPrompt(id)}
            />
          </Flex>
        )
      },
      header: '反映',
    }),
  ]
  return (
    <Box
      bgColor="#E8ECEF"
      borderBottomRadius={4}
      minHeight="calc(100vh - 216px)"
      padding="24px 24px 8px 24px"
      w="100%"
    >
      <Box bgColor="E8ECEF" borderRadius="12px">
        <DataTable
          bgColorTable="white.50"
          columns={columns}
          data={promptList.data}
          isPagination={true}
          isScroll={true}
          isShowAction={selectRecordId.length ? true : false}
          loadingData={promptList.loading}
          maxH={
            selectRecordId.length
              ? 'calc(100vh - 360px)'
              : 'calc(100vh - 296px)'
          }
          pagination={pagination}
          rangePage={[15, 30, 50]}
          setPagination={setPagination}
          onCancel={handleCancelSelected}
          onDelete={onOpen}
        />
        <ModalConfirm
          isOpen={isOpen}
          title="このプロンプトを削除します。
          よろしいですか？"
          onClose={onClose}
          onSubmit={handleDeleteMultiRecord}
        />
      </Box>
    </Box>
  )
}
