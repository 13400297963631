const ratio = 25

interface ILineHeights {
  [key: string]: string | number
}

export const lineHeights: ILineHeights = {
  normal: 'normal',
  none: 1,
  shorter: '24px',
  short: '30px',
  base: '32px',
  tall: '40px',
  taller: '2',
}

for (let i = 0; i < 10; i++) {
  lineHeights[i] = `${ratio * i}%`
}
