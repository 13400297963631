import { images } from 'constant/images'
import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { CheckDone } from 'components/atoms/Icons'
import { Navigate, useLocation } from 'react-router-dom'
import { storageServices } from 'services'
import axios, { AxiosError } from 'axios'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { loginSuccess, userLogin } from 'context/Auth/auth.slice'
import { useDispatch } from 'react-redux'
import { ROUTES } from 'constant'

/**
 * login page
 * @constructor
 */
export const ExternalAuthen: FC<{}> = () => {
  const dispatch = useDispatch()

  const { search } = useLocation()
  const queryString = new URLSearchParams(search)
  const companyId = queryString.get('company_id')
  const hashedToken = queryString.get('hashed_token')
  const accessToken = storageServices.getAccessToken()

  const [loading, setLoading] = useState(true)
  const [resError, setResError] = useState('')

  axios.defaults.headers.common = {
    'api-key': process.env.REACT_APP_API_KEY,
  }

  /**
   *
   */
  const handleVerifyAuthen = useCallback(async () => {
    if (!hashedToken) {
      setLoading(false)
      return
    }
    try {
      setResError('')

      const { data } = await axios({
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/external/kabuad/auth`,
        data: {
          hashed_token: hashedToken,
        },
      })

      if (data?.is_success === false) {
        setResError(data?.message)
        setLoading(false)
        return
      }

      if (data) {
        const { access_token, refresh_token, user_type } = data
        console.log('refresh_token', refresh_token)
        console.log('access_token', access_token)
        access_token && storageServices.setAccessToken(access_token)
        refresh_token && storageServices.setRefreshToken(refresh_token)
        user_type && storageServices.setRoleUser(user_type)
        dispatch(loginSuccess())
        dispatch(userLogin({ user: data }))
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      storageServices.removeAccessToken()
      storageServices.removeRefreshToken()
      storageServices.removeRoleUser()
      if (error instanceof AxiosError) {
        if (error.response) {
          setResError(error.response.data?.detail)
        } else {
          setResError(error.message)
        }
      }
    }
  }, [dispatch, hashedToken])

  useEffect(() => {
    handleVerifyAuthen()
  }, [handleVerifyAuthen])

  if (accessToken) {
    return <Navigate to={`${ROUTES.COMPANY}/${companyId}`} />
  }

  return (
    <Box
      bgImage={images.BACKGROUND_LOGIN}
      bgSize="cover"
      boxSizing="border-box"
      minH="100vh"
      padding="24px"
      position="relative"
      w="100%"
    >
      <Box
        bgColor="white.50"
        borderRadius="xl"
        h="calc(100vh - 48px)"
        maxW={647}
        ml={2}
        position="relative"
        w="100%"
      >
        <Flex
          alignItems="center"
          direction="column"
          h="100%"
          justifyContent="center"
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            px="40px"
          >
            {loading ? (
              <CircularProgress isIndeterminate color="blue.900" size={10} />
            ) : resError ? (
              <WarningTwoIcon color="red" fontSize="50px" />
            ) : (
              <CheckDone fontSize="50px" />
            )}
            {resError && (
              <Text fontSize="18px" fontWeight="700" lineHeight="24px" mt="8px">
                {resError}
              </Text>
            )}
          </Flex>
        </Flex>
      </Box>
      <Flex
        bottom={6}
        gap={4}
        justifyContent="flex-end"
        position="absolute"
        width="calc(100% - 48px)"
      >
        <Text
          color="white.base"
          fontSize="sm"
          fontWeight="bold"
          letterSpacing="-0.02em"
          lineHeight="shorter"
        >
          利用規約
        </Text>
        <Text
          color="white.base"
          fontSize="sm"
          fontWeight="bold"
          letterSpacing="-0.02em"
          lineHeight="shorter"
        >
          プライバシーポリシー
        </Text>
        <Text
          color="white.base"
          fontSize="sm"
          fontWeight="bold"
          letterSpacing="-0.02em"
          lineHeight="shorter"
        >
          運営会社
        </Text>
      </Flex>
    </Box>
  )
}
