import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { ADMIN_ROUTES, icons } from 'constant'
import { useNavigate } from 'react-router-dom'

/**
 * login page
 * @constructor
 */
export const AdminTopPage: FC = () => {
  const navigate = useNavigate()

  /**
   * @returns function that handle navigate
   */
  const handleNavigate = (path: string) => {
    navigate(path)
  }

  const menuTop = [
    {
      items: [
        {
          icon: icons.USER_LIST,
          title: 'ユーザー一覧',
          link: ADMIN_ROUTES.USER_LIST,
        },
        {
          icon: icons.AI_SETTING,
          title: 'AI条件設定',
          link: ADMIN_ROUTES.AI_SETTING,
        },
        {
          icon: icons.PROMPT_SETTING,
          title: 'プロンプト設定',
          link: ADMIN_ROUTES.PROMPT_SETTINGS,
        },
      ],
    },
    {
      items: [
        {
          icon: icons.COMPANY_SETTING,
          title: '企業データ連携',
          link: ADMIN_ROUTES.COMPANY_LIST,
        },
        {
          icon: icons.PRODUCT_SETTING,
          title: '商品設定',
          link: ADMIN_ROUTES.PRODUCT_LIST,
        },
        {
          icon: icons.ACCESS_ANALYSIS,
          title: 'アクセスログ',
          link: ADMIN_ROUTES.ACCESS_LOG,
        },
      ],
    },
  ]

  return (
    <Box
      alignItems="center"
      bgColor="white.50"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap="80px"
      height="calc(100vh - 88px)"
      justifyContent="center"
      w="100%"
    >
      {menuTop.map(it => (
        <Box display="flex" gap="100px">
          {it.items.map((i, index) => (
            <Flex
              key={index}
              _hover={{
                boxShadow:
                  '0px 33px 24px -17px rgba(0, 0, 0, 0.08), 0px 0px 25px 4px rgba(0, 0, 0, 0.03)',
              }}
              alignItems="center"
              border="1px solid #E8ECEF"
              borderRadius="20px"
              cursor="pointer"
              flexDirection="column"
              h={'224px'}
              justifyContent="center"
              padding="48px 0"
              width="224px"
              onClick={() => handleNavigate(i.link)}
            >
              <Image src={i.icon} />
              <Text
                fontSize="18px"
                fontWeight="700"
                lineHeight="32px"
                marginTop="16px"
              >
                {i.title}
              </Text>
            </Flex>
          ))}
        </Box>
      ))}
    </Box>
  )
}
