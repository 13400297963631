import { useCallback, useEffect, useState } from 'react'
import { chatAIServices } from 'services'

import {
  CategoryType,
  ConsultationConverted,
  HistorySearchType,
} from 'services/chatAIServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant'
import { useGetQueryParams } from 'navigations/getQueryParams'

/**
 * @returns function that handle any function for consultation list
 */
export const useDefaultPromptTabContent = () => {
  const navigate = useNavigate()
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [categorySelected, setCategorySelected] = useState<number | null>(null)
  const [consultations, setConsultations] = useState<ConsultationConverted[]>(
    [],
  )
  const [listConsultationParams, setListConsultationParams] = useState<{
    free_word: string
    sort_by: string
  }>({
    free_word: '',
    sort_by: 'created_at',
  })
  const [listHistorySearch, setListHistorySearch] = useState<{
    isLoading: boolean
    data: HistorySearchType[]
  }>({
    isLoading: false,
    data: [],
  })
  const [isLoadingFetchData, setIsLoadingFetchData] = useState<boolean>(false)
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const defaultPromptTab = queryParams.get('defaultPromptTab')

  /**
   * @returns function that handle fetch categories
   */
  const handleFetchCategories = async () => {
    try {
      const { data } = await chatAIServices.getOptionCategory()
      setCategories(data)
    } catch (error) {}
  }

  useEffect(() => {
    handleFetchCategories()
  }, [])

  /**
   * @returns function that handle fetch history search data
   */
  const handleFetchHistorySearch = useCallback(async () => {
    try {
      setListHistorySearch({ isLoading: true, data: [] })
      const { data } = await chatAIServices.getHistorySearchList()
      setListHistorySearch({ isLoading: false, data })
    } catch (error) {}
  }, [])

  useEffect(() => {
    handleFetchHistorySearch()
  }, [handleFetchHistorySearch])

  /**
   * @returns function that handle favorite item
   */
  const handleCheckFavouriteContent = async (id: number) => {
    const newState = consultations?.map(consultation =>
      consultation.id === id
        ? { ...consultation, is_favorite: !consultation.is_favorite }
        : consultation,
    )
    setConsultations(newState)

    const findConsultations = consultations?.find(
      consultation => consultation.id === id,
    )
    if (!findConsultations) return
    const body = {
      status: !findConsultations.is_favorite,
    }
    await chatAIServices.toggleFavorite(id, body)
  }

  /**
   * @returns function that handle set value search prompt
   */
  const handleSearchPrompt = async (value: string, isAddHistory: boolean) => {
    if (!value.trim()) return
    setListConsultationParams({
      ...listConsultationParams,
      free_word: value.trim(),
    })
    if (value && isAddHistory) {
      setListHistorySearch({ ...listHistorySearch, isLoading: true })
      try {
        await chatAIServices.handleHistorySearch({
          search_content: value.trim(),
        })
        const { data } = await chatAIServices.getHistorySearchList()
        setListHistorySearch({ ...listHistorySearch, isLoading: false, data })
      } catch (error) {
        setListHistorySearch({ ...listHistorySearch, isLoading: false })
      }
    }
  }

  /**
   * @returns function that handle set value sort prompt
   */
  const handleSortPrompt = async (value: string) => {
    setListConsultationParams({ ...listConsultationParams, sort_by: value })
  }

  /**
   * @returns function that handle fetch consultations
   */
  const handleFetchList = useCallback(async () => {
    const categoryId =
      categorySelected || categories[Number(defaultPromptTab)]?.id
    if (!categoryId) return
    setIsLoadingFetchData(true)
    try {
      const params = {
        category_id: categoryId,
        free_word: listConsultationParams.free_word,
        sort_by: listConsultationParams.sort_by,
        type_prompt: 'system',
      }
      const { data } = await chatAIServices.getConsultationList(params)

      setConsultations(data)
    } finally {
      setIsLoadingFetchData(false)
    }
  }, [categories, categorySelected, defaultPromptTab, listConsultationParams])

  useEffect(() => {
    handleFetchList()
  }, [handleFetchList])

  /**
   * @returns function that handle push categoryId and defaultPromptTab on params
   */
  const handleOnChangeTabCategory = (id: number, index: number) => {
    setCategorySelected(id)
    navigateWithQueryParams(
      navigate,
      `${ROUTES.CHAT_AI}`,
      'defaultPromptTab',
      index,
    )
  }

  return {
    isLoadingFetchData,
    handleOnChangeTabCategory,
    handleCheckFavouriteContent,
    categories,
    consultations,
    handleSearchPrompt,
    handleSortPrompt,
    listHistorySearch,
  }
}
