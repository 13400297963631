import { Box, CircularProgress, Flex, Text, useToast } from '@chakra-ui/react'
import { Button } from 'components/fromAdvisor/elements/Button'
import { FormikProvider, useFormik } from 'formik'
import { FC, useCallback, useEffect, useState } from 'react'
import { settingServices } from 'services'
import { useSelector } from 'react-redux'
import { auth } from 'context/Auth/auth.selector'
import { ConditionSettingField } from './components/SettingField'
import { aiSettingSchema } from './validation'

/**
 * ConditionSetting Page
 * @constructor
 */
export const ConditionSetting: FC = () => {
  const toast = useToast()
  const [loading, setLoading] = useState<boolean>(false)
  const currentUser = useSelector(auth)
  const [dataSetting, setDataSetting] = useState<{
    constraints: string
    action: string
  }>({
    constraints: '',
    action: '',
  })
  const [isDisableField, setIsDisableField] = useState<{
    constraints: boolean
    action: boolean
  }>({
    constraints: true,
    action: true,
  })

  const isDisableButton = !isDisableField.action || !isDisableField.constraints

  const formik = useFormik({
    initialValues: { constraints: '', action: '' },
    enableReinitialize: true,
    validationSchema: aiSettingSchema,
    /**
     *
     */
    async onSubmit(values: { constraints: string; action: string }) {
      try {
        await settingServices.changeSetting(currentUser?.user_id ?? 0, values)
        toast({
          status: 'success',
          description: '条件設定が更新されました。',
        })
        setDataSetting(values)
        setIsDisableField({
          constraints: true,
          action: true,
        })
      } catch (error: any) {
        toast({
          status: 'error',
          description: error.response
            ? error.response?.data?.message
            : error.message,
        })
      }
    },
  })
  const { isSubmitting, handleSubmit, values } = formik

  const isDisableSubmitBtn =
    values.action === dataSetting.action &&
    values.constraints === dataSetting.constraints

  const { setValues } = formik

  const getSetting = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await settingServices.getSetting()
      if (data) {
        setValues({ action: data.action, constraints: data.constraints })
        setDataSetting(prev => ({
          ...prev,
          constraints: data.constraints,
          action: data.action,
        }))
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [setValues])

  useEffect(() => {
    getSetting()
  }, [getSetting])

  const handleCancel = () => {
    setIsDisableField({ action: true, constraints: true })
    setValues(dataSetting)
  }
  if (loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <FormikProvider value={formik}>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minHeight="calc(100vh - 88px)"
        padding="24px 32px"
        w="100%"
      >
        <Text
          fontSize="28px"
          fontWeight="700"
          letterSpacing="-0.48px"
          lineHeight="40px"
          minW="158px"
          paddingBottom="16px"
        >
          AI条件設定
        </Text>
        <Box display="flex" gap="24px" height="calc( 100vh - 264px )">
          <Box
            backgroundColor={isDisableField.constraints ? '#FEFEFE' : '#F4F7F9'}
            border={isDisableField.constraints ? '2px solid #E8ECEF' : 'none'}
            borderRadius="20px"
            height="calc( 100vh - 264px )"
            p="24px"
            w="50%"
          >
            <ConditionSettingField
              iconLable
              changeDisableField={() =>
                setIsDisableField(prev => ({
                  ...prev,
                  constraints: !prev.constraints,
                }))
              }
              contentPopover={
                <Text
                  color={'#FEFEFE'}
                  fontSize="14px"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  lineHeight="24px"
                  textAlign="left"
                  whiteSpace="break-spaces"
                >
                  {`制約条件とは、AIに特定の応答を生成させるために設定される条件やルールのことです。  

［例］ 
・箇条書きにしてください。
・出力は#出力形式に基づいて行なってください。
・良いと思う提案順に並べてください。`}
                </Text>
              }
              disabled={isDisableField.constraints}
              height="calc( 100vh - 350px )"
              label="制約条件"
              maxLength={500}
              maxWidthPopover={'266px'}
              name="constraints"
              placeholder="500文字以内で入力してください"
              sizeLabel="20px"
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            backgroundColor={isDisableField.action ? '#FEFEFE' : '#F4F7F9'}
            border={isDisableField.action ? '2px solid #E8ECEF' : 'none'}
            borderRadius="20px"
            height="calc( 100vh - 264px )"
            p="24px"
            w="50%"
          >
            <ConditionSettingField
              iconLable
              changeDisableField={() =>
                setIsDisableField(prev => ({
                  ...prev,
                  action: !prev.action,
                }))
              }
              contentPopover={
                <Text
                  color={'#FEFEFE'}
                  fontSize="14px"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  lineHeight="24px"
                  textAlign="left"
                  whiteSpace="break-spaces"
                >
                  {`制約条件とは、AIに特定の応答を生成させるために設定される条件やルールのことです。  

［例］ 
・箇条書きにしてください。
・出力は#出力形式に基づいて行なってください。
・良いと思う提案順に並べてください。`}
                </Text>
              }
              disabled={isDisableField.action}
              height="calc( 100vh - 350px )"
              label="行動指針"
              maxLength={500}
              maxWidthPopover={'266px'}
              name="action"
              placeholder="500文字以内で入力してください"
              sizeLabel="20px"
              onChange={formik.handleChange}
            />
          </Box>
        </Box>

        {isDisableButton && (
          <Box display="flex" gap="16px" justifyContent="end" marginTop="24px">
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              height="48px"
              text="キャンセル"
              width="127px"
              onClick={handleCancel}
            />
            <Button
              backgroundColor="#0084FF"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="white.50"
              height="48px"
              isDisabled={isDisableSubmitBtn}
              isLoading={isSubmitting}
              text="登録する"
              width="206px"
              onClick={() => handleSubmit()}
            />
          </Box>
        )}
      </Box>
    </FormikProvider>
  )
}
