export const radii = {
  none: '0',
  sm: '2px',
  base: '4px',
  md: '6px',
  lg: '8px',
  xl: '12px',
  '2xl': '20px',
  '3xl': '24px',
  '4xl': '30px',
  '5xl': '40px',
  '6xl': '100px',
  full: '9999px',
}
