import React from 'react'
import { useToast } from '@chakra-ui/react'
import { NewsType } from 'services/newServices'
import { newServices } from 'services'
import { useNavigate } from 'react-router-dom'

export type CardPostContentProps = {
  formik: any
  page: number
  handleSetPagePdf: (value: string) => void
  setPage: (pageIndex: number) => void
  setCunrentPage: (pageIndex: number) => void
  newsId: string | undefined
  cunrentPage: number
  onCloseDrawer: () => void
  newsDetail: { data: NewsType; favorite: boolean; isLoading: boolean }
  setNewsDetail: (
    value: React.SetStateAction<{
      data: NewsType
      favorite: boolean
      isLoading: boolean
    }>,
  ) => void
}
export const useCardPostContent = (props: CardPostContentProps) => {
  const { newsId, newsDetail, setNewsDetail, formik } = props
  const toast = useToast()
  const navigate = useNavigate()

  const { values } = formik

  const handleToggleNewsFavorite = async () => {
    if (!newsId) return
    try {
      if (newsDetail.favorite) {
        await newServices.actionsRemoveFavoriteNews(newsId)
      } else {
        await newServices.actionsFavoriteNews(newsId)
      }
      setNewsDetail(prev => ({ ...prev, favorite: !prev.favorite }))
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message ?? 'Error',
      })
    }
  }

  const downloadPDF = () => {
    const url = newsDetail.data.company_report?.file_path
    const title = newsDetail.data.news_title

    if (!url) return
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = title
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(err => console.error('Error downloading PDF:', err))
  }

  return {
    ...props,
    formik,
    handleToggleNewsFavorite,
    values,
    navigate,
    downloadPDF,
  }
}
