import { Center, Flex, Image, Text } from '@chakra-ui/react'
import { Modal } from 'components/fromAdvisor/elements'
import { icons } from 'constant'
import React, { FC } from 'react'

type RegistrationLimitProps = {
  isOpen: boolean
  onClose: () => void
}

/**
 * RegistrationLimitModal molecules
 * @constructor
 */
const RegistrationLimitModal: FC<RegistrationLimitProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={true}
      isOpen={isOpen}
      maxWidth={'423px'}
      onClose={onClose}
    >
      <Flex
        bgColor={'white.150'}
        borderRadius={'20px'}
        direction={'column'}
        justify={'center'}
        p="56px 41px 69px 42px"
      >
        <Center mb={'8px'}>
          <Image src={icons.ERROR} />
        </Center>
        <Text
          align={'center'}
          color="red.700"
          fontSize="xl"
          fontWeight="bold"
          lineHeight="base"
        >
          スコア対象の登録上限を超えています
        </Text>
        <Text
          align={'center'}
          color="red.700"
          fontSize="xl"
          fontWeight="bold"
          lineHeight="base"
        >
          （登録上限：10個）
        </Text>
      </Flex>
    </Modal>
  )
}

export default RegistrationLimitModal
