import { AxiosInstance } from 'axios'
import MockAdapter from 'axios-mock-adapter'

/**
 * create mock axios adapter
 * @param {AxiosInstance} axios
 */
export const createMock = (axios: AxiosInstance): MockAdapter => {
  const mock = new MockAdapter(axios)

  mock.onPost('/login').reply(200, {})
  return mock
}
