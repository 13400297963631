import React from 'react'
import {
  Box,
  Flex,
  IconButton,
  useTheme,
  Tooltip,
  Text,
  Avatar,
} from '@chakra-ui/react'
import {
  FavouriteActiveIcon,
  FavouriteInActiveIcon,
  HashTagIcon,
} from 'components/fromAdvisor/elements/Icons'
import { SavedConsultationBoxProps } from './SavedConsultations.type'

/**
 * SavedConsultationBox component page.
 * @return {JSX.Element} SavedConsultationBox component.
 */
export const SavedConsultationBox = (props: SavedConsultationBoxProps) => {
  const { item, index, handleCheckFavouriteContent } = props
  const theme = useTheme()
  /**
   * @returns function that handle click favorite item
   */
  const handleClickFavourite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleCheckFavouriteContent()
  }

  /**
   * @returns function that handle click item
   */
  const handleClick = () => {}

  return (
    <Flex
      key={index}
      _hover={{
        background: 'blue.200',
      }}
      alignItems="center"
      bg={'white.50'}
      borderRadius="xl"
      cursor={'pointer'}
      gap={8}
      h={12}
      justifyContent="space-between"
      padding="12px 16px"
      onClick={handleClick}
    >
      <Flex alignItems="center" gap={1}>
        <Box h={5} minW={5}>
          <HashTagIcon fill={theme.colors.blue[900]} />
        </Box>
        <Tooltip
          bgColor="black"
          color="white"
          label={item.title}
          maxW="calc(100vw - 393px)"
          placement="top-start"
        >
          <Text
            color="blue.900"
            cursor="pointer"
            fontSize="sm"
            fontWeight="normal"
            lineHeight="shorter"
            maxW="calc(100vw - 393px)"
            overflow="hidden"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.title}
          </Text>
        </Tooltip>
      </Flex>
      <Flex alignItems="center" gap={2} justifyContent="space-between">
        {item.avatar_url && (
          <Avatar bg="teal.500" size="xs" src={item.avatar_url} />
        )}
        <Text
          border="1px solid"
          borderColor="blue.900"
          borderRadius="4px"
          color="blue.900"
          fontSize="sm"
          fontWeight="normal"
          h="24px"
          lineHeight="14px"
          minW="fit-content"
          padding="4px 6px 6px 6px"
        >
          {item.ja_name}
        </Text>
        <IconButton
          _hover={{
            transform: 'scale(1.2)',
          }}
          aria-label="favourite"
          bg="transparent"
          h={6}
          icon={
            item?.is_favorite ? (
              <FavouriteActiveIcon />
            ) : (
              <FavouriteInActiveIcon fill={theme.colors.blue[900]} />
            )
          }
          minW={6}
          onClick={handleClickFavourite}
        />
      </Flex>
    </Flex>
  )
}
