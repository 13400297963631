import { FC, ReactElement } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { Box, Text } from '@chakra-ui/react'
import { BinIcon } from 'components/atoms/Icons'

/**
 * ModalDeletePrompt component page.
 * @return {JSX.Element} ModalDeletePrompt component.
 */
export const ModalDeletePrompt: FC<{
  isOpen: boolean
  onClose: () => void
  handleRemovePrompt: () => void
  isLoading: boolean
}> = ({ isOpen, onClose, handleRemovePrompt, isLoading }): ReactElement => {
  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxWidth={'360px'}
      onClose={onClose}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p="32px 40px"
      >
        <BinIcon />
        <Text
          fontSize="20px"
          fontWeight="700"
          lineHeight="32px"
          marginTop="6px"
          textAlign="center"
        >
          このプロンプトを削除します。 よろしいですか？
        </Text>
        <Button
          backgroundColor="#0084FF"
          border="1px solid #0084FF"
          borderRadius="12px"
          color="white.50"
          fontWeight={'bold'}
          height="48px"
          isLoading={isLoading}
          marginTop="56px"
          text="削除する"
          width="100%"
          onClick={handleRemovePrompt}
        />
        <Button
          backgroundColor="#FEFEFE"
          border="1px solid #0084FF"
          borderRadius="12px"
          color="#0084FF"
          fontWeight={'bold'}
          height="48px"
          marginTop="16px"
          text="キャンセル"
          width="100%"
          onClick={onClose}
        />
      </Box>
    </Modal>
  )
}
