import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'libs'

/**
 *
 */
const breadcrumbSelector = (state: RootState) => state.breadcrumb

export const BreadcrumDetailSelector = createSelector(
  breadcrumbSelector,
  breadcrumbSlice => breadcrumbSlice.breadcrumb,
)
