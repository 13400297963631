import { FC } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { Box, Flex } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import { FormikProvider } from 'formik'
import { AddVariableModalProps, useAddVariableModal } from './hook'

/**
 * ModalDeletePrompt component page.
 * @return {JSX.Element} ModalDeletePrompt component.
 */
export const AddVariableModal: FC<AddVariableModalProps> = props => {
  const {
    values,
    setFieldValue,
    formik,
    isOpen,
    onAddVariable,
    onClose,
    dummyOption,
  } = useAddVariableModal(props)
  return (
    <FormikProvider value={formik}>
      <Modal
        isShowIconClose
        closeOnOverlayClick={false}
        isOpen={isOpen}
        maxWidth={'500px'}
        onClose={() => {
          onClose()
          setFieldValue('variable_name', '')
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap={'16px'}
          p="32px 40px"
        >
          <SelectField
            height="40px"
            isInForm={false}
            name="variable_name"
            options={dummyOption}
            placeholder="変数追加"
            valueOption={dummyOption.find(
              it => it.value === values.variable_name,
            )}
            width="full"
            onChange={e => {
              setFieldValue('variable_name', e?.value)
            }}
          />
          <Flex gap={'8px'} justify={'space-between'} w={'full'}>
            <Button
              backgroundColor="#0084FF"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="white.50"
              fontWeight={'bold'}
              height="48px"
              isDisabled={!values.variable_name}
              text="追加する"
              width="100%"
              onClick={() => {
                onAddVariable(values.variable_name)
                setFieldValue('variable_name', '')
              }}
            />
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              fontWeight={'bold'}
              height="48px"
              text="キャンセル"
              width="100%"
              onClick={() => {
                onClose()
                setFieldValue('variable_name', '')
              }}
            />
          </Flex>
        </Box>
      </Modal>
    </FormikProvider>
  )
}
