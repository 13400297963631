import { Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { FC } from 'react'

type NewsDateColumnProps = {
  date: string
}

const NewsDateColumn: FC<NewsDateColumnProps> = props => {
  const { date } = props

  return (
    <Text
      fontSize="sm"
      fontWeight="normal"
      lineHeight="14px"
      minW={'fit-content'}
    >
      {moment(date).format('YYYY/MM/DD')}
    </Text>
  )
}

export default NewsDateColumn
