import { IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * DeleteIcons icon
 * @param props
 * @constructor
 */
export const DeleteIcons: FC<IconProps> = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2308 3.07692H15.5805C14.6564 3.07692 14.6174 2.96 14.3641 2.20102L14.1569 1.57846C13.842 0.634873 12.9631 0 11.9682 0H8.03179C7.03692 0 6.15692 0.633848 5.84308 1.57846L5.6359 2.20102C5.38257 2.96102 5.34359 3.07692 4.41949 3.07692H0.769231C0.344615 3.07692 0 3.42154 0 3.84615C0 4.27077 0.344615 4.61539 0.769231 4.61539H2.10051L2.88718 16.4092C3.03898 18.6913 4.43796 20 6.72514 20H13.2759C15.5621 20 16.961 18.6913 17.1139 16.4092L17.9005 4.61539H19.2308C19.6554 4.61539 20 4.27077 20 3.84615C20 3.42154 19.6554 3.07692 19.2308 3.07692ZM7.30256 2.06462C7.4082 1.74974 7.70051 1.53846 8.03179 1.53846H11.9682C12.2995 1.53846 12.5928 1.74974 12.6974 2.06462L12.9046 2.68717C12.9497 2.82153 12.9949 2.9518 13.0441 3.07692H6.95385C7.00308 2.95077 7.04924 2.82051 7.09437 2.68717L7.30256 2.06462ZM15.5774 16.3067C15.48 17.7764 14.7477 18.4615 13.2749 18.4615H6.7241C5.25128 18.4615 4.52 17.7774 4.42154 16.3067L3.64205 4.61539H4.41846C4.54667 4.61539 4.65334 4.60206 4.76821 4.59385C4.80308 4.59898 4.83487 4.61539 4.87077 4.61539H15.1272C15.1641 4.61539 15.1949 4.59898 15.2297 4.59385C15.3446 4.60206 15.4513 4.61539 15.5795 4.61539H16.3559L15.5774 16.3067ZM12.8205 8.97436V14.1026C12.8205 14.5272 12.4759 14.8718 12.0513 14.8718C11.6267 14.8718 11.2821 14.5272 11.2821 14.1026V8.97436C11.2821 8.54974 11.6267 8.20513 12.0513 8.20513C12.4759 8.20513 12.8205 8.54974 12.8205 8.97436ZM8.71795 8.97436V14.1026C8.71795 14.5272 8.37333 14.8718 7.94872 14.8718C7.5241 14.8718 7.17949 14.5272 7.17949 14.1026V8.97436C7.17949 8.54974 7.5241 8.20513 7.94872 8.20513C8.37333 8.20513 8.71795 8.54974 8.71795 8.97436Z"
        fill="white"
      />
    </svg>
  )
}
