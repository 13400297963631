/**
 * PaperPlaneIcon icon
 * @constructor
 */
export const PaperPlaneIcon = () => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0046 0C37.2568 0 48 10.7438 48 23.9967C48 37.2497 37.2568 47.9933 24.0046 47.9933C20.4315 47.9933 17.0185 47.1934 13.9787 45.7801C12.8055 45.2201 11.4458 45.22 10.1926 45.62L3.52646 47.86C1.44663 48.5533 -0.553258 46.5533 0.140018 44.4734L2.38007 37.8069C2.78003 36.5536 2.77988 35.1937 2.21992 34.0204C0.806708 30.9804 0.00656573 27.5672 0.00656573 23.9939C0.00923218 10.7437 10.7523 0 24.0046 0Z"
      fill="#0084FF"
    />
    <path
      d="M32.6163 21.5673L20.8983 14.5671C19.3276 13.6261 17.4097 13.8744 16.1266 15.1766C14.8508 16.4726 14.638 18.3812 15.5974 19.9245L18.1318 24.0003L15.5974 28.0761C14.6369 29.6194 14.8497 31.528 16.1266 32.8241C16.8899 33.5988 17.8769 34 18.8836 34C19.5678 34 20.2624 33.8143 20.8983 33.4336L32.6163 26.4333C33.4834 25.9161 34 25.0059 34 24.0003C34 22.9947 33.4834 22.0845 32.6163 21.5673ZM31.8263 25.1127L20.1083 32.1129C18.9382 32.8107 17.8235 32.352 17.2256 31.7446C16.6339 31.1432 16.1945 30.034 16.9064 28.8878L19.4673 24.7689H25.0114C25.4367 24.7689 25.7818 24.4241 25.7818 23.9993C25.7818 23.5745 25.4367 23.2297 25.0114 23.2297H19.4673L16.9064 19.1108C16.1945 17.9646 16.6329 16.8553 17.2256 16.254C17.6098 15.8641 18.2067 15.5347 18.8949 15.5347C19.2781 15.5347 19.6892 15.6363 20.1083 15.8866L31.8263 22.887C32.2229 23.124 32.4591 23.5396 32.4591 23.9993C32.4591 24.459 32.2229 24.8756 31.8263 25.1127Z"
      fill="#FEFEFE"
    />
  </svg>
)
