import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { FormikProvider, useFormik } from 'formik'
import React, { FC, useState } from 'react'
import { importanceOption } from 'utils'
import RegistrationLimitModal from '../RegistrationLimitModal'

type ProductImportanceProps = {
  isOpen: boolean
  onClose: () => void
  handleUpdateScoreProduct: (values: { score_target: string }) => void
}

/**
 * ProductImportanceModal molecules
 * @constructor
 */
const ProductImportanceModal: FC<ProductImportanceProps> = ({
  isOpen,
  onClose,
  handleUpdateScoreProduct,
}) => {
  const {
    isOpen: isOpenErrorModal,
    // onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      score_target: '',
    },
    /**
     *
     */
    onSubmit: async (values: { score_target: string }) => {
      try {
        setLoading(true)
        await handleUpdateScoreProduct(values)
        onClose()
        resetForm()
        setLoading(false)
        // onOpenErrorModal()
      } catch (error) {
        setLoading(false)
      }
    },
  })
  const { values, setFieldValue, handleSubmit, resetForm } = formik

  /**
   *
   */
  const handleCloseModal = () => {
    setFieldValue('score_target', '')
    onClose()
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Modal
          isShowIconClose
          closeOnOverlayClick={true}
          isOpen={isOpen}
          maxWidth={'440px'}
          onClose={handleCloseModal}
        >
          <Box bgColor={'white.150'} borderRadius={'20px'} p="32px 40px">
            <Text
              align={'center'}
              color="black.100"
              fontSize="xl"
              fontWeight="bold"
              lineHeight="base"
            >
              選択した商品の
            </Text>
            <Text
              align={'center'}
              color="black.100"
              fontSize="xl"
              fontWeight="bold"
              lineHeight="base"
            >
              重要度をレベル1〜5で
            </Text>
            <Text
              align={'center'}
              color="black.100"
              fontSize="xl"
              fontWeight="bold"
              lineHeight="base"
              mb={'40px'}
            >
              設定してください
            </Text>
            <Box mb={'40px'}>
              <SelectField
                colorLabel="black.100"
                height="48px"
                label="重要度"
                name="score_target"
                options={importanceOption}
                placeholder="選択してください"
                sizeLabel="14px"
                valueOption={importanceOption.find(
                  it => it.value === values.score_target,
                )}
                onChange={e => setFieldValue('score_target', e?.value)}
              />
            </Box>
            <Flex direction={'column'} gap={6} mt={'40px'}>
              <Button
                bgColor={'blue.900'}
                border="solid 1px"
                borderColor="blue.900"
                borderRadius={'12px'}
                color="white.50"
                fontSize={'md'}
                fontWeight={'bold'}
                h={'48px'}
                isDisabled={!Boolean(formik.values.score_target)}
                isLoading={loading}
                letterSpacing={'tighter'}
                lineHeight={'shorter'}
                p="12px"
                text="スコア対象として登録する"
                w={'full'}
                onClick={() => {
                  handleSubmit()
                }}
              />
              <Button
                bgColor={'white.base'}
                border="solid 1px"
                borderColor="blue.900"
                borderRadius={'12px'}
                color="blue.900"
                fontSize={'md'}
                fontWeight={'bold'}
                h={'48px'}
                letterSpacing={'tighter'}
                lineHeight={'shorter'}
                p="11px, 24px, 13px, 24px"
                text="キャンセル"
                w={'full'}
                onClick={handleCloseModal}
              />
            </Flex>
          </Box>
        </Modal>
      </FormikProvider>
      <RegistrationLimitModal
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
      />
    </>
  )
}

export default ProductImportanceModal
