import { Box, Flex, Image } from '@chakra-ui/react'
import { icons } from 'constant'
import { FC, useCallback } from 'react'

type Props = {
  value: number
  isDisable?: boolean
  type?: string
}
/**
 * RattingImportance components
 * @constructor
 */
export const RattingImportance: FC<Props> = ({
  value,
  isDisable = false,
  type = 'circle',
}) => {
  const handleRenderRate = useCallback(
    ({ item, index }: { item: number; index: number }) => {
      switch (type) {
        case 'star':
          return <Image src={item >= value ? icons.EMPTY_STAR : icons.STAR} />

        default:
          return (
            <Box
              key={index}
              alignItems="center"
              backgroundColor={
                index + 1 <= value && !isDisable ? 'blue.900' : 'purple.250'
              }
              borderRadius="50%"
              color={
                index + 1 <= value && !isDisable ? 'white.base' : '#C4C4C4'
              }
              display="flex"
              fontSize="12px"
              height="15px"
              justifyContent="center"
              w="15px"
            >
              {item + 1}
            </Box>
          )
      }
    },
    [isDisable, type, value],
  )
  return (
    <Flex alignItems="center" gap="3px" height="100%">
      {Array.from(Array(5).keys()).map((item, index) => {
        return handleRenderRate({ item, index })
      })}
    </Flex>
  )
}
