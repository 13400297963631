import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * EyeOff icon
 * @param props
 * @constructor
 */
export const EyeOff: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5721 10.179C23.249 11.299 23.249 12.702 22.5721 13.822C21.0582 16.3448 17.6735 20.6155 11.9997 20.6155C11.0791 20.6155 10.2127 20.4986 9.40776 20.2955C9.1333 20.2266 9.04699 19.8748 9.2476 19.6753L11.5811 17.3419C11.7239 17.1991 11.9012 17.231 11.9997 17.231C14.8846 17.231 17.2304 14.8841 17.2304 12.0005C17.2304 11.902 17.2158 11.7075 17.3179 11.6053L20.6926 8.23078C20.8489 8.07448 21.1122 8.0906 21.2488 8.26414C21.7903 8.94841 22.2312 9.60427 22.5721 10.179ZM23.7289 1.57628L1.57514 23.7293C1.39546 23.9089 1.15911 24 0.922802 24C0.686496 24 0.450152 23.9102 0.270461 23.7293C-0.0901536 23.3687 -0.0901536 22.784 0.270461 22.4234L4.83054 17.8636C3.26747 16.5331 2.13377 14.9986 1.4273 13.8208C0.750388 12.7008 0.750388 11.2978 1.4273 10.1778C2.94115 7.65484 6.32587 3.38422 11.9997 3.38422C14.2446 3.38422 16.1191 4.0573 17.6773 5.01726L22.4243 0.270451C22.7849 -0.0901504 23.3695 -0.0901504 23.7301 0.270451C24.0907 0.631052 24.0895 1.21568 23.7289 1.57628ZM7.71666 14.9776L9.0568 13.6374C8.78357 13.1513 8.61509 12.5986 8.61509 12.0005C8.61509 11.5697 8.70118 11.1636 8.84886 10.7821C9.08272 10.979 9.37804 11.1021 9.71034 11.1021C10.4734 11.1021 11.1013 10.4742 11.1013 9.71119C11.1013 9.3789 10.9782 9.08358 10.7813 8.84975C11.1628 8.70206 11.5689 8.61598 11.9997 8.61598C12.5978 8.61598 13.1518 8.78446 13.6367 9.05767L14.9768 7.71758C14.1301 7.12438 13.1062 6.7699 11.9997 6.7699C9.11971 6.7699 6.76895 9.12058 6.76895 12.0005C6.76895 13.1081 7.12343 14.1307 7.71666 14.9776Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}
