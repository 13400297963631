import { useField } from 'formik'
import React, { FC } from 'react'
import { Flex, FormControl, FormLabel, useTheme, Text } from '@chakra-ui/react'
import Select, { MultiValue } from 'react-select'
import { Option } from 'types/conmon'
import { ArrowDown, QuestionCircle } from '../Icons'

export type MultiSelectFieldProps = {
  name: string
  placeholder?: string
  label?: string
  height?: string
  width?: string
  maxWidth?: string
  minHeight?: string
  error?: boolean | undefined
  errorText?: any
  options: Option[]
  handleScrollBottom?: () => void
  onChange: (newValue: MultiValue<Option>) => void
  isDisabled?: boolean
  iconLable?: boolean
  colorLabel?: string
  sizeLabel?: string
  fontWeight?: string | number
  fontSize?: string
  fontWeightLabel?: string
  test?: boolean
  subLabel?: string
  isSearchable?: boolean
  onFocus?: () => void
  isInForm?: boolean
  sizePlaceholder?: string
  heightListOptions?: string
  isResetValue?: boolean
  isShowCompanySelected?: boolean
}
/**
 * MultiSelectField component
 * @constructor
 */
export const MultiSelectField: FC<MultiSelectFieldProps> = ({
  name,
  options,
  label,
  placeholder,
  height,
  width,
  maxWidth,
  minHeight,
  handleScrollBottom,
  onChange,
  onFocus,
  isDisabled = false,
  iconLable = false,
  colorLabel,
  sizeLabel,
  error,
  errorText,
  fontWeight = 'normal',
  fontSize = '14px',
  fontWeightLabel,
  subLabel,
  isSearchable = false,
  sizePlaceholder = '16px',
  heightListOptions = '312px',
  isResetValue = false,
  isInForm = true,
  // isShowCompanySelected = false,
}) => {
  const [field, meta, helpers] = useField(name)
  const { value, touched } = meta
  const { setTouched } = helpers
  const theme = useTheme()
  /**
   * customDropdownIndicator
   */
  const customDropdownIndicator = () => (
    <Flex
      alignItems="center"
      display={isDisabled ? 'none' : 'block'}
      justifyContent="center"
    >
      <ArrowDown fontSize="24px" />
    </Flex>
  )
  const customOption = isResetValue
    ? [{ value: '', label: '(選択なし）' }, ...options]
    : options

  // const ValueContainer = (props: any) => {
  //   const { getValue, children, hasValue } = props
  //   let maxToShow = 1
  //   var length = getValue().length
  //   let displayChips = React.Children.toArray(children).slice(0, maxToShow)
  //   let shouldBadgeShow = length > maxToShow
  //   let displayLength = length - maxToShow

  //   if (!hasValue) {
  //     return (
  //       <components.ValueContainer {...props}>
  //         {children}
  //       </components.ValueContainer>
  //     )
  //   }
  //   if (isShowCompanySelected) {
  //     return (
  //       <components.ValueContainer {...props}>
  //         <div style={{ display: 'flex' }}>
  //           {!props.selectProps.inputValue && displayChips}
  //         </div>
  //         <div style={{ color: 'black' }}>
  //           {shouldBadgeShow &&
  //             `+ ${displayLength} compan${length >= 3 ? 'ies' : 'y'} selected`}
  //         </div>
  //       </components.ValueContainer>
  //     )
  //   } else {
  //     return (
  //       <components.ValueContainer {...props}>
  //         <Text color={'black'}>
  //           {length > 0
  //             ? `${length} compan${length >= 2 ? 'ies' : 'y'} selected`
  //             : children}
  //         </Text>
  //       </components.ValueContainer>
  //     )
  //   }
  // }

  return (
    <FormControl maxWidth={maxWidth} width={width}>
      {label && (
        <FormLabel
          alignItems="center"
          color={colorLabel}
          display="flex"
          fontSize={sizeLabel}
          fontWeight={fontWeightLabel ? fontWeightLabel : '700'}
          gap="8px"
          lineHeight="24px"
          margin="0 0 8px 0"
        >
          <Flex>
            {label}
            {subLabel && <Text color={'gray.800'}>{subLabel}</Text>}
            {iconLable && <QuestionCircle />}
          </Flex>
        </FormLabel>
      )}
      <Select
        components={{
          DropdownIndicator: customDropdownIndicator,
          // ValueContainer,
        }}
        hideSelectedOptions={false}
        isClearable={false}
        isDisabled={isDisabled}
        isLoading={false}
        isMulti={true}
        isOptionDisabled={() => value.length >= 2}
        isRtl={false}
        isSearchable={isSearchable}
        menuPlacement={'auto'}
        name={name}
        noOptionsMessage={() => <Text>データがありません。</Text>}
        options={customOption}
        placeholder={placeholder}
        styles={{
          singleValue: base => ({
            ...base,
            color: '#141718',
            fontSize: '14px',
            lineHeight: '24px',
          }),
          control: (base, { isDisabled }) => ({
            ...base,
            height: height,
            maxWidth: maxWidth,
            borderRadius: '12px',
            boxShadow: '0px 5px 10px -5px #1417181A inset',
            border:
              error && touched ? '1px solid #F75555' : '1px solid #E8ECEF',
            gap: '10px',
            color: isDisabled ? '#aaa ' : theme.colors.white[50],
            '&:hover': {
              borderStyle: 'solid',
              borderColor: theme.colors.blue[900],
              cursor: 'pointer',
              borderWidth: '1px',
            },
            background: isDisabled ? '#F3F5F7' : theme.colors.white[50],
            borderWidth: theme.space.px,
            width: width ?? theme.sizes.full,
            cursor: isDisabled ? 'not-allowed' : 'default',
            fontWeight: fontWeight,
            fontSize: fontSize,
            lineHeight: '24px',
            minHeight: minHeight,
          }),
          indicatorSeparator: base => ({
            ...base,
            display: 'none',
            height: height,
          }),
          valueContainer: base => ({
            ...base,
            paddingLeft: '10px',
            display: 'flex',
            flexWrap: 'nowrap',
            // justifyContent: 'space-between',
          }),
          menu: styles => ({
            ...styles,
            margin: 0,
            background: `${theme.colors.black[300]}`,
            borderRadius: `${theme.radii['2xl']}`,
            zIndex: 3,
          }),
          option: (styles, props) => {
            return {
              ...styles,
              padding: `${theme.space[3]} ${theme.space[6]}`,
              fontWeight: props.isSelected
                ? theme.fontWeights.bold
                : theme.fontWeights.medium,
              letterSpacing: theme.letterSpacings.tighter,
              fontSize: theme.fontSizes.sm,
              lineHeight: theme.lineHeights.shorter,
              color: theme.colors.white[50],
              cursor: 'pointer',
              background: props.isSelected
                ? theme.colors.blue[900]
                : 'transparent',
              '&:hover': {
                background: theme.colors.blue[900],
                fontWeight: theme.fontWeights.bold,
                cursor: value.length >= 2 ? 'not-allowed' : 'pointer',
              },
            }
          },
          placeholder: styles => ({
            ...styles,
            fontSize: sizePlaceholder ?? theme.fontSizes.md,
            color: theme.colors.black[100],
            opacity: 0.3,
          }),
          menuList: styles => ({
            ...styles,
            padding: `${theme.space[4]} 0`,
            maxHeight: heightListOptions,
          }),
        }}
        value={value}
        onBlur={isInForm ? field.onBlur : () => {}}
        onChange={(newValue: MultiValue<Option>) => {
          onChange(newValue)
        }}
        onFocus={onFocus}
        onKeyDown={e => {
          if (e.keyCode === 9 || e.code === 'Space') e.preventDefault()
        }}
        onMenuClose={() => (isInForm ? setTouched(true) : {})}
        onMenuScrollToBottom={handleScrollBottom}
      />
      {Boolean(error && touched) && (
        <Text
          color="#F75555"
          display="block"
          fontSize="14px"
          lineHeight="normal"
          marginTop="8px"
        >
          {errorText}
        </Text>
      )}
    </FormControl>
  )
}
