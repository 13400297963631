import { Close, Logout } from 'components/atoms/Icons'
import { FC } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  List,
  ListItem,
  DrawerFooter,
  Text,
  Divider,
  Image,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ADMIN_ROUTES, ROLE, ROUTES, icons } from 'constant'
import { storageServices, userAdminServices } from 'services'
import { userLogout } from 'context/Auth/auth.slice'
import { removeBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'

type MenuDrawerProps = {
  showDrawer: boolean
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

type ListDrawerType = {
  id: number
  title: string
  icon?: string
  link?: string
}

const listBottomDrawer: ListDrawerType[] = [
  {
    id: 1,
    title: '利用規約',
  },
  {
    id: 2,
    title: 'プライバシーポリシー',
    link: 'https://milize.co.jp/about/privacy',
  },
  {
    id: 3,
    title: '運営会社',
    link: 'https://milize.co.jp/',
  },
]
/**
 * MenuDrawer molecules
 * @constructor
 */
export const MenuDrawer: FC<MenuDrawerProps> = ({
  showDrawer,
  setShowDrawer,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isAdmin = storageServices.getRoleUser() === ROLE.ADMIN
  const size = storageServices.getRoleUser() ?? ''

  /**
   * logout
   */
  const handleLogout = async () => {
    try {
      await userAdminServices.logout(size)
      storageServices.removeAccessToken()
      storageServices.removeRefreshToken()
      storageServices.removeRoleUser()
      dispatch(removeBreadcrumb())
      dispatch(userLogout())
      if (pathname.split('/')[1] === ROLE.ADMIN) {
        navigate(ADMIN_ROUTES.LOGIN)
      } else {
        navigate(ROUTES.LOGIN)
      }
    } catch (error) {}
  }

  const listTopDrawer: ListDrawerType[] = [
    {
      id: 1,
      title: 'HOME',
      icon: icons.HOME,
      link: isAdmin ? ADMIN_ROUTES.ROOT : ROUTES.CHAT_AI,
    },
    {
      id: 2,
      title: '企業一覧',
      icon: icons.BRIEFCASE,
      link: isAdmin ? ADMIN_ROUTES.COMPANY_LIST : ROUTES.COMPANY_LIST,
    },
    {
      id: 3,
      title: 'アカウント情報',
      icon: icons.USER_SETTING,
      link: isAdmin ? ADMIN_ROUTES.MY_PAGE : ROUTES.MY_PAGE,
    },
    {
      id: 4,
      title: '使い方マニュアル',
      icon: icons.FILE_CIRCLE,
    },
  ]
  return (
    <Drawer
      isOpen={showDrawer}
      placement="right"
      onClose={() => setShowDrawer(false)}
    >
      <DrawerOverlay />
      <DrawerContent bgColor="black.800" width="320px">
        <DrawerHeader p={'28px 24px'}>
          <Flex alignItems="center" h="40px" justifyContent="flex-end">
            <Close
              color="blue.900"
              cursor="pointer"
              fontSize="2xl"
              onClick={() => setShowDrawer(false)}
            />
          </Flex>
        </DrawerHeader>
        <DrawerBody paddingY={0}>
          <List spacing={0}>
            {listTopDrawer.map((item, index) => (
              <ListItem
                key={index}
                _hover={{ bgColor: '#2F3030', borderRadius: '100px' }}
                alignItems="center"
                color="white.50"
                cursor={item.link ? 'pointer' : 'not-allowed'}
                display="flex"
                fontSize="sm"
                fontWeight="bold"
                gap="20px"
                h="48px"
                letterSpacing="tighter"
                lineHeight="shorter"
                padding="0 16px 0 20px"
                onClick={() => {
                  if (item.link) {
                    navigate(item.link)
                    setShowDrawer(false)
                  }
                }}
              >
                <Image src={item.icon} />
                {item.title}
              </ListItem>
            ))}
          </List>
        </DrawerBody>
        <DrawerFooter flexDirection="column" mb={4}>
          <Flex
            alignItems="center"
            cursor="pointer"
            gap={4}
            mb="28px"
            w="100%"
            onClick={() => handleLogout()}
          >
            <Logout color="white.50" fontSize="2xl" />
            <Text color="white.50">ログアウト</Text>
          </Flex>
          <Divider borderColor="black.300" opacity={1} />
          <List mt="32px" spacing={8} w="100%">
            {listBottomDrawer.map((item, index) => (
              <ListItem
                key={index}
                color="white.50"
                cursor={item.link ? 'pointer' : 'not-allowed'}
                fontSize="sm"
                fontWeight="normal"
                h="24px"
                letterSpacing="tighter"
                lineHeight="shorter"
                onClick={() => {
                  if (item.link) {
                    window.open(item.link)
                  }
                }}
              >
                {item.title}
              </ListItem>
            ))}
          </List>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
