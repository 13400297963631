import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { StyledCheckbox } from 'components/atoms/CheckboxField'
import { InitialValuesType } from 'components/molecules/SearchPost/hook'
import { useField } from 'formik'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { Option } from 'types/conmon'
import ShowIcon from 'utils/showIconTable'

type CheckboxGroupPopoverType = {
  name: string
  options: Option[]
  title: string
  columns?: number
  mode?: string
  isInForm?: boolean
  setValue: Dispatch<SetStateAction<InitialValuesType>>
  value: string
}

const CheckboxGroupPopover: FC<CheckboxGroupPopoverType> = ({
  options,
  title,
  columns = 1,
  mode = 'list',
  name,
  isInForm = true,
  value,
  setValue,
}) => {
  const [field] = useField(name)

  const handleChange = (currentValue: string) => {
    const arrValue = !value ? [] : value.split(',')
    const index = arrValue.indexOf(currentValue)
    let updatedValue: string[] = [...arrValue]
    if (index !== -1) {
      updatedValue.splice(index, 1)
    } else {
      updatedValue.push(currentValue)
    }
    setValue(prev => ({ ...prev, [name]: updatedValue.join() }))
  }

  const handleShowContent = (item: Option) => {
    switch (mode) {
      case 'list-icon':
        return (
          <ShowIcon
            changeColor={false}
            isShowTitle={true}
            label={item.label}
            value={item.value}
          />
        )

      default:
        return (
          <Text
            fontSize="14px"
            fontWeight="normal"
            letterSpacing="0em"
            lineHeight="shorter"
            maxW={'168px'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
          >
            {item.label}
          </Text>
        )
    }
  }

  return (
    <Box>
      <Text
        fontSize="14px"
        fontWeight="bold"
        letterSpacing="0em"
        lineHeight="shorter"
        mb={'8px'}
      >
        {title}
      </Text>
      <SimpleGrid
        columns={columns}
        maxH={'112px'}
        overflowY={'auto'}
        spacing={4}
      >
        {(options || []).map((item, index) => {
          const isChecked = Boolean(
            value.split(',').find(it => it === String(item.value)),
          )
          return (
            <Flex key={index} align={'center'} gap={'8px'} h={'24px'}>
              <StyledCheckbox
                {...(isInForm ? field : {})}
                isChecked={isChecked}
                name={name}
                value={item.value}
                onChange={() => {
                  handleChange(String(item.value))
                }}
              />
              {handleShowContent(item)}
            </Flex>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default CheckboxGroupPopover
