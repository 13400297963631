import { images } from 'constant/images'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { FC, useState } from 'react'
import { InputField } from 'components/atoms'
import _ from 'lodash'
import { Close, Mail, SendMailIcon } from 'components/atoms/Icons'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { Button } from 'components/fromAdvisor/elements'
import { authServices } from 'services'
import { forgotPasswordSchema } from './validation'

/**
 * login page
 * @constructor
 */
export const ForgotPassword: FC<{ size: string }> = ({ size }) => {
  const navigate = useNavigate()
  const [resError, setResError] = useState('')
  const [isSuccses, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const urlSendMail = `${process.env.REACT_APP_EMAIL_URL}${
    size === ROLE.ADMIN ? ADMIN_ROUTES.RESET_PASSWORD : ROUTES.RESET_PASSWORD
  }?`

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: forgotPasswordSchema,
    /**
     *
     */
    async onSubmit(values: { email: string }) {
      setIsLoading(true)
      try {
        await authServices.forgotPassword(
          size,
          {
            email: values.email,
            user_type: size,
          },
          {
            call_back: urlSendMail,
          },
        )
        setIsLoading(false)
        setIsSuccess(true)
      } catch (error: any) {
        setIsLoading(false)
        setResError(error.response?.data?.detail)
      }
    },
  })

  const { handleSubmit, errors, handleChange, dirty, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Box
        bgImage={images.BACKGROUND_LOGIN}
        bgSize="cover"
        boxSizing="border-box"
        minH="100vh"
        padding="24px"
        position="relative"
        w="100%"
      >
        <Box
          bgColor="white.50"
          borderRadius="xl"
          h="calc(100vh - 48px)"
          maxW={647}
          ml={2}
          position="relative"
          w="100%"
        >
          {isSuccses && (
            <Close
              cursor="pointer"
              fontSize="24px"
              ml="10px"
              mt="10px"
              onClick={() =>
                navigate(
                  size === ROLE.ADVISOR ? ROUTES.LOGIN : ADMIN_ROUTES.LOGIN,
                )
              }
            />
          )}
          <Flex
            alignItems="center"
            direction="column"
            h="100%"
            justifyContent="center"
          >
            {isSuccses ? (
              <Flex
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                px="40px"
              >
                <SendMailIcon />
                <Text
                  fontSize="18px"
                  fontWeight="700"
                  lineHeight="24px"
                  mt="8px"
                >
                  メール送信が完了しました。
                </Text>
                <Text fontSize="14px" lineHeight="24px" maxW="400px" mt="16px">
                  パスワード再設定のメールを送信しました。
                  メールが届かない場合は、迷惑メールフォルダを確認してください。
                </Text>
              </Flex>
            ) : (
              <>
                <Image alt="Logo" mb="24px" src={images.LOGO_LOGIN} />
                {resError && (
                  <Text
                    color="red.700"
                    fontSize="sm"
                    fontWeight="medium"
                    lineHeight="shorter"
                    mb={10}
                    mt="32px"
                  >
                    {resError}
                  </Text>
                )}
                <Flex
                  direction="column"
                  gap={4}
                  maxW={327}
                  mb="24px"
                  mt="16px"
                  w="100%"
                >
                  <InputField
                    error={Boolean(errors.email || resError)}
                    errorText={errors.email}
                    height="56px"
                    leftIcon={
                      <Mail color="blue.900" fontSize="2xl" opacity={0.5} />
                    }
                    name="email"
                    placeholder="IDまたはメールアドレス"
                    type="email"
                    width="100%"
                    onChange={handleChange}
                    onFocus={() => setResError('')}
                  />
                </Flex>
                <Button
                  _hover={{ bgColor: 'blue.900' }}
                  bgColor="blue.900"
                  borderRadius="xl"
                  color="white.50"
                  fontSize="md"
                  fontWeight="bold"
                  height="56px"
                  isDisabled={!_.isEmpty(errors) || !dirty || !isValid}
                  isLoading={isLoading}
                  letterSpacing="-0.02em"
                  lineHeight="shorter"
                  maxWidth={327}
                  opacity={_.isEmpty(errors) ? 1 : 0.2}
                  padding="12px 14px"
                  text="再設定メールを送信"
                  width="100%"
                  onClick={() => handleSubmit()}
                />
              </>
            )}
          </Flex>
          <Flex
            bottom={0}
            justifyContent="space-between"
            padding={6}
            position="absolute"
            width="100%"
          >
            <Text
              color="blue.900"
              cursor={'pointer'}
              fontSize="sm"
              fontWeight="medium"
              letterSpacing="-0.01em"
              lineHeight="shorter"
              onClick={() =>
                navigate(
                  size === ROLE.ADVISOR ? ROUTES.LOGIN : ADMIN_ROUTES.LOGIN,
                )
              }
            >
              {size === ROLE.ADVISOR
                ? 'ユーザーログインはこちら'
                : '管理者ログインはこちら'}
            </Text>
            <Text
              color="gray.800"
              fontSize="sm"
              fontWeight="normal"
              letterSpacing="-0.02em"
              lineHeight="shorter"
            >
              © MILIZE Inc.
            </Text>
          </Flex>
        </Box>
        <Flex
          bottom={6}
          gap={4}
          justifyContent="flex-end"
          position="absolute"
          width="calc(100% - 48px)"
        >
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            利用規約
          </Text>
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            プライバシーポリシー
          </Text>
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            運営会社
          </Text>
        </Flex>
      </Box>
    </FormikProvider>
  )
}
