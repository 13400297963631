/**
 * DropdownIcon icon
 * @constructor
 */
export const DropdownIcon = () => (
  <svg
    fill="none"
    height="9"
    viewBox="0 0 13 9"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.49866 8.00195C6.23597 8.00195 5.97323 7.89583 5.77348 7.68204L0.300663 1.86466C-0.100221 1.43853 -0.100221 0.747669 0.300663 0.321546C0.701546 -0.104578 1.35149 -0.104578 1.75237 0.321546L6.5 5.36808L11.2476 0.321546C11.6485 -0.104578 12.2985 -0.104578 12.6993 0.321546C13.1002 0.747669 13.1002 1.43853 12.6993 1.86466L7.22652 7.68204C7.02403 7.89583 6.76136 8.00195 6.49866 8.00195Z"
      fill="#0084FF"
    />
  </svg>
)
