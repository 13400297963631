import { CategoriesResponse } from './chatAIService.type'

export const Categories: CategoriesResponse[] = [
  {
    code: 'consult',
    created_at: '2023-10-12T04:07:01',
    id: 1,
    ja_name: '相談する',
    themes: [
      {
        category_id: 1,
        code: 'asset_management',
        created_at: '2023-10-12T04:07:01',
        id: 1,
        ja_name: '資産運用',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 1,
        code: 'old_age',
        created_at: '2023-10-12T04:07:01',
        id: 2,
        ja_name: '老後',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 1,
        code: 'old_age',
        created_at: '2023-10-12T04:07:01',
        id: 3,
        ja_name: '老後',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 1,
        code: 'housing',
        created_at: '2023-10-12T04:07:01',
        id: 4,
        ja_name: '住宅',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 1,
        code: 'insurance',
        created_at: '2023-10-12T04:07:01',
        id: 5,
        ja_name: '保険',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 1,
        code: 'loan',
        created_at: '2023-10-12T04:07:01',
        id: 6,
        ja_name: 'ローン',
        updated_at: '2023-10-12T04:07:01',
      },
    ],
    updated_at: '2023-10-12T04:07:01',
  },
  {
    code: 'suggest',
    created_at: '2023-10-12T04:07:01',
    id: 2,
    ja_name: '提案する',
    themes: [
      {
        category_id: 2,
        code: 'insurance_products',
        created_at: '2023-10-12T04:07:01',
        id: 7,
        ja_name: '保険商品',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 2,
        code: 'investment_trust',
        created_at: '2023-10-12T04:07:01',
        id: 8,
        ja_name: '投資信託',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 2,
        code: 'nisa',
        created_at: '2023-10-12T04:07:01',
        id: 9,
        ja_name: 'NISA',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 2,
        code: 'housing',
        created_at: '2023-10-12T04:07:01',
        id: 10,
        ja_name: '住宅',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 2,
        code: 'insurance',
        created_at: '2023-10-12T04:07:01',
        id: 11,
        ja_name: '保険',
        updated_at: '2023-10-12T04:07:01',
      },
    ],
    updated_at: '2023-10-12T04:07:01',
  },
  {
    code: 'knowledge',
    created_at: '2023-10-12T04:07:01',
    id: 3,
    ja_name: '知識',
    themes: [
      {
        category_id: 3,
        code: 'product_knowledge',
        created_at: '2023-10-12T04:07:01',
        id: 13,
        ja_name: '商品知識',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 3,
        code: 'tax_system',
        created_at: '2023-10-12T04:07:01',
        id: 14,
        ja_name: '税制度',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 3,
        code: 'social_security_system',
        created_at: '2023-10-12T04:07:01',
        id: 15,
        ja_name: '社会保障制度',
        updated_at: '2023-10-12T04:07:01',
      },
      {
        category_id: 3,
        code: 'financial_regulation',
        created_at: '2023-10-12T04:07:01',
        id: 16,
        ja_name: '金融規制',
        updated_at: '2023-10-12T04:07:01',
      },
    ],
    updated_at: '2023-10-12T04:07:01',
  },
  {
    code: 'build_trust',
    created_at: '2023-10-12T04:07:01',
    id: 4,
    ja_name: '信頼を築く',
    themes: [
      {
        category_id: 4,
        code: 'new_customer',
        created_at: '2023-10-12T04:07:01',
        id: 17,
        ja_name: '新規顧客',
        updated_at: '2023-10-12T04:07:01',
      },
    ],
    updated_at: '2023-10-12T04:07:01',
  },
]

export const Consultation = [
  {
    category_id: 1,
    created_at: '2023-08-12T03:42:29',
    id: 997,
    is_favorite: true,
    is_sub_prompt: false,
    next_prompt_id: null,
    prompt:
      'あなたは一流のFPです。\r 資産運用をしたいが、パートナーの理解が得られないお客様へアドバイスをしてください\r \r ＃顧客情報\r ・年齢:50歳\r ・収入合計:700万円\r ・本人収入:700万円\r ・本人退職希望年齢:60歳\r ・本人退職金予想額:2,500万円\r ・支出合計:700万円\r ・資産時価:2,000万円(資産内訳:預貯金2,000万円)\r ・住宅ローン有無:あり\r ・住宅ローン残高:1,500万円\r \r ＃制約条件\r ・定期預金や国債の金利はほぼ0なので提案しない',
    theme_id: 1,
    title: '資産運用を始めることにパートナーの理解が得られない',
    updated_at: '2023-08-12T03:42:29',
  },
  {
    category_id: 1,
    created_at: '2023-08-12T03:42:29',
    id: 995,
    is_favorite: true,
    is_sub_prompt: false,
    next_prompt_id: null,
    prompt:
      'あなたは一流のFPです。\r 資産運用をしたいが、パートナーの理解が得られないお客様へアドバイスをしてください\r \r ＃顧客情報\r ・年齢:50歳\r ・収入合計:700万円\r ・本人収入:700万円\r ・本人退職希望年齢:60歳\r ・本人退職金予想額:2,500万円\r ・支出合計:700万円\r ・資産時価:2,000万円(資産内訳:預貯金2,000万円)\r ・住宅ローン有無:あり\r ・住宅ローン残高:1,500万円\r \r ＃制約条件\r ・定期預金や国債の金利はほぼ0なので提案しない',
    theme_id: 1,
    title: '資産運用を始めることにパートナーの理解が得られない',
    updated_at: '2023-08-12T03:42:29',
  },
  {
    category_id: 1,
    created_at: '2023-08-12T03:42:29',
    id: 1001,
    is_favorite: true,
    is_sub_prompt: false,
    next_prompt_id: null,
    prompt:
      'あなたは一流のFPです。\r 資産運用をしたいが、パートナーの理解が得られないお客様へアドバイスをしてください\r \r ＃顧客情報\r ・年齢:50歳\r ・収入合計:700万円\r ・本人収入:700万円\r ・本人退職希望年齢:60歳\r ・本人退職金予想額:2,500万円\r ・支出合計:700万円\r ・資産時価:2,000万円(資産内訳:預貯金2,000万円)\r ・住宅ローン有無:あり\r ・住宅ローン残高:1,500万円\r \r ＃制約条件\r ・定期預金や国債の金利はほぼ0なので提案しない',
    theme_id: 3,
    title: '資産運用を始めることにパートナーの理解が得られない',
    updated_at: '2023-08-12T03:42:29',
  },
  {
    category_id: 1,
    created_at: '2023-08-12T03:42:29',
    id: 996,
    is_favorite: true,
    is_sub_prompt: false,
    next_prompt_id: null,
    prompt:
      'あなたは一流のFPです。\r 資産運用をしたいが、パートナーの理解が得られないお客様へアドバイスをしてください\r \r ＃顧客情報\r ・年齢:50歳\r ・収入合計:700万円\r ・本人収入:700万円\r ・本人退職希望年齢:60歳\r ・本人退職金予想額:2,500万円\r ・支出合計:700万円\r ・資産時価:2,000万円(資産内訳:預貯金2,000万円)\r ・住宅ローン有無:あり\r ・住宅ローン残高:1,500万円\r \r ＃制約条件\r ・定期預金や国債の金利はほぼ0なので提案しない',
    theme_id: 1,
    title: '資産運用を始めることにパートナーの理解が得られない',
    updated_at: '2023-08-12T03:42:29',
  },
  {
    category_id: 1,
    created_at: '2023-08-12T03:42:29',
    id: 1000,
    is_favorite: false,
    is_sub_prompt: false,
    next_prompt_id: null,
    prompt:
      'あなたは一流のFPです。\r 資産運用をしたいが、パートナーの理解が得られないお客様へアドバイスをしてください\r \r ＃顧客情報\r ・年齢:50歳\r ・収入合計:700万円\r ・本人収入:700万円\r ・本人退職希望年齢:60歳\r ・本人退職金予想額:2,500万円\r ・支出合計:700万円\r ・資産時価:2,000万円(資産内訳:預貯金2,000万円)\r ・住宅ローン有無:あり\r ・住宅ローン残高:1,500万円\r \r ＃制約条件\r ・定期預金や国債の金利はほぼ0なので提案しない',
    theme_id: 2,
    title: '資産運用を始めることにパートナーの理解が得られない',
    updated_at: '2023-08-12T03:42:29',
  },
]
