import { Text } from '@chakra-ui/react'
import React, { FC } from 'react'

type ScoreColumnProps = {
  valueIntegerPart: string
  valueDecimalPart: string
}

const ScoreColumn: FC<ScoreColumnProps> = props => {
  const { valueDecimalPart, valueIntegerPart } = props

  return (
    <Text
      color="black.100"
      fontSize="2xl"
      fontWeight="normal"
      lineHeight="shorter"
      minW={'fit-content'}
      textAlign="center"
    >
      {valueIntegerPart}
      {'.'}
      <Text display="inline-block" fontSize="lg" lineHeight="18px">
        {valueDecimalPart}
      </Text>
    </Text>
  )
}

export default ScoreColumn
