import * as React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, Box, Text } from '@chakra-ui/react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import { CardLoading } from 'components/molecules'
import { isEmpty } from 'lodash'
import PaginationTable from 'components/molecules/PaginationTable'
import ActionTable from 'components/molecules/ActionTable'
import PopoverHeader from './PopoverHeader'

export type DataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  isPagination: boolean
  bgColorTable?: string
  pagination?: {
    page_size: number
    page: number
    page_count: number
    total_count: number
  }
  setPagination?: React.Dispatch<
    React.SetStateAction<{
      page_size: number
      page: number
      page_count: number
      total_count: number
    }>
  >
  loadingData: boolean
  isScroll?: boolean
  maxH?: string
  minH?: string
  isShowAction: boolean
  onOpenModal?: () => void
  onDelete?: () => void
  onCancel?: () => void
  titleOpenModal?: string
  rangePage?: number[]
  isFilter?: boolean
  height?: string
  handleSort?: (field: string) => void
  lengthTable?: number
}
/**
 * DataTable component
 * @constructor
 */
export function DataTable<Data extends object>({
  data,
  columns,
  isPagination,
  pagination,
  setPagination,
  loadingData,
  isScroll,
  maxH,
  isShowAction,
  onOpenModal,
  onDelete,
  onCancel,
  titleOpenModal,
  rangePage,
  bgColorTable,
  isFilter,
  minH,
  height,
  handleSort,
  lengthTable,
}: DataTableProps<Data>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
    initialState: {
      pagination: {
        pageSize: isPagination
          ? pagination?.page_size
          : lengthTable
          ? lengthTable
          : 5,
        pageIndex: 0,
      },
    },
  })
  const { setPageSize } = table

  const pageSize = pagination?.page_size
    ? pagination?.page_size
    : lengthTable
    ? lengthTable
    : 5
  const pageIndex = pagination?.page ?? 1
  const [state, setState] = React.useState<{
    headerHover: string
    popoverOpen: string
  }>({ headerHover: '', popoverOpen: '' })

  /**
   *
   */
  const getPageNumber = React.useCallback(() => {
    if (pagination?.total_count) {
      if (pagination.total_count < pageSize) {
        return [1]
      } else {
        return Array.from(
          Array.from(
            { length: Math.ceil(pagination.total_count / pageSize) },
            (_, index) => index + 1,
          ),
        )
      }
    }
    return [1]
  }, [pageSize, pagination?.total_count])

  return (
    <React.Fragment>
      <Box
        borderTopRadius={12}
        height={height}
        maxH={maxH}
        minH={minH}
        overflowY={isScroll ? 'auto' : undefined}
        zIndex={1}
      >
        <Table
          bgColor={bgColorTable}
          border="1px solid"
          borderColor="purple.250"
          borderRadius={12}
          borderTop={'none'}
          style={{ borderCollapse: 'separate', borderSpacing: 0 }}
        >
          <Thead
            position={isScroll && maxH ? 'sticky' : undefined}
            top={0}
            zIndex={1}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id} bgColor="white.100" h={12}>
                {headerGroup.headers.map(header => {
                  const meta = header.column.columnDef.meta
                  const width = header.column.getSize() || undefined
                  if (meta?.colspan === 0) {
                    return <></>
                  }
                  return (
                    <Th
                      key={header.id}
                      _first={{ borderRadius: '12px 0  0 0' }}
                      _last={{
                        borderRight: 'none',
                        borderRadius: ' 0 12px 0 0',
                      }}
                      borderBottomColor="purple.250"
                      borderRight="1px solid"
                      borderRightColor="purple.250"
                      borderTop="1px solid #E8ECEF"
                      boxSizing="border-box"
                      color="black.100"
                      colSpan={meta?.colspan}
                      fontFamily="body"
                      fontSize="sm"
                      fontWeight="bold"
                      letterSpacing={0}
                      lineHeight="shorter"
                      maxW={width + 'px'}
                      minW={meta?.minWidthColumn ?? undefined}
                      p="12px"
                      onMouseEnter={() => {
                        setState(prev => ({
                          ...prev,
                          headerHover: header.id,
                        }))
                      }}
                      onMouseLeave={() =>
                        setState(prev => ({ ...prev, headerHover: '' }))
                      }
                    >
                      {isFilter ? (
                        meta !== undefined ? (
                          <PopoverHeader
                            field={header.id}
                            handleSort={() =>
                              handleSort && handleSort(header.column.id)
                            }
                            meta={meta}
                            setPagination={setPagination}
                            setState={setState}
                            state={state}
                            title={flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          />
                        ) : (
                          <Text textTransform="capitalize">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Text>
                        )
                      ) : (
                        <Text textTransform="capitalize">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </Text>
                      )}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {loadingData ? (
              <Tr>
                <Td colSpan={table.getAllColumns().length}>
                  <CardLoading />
                </Td>
              </Tr>
            ) : isEmpty(table.getRowModel().rows) ? (
              <Tr>
                <Td
                  colSpan={table.getAllColumns().length}
                  h={'250px'}
                  textAlign="center"
                >
                  データがありません。
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map(row => (
                <Tr key={row.id} _last={{ td: { borderBottom: 'none' } }}>
                  {row.getVisibleCells().map(cell => {
                    const width = cell.column.getSize() || undefined
                    const meta = cell.column.columnDef.meta || undefined

                    return (
                      <Td
                        key={cell.id}
                        _last={{ borderRight: 'none' }}
                        borderBottomColor="purple.250"
                        borderRight="1px solid"
                        borderRightColor="purple.250"
                        fontFamily="body"
                        maxW={width + 'px'}
                        minW={meta?.minWidthColumn ?? undefined}
                        padding={3}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      {isPagination && (
        <PaginationTable
          getPageNumber={getPageNumber}
          pageIndex={pageIndex}
          pagination={pagination}
          rangePage={rangePage}
          setPageSize={setPageSize}
          setPagination={setPagination}
        />
      )}
      {isShowAction && (
        <ActionTable
          titleOpenModal={titleOpenModal}
          onCancel={onCancel}
          onDelete={onDelete}
          onOpenModal={onOpenModal}
        />
      )}
    </React.Fragment>
  )
}
