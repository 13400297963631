import { Box, Flex, Text } from '@chakra-ui/react'
import { GptIcon } from 'components/atoms/Icons'
import React, { FC, useEffect, useRef, useState } from 'react'
import ReactMarkdown from '../ReactMarkdown'

type ContentAskAIProps = {
  toggleExpanded?: () => void
  isValues: boolean
  content: {
    data: string
    loading: boolean
    done: boolean
  }
  minHeight?: string
  heightContent?: string
  isHidden: boolean
  isDone?: boolean
  isCopyBtn?: boolean
  isOpenModal?: boolean
}

/**
 * AIAdvice molecules
 * @constructor
 */
export const ContentAskAI: FC<ContentAskAIProps> = ({
  isValues,
  toggleExpanded,
  content,
  minHeight = '204px',
  heightContent = 'auto',
  isHidden,
  isDone,
  isCopyBtn = true,
  isOpenModal = false,
}) => {
  const ref = useRef<any>()
  const maxHeightContent = toggleExpanded ? '144px' : heightContent
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    if (ref.current?.clientHeight >= 144) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [content.data])

  return (
    <Box bg="white.base" borderRadius="20px" boxSizing="border-box" w="100%">
      {isValues && (
        <Flex
          bgColor="gray.600"
          borderRadius={12}
          gap={3}
          minH={toggleExpanded ? minHeight : 'auto'}
          padding={3}
        >
          <Box w={10}>
            <Flex
              alignItems="center"
              bgGradient="linear(160.99deg, #AFA4D0 0.9%, #4474CD 30.15%, #3452A4 92.96%)"
              borderRadius={12}
              h={10}
              justifyContent="center"
              w={10}
            >
              <GptIcon fontSize={24} />
            </Flex>
          </Box>
          <Box w="100%">
            <Box
              ref={ref}
              maxH={maxHeightContent}
              overflowY={isHidden ? 'hidden' : 'auto'}
            >
              <ReactMarkdown
                data={content.data}
                isCopyBtn={isCopyBtn}
                isDone={isDone}
              />
            </Box>
            {!isOpenModal && isShow && content.data && (
              <Flex justifyContent="flex-end">
                <Text
                  color="blue.900"
                  cursor="pointer"
                  display="inline-block"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  textAlign="end"
                  onClick={toggleExpanded}
                >
                  別ウィンドウで表示
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  )
}
