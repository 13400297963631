import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { newServices } from 'services'
import { ExtractPdfItemType } from 'services/newServices'

export type ArticleSummaryProps = {
  newsId?: string
  question: string
  height?: string
  onSubmit: () => void
  onEnter: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  onChangeInput: (event: ChangeEvent<HTMLTextAreaElement>) => void
  avatar?: string
  handleSetPagePdf: (e: string, type?: boolean) => void
}

export const useArticleSummary = (props: ArticleSummaryProps) => {
  const { newsId: id } = props
  const [data, setData] = useState<{
    loading: boolean
    data: ExtractPdfItemType[]
  }>({ loading: false, data: [] })

  const getExtractPdf = useCallback(async () => {
    if (!id) return

    setData(prev => ({ ...prev, loading: true }))

    try {
      const { data: dataPdf } = await newServices.addExtractPdf({
        new_id: id?.toString(),
      })

      setData(prev => ({ ...prev, loading: false, data: dataPdf.response }))
    } catch (error) {
      setData(prev => ({ ...prev, loading: false }))
    }
  }, [id])

  useEffect(() => {
    getExtractPdf()
  }, [getExtractPdf])

  return {
    ...props,
    data,
  }
}
