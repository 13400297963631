import { Flex, Text } from '@chakra-ui/react'
import { DatePicker } from 'components/fromAdvisor/elements'
import { InitialValuesType } from 'components/molecules/SearchPost/hook'
import { FormikErrors } from 'formik'
import React from 'react'

type RangeDatePopoverProps = {
  title: string
  name: string
  name2: string
  errors: FormikErrors<{
    [key: string]: any
  }>
  startDate: Date | undefined
  endDate: Date | undefined
  setCurrentValue: React.Dispatch<React.SetStateAction<InitialValuesType>>
}

/**
 * RangeDatePopover component
 */
const RangeDatePopover = ({
  title,
  name,
  name2,
  errors,
  startDate,
  endDate,
  setCurrentValue,
}: RangeDatePopoverProps) => {
  return (
    <>
      <Text
        fontSize="14px"
        fontWeight="bold"
        letterSpacing="0em"
        lineHeight="shorter"
        mb={'8px'}
      >
        {title}
      </Text>
      <Flex alignItems="center">
        <DatePicker
          clear={startDate ? true : false}
          endDate={endDate}
          height="48px"
          isInvalid={!!errors[name]}
          maxDate={endDate}
          name={name}
          placeholder={'2023/04/31'}
          selectsStart={true}
          startDate={startDate}
          value={startDate}
          width="120px"
          onChange={e => setCurrentValue(prev => ({ ...prev, [name]: e }))}
          onKeyDown={e => e.preventDefault()}
        />
        <Text
          color="black.100"
          display="inline-block"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
          mx="8px"
        >
          〜
        </Text>
        <DatePicker
          clear={endDate ? true : false}
          endDate={endDate}
          height="48px"
          isInvalid={!!errors[name2]}
          minDate={startDate}
          name={name2}
          placeholder={'2023/04/31'}
          selectsEnd={true}
          startDate={startDate}
          value={endDate}
          width="120px"
          onChange={e => setCurrentValue(prev => ({ ...prev, [name2]: e }))}
          onKeyDown={e => e.preventDefault()}
        />
      </Flex>
    </>
  )
}

export default RangeDatePopover
