import { useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ADMIN_ROUTES } from 'constant'
import { companyServices } from 'services'
import { isEmpty, omit } from 'lodash'
import { CompanyRegistrationType } from 'services/companyServices'
import axios from 'axios'
import { companyRegistrationSchema, initialValues } from './validation'

interface Options {
  label: string
  value: string | number
}
interface TypeOptions {
  optionsCategory: Options[]
  optionsPosition: Options[]
  optionsKindOffice: Options[]
  optionsStates: Options[]
  optionsCities: Options[]
  optionsAllCities: Options[]
}
export type CompanyRegistrationProps = {
  size: string
}

export const useCompanyRegistration = (props: CompanyRegistrationProps) => {
  const { id: companyId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [options, setOptions] = useState<TypeOptions>({
    optionsCategory: [],
    optionsPosition: [],
    optionsKindOffice: [],
    optionsStates: [],
    optionsCities: [],
    optionsAllCities: [],
  })
  const convertOptions = (data: { id: string | number; name: string }[]) => {
    if (!data) {
      return []
    }
    const options = data.map(o => ({
      label: o.name,
      value: o.id,
    }))
    return options
  }
  const toast = useToast()

  const getOptions = useCallback(async () => {
    try {
      const [
        { data: categories },
        { data: kindOffice },
        { data: positions },
        { data: states },
        { data: allCities },
      ] = await Promise.all([
        companyServices.getCompanyCategories(),
        companyServices.getCompanyKindOffice(),
        companyServices.getCompanyPositions(),
        companyServices.getStates(),
        companyServices.getAllCities(),
      ])
      setOptions(prev => ({
        ...prev,
        optionsCategory: convertOptions(categories),
        optionsKindOffice: convertOptions(kindOffice),
        optionsPosition: convertOptions(positions),
        optionsStates: states.map(o => ({
          label: o.name_jp,
          value: o.id,
        })),
        optionsAllCities: allCities.map(o => ({
          label: o.name_jp,
          value: o.id,
        })),
      }))
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getOptions()
  }, [getOptions])
  /**
   *
   */
  const onDrop = async (file: File[]) => {
    if (!file[0]) {
      return toast({
        status: 'error',
        description: 'CSVファイルをアップロードしてください',
      })
    }
    try {
      setLoadingUpload(true)
      const formData = new FormData()
      formData.append('file', file[0])
      await companyServices.uploadCsvCompany(formData)
      setLoadingUpload(false)
      navigate(ADMIN_ROUTES.COMPANY_LIST)
    } catch (error: any) {
      setLoadingUpload(false)
      toast({
        status: 'error',
        description: error?.response?.data?.message,
      })
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
    onDrop,
  })
  const convertData = (values: CompanyRegistrationType) => {
    const date =
      values.year && values.month && values.day
        ? `${values.year}-${values.month}-${values.day}`
        : null
    return {
      ...omit(
        values,
        'year',
        'month',
        'day',
        'postCode_1',
        'postCode_2',
        'bank',
      ),
      established_date: date,
      post_code: `${values.postCode_1}-${values.postCode_2}`,
      performance_trends: {
        ...values.performance_trends,
        Banks_Transaction__c: values.bank,
      },
      funds: values.funds ? values.funds : null,
    }
  }
  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: companyRegistrationSchema,
    enableReinitialize: true,

    /**
     *
     */
    async onSubmit(values: CompanyRegistrationType) {
      try {
        if (companyId) {
          await companyServices.updateCompany(companyId, convertData(values))
          toast({
            status: 'success',
            description: '企業更新が成功されました。',
          })
          navigate(`${ADMIN_ROUTES.COMPANY}/${companyId}`)
        } else {
          await companyServices.createCompany(convertData(values))
          toast({
            status: 'success',
            description: '企業作成が成功されました。',
          })
          navigate(ADMIN_ROUTES.COMPANY_LIST)
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        toast({
          status: 'error',
          description: error?.response?.data?.message,
        })
      }
    },
  })
  const { values, setFieldValue, setValues } = formik
  const handleGetCities = useCallback(async (id: number) => {
    try {
      const { data } = await companyServices.getCities(id)
      setOptions(prev => ({
        ...prev,
        optionsCities: data.map(o => ({
          label: o.name_jp,
          value: o.id,
        })),
      }))
    } catch (error) {}
  }, [])

  const getLocation = useCallback(
    async (code: string) => {
      try {
        const {
          data: { results },
        } = await axios.get(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${code}`,
        )
        if (isEmpty(results)) {
          setFieldValue('state_id', null)
          setFieldValue('city_id', null)
        }
        const state = options.optionsStates.find(
          it => it.label === results[0].address1,
        )
        if (state?.value) {
          handleGetCities(Number(state?.value))
        }
        const city = options.optionsAllCities.find(
          it => it.label === results[0].address2,
        )
        setFieldValue('state_id', state?.value)
        setFieldValue('city_id', city?.value)
      } catch (error) {}
    },
    [
      handleGetCities,
      options.optionsAllCities,
      options.optionsStates,
      setFieldValue,
    ],
  )
  /**
   * @returns function that get company detail
   */
  const fetchDataCompanyDetail = useCallback(async () => {
    if (!companyId) return
    try {
      setLoadingData(true)
      const { data } = await companyServices.getCompanyDetail(props.size, {
        id: Number(companyId),
      })
      const getOptionCreateAt = data.established_date
        ? data.established_date.split('-')
        : ['', '', '']
      const getPostcode = data?.post_code?.split('-')
      if (data.state) {
        handleGetCities(Number(data.state.id))
      }
      setValues({
        ...omit(
          data,
          'city',
          'state',
          'updated_at',
          'update_user',
          'salesforce_lightning',
          'corporate_number',
          'company_name_furi',
          'company_code',
          'category',
          'stock_code',
          'description',
          'deleted_at',
          'contact_person_mail',
        ),
        bank:
          typeof data?.performance_trends?.Banks_Transaction__c === 'object'
            ? data?.performance_trends?.Banks_Transaction__c
            : [
                {
                  name: '',
                },
              ],
        performance_trends:
          typeof data?.performance_trends === 'object'
            ? data.performance_trends === null
              ? {
                  Fiscal_Closing_Month__c: '',
                  Sales_Ordinance_2_3__c: '',
                  Sales_Ordinance_3_3__c: '',
                  Sales_Ordinance_4_3__c: '',
                  Operating_Profit_Ordinance_2_3__c: '',
                  Operating_Profit_Ordinance_3_3__c: '',
                  Operating_Profit_Ordinance_4_3__c: '',
                  Ordinary_Profit_Ordinance_2_3__c: '',
                  Ordinary_Profit_Ordinance_3_3__c: '',
                  Ordinary_Profit_Ordinance_4_3__c: '',
                  Net_Income_Ordinance_2_3__c: '',
                  Net_Income_Ordinance_3_3__c: '',
                  Net_Income_Ordinance_4_3__c: '',
                  Declared_Income_Ordinance_2_3__c: '',
                  Declared_Income_Ordinance_3_3__c: '',
                  Declared_Income_Ordinance_4_3__c: '',
                }
              : data?.performance_trends
            : {
                Fiscal_Closing_Month__c: '',
                Sales_Ordinance_2_3__c: '',
                Sales_Ordinance_3_3__c: '',
                Sales_Ordinance_4_3__c: '',
                Operating_Profit_Ordinance_2_3__c: '',
                Operating_Profit_Ordinance_3_3__c: '',
                Operating_Profit_Ordinance_4_3__c: '',
                Ordinary_Profit_Ordinance_2_3__c: '',
                Ordinary_Profit_Ordinance_3_3__c: '',
                Ordinary_Profit_Ordinance_4_3__c: '',
                Net_Income_Ordinance_2_3__c: '',
                Net_Income_Ordinance_3_3__c: '',
                Net_Income_Ordinance_4_3__c: '',
                Declared_Income_Ordinance_2_3__c: '',
                Declared_Income_Ordinance_3_3__c: '',
                Declared_Income_Ordinance_4_3__c: '',
              },
        company_name_kana: data?.company_name_kana,
        postCode_1: getPostcode ? getPostcode[0] : '',
        postCode_2: getPostcode ? getPostcode[1] : '',
        year: getOptionCreateAt ? getOptionCreateAt[0] : '',
        month: getOptionCreateAt ? getOptionCreateAt[1] : '',
        day: getOptionCreateAt ? getOptionCreateAt[2].slice(0, 2) : '',
        state_id: data?.state?.id ?? null,
        city_id: data?.city?.id ?? null,
        business_activity: isEmpty(data.business_activity)
          ? [
              {
                category_id: null,
                description: null,
              },
            ]
          : data.business_activity,
        offices: isEmpty(data.offices)
          ? [
              {
                office_type: null,
                city_id: null,
              },
            ]
          : data.offices.map(it => ({
              office_type: it.office_type.id,
              city_id: it.city.id,
            })),
        customer_company: isEmpty(data.customer_company)
          ? [
              {
                name: null,
              },
            ]
          : data.customer_company,
        supplier_company: isEmpty(data.supplier_company)
          ? [
              {
                name: null,
              },
            ]
          : data.supplier_company,
        company_employees: isEmpty(data.company_employees)
          ? [
              {
                position_id: null,
                first_name_kanji: null,
                last_name_kanji: null,
                first_name_kata: null,
                last_name_kata: null,
              },
            ]
          : data.company_employees.map(it => ({
              ...omit(it, 'position'),
              position_id: it.position?.id,
            })),
      })
      setLoadingData(false)
    } catch (error: any) {
      setLoadingData(false)
    }
  }, [companyId, handleGetCities, props.size, setValues])

  useEffect(() => {
    fetchDataCompanyDetail()
  }, [fetchDataCompanyDetail])

  /**
   * @returns function that handle next input
   */
  const handleNextInput = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    const { maxLength, name } = e.target
    if (value.length > maxLength) return
    setFieldValue(name, value)
    if (value.length === maxLength) {
      // Get the next input field
      const nextSibling: HTMLInputElement | null = document.querySelector(
        `input[name=postCode_${parseInt(name.split('_')[1], 10) + 1}]`,
      )

      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus()
        setFieldValue('postCode_2', '')
      }
    }
  }

  /**
   * @returns function that handle add field
   */
  const handleAddField = (name: string) => {
    switch (name) {
      case 'offices':
        return setFieldValue('offices', [
          ...values?.offices,
          {
            name: null,
            city_id: null,
          },
        ])
      case 'business_activity':
        return setFieldValue('business_activity', [
          ...values?.business_activity,
          {
            category_id: null,
            description: '',
          },
        ])
      case 'customer_company':
        return setFieldValue('customer_company', [
          ...values?.customer_company,
          {
            name: '',
          },
        ])
      case 'supplier_company':
        return setFieldValue('supplier_company', [
          ...values?.supplier_company,
          {
            name: '',
          },
        ])
      case 'bank':
        return setFieldValue('bank', [
          ...values?.bank,
          {
            name: '',
          },
        ])
      case 'company_employees':
        return setFieldValue('company_employees', [
          ...values?.company_employees,
          {
            position_id: null,
            first_name_kanji: null,
            last_name_kanji: null,
            first_name_kata: null,
            last_name_kata: null,
          },
        ])
      default:
        break
    }
  }

  return {
    ...props,
    handleAddField,
    handleNextInput,
    getRootProps,
    getInputProps,
    navigate,
    location,
    loading,
    loadingData,
    loadingUpload,
    options,
    formik,
    handleGetCities,
    getLocation,
  }
}
