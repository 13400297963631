import { Box, Center, Flex, Text } from '@chakra-ui/react'
import { DataTable, InputField } from 'components/atoms'
import { Plus } from 'components/atoms/Icons'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Checkbox } from 'components/fromAdvisor/elements/CheckBox'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { ModalConfirm, RattingImportance } from 'components/molecules'
import ProductImportanceModal from 'components/molecules/ProductImportanceModal'
import { ADMIN_ROUTES } from 'constant'
import { FormikProvider } from 'formik'
import moment from 'moment'
import { FC } from 'react'

import ProductRegistrationModal from 'components/molecules/ProductRegistrationModal'
import { ProductListProps, useProductList } from './hook'

/**
 * ProductList Page
 * @constructor
 */
export const ProductList: FC<ProductListProps> = props => {
  const {
    formik,
    setFieldValue,
    setSearchProduct,
    setPagination,
    productList,
    navigate,
    selectRecordId,
    pagination,
    handleCancelSelected,
    onOpenDelete,
    onOpen,
    openDetele,
    onCloseDelete,
    handleDeleteMultiRecord,
    handleUpdateScoreProduct,
    isOpen,
    onClose,
    columnHelper,
    handleSelectRecordAll,
    handleSelectRecord,
    openEmail,
    onCloseEmail,
  } = useProductList(props)
  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      size: 48,
      meta: {
        minWidthColumn: '48px',
      },
      cell: info => {
        const checked = selectRecordId.find(
          recordId => recordId === info.row.original.id,
        )
        return (
          <Center>
            <Checkbox
              isChecked={checked !== undefined ? true : false}
              onChange={() => {
                handleSelectRecord(info.row.original.id)
              }}
            />
          </Center>
        )
      },
      header: () => {
        return (
          <Center>
            <Checkbox
              isChecked={
                selectRecordId.length &&
                selectRecordId.length === productList.data.length
                  ? true
                  : false
              }
              isDisabled={!productList.data.length}
              onChange={handleSelectRecordAll}
            />
          </Center>
        )
      },
    }),
    columnHelper.accessor('product_code', {
      id: 'product_code',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          lineHeight="24px"
          value={info.getValue() ?? '-'}
          width="100%"
        />
      ),
      header: '商品コード',
      size: 94,
      meta: {
        minWidthColumn: '94px',
      },
    }),
    columnHelper.accessor('category_name', {
      id: 'category_name',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue() ?? '-'}
          width="100%"
        />
      ),
      header: 'カテゴリー',
      size: 167,
      meta: {
        minWidthColumn: '167px',
      },
    }),
    columnHelper.accessor('product_name', {
      id: 'product_name',
      cell: info => {
        const productId = info.row.original.id
        return (
          <TextOverflowTooltip
            isCursor
            color="blue.900"
            fontSize="sm"
            fontWeight={'bold'}
            value={info.getValue() ?? '-'}
            onClick={() =>
              navigate(ADMIN_ROUTES.PRODUCT_DETAIL + `/${productId}`)
            }
          />
        )
      },
      header: '商品名（略称）',
      size: 233,
      meta: {
        minWidthColumn: '233px',
      },
    }),
    columnHelper.accessor('product_short_name', {
      id: 'product_short_name',
      cell: info => {
        return (
          <TextOverflowTooltip fontSize="sm" value={info.getValue() ?? '-'} />
        )
      },
      header: '商品略称',
      size: 157,
      meta: {
        minWidthColumn: '157px',
      },
    }),
    columnHelper.accessor('description', {
      id: 'description',
      cell: info => (
        <TextOverflowTooltip
          fontSize="sm"
          fontWeight={'normal'}
          value={info.getValue()}
        />
      ),
      header: '概要',
      size: 350,
      meta: {
        minWidthColumn: '350px',
      },
    }),
    columnHelper.accessor('score_target', {
      id: 'switch',
      cell: info => {
        return (
          <Center>
            <Switch
              isChecked={Boolean(info.getValue())}
              islabelinswitch={true}
              offtext="非対象"
              offtextswitch="対象"
            />
          </Center>
        )
      },
      header: 'スコア対象',
      size: 94,
      meta: {
        minWidthColumn: '94px',
      },
    }),
    columnHelper.accessor('score_target', {
      id: 'score_target',
      cell: info => <RattingImportance type="circle" value={info.getValue()} />,
      header: '重要度',
      size: 110,
      meta: {
        minWidthColumn: '110px',
      },
    }),
  ]

  return (
    <>
      <FormikProvider value={formik}>
        <Box
          bgColor="white.50"
          borderRadius={4}
          minH="calc(100vh - 88px)"
          padding="12px 24px"
          w="100%"
        >
          <Flex align={'center'} justifyContent="space-between">
            <Flex alignItems="center" gap="24px">
              <Text
                fontSize="24px"
                fontWeight="700"
                letterSpacing="tighter"
                lineHeight="40px"
                minW="330px"
                padding="0 8px"
              >
                取引可能性スコア対象商品設定
              </Text>
              <InputField
                height="40px"
                name="name"
                placeholder="商品名検索"
                width="242px"
                onChange={e => {
                  setFieldValue('product_search', e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearchProduct(formik.values.product_search.trim())
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }
                }}
              />
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="12px"
                minW="135px"
                opacity="0.5"
              >
                最終更新日：
                {moment(productList?.last_updated).format('YYYY/MM/DD')}
              </Text>
            </Flex>
            <Flex gap={'10px'}>
              <Button
                backgroundColor="#FEFEFE"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="#0084FF"
                fontSize="14px"
                fontWeight={700}
                height="40px"
                text="アップロード状態"
                w="143px"
                onClick={() => {
                  navigate(ADMIN_ROUTES.PRODUCT_LOG)
                }}
              />
              <Button
                backgroundColor="#FEFEFE"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="#0084FF"
                fontSize="14px"
                fontWeight={700}
                height="40px"
                leftIcon={<Plus fontSize="20px" />}
                text="新規商品登録"
                w="143px"
                onClick={() => navigate(ADMIN_ROUTES.PRODUCT_REGISTRATION)}
              />
            </Flex>
          </Flex>
          <Box marginTop="16px">
            <DataTable
              columns={columns}
              data={productList.data}
              isPagination={true}
              isScroll={true}
              isShowAction={selectRecordId.length ? true : false}
              loadingData={productList.loading}
              maxH={
                selectRecordId.length
                  ? 'calc(100vh - 280px)'
                  : 'calc(100vh - 216px)'
              }
              pagination={pagination}
              rangePage={[15, 30, 50]}
              setPagination={setPagination}
              titleOpenModal="スコア対象として登録"
              onCancel={handleCancelSelected}
              onDelete={onOpenDelete}
              onOpenModal={onOpen}
            />
            <ModalConfirm
              isKeepAll={true}
              isOpen={openDetele}
              title={`選択した商品は削除され、
              スコア測定ができなくなります。
              本当によろしいですか？`}
              onClose={onCloseDelete}
              onSubmit={handleDeleteMultiRecord}
            />
          </Box>
        </Box>
      </FormikProvider>
      <ProductImportanceModal
        handleUpdateScoreProduct={handleUpdateScoreProduct}
        isOpen={isOpen}
        onClose={onClose}
      />
      <ProductRegistrationModal
        isOpen={openEmail}
        onClose={() => {
          onCloseEmail()
          // navigate(ADMIN_ROUTES.PRODUCT_LIST)
        }}
      />
    </>
  )
}
