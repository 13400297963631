import { axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import {
  GetAccessLogRequest,
  GetAccessLogResponse,
  GetProductLogDetailResponse,
  GetProductLogRequest,
  GetProductLogResponse,
} from './accessLogServices.type'

/**
 * @returns function that handle company services
 */
export class AccessLogServices {
  /**
   * @returns function that handle get list prompt admin
   */
  async getListAccessLog(
    params: GetAccessLogRequest,
  ): Promise<BasicResponse<GetAccessLogResponse[]>> {
    return (await axiosWithToken.get(`/v1/admin/access_log`, { params })).data
  }

  /**
   * @returns function that handle get list product upload csv log
   */
  async getListProductLog(
    params: GetProductLogRequest,
  ): Promise<BasicResponse<GetProductLogResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/admin/product/import/log`, { params })
    ).data
  }

  /**
   * @returns function that handle get upload csv log product detail
   */
  async getProductLogDetail(id: number): Promise<GetProductLogDetailResponse> {
    return (await axiosWithToken.get(`/v1/admin/product/import/log/${id}`)).data
  }
}
