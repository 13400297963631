import React from 'react'

/**
 * CloseWhiteSmallIcon icon
 * @constructor
 */
export const CloseWhiteSmallIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.823223 11.6497C0.776339 11.6965 0.75 11.7601 0.75 11.8264C0.75 11.8927 0.776339 11.9563 0.823223 12.0032L1.7463 12.9263C1.84393 13.0239 2.00222 13.0239 2.09985 12.9263L7 8.02614L11.9001 12.9263C11.9978 13.0239 12.1561 13.0239 12.2537 12.9263L13.1768 12.0032C13.2744 11.9056 13.2744 11.7473 13.1768 11.6497L8.27663 6.74951L13.1768 1.84937C13.2744 1.75173 13.2744 1.59344 13.1768 1.49581L12.2537 0.572735C12.2068 0.525851 12.1432 0.499512 12.0769 0.499512C12.0106 0.499512 11.947 0.525851 11.9001 0.572735L7 5.47288L2.09985 0.572735C2.05297 0.525851 1.98938 0.499512 1.92308 0.499512C1.85677 0.499512 1.79318 0.525851 1.7463 0.572735L0.823223 1.49581C0.776339 1.5427 0.75 1.60628 0.75 1.67259C0.75 1.73889 0.776339 1.80248 0.823223 1.84937L5.72337 6.74951L0.823223 11.6497Z"
      fill="white"
      stroke="white"
      strokeLinejoin="round"
      strokeWidth="0.5"
    />
  </svg>
)
