import { Flex, IconButton } from '@chakra-ui/react'
import { TrashIcon } from 'components/atoms/Icons'
import { Button } from 'components/fromAdvisor/elements'
import React, { FC } from 'react'

type ActionTableProps = {
  onCancel: (() => void) | undefined
  titleOpenModal: string | undefined
  onOpenModal: (() => void) | undefined
  onDelete: (() => void) | undefined
}
/**
 * ActionTable component
 * @constructor
 */
const ActionTable: FC<ActionTableProps> = ({
  onCancel,
  titleOpenModal,
  onOpenModal,
  onDelete,
}) => {
  return (
    <Flex gap={4} justify={'flex-end'} mt={'16px'} w="full">
      <Button
        bgColor={'white.50'}
        border="solid 1px"
        borderColor="blue.900"
        borderRadius={'12px'}
        color="blue.900"
        fontSize={'md'}
        fontWeight={'bold'}
        h={'48px'}
        letterSpacing={'tighter'}
        lineHeight={'shorter'}
        p="11px, 24px, 13px, 24px"
        text="キャンセル"
        w={'127px'}
        onClick={onCancel}
      />
      {titleOpenModal && (
        <Button
          bgColor={'blue.900'}
          border="solid 1px"
          borderColor="blue.900"
          borderRadius={'12px'}
          color="white.50"
          fontSize={'md'}
          fontWeight={'bold'}
          h={'48px'}
          letterSpacing={'tighter'}
          lineHeight={'shorter'}
          p="12px"
          text={titleOpenModal ?? ''}
          w={'206px'}
          onClick={onOpenModal}
        />
      )}
      <IconButton
        _hover={{
          bgColor: 'white.50',
        }}
        aria-label="trash"
        bgColor={'white.50'}
        h={'48px'}
        icon={<TrashIcon />}
        w={'48px'}
        onClick={onDelete}
      />
    </Flex>
  )
}

export default ActionTable
