import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { FC } from 'react'

export type RadioGroupFieldProps = {
  name: string
  disable?: boolean
  onChange?: (e: string | number) => void
  options?: { value: string; label: string }[]
}

/**
 * RadioGroupField component
 * @constructor
 */
export const RadioGroupField: FC<RadioGroupFieldProps> = ({
  name,
  disable = false,
  onChange,
  options,
}) => {
  const [meta] = useField(name)
  const { value } = meta
  return (
    <FormControl>
      <FormLabel
        fontSize="14px"
        fontWeight="700"
        lineHeight="24px"
        m="0"
        mb="8px"
      >
        ユーザー権限
      </FormLabel>
      <StyledRadioGroup
        isDisabled={disable}
        name={name}
        value={value}
        onChange={(e: string | number) => {
          if (onChange) {
            onChange(e)
          }
        }}
      >
        <Stack direction="row" px="16px" spacing="40px">
          {options?.map(item => (
            <Radio size="lg" value={item.value}>
              <Text fontSize="14px" lineHeight="24px">
                {item.label}
              </Text>
            </Radio>
          ))}
        </Stack>
      </StyledRadioGroup>
    </FormControl>
  )
}

const StyledRadioGroup = styled(RadioGroup)`
  .chakra-radio__control {
    width: 24px;
    height: 24px;
    border-color: #e8ecef;
    background-color: #ffffff;
    border-width: 2px;
    &[data-checked] {
      border-color: #e8ecef;
      background-color: #ffffff;
    }
    &[data-checked]::before {
      background-color: #0084ff;
      width: 16px;
      height: 16px;
    }
  }
`
