import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { SelectField } from 'components/atoms/SelectField'
import { InputField } from 'components/atoms/InputField'
import { useFormikContext } from 'formik'
import { icons } from 'constant'
import { monthOptionsCompany } from 'utils'
import { CompanyRegistrationType } from 'services/companyServices'
import { CompanyForm } from './CompanyForm'
import { PerformanceChange } from './PerformanceChange'
import { ButtonAddField } from './ButtonAddField'

type SelectFieldProps = {
  handleAddField: (value: string) => void
}
/**
 * FinancialIntelligence components
 * @constructor
 */
export const FinancialIntelligence: FC<SelectFieldProps> = ({
  handleAddField,
}) => {
  const { setFieldValue, values } = useFormikContext<CompanyRegistrationType>()
  /**
   * @returns function that handle next input
   */
  const handleChangeBankField = (
    value: string | number | undefined,
    name: string,
    index: number,
  ) => {
    const valuesFiled: any = values.bank && [...values.bank]
    valuesFiled[index][name] = value
    setFieldValue('bank', valuesFiled)
  }
  return (
    <Box marginTop="32px">
      <Text
        alignItems="center"
        display="flex"
        fontSize="18px"
        fontWeight="700"
        gap="8px"
        lineHeight="24px"
      >
        <Image src={icons.MONEY_BILL} />
        財務情報
      </Text>
      <CompanyForm
        fieldform={[
          {
            label: '取引銀行',
            components: (
              <Box m="8px">
                <Grid
                  gap="16px"
                  position="relative"
                  templateColumns="repeat(2, 327px)"
                >
                  {values.bank &&
                    values.bank.map((field, index) => (
                      <Box key={index}>
                        <InputField
                          height="48px"
                          name="name"
                          placeholder="入力してください"
                          sizeLabel="14px"
                          value={field.name}
                          width="327px"
                          onChange={e =>
                            handleChangeBankField(e.target.value, 'name', index)
                          }
                        />
                      </Box>
                    ))}
                  {values.bank === null && '-'}
                </Grid>
                <Box
                  alignItems="end"
                  bottom="8px"
                  display="flex"
                  flex="1"
                  justifyContent="end"
                  mb="8px"
                  position="absolute"
                  right="12px"
                >
                  <ButtonAddField onClick={() => handleAddField('bank')} />
                </Box>
              </Box>
            ),
          },
          {
            label: '決算月',
            components: (
              <Flex alignItems="center" gap="8px" my="8px" paddingLeft="8px">
                <SelectField
                  height="48px"
                  name="performance_trends.Fiscal_Closing_Month__c"
                  options={monthOptionsCompany}
                  placeholder="--"
                  valueOption={
                    monthOptionsCompany.find(
                      it =>
                        it.value ===
                        values.performance_trends.Fiscal_Closing_Month__c,
                    ) ?? null
                  }
                  width="80px"
                  onChange={e =>
                    setFieldValue(
                      'performance_trends.Fiscal_Closing_Month__c',
                      e?.value,
                    )
                  }
                />
                月
              </Flex>
            ),
          },
          {
            label: '業績推移',
            components: <PerformanceChange />,
          },
        ]}
        marginTop="16px"
      />
    </Box>
  )
}
