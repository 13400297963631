import { Divider, Flex, Image, Text, Tooltip } from '@chakra-ui/react'
// import { MoreVertical } from 'components/atoms/Icons'
import { icons } from 'constant'
import { formatCurrency } from 'constant/conmon'
import { FC } from 'react'
import { InfoCompanyProps, useInfoCompany } from './hook'

/**
 * InfoCompany molecules
 * @constructor
 */
const InfoCompany: FC<InfoCompanyProps> = props => {
  const { companyInfo, handleChangeFavorite, handleNavigationYahoo } =
    useInfoCompany(props)
  const { score, old_score } = companyInfo.dataRanking

  const companyScore = Number(score || 0) - Number(old_score || 0)

  // if (loading) {
  //   return (
  //     <Flex
  //       alignItems="center"
  //       bg="gray.600"
  //       h="calc(100vh - 88px)"
  //       justifyContent="center"
  //       w="100%"
  //     >
  //       <CircularProgress isIndeterminate color="blue.900" size={10} />
  //     </Flex>
  //   )
  // }

  return (
    <Flex
      alignItems="center"
      backgroundColor="white.50"
      borderRadius={4}
      gap="16px"
      height="64px"
      justifyContent="space-between"
      p="12px 20px 12px 24px"
      w="100%"
    >
      <Flex gap="16px" h="100%">
        <Flex alignItems="center" marginRight="4px">
          <Tooltip
            bgColor="black"
            color="white"
            label={companyInfo?.dataInfo?.company_name ?? ''}
            placement="top"
          >
            <Text
              fontSize="xl"
              fontWeight="bold"
              letterSpacing="-0.2px"
              lineHeight="tall"
              maxW="230px"
              overflow="hidden"
              px={'8px'}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
              }}
              w="100%"
            >
              {companyInfo?.dataInfo?.company_name}
            </Text>
          </Tooltip>
          <Image
            cursor="pointer"
            src={
              companyInfo.dataFavorite
                ? icons.EMPTY_STAR_COMPANY
                : icons.EMPTY_STAR
            }
            onClick={handleChangeFavorite}
          />
        </Flex>
        <Divider orientation="vertical" />
        <Flex direction="column" justifyContent="space-between">
          <Text
            color="black.100"
            fontSize="xs"
            fontWeight="normal"
            lineHeight="12px"
            opacity={0.3}
          >
            業界
          </Text>
          <Text
            color="black.100"
            fontSize="xs"
            fontWeight="normal"
            lineHeight="12px"
          >
            {companyInfo.dataInfo?.industry_name}
          </Text>
        </Flex>
        <Divider orientation="vertical" />
        <Flex direction="column" justifyContent="space-between">
          <Text
            color="black.100"
            fontSize="xs"
            fontWeight="normal"
            lineHeight="12px"
            opacity={0.3}
          >
            企業スコア
          </Text>
          <Flex gap={1} height="24px">
            <Flex alignItems="baseline">
              <Text
                color="black.100"
                fontSize="2xl"
                fontWeight="normal"
                lineHeight="shorter"
              >
                {Number(
                  Number(companyInfo?.dataRanking?.score ?? 0)
                    .toFixed(1)
                    .split('.')[0],
                ) ?? 0}
                {Number(companyInfo?.dataRanking?.score ?? 0)
                  .toFixed(1)
                  .split('.')[1] && '.'}
              </Text>
              <Text
                color="black.100"
                fontSize="18px"
                fontWeight="normal"
                lineHeight="24px"
              >
                {Number(companyInfo?.dataRanking?.score ?? 0)
                  .toFixed(1)
                  .split('.')[1] ?? ''}
              </Text>
            </Flex>
            <Text
              color="red.700"
              fontSize="xs"
              fontWeight="normal"
              lineHeight="32px"
            >
              {companyScore > 0
                ? `(+${companyScore.toFixed(1)})`
                : `(${companyScore.toFixed(1)})`}
            </Text>
          </Flex>
        </Flex>
        <Divider orientation="vertical" />
        <Flex
          cursor={'pointer'}
          direction="row"
          gap={5}
          justifyContent="space-between"
          onClick={handleNavigationYahoo}
        >
          <Flex direction={'column'} gap={1}>
            <Flex direction={'row'} gap={2}>
              <Text
                color="black.100"
                fontSize="xs"
                fontWeight="normal"
                lineHeight="12px"
                opacity={0.3}
              >
                株価
              </Text>
              <Text
                color="black.100"
                fontSize="xs"
                fontWeight="normal"
                lineHeight="12px"
                opacity={0.3}
              >
                前日終値
              </Text>
            </Flex>
            <Flex gap={1} justifyContent={'center'}>
              <Text
                color="black.100"
                fontSize="2xl"
                fontWeight="normal"
                lineHeight="shorter"
              >
                {formatCurrency(companyInfo?.dataInfo?.close_price ?? 0)}
              </Text>
              <Text
                color="black.100"
                fontSize="xs"
                fontWeight="normal"
                lineHeight="32px"
              >
                JPY
              </Text>
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={1}>
            <Text
              color="black.100"
              fontSize="xs"
              fontWeight="normal"
              lineHeight="12px"
              opacity={0.3}
            >
              前日比
            </Text>
            <Text
              color="red.700"
              fontSize="xs"
              fontWeight="normal"
              lineHeight="32px"
            >
              {companyInfo?.dataInfo?.value === 0
                ? ''
                : companyInfo?.dataInfo?.value &&
                  companyInfo?.dataInfo?.value > 0
                ? '+'
                : ''}
              {companyInfo?.dataInfo?.value ?? 0} (
              {companyInfo?.dataInfo?.valueRatio ?? 0}％)
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} /> */}
    </Flex>
  )
}

export default InfoCompany
