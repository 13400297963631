const ratio = 4
const step = 0.5

type Space = {
  [key: string]: string | number
}

export const space: Space = {
  px: '1px',
}

for (let i = -8; i <= 200; i += step) {
  space[i] = `${i * ratio}px`
}
