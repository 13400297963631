import { axiosWithFile, axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import { GetAccessLogRequest } from 'services/accessLogServices'
import {
  CategoryOptionsResponse,
  GetDetailProductResponse,
  GetListCategoryProductResponse,
  GetProductListRequest,
  ProductRankingRequest,
  ProductRankingResponse,
  ProductResponse,
  UpdateProductRequestApi,
  UpdateScoreProductRequest,
} from './productServices.type'

/**
 * @returns function that handle news services
 */
export class ProductAdminServices {
  /**
   * @returns function that handle save create prompt service
   */
  async createProduct(body: FormData) {
    return await axiosWithFile.post('/v1/admin/product/', body)
  }

  /**
   * @returns function that handle save get id prompt service
   */
  async getProductDetail(
    id: string,
  ): Promise<BasicResponse<GetDetailProductResponse>> {
    return (await axiosWithToken.get(`/v1/admin/product/${id}`)).data
  }

  /**
   * @returns function that handle save consultation service
   */
  async removeProduct(ids: number[]) {
    return await axiosWithToken.delete(`/v1/admin/product/`, { data: { ids } })
  }

  /**
   * @returns function that handle save consultation service
   */
  async updateProduct(id: string, body: UpdateProductRequestApi) {
    return await axiosWithToken.put(`/v1/admin/product/${id}`, body)
  }

  /**
   * @returns function that handle get product list service
   */
  async getProductList(
    params: GetProductListRequest,
  ): Promise<BasicResponse<ProductResponse[]>> {
    return (await axiosWithToken.get(`/v1/admin/product`, { params })).data
  }

  /**
   * @returns function that handle get product list service
   */
  async getProductChangeLogs(
    params: GetAccessLogRequest, // params: {},
  ): Promise<
    BasicResponse<
      {
        id: string
        action: string
        status: string
        created_at: string
        new_content: {
          product_name: string
        }
      }[]
    >
  > {
    return (
      await axiosWithToken.get(`/v1/admin/changelogs/process_request/`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that handle save consultation service
   */
  async updateChangeLogs(id: string, params: { status: string }) {
    return await axiosWithToken.patch(
      `/v1/admin/changelogs/process_request/${id}/?status=${params.status}`,
    )
  }

  /**
   * @returns function that create company
   */
  async uploadCsvProduct(body: FormData) {
    return await axiosWithFile.post('/v1/admin/product/import', body)
  }

  /**
   * @returns function that update score product
   */
  async updateScoreProduct(body: UpdateScoreProductRequest) {
    return await axiosWithToken.post('/v1/admin/product/update_score', body)
  }

  /**
   * @returns function that get list category product
   */
  async getListCategoryProduct(): Promise<
    BasicResponse<GetListCategoryProductResponse[]>
  > {
    return (await axiosWithToken.get('/v1/admin/category_product')).data
  }

  /**
   * @returns function that get options category product ranking
   */
  async getOptionsCategory(): Promise<
    BasicResponse<CategoryOptionsResponse[]>
  > {
    return (await axiosWithToken.get(`/v1/admin/category_product`)).data
  }

  /**
   * @returns function that get data product ranking
   */
  async getCorporateRanking(
    params: ProductRankingRequest,
  ): Promise<BasicResponse<ProductRankingResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/product/ranking/`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get data product ranking
   */
  async getCorporateRankingNews(params: {
    product_type: string
    news_id?: string
    company_id?: number
  }): Promise<BasicResponse<ProductRankingResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/product/product_ranking_score/`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that create company
   */
  async uploadfiles(body: FormData, idPro: number) {
    return await axiosWithFile.put(
      `/v1/admin/product/${idPro}/upload_files/`,
      body,
    )
  }
}
