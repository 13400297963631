import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'libs'

/**
 *
 */
const authSelector = (state: RootState) => state.auth

export const loggedInUserSelector = createSelector(
  authSelector,
  authState => authState.loggedInUser,
)

export const auth = createSelector(authSelector, authState => authState.user)

export const isAdminLoginSelector = createSelector(
  authSelector,
  authState => authState.isAdmin,
)
