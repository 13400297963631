/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { FormikProvider } from 'formik'

import ModalAIAdvice from '../ModalAIAdvice'
import { ContentAskAI } from '../ContentAskAI'
import ModalConfirmAIAdvice from '../ModalConfirmAIAdvice'
import { AIAdviceProps, useAIAdvice } from './hook'

/**
 * AIAdvice molecules
 * @constructor
 */
export const AIAdvice: FC<AIAdviceProps> = props => {
  const {
    formik,
    askAIOptions,
    values,
    setAIAnswer,
    setConversationId,
    setFieldValue,
    AIAnswer,
    toggleExpanded,
    conversationId,
    expanded,
    setExpanded,
    setShowConfirmModal,
    handleCloseModal,
    showConfirmModal,
    setRenderPrompt,
  } = useAIAdvice(props)
  return (
    <FormikProvider value={formik}>
      <Box boxSizing="border-box" padding="12px 16px 16px 16px" w="100%">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={'12px'}
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            <SelectField
              height="40px"
              heightListOptions="220px"
              isDisabled={!AIAnswer.done}
              maxWidth="294px"
              name="askAI"
              options={askAIOptions}
              placeholder="連想されるビジネスは？ "
              valueOption={askAIOptions.find(it => it.value === values.askAI)}
              onChange={e => {
                if (values.askAI !== e?.value) {
                  setAIAnswer(prev => ({
                    ...prev,
                    data: '',
                    loading: true,
                    done: false,
                  }))
                  setConversationId(null)
                  setFieldValue('askAI', e?.value)
                  setRenderPrompt('')
                }
              }}
            />
          </Flex>
        </Flex>
        <ContentAskAI
          content={AIAnswer}
          heightContent="calc(100vh - 240px)"
          isCopyBtn={true}
          isDone={AIAnswer.done}
          isHidden={false}
          isOpenModal={true}
          isValues={Boolean(values.askAI)}
        />
      </Box>
      <ModalAIAdvice
        AIAnswer={AIAnswer}
        askAIOptions={askAIOptions}
        conversationId={conversationId}
        isOpen={expanded}
        isValues={Boolean(values.askAI)}
        setAIAnswer={setAIAnswer}
        setConversationId={setConversationId}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
        setValueAskAI={value => setFieldValue('askAI', value)}
        value={values.askAI}
        onClose={handleCloseModal}
      />
      <ModalConfirmAIAdvice
        conversationId={conversationId}
        isOpen={showConfirmModal}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
      />
    </FormikProvider>
  )
}
