import { CompanyDetailContainer } from 'components/organisms'
import { FC } from 'react'

/**
 * CompanyDetail page
 * @constructor
 */
export const CompanyDetail: FC = () => {
  return <CompanyDetailContainer />
}
