import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'libs'

/**
 *
 */
const searchNewsKey = (state: RootState) => state.searchNewsKey

export const SearchNewsKeySelector = createSelector(
  searchNewsKey,
  searchNewsKeySlice => searchNewsKeySlice,
)
