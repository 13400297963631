import { Divider, Flex, ListItem, Text, Tooltip } from '@chakra-ui/react'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { ROUTES } from 'constant'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { RankingProps } from 'types/conmon'

type ListItemProps = {
  stt: number
  separator: boolean
  isPositive: boolean
  scoreChange: number
  showScoreChange: boolean
  isNavigate: boolean
  item: RankingProps
  isColumn?: boolean
}

const RenderListItem: FC<ListItemProps> = ({
  stt,
  separator,
  isPositive,
  scoreChange,
  showScoreChange,
  isNavigate,
  item,
  isColumn,
}) => {
  const navigate = useNavigate()
  const { id, score, title, score_reason } = item
  return (
    <ListItem
      key={stt}
      alignItems="center"
      display="flex"
      gap={2}
      height="32px"
      justifyContent="space-between"
      w={'100%'}
    >
      <Flex
        alignItems="center"
        bgColor={stt <= 3 ? 'blue.800' : 'black.1000'}
        borderRadius="xl"
        height="32px"
        justifyContent="center"
        minWidth={'32px'}
        width="32px"
      >
        <Text
          bottom={0}
          color="black.100"
          fontSize="md"
          fontWeight="bold"
          lineHeight="16px"
          opacity={0.6}
        >
          {stt}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        gap={2}
        maxW={'calc(100% - 113px)'}
        padding="10px"
        width={'full'}
      >
        <TextOverflowTooltip
          isCursor
          color="blue.900"
          fontSize="sm"
          fontWeight="bold"
          lineHeight="14px"
          minW={isColumn ? 'fit-content' : 'full'}
          value={title}
          onClick={() => {
            if (isNavigate && id) {
              navigate(`${ROUTES.COMPANY}/${id}`)
            }
          }}
        />
        {separator && <Divider />}
      </Flex>
      <Flex
        alignItems="flex-end"
        gap="4px"
        h="32px"
        justifyContent={isColumn ? 'flex-start' : 'flex-end'}
        w={isColumn ? 'auto' : '65px'}
      >
        <Tooltip
          bgColor="black"
          color="white"
          label={score_reason}
          placement="top-start"
        >
          <Text
            alignItems="flex-end"
            color="black.100"
            display="flex"
            fontSize="2xl"
            textAlign="center"
          >
            {Number(score).toFixed(1).split('.')[0]}
            {'.'}
            <Text
              color="black.100"
              display="inline-block"
              fontSize="lg"
              lineHeight="27px"
            >
              {Number(score).toFixed(1).split('.')[1] || '0'}
            </Text>
          </Text>
        </Tooltip>
        {showScoreChange && (
          <Text
            color={isPositive ? 'green.500' : 'red.700'}
            fontSize="xs"
            fontWeight="normal"
            lineHeight="26px"
          >
            {scoreChange > 0
              ? `(+${scoreChange.toFixed(1)})`
              : `(${scoreChange.toFixed(1)})`}
          </Text>
        )}
      </Flex>
    </ListItem>
  )
}

export default RenderListItem
