import React, { FC, ReactElement } from 'react'
import ReactSelect, {
  Props as ReactSelectProps,
  StylesConfig,
  components,
  DropdownIndicatorProps,
} from 'react-select'
import { DropdownIcon } from 'components/fromAdvisor/elements/Icons'
import { Image, useTheme } from '@chakra-ui/react'

/**
 * @returns function that handle render dropdown icon
 */
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  )
}

/**
 * @returns function that handle render option icon
 */
const IconOption = (props: any) => {
  const {
    data: { label, icon },
  } = props

  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        {typeof icon === 'string' ? (
          <Image alt={label} src={icon} />
        ) : (
          <span>{icon}</span>
        )}{' '}
        {label}
      </div>
    </components.Option>
  )
}

type SelectCustomProps = ReactSelectProps

/**
 * Select component page.
 * @return {JSX.Element} Select component.
 */
export const Select: FC<SelectCustomProps> = (props): ReactElement => {
  const theme = useTheme()
  const CustomStyles: StylesConfig = {
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: theme.fontSizes.sm,
      color: theme.colors.black[100],
      opacity: 0.3,
    }),
    option: (styles, props) => {
      return {
        ...styles,
        padding: `${theme.space[3]} ${theme.space[6]}`,
        fontWeight: props.isSelected
          ? theme.fontWeights.bold
          : theme.fontWeights.medium,
        letterSpacing: theme.letterSpacings.tighter,
        fontSize: theme.fontSizes.sm,
        lineHeight: theme.lineHeights.shorter,
        color: theme.colors.white[50],
        cursor: 'pointer',
        background: props.isSelected ? theme.colors.black[150] : 'transparent',
        '&:hover': {
          background: theme.colors.black[150],
          fontWeight: theme.fontWeights.bold,
        },
      }
    },
    singleValue: styles => ({
      ...styles,
      color: theme.colors.black[100],
      fontSize: theme.fontSizes.sm,
      lineHeight: theme.lineHeights.shorter,
      fontWeight: theme.fontWeights.normal,
    }),
    control: (styles, props) => ({
      ...styles,
      '&:hover': {
        borderStyle: 'solid',
        borderColor: theme.colors.blue[900],
        cursor: 'pointer',
      },
      background: theme.colors.white[50],
      borderColor: props.menuIsOpen
        ? theme.colors.blue[500]
        : theme.colors.purple[250],
      borderWidth: theme.space.px,
      height: theme.sizes[12],
      width: theme.sizes.full,
      boxShadow: theme.boxShadows.passwordInput,
      borderRadius: theme.radii.xl,
    }),
    menuList: styles => ({
      ...styles,
      padding: `${theme.space[3]} 0`,
    }),
    menu: styles => ({
      ...styles,
      margin: 0,
      background: `${theme.colors.black[300]}`,
      borderRadius: `${theme.radii['2xl']}`,
    }),
    indicatorsContainer: styles => ({
      ...styles,
      paddingRight: theme.space[2],
    }),
    valueContainer: styles => ({
      ...styles,
      paddingRight: 0,
    }),
  }

  return (
    <ReactSelect
      components={{ DropdownIndicator, Option: IconOption }}
      isSearchable={false}
      styles={CustomStyles}
      {...props}
    />
  )
}
