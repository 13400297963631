import React from 'react'
import { Box, BoxProps, useTheme } from '@chakra-ui/react'

export type ErrorFormProps = BoxProps & {
  error?: string
}

/**
 * ErrorForm component page.
 * @return {JSX.Element} ErrorForm component.
 */
export const ErrorForm: React.FC<ErrorFormProps> = ({
  error,
  ...props
}): React.ReactElement => {
  const theme = useTheme()
  return (
    <Box
      as={'p'}
      color="red.700"
      fontSize={'xs'}
      fontWeight={'medium'}
      letterSpacing={theme.letterSpacings[-1.4]}
      lineHeight={'shorter'}
      minH={theme.space[3]}
      mt={theme.space[1.5]}
      {...props}
    >
      {error}
    </Box>
  )
}
