import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Smile icon
 * @param props
 * @constructor
 */
export const Smile: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22.793A10.793 10.793 0 1022.793 12 10.805 10.805 0 0012 1.207z"
          fill="currentColor"
        ></path>
        <path
          d="M17.664 14.105a6.085 6.085 0 01-11.33 0c-.301-.712-1.342-.097-1.042.61a7.273 7.273 0 0013.414 0c.299-.707-.74-1.328-1.042-.61zM8.38 9.901a1.509 1.509 0 100-3.017 1.509 1.509 0 000 3.017zM15.622 9.901a1.509 1.509 0 100-3.017 1.509 1.509 0 000 3.017z"
          fill="currentColor"
        ></path>
      </svg>
    </Icon>
  )
}
