import React, { FC } from 'react'
import { CircularProgress, Flex, IconButton, useTheme } from '@chakra-ui/react'

import { Button } from 'components/fromAdvisor/elements'
import {
  FilterListIcon,
  LeftDrawerIcon,
  PlusIcon,
} from 'components/fromAdvisor/elements/Icons'
import { HistoryList } from './List'
import { ConversationListProps } from './ChatHistory.types'
import { ModalFilterHistory } from './ModalFilterHistory'

/**
 * ChatHistoryContainer component page.
 * @return {JSX.Element} ChatHistoryContainer component.
 */
export const ChatHistoryContainer: FC<ConversationListProps> = ({
  onNewChat,
  conversationList,
  isOpenModalFilter,
  onFilterConversation,
  onOpenModalFilter,
  onCloseModalFilter,
  onChangeBookmark,
  isLoading,
  onDeleteConversation,
  onCloseDrawerLeft,
  selectedConversation,
  onChangePageSize,
  onSelectConversation,
  paramsFilterHistory,
  setParamsFilterHistory,
  setPageCount,
  setConversationList,
}) => {
  const theme = useTheme()
  return (
    <Flex
      bg={'white.150'}
      borderBottomLeftRadius={4}
      flexDirection={'column'}
      h={'full'}
      pb={6}
      w={'full'}
    >
      <Flex
        alignItems={'center'}
        borderBottomColor={'purple.250'}
        borderBottomWidth={theme.space.px}
        justifyContent={'space-between'}
        p={6}
      >
        <Button
          bg={'blue.900'}
          color={'white.50'}
          h={12}
          leftIcon={<PlusIcon />}
          text={'New chat'}
          w={46}
          onClick={onNewChat}
        />

        <IconButton
          _hover={{
            background: 'transparent',
          }}
          aria-label="filter list"
          bg={'transparent'}
          h={6}
          icon={<FilterListIcon />}
          minW={6}
          onClick={onOpenModalFilter}
        />

        <IconButton
          _hover={{
            background: 'transparent',
          }}
          aria-label="left drawer"
          bg={'transparent'}
          h={6}
          icon={<LeftDrawerIcon />}
          minW={6}
          onClick={onCloseDrawerLeft}
        />
      </Flex>

      {!conversationList && isLoading ? (
        <Flex
          alignItems={'center'}
          h={'full'}
          justifyContent={'center'}
          mt={4}
          w={'full'}
        >
          <CircularProgress isIndeterminate color="blue.400" size={10} />
        </Flex>
      ) : (
        <HistoryList
          conversationList={conversationList}
          selectedConversation={selectedConversation}
          onChangeBookmark={onChangeBookmark}
          onChangePageSize={onChangePageSize}
          onDeleteConversation={onDeleteConversation}
          onOpenModalFilter={onOpenModalFilter}
          onSelectConversation={onSelectConversation}
        />
      )}
      {conversationList && isLoading && (
        <Flex alignItems={'flex-end'} justifyContent={'center'} w={'full'}>
          <CircularProgress isIndeterminate color="blue.400" size={10} />
        </Flex>
      )}
      <ModalFilterHistory
        isLoading={isLoading}
        isOpenModalFilter={isOpenModalFilter}
        paramsFilterHistory={paramsFilterHistory}
        setConversationList={setConversationList}
        setPageCount={setPageCount}
        setParamsFilterHistory={setParamsFilterHistory}
        onCloseModalFilter={onCloseModalFilter}
        onFilterConversation={onFilterConversation}
      />
    </Flex>
  )
}
