import React, { FC } from 'react'
import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import { Avatar } from 'components/fromAdvisor/elements'
import { QuestionBoxProps } from './MainChat.types'

/**
 * QuestionBox component page.
 * @return {JSX.Element} QuestionBox component.
 */
export const QuestionBox: FC<QuestionBoxProps> = ({
  questionHistory,
  avatar,
}) => {
  const theme = useTheme()
  return (
    <Flex
      bg={'white.50'}
      borderColor={'purple.250'}
      borderRadius={'xl'}
      borderWidth={'3px'}
      boxShadow={theme.boxShadows.insetInputBase}
      gap={6}
      minH={'156px'}
      px={6}
      py={8}
      w={'full'}
    >
      <Box maxH={15} minW={15} w={15}>
        <Avatar src={avatar} />
      </Box>
      <Text
        as={'span'}
        color={'black.base'}
        fontSize={'md'}
        letterSpacing={theme.letterSpacings[6]}
        lineHeight={'27.2px'}
        maxW={'calc(100% - 74px)'}
        whiteSpace={'pre-wrap'}
      >
        <Box dangerouslySetInnerHTML={{ __html: questionHistory ?? '' }}></Box>
      </Text>
    </Flex>
  )
}
