import { StorageServices } from './storageServices'
import { ChatAIService } from './chatAIServices'
import { ConsultationsServices } from './consultationsServices'
import { SaveBoxServices } from './saveBoxServices'
import { AuthServices } from './authServices'
import { NewServices } from './newServices'
import { AskAIServices } from './askAIServices'
import { ProductAdminServices } from './productServices'
import { UserAdminServices } from './userServices'
import { CompanyServices } from './companyServices'
import { SettingServices } from './settingServices'
import { AccessLogServices } from './accessLogServices'

export const storageServices = new StorageServices()
export const chatAIServices = new ChatAIService()
export const consultationsServices = new ConsultationsServices()
export const saveBoxServices = new SaveBoxServices()
export const authServices = new AuthServices()
export const newServices = new NewServices()
export const askAIServices = new AskAIServices()
export const productAdminServices = new ProductAdminServices()
export const userAdminServices = new UserAdminServices()
export const companyServices = new CompanyServices()
export const settingServices = new SettingServices()
export const accessLogServices = new AccessLogServices()
