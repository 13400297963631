import React, { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import { ContentPdfAI } from '../ContentPdfAI'
import { ArticleSummaryProps, useArticleSummary } from './hook'

/**
 * ArticleSummary component page.
 * @return {JSX.Element} ArticleSummary component.
 */
export const ArticleSummary: FC<ArticleSummaryProps> = props => {
  const {
    data: { data, loading },
    handleSetPagePdf,
  } = useArticleSummary(props)

  return (
    <Flex flexDirection="column" gap="12px" padding="12px">
      <ContentPdfAI
        data={data}
        handleSetPagePdf={handleSetPagePdf}
        loading={loading}
      />
      {/* <Flex
        bg={'white.50'}
        borderColor={'purple.250'}
        borderRadius={'xl'}
        borderWidth={'1px'}
        boxShadow={theme.boxShadows.insetInputBase}
        padding="12px"
        w={'full'}
      >
        <Avatar
          src={'https://th.bing.com/th/id/OIG.MxQxUggA0RKmKdTjwAqw'}
          w="40px"
        />
        <Flex
          alignItems={'flex-end'}
          flexDirection={'column'}
          h={'full'}
          w={'full'}
        >
          <AutoResizeTextarea
            _focusVisible={{
              border: 'none',
            }}
            _placeholder={{
              opacity: 0.3,
              color: 'black.100',
            }}
            border={'none'}
            color={'black.100'}
            fontSize={'md'}
            fontWeight={'normal'}
            lineHeight={'30px'}
            overflow={'auto'}
            placeholder={'質問を入力してください'}
            resize={'none'}
            value={question}
            w={'full'}
            onChange={onChangeInput}
            onKeyDown={onEnter}
          />
        </Flex>
        <IconButton
          _hover={{
            background: 'transparent',
          }}
          aria-label="send message"
          bg={'transparent'}
          icon={<PaperPlaneIcon />}
          zIndex={theme.zIndices.docked}
          onClick={onSubmit}
        />
      </Flex> */}
    </Flex>
  )
}
