import { FC, ReactElement, useState } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useFormik, FormikProvider } from 'formik'
import { InputField } from 'components/atoms/InputField'
import { SendMailIcon } from 'components/atoms/Icons'
import { authServices } from 'services'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import _ from 'lodash'
import { AxiosError } from 'axios'
import { changeEmailSchema } from '../validation'

/**
 * ModalChangeEmail component page.
 * @return {JSX.Element} ModalChangeEmail component.
 */
export const ModalChangeEmail: FC<{
  isOpen: boolean
  onClose: () => void
  email?: string
  size: string
}> = ({ isOpen, onClose, email, size }): ReactElement => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [resError, setResError] = useState('')
  const urlSendMail = `${process.env.REACT_APP_EMAIL_URL}${
    size === ROLE.ADMIN ? ADMIN_ROUTES.VERIFY_EMAIL : ROUTES.VERIFY_EMAIL
  }?`
  const formik = useFormik({
    initialValues: {
      email: email ?? '',
      new_email: '',
      cf_email: '',
    },
    validationSchema: changeEmailSchema,
    validateOnChange: false,

    /**
     *
     */
    async onSubmit(values: {
      email: string
      new_email: string
      cf_email: string
    }) {
      try {
        setLoading(true)
        await authServices.changeEmail(
          size,
          { new_email: values.new_email },
          {
            call_back: urlSendMail,
          },
        )
        setIsSuccess(true)
        setLoading(false)
        resetForm()
      } catch (error) {
        setLoading(false)
        if (error instanceof AxiosError) {
          if (error.response) {
            setResError(error.response.data?.detail)
          } else {
            setResError(error.message)
          }
        }
      }
    },
  })
  const { handleSubmit, handleChange, errors, dirty, resetForm, isValid } =
    formik
  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxWidth={'423px'}
      onClose={() => {
        onClose()
        setIsSuccess(false)
        resetForm()
        setResError('')
      }}
    >
      <FormikProvider value={formik}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p="32px 40px"
        >
          {isSuccess ? (
            <>
              <SendMailIcon />
              <Text
                fontSize="20px"
                fontWeight="700"
                lineHeight="32px"
                marginTop="16px"
                textAlign="center"
              >
                新しいメールアドレスに <br /> メールを送信しました
              </Text>
              <Text
                fontSize="14px"
                lineHeight="24px"
                marginTop="16px"
                textAlign="center"
              >
                メールに記載されている <br />
                認証用URLにアクセスし、 <br />
                メールアドレスの変更手続きを <br />
                完了してください。
              </Text>
            </>
          ) : (
            <>
              <Text
                fontSize="20px"
                fontWeight="700"
                lineHeight="32px"
                marginTop="6px"
                textAlign="center"
              >
                メールアドレス変更
              </Text>
              {resError && (
                <Text
                  color="red.700"
                  fontSize="sm"
                  fontWeight="medium"
                  lineHeight="shorter"
                  mt="32px"
                  px="32px"
                  textAlign="center"
                  whiteSpace="break-spaces"
                >
                  {resError}
                </Text>
              )}
              <Flex
                flexDirection="column"
                gap="24px"
                marginY="32px"
                width="100%"
              >
                <Box>
                  <Text fontWeight="700" lineHeight="24px">
                    現在のメールアドレス
                  </Text>
                  <Text
                    fontSize="14px"
                    lineHeight="24px"
                    mt="16px"
                    padding="12px 16px"
                  >
                    {email}
                  </Text>
                </Box>
                <InputField
                  error={Boolean(errors.new_email || resError)}
                  errorText={errors.new_email}
                  height="48px"
                  label="新しいメールアドレス"
                  name="new_email"
                  placeholder="例：sample@sample.co.jp"
                  sizeInput="14px"
                  sizeLabel="16px"
                  width="100%"
                  onChange={handleChange}
                />
                <InputField
                  error={Boolean(errors.cf_email || resError)}
                  errorText={errors.cf_email}
                  height="48px"
                  label="新しいメールアドレス（確認用）"
                  name="cf_email"
                  placeholder="例：sample@sample.co.jp"
                  sizeInput="14px"
                  sizeLabel="16px"
                  width="100%"
                  onChange={handleChange}
                />
              </Flex>
              <Button
                backgroundColor="#0084FF"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="white.50"
                fontWeight={'bold'}
                height="48px"
                isDisabled={!_.isEmpty(errors) || !dirty || !isValid}
                isLoading={loading}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                text="確認メールを送信する"
                width="100%"
                onClick={() => handleSubmit()}
              />
              <Button
                backgroundColor="#FEFEFE"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="#0084FF"
                fontWeight={'bold'}
                height="48px"
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                marginTop="16px"
                text="キャンセル"
                width="100%"
                onClick={() => {
                  resetForm()
                  onClose()
                  setResError('')
                }}
              />
            </>
          )}
        </Box>
      </FormikProvider>
    </Modal>
  )
}
