import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { companyServices } from 'services'
import { Option, RankingProps } from 'types/conmon'

export type RankingCorporateProps = {}

export const useRankingCorporate = (props: RankingCorporateProps) => {
  const toast = useToast()
  const formik = useFormik({
    initialValues: {
      date_range_type: '0',
      industry_name: '',
      product_name: '',
      contact_person_name: '',
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { values, setFieldValue } = formik

  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [productOptions, setProductOptions] = useState<{
    data: Option[]
    page: number
    loadingMore: boolean
  }>({
    data: [],
    page: 1,
    loadingMore: true,
  })
  const [contactPersonOptions, setContactPersonOptions] = useState<{
    data: Option[]
    page: number
    loadingMore: boolean
  }>({
    data: [],
    page: 1,
    loadingMore: true,
  })
  const [dataRanking, setDataRanking] = useState<{
    loading: boolean
    data: RankingProps[]
  }>({ loading: false, data: [] })
  /**
   * fetch industry options
   */
  const fetchOptionsIndustry = useCallback(async () => {
    try {
      const { data } = await companyServices.getOptionsIndustry()
      const options = data.map(d => ({
        label: d.industry_name,
        value: d.industry_name,
      }))

      setIndustryOptions(options)
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message ?? 'Error',
      })
    }
  }, [toast])

  useEffect(() => {
    fetchOptionsIndustry()
  }, [fetchOptionsIndustry])
  /**
   * fetch product options
   */
  const fetchOptionsProduct = useCallback(async () => {
    try {
      const { data, paginate } = await companyServices.getOptionsProduct({
        page: productOptions.page,
      })
      const options = data.map(d => ({
        label: d.product_name,
        value: d.product_name,
      }))
      setProductOptions(prev => ({
        ...prev,
        data: [...prev.data, ...options],
      }))

      if (paginate && productOptions.page >= paginate.page_count) {
        setProductOptions(prev => ({ ...prev, loadingMore: false }))
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message ?? 'Error',
      })
    }
  }, [productOptions.page, toast])

  const handleScrollBottomProduct = () => {
    if (productOptions.loadingMore) {
      setProductOptions(prev => ({ ...prev, page: prev.page + 1 }))
    }
  }

  useEffect(() => {
    fetchOptionsProduct()
  }, [fetchOptionsProduct])
  /**
   * fetch contact person options
   */
  const fetchOptionsContactPerson = useCallback(async () => {
    try {
      const { data, paginate } = await companyServices.getOptionsContactPerson({
        page: contactPersonOptions.page,
      })
      const options = data.map(d => ({
        label: d.contact_person_name,
        value: d.contact_person_name,
      }))
      setContactPersonOptions(prev => ({
        ...prev,
        data: [...prev.data, ...options],
      }))

      if (paginate && contactPersonOptions.page >= paginate.page_count) {
        setContactPersonOptions(prev => ({ ...prev, loadingMore: false }))
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message ?? 'Error',
      })
    }
  }, [contactPersonOptions.page, toast])

  const handleScrollBottomContactPerson = () => {
    if (contactPersonOptions.loadingMore) {
      setContactPersonOptions(prev => ({ ...prev, page: prev.page + 1 }))
    }
  }

  useEffect(() => {
    fetchOptionsContactPerson()
  }, [fetchOptionsContactPerson])

  const fetchDataRanking = useCallback(async () => {
    try {
      setDataRanking(prev => ({ ...prev, loading: true, data: [] }))
      const { data } = await companyServices.getCorporateRanking(values)
      const convertData = data.map(d => ({
        title: d.company,
        score: d.score,
        old_score: d.old_score,
        id: d.company_id,
      }))
      setDataRanking(prev => ({ ...prev, loading: false, data: convertData }))
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message ?? 'Error',
      })
      setDataRanking(prev => ({ ...prev, loading: false, data: [] }))
    }
  }, [toast, values])

  useEffect(() => {
    fetchDataRanking()
  }, [fetchDataRanking])
  return {
    ...props,
    formik,
    values,
    industryOptions,
    setFieldValue,
    handleScrollBottomProduct,
    productOptions,
    handleScrollBottomContactPerson,
    contactPersonOptions,
    dataRanking,
  }
}
