import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'

import { Provider as ReduxProvider } from 'react-redux'
import store from 'libs/redux'
import CustomToast from 'components/atoms/CustomToast'
import { Navigations } from './navigations'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ReduxProvider store={store}>
    <ChakraProvider
      resetCSS
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: 'top',
          duration: 3000,
          isClosable: false,
          render: ({ title, description, status }) => (
            <CustomToast
              description={description}
              status={status}
              title={title}
            />
          ),
        },
      }}
    >
      <Navigations />
    </ChakraProvider>
  </ReduxProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
