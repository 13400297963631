import { Post } from 'components/molecules'
import { FC } from 'react'
import { Flex } from '@chakra-ui/react'

/**
 * PostDetailContainer organisms
 * @constructor
 */
export const PostDetailContainer: FC = () => {
  return (
    <Flex gap="16px" w="100%">
      <Post />
    </Flex>
  )
}
