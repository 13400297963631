import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { storageServices } from 'services'
import { configs } from 'configs'
import { ROLE } from 'constant'

interface IConfig {
  withAuthToken: boolean
  withFile?: boolean
}

const defaultConfig: IConfig = {
  withAuthToken: true,
  withFile: false,
}

/**
 * Custom Config Axios
 * Custom BaseURL
 * Custom interceptors request
 * */
export function createAxios(config?: Partial<IConfig>): AxiosInstance {
  const configValues: IConfig = config
    ? { ...defaultConfig, ...config }
    : defaultConfig

  const headers: { [key: string]: string } = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': configValues.withFile
      ? 'application/zip'
      : 'application/json;charset=utf-8',
  }

  const baseURL = `${configs.apiURL}/api`
  const client = axios.create({
    baseURL,
    headers,
  })

  if (configValues.withAuthToken) {
    // @ts-ignore
    client.interceptors.request.use(function (config: AxiosRequestConfig) {
      const accessToken = storageServices.getAccessToken()
      config.headers = config.headers ?? {}
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    })

    client.interceptors.response.use(
      response => {
        return response
      },
      // eslint-disable-next-line @typescript-eslint/typedef
      async function (error) {
        if (!navigator.onLine) {
          return Promise.reject({
            ...error,
            message: 'ネットワークの問題が発生しました。',
          })
        }
        const refreshToken = storageServices.getRefreshToken()
        if (!refreshToken) return Promise.reject(error)
        const originalRequest = error.config
        if (
          configValues.withAuthToken &&
          (error.response.status === 403 || error.response.status === 401) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          const isAdminRole = storageServices.getRoleUser() === ROLE.ADMIN
          const url = isAdminRole
            ? `/v1/admin/oauth/refresh`
            : `/v1/advisor/oauth/refresh`

          const { data } = await client.post(url, {
            refresh_token: refreshToken,
          })
          const access_token = data?.data?.access_token
          storageServices.setAccessToken(access_token)
          client.defaults.headers.common['Authorization'] =
            'Bearer ' + access_token
          return client(originalRequest)
        }
        return Promise.reject(error)
      },
    )
  }
  return client
}
