import React, { FC, useMemo } from 'react'
import { Flex } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import { Option } from 'types/conmon'
import { InitialValuesType } from 'components/molecules/SearchPost/hook'
import { InputField } from '../InputField'
import { SelectField } from '../SelectField'
import { CheckboxField } from '../CheckboxField'
import RangeDatePopover from './ContentPopover/RangeDatePopover'
import RangeNumberPopover from './ContentPopover/RangeNumberPopover'
import CheckboxGroupPopover from './ContentPopover/CheckboxGroupPopover'

type RenderFieldProps = {
  meta: any
  currentValue: {
    [key: string]: any
  }
  errors: FormikErrors<{
    [key: string]: any
  }>
  setCurrentValue: React.Dispatch<React.SetStateAction<InitialValuesType>>
  title: string
}

const RenderField: FC<RenderFieldProps> = ({
  meta,
  currentValue,
  errors,
  setCurrentValue,
  title,
}) => {
  const handleShowField = useMemo(() => {
    const { field_name, field_name_2, text, options, columns, mode, type } =
      meta

    switch (type) {
      case 'range-date':
        return (
          <RangeDatePopover
            endDate={currentValue[field_name_2]}
            errors={errors}
            name={field_name}
            name2={field_name_2}
            setCurrentValue={setCurrentValue}
            startDate={currentValue[field_name]}
            title={title}
          />
        )
      case 'text':
        return (
          <InputField
            height={'48px'}
            isInForm={false}
            label={title}
            name={field_name}
            placeholder={'入力してください'}
            sizeLabel="14px"
            value={currentValue[field_name]}
            width={'full'}
            onChange={e => {
              setCurrentValue(prev => ({
                ...prev,
                [field_name]: e.target.value,
              }))
            }}
          />
        )

      case 'select':
        return (
          <SelectField
            isResetValue
            height={'48px'}
            heightListOptions="224px"
            label={title}
            name={field_name}
            options={options as Option[]}
            placeholder={'選択してください'}
            sizeLabel="14px"
            valueOption={(options as Option[]).find(
              o => o.value === currentValue[field_name],
            )}
            width={'full'}
            onChange={e => {
              setCurrentValue(prev => ({
                ...prev,
                [field_name]: e?.value,
              }))
            }}
          />
        )
      case 'title':
        return (
          <Flex direction={'column'} gap={'16px'}>
            <InputField
              height={'48px'}
              isInForm={false}
              label={title}
              name={field_name}
              placeholder={'入力してください'}
              sizeLabel="14px"
              value={currentValue[field_name]}
              width={'full'}
              onChange={e => {
                setCurrentValue(prev => ({
                  ...prev,
                  [field_name]: e.target.value,
                }))
              }}
            />
            {field_name_2 && (
              <CheckboxField
                isInForm={false}
                label={text}
                name={field_name_2}
                value={currentValue[field_name_2]}
                onChange={() => {
                  setCurrentValue(prev => ({
                    ...prev,
                    [field_name_2]: !Boolean(currentValue[field_name_2]),
                  }))
                }}
              />
            )}
          </Flex>
        )
      case 'range-number':
        return (
          <RangeNumberPopover
            fromNumber={currentValue[field_name]}
            name={field_name}
            name2={field_name_2}
            setValue={setCurrentValue}
            title={title}
            toNumber={currentValue[field_name_2]}
          />
        )
      case 'checkbox-group':
        return (
          <CheckboxGroupPopover
            columns={columns}
            isInForm={false}
            mode={mode}
            name={field_name}
            options={options}
            setValue={setCurrentValue}
            title={title}
            value={currentValue[field_name]}
          />
        )

      default:
        return (
          <InputField
            height={'48px'}
            isInForm={false}
            label={title}
            name={field_name}
            placeholder={'入力してください'}
            sizeLabel="14px"
            value={currentValue[field_name]}
            width={'full'}
            onChange={e => {
              setCurrentValue(prev => ({
                ...prev,
                [field_name]: e.target.value,
              }))
            }}
          />
        )
    }
  }, [currentValue, errors, meta, setCurrentValue, title])
  return <React.Fragment>{handleShowField}</React.Fragment>
}

export default RenderField
