import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { ROUTES } from 'constant'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { companyServices } from 'services'
import {
  CompanyInfoResponse,
  CompanyRankingResponse,
} from 'services/companyServices'

export type InfoCompanyProps = {}

export const useInfoCompany = (props: InfoCompanyProps) => {
  const { id } = useParams()
  const toast = useToast()
  const dispatch = useDispatch()
  const [companyInfo, setCompanyInfo] = useState<{
    dataInfo: CompanyInfoResponse | null
    dataRanking: CompanyRankingResponse
    dataFavorite: boolean | null
  }>({
    dataInfo: null,
    dataRanking: {},
    dataFavorite: null,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false)

  const formatDataRatio = ({
    newValue,
    oldValue,
  }: {
    newValue: number
    oldValue: number
  }) => {
    if (!oldValue && newValue) {
      return 100
    }
    if (oldValue && !newValue) {
      return -100
    }
    if (newValue - oldValue === 0 || (!newValue && !oldValue)) {
      return 0
    }
    return ((newValue - oldValue) / oldValue) * 100
  }

  const getInfoCompany = useCallback(async () => {
    if (!id) {
      return
    }
    try {
      setLoading(true)
      const { data: dataFavorite } =
        await companyServices.geCompanyFavoriteDetail({ id: id })
      const { data } = await companyServices.getCompanyInfo({
        company_id: id,
        mkt_date: moment().format('YYYY-MM-DD'),
      })
      setCompanyInfo(prev => ({
        ...prev,
        dataFavorite: dataFavorite.is_favorite,
        dataInfo: {
          ...data,
          value: Number((data.close_price - data.last_close_price).toFixed(2)),
          valueRatio: Number(
            formatDataRatio({
              newValue: data.close_price,
              oldValue: data.last_close_price,
            }).toFixed(2),
          ),
        },
      }))
      data.company_name &&
        dispatch(
          setBreadcrumb([
            {
              text: data.company_name,
              link: `${ROUTES.COMPANY}/${id}`,
            },
          ]),
        )
      setLoading(false)
    } catch (error) {
      toast({
        status: 'error',
        description:
          'ご指定の会社が見つかりません。会社名を再確認してもう一度お試しください。',
      })
      setLoading(false)
    }
  }, [dispatch, id, toast])

  const getCompanyRanking = useCallback(async () => {
    if (!companyInfo.dataInfo?.company_name) return

    try {
      const { data: dataRanking } = await companyServices.getCompanyRanking({
        company_name: companyInfo.dataInfo?.company_name,
      })
      setCompanyInfo(prev => ({
        ...prev,
        dataRanking: !isEmpty(dataRanking)
          ? {
              ...dataRanking[0],
              value:
                Number(dataRanking[0].score) - Number(dataRanking[0].old_score),
            }
          : {},
      }))
    } catch (error) {}
  }, [companyInfo.dataInfo?.company_name])

  const handleChangeFavorite = async () => {
    if (!id) {
      return
    }
    try {
      if (companyInfo.dataFavorite) {
        await companyServices.removeCompanyFavorite({ company_id: id })
      } else {
        await companyServices.addCompanyFavorite({ company_id: id })
      }
      setCompanyInfo(prev => ({ ...prev, dataFavorite: !prev.dataFavorite }))
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: 'error',
          description: error.response
            ? error.response.data?.message
            : error.message,
        })
      }
    }
  }

  const handleNavigationYahoo = () => {
    if (!companyInfo.dataInfo?.stock_code) return
    window.open(
      `https://finance.yahoo.co.jp/quote/${companyInfo.dataInfo.stock_code}`,
      '_blank',
    )
  }

  useEffect(() => {
    getInfoCompany()
  }, [getInfoCompany])

  useEffect(() => {
    getCompanyRanking()
  }, [getCompanyRanking])

  return {
    ...props,
    companyInfo,
    handleChangeFavorite,
    handleNavigationYahoo,
  }
}
