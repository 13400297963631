/* eslint-disable require-jsdoc */
import { get } from 'lodash'
import { FC, useState, useEffect, ReactNode } from 'react'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './style.css'

type DndProps = {
  component: { [index: string]: ReactNode }
  lg: Layout[]
  type?: 'vertical' | 'horizontal' | null | undefined
  onDrop?: (layout: Layout[], item: Layout, e: Event) => void
  onDrag?: (layout: Layout[]) => void
  onDragStop?: (layout: Layout[]) => void
  isBounded: boolean
  draggableHandle: string
}

const ResponsiveReactGridLayout = WidthProvider(Responsive)

/**
 * Dnd molecules
 * @constructor
 */
export const Dnd: FC<DndProps> = ({
  component,
  lg,
  type = 'horizontal',
  onDrag,
  onDragStop,
  isBounded,
  draggableHandle,
}) => {
  const [compactType] = useState<DndProps['type']>(type)
  const [layouts, setLayouts] = useState<{ [index: string]: Layout[] }>({
    lg,
  })
  const [mounted, setMounted] = useState<boolean>(false)

  const onLayoutChange = () => setLayouts({ lg: layouts.lg })

  const generateDom = (component: DndProps['component']) =>
    layouts.lg.map((item, index) => {
      const getComponent = get(component, item.i)
      return (
        <div key={index} style={{ zIndex: index === 0 ? 2 : 1 }}>
          {getComponent}
        </div>
      )
    })

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    setLayouts({
      lg,
    })
  }, [lg])

  return (
    <div className="mb-4">
      <ResponsiveReactGridLayout
        isDroppable
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        className="layout"
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        compactType={compactType}
        containerPadding={[0, 0]}
        draggableHandle={draggableHandle}
        isBounded={isBounded}
        isResizable={false}
        layouts={layouts}
        margin={[16, 16]}
        measureBeforeMount={false}
        preventCollision={!compactType}
        rowHeight={30}
        useCSSTransforms={mounted}
        onDrag={onDrag}
        onDragStop={onDragStop}
        onLayoutChange={onLayoutChange}
      >
        {generateDom(component)}
      </ResponsiveReactGridLayout>
    </div>
  )
}
