import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import React, { FC } from 'react'

type InputNumberProps = {
  isStep?: boolean
  step?: number
  placeholder?: string
  min?: number
  max?: number
  name: string
  onChange: (e: any) => void
  isInForm?: boolean
  value?: number
}

const InputNumber: FC<InputNumberProps> = ({
  isStep = false,
  step = 0.1,
  placeholder = '',
  min = 0,
  max = 100.0,
  name,
  isInForm = true,
  onChange,
  value = undefined,
}) => {
  const [field, meta] = useField(name)
  const { value: valueFormik } = meta
  return (
    <StyledInputNumber
      height={'48px'}
      max={max}
      min={min}
      precision={1}
      step={step}
      value={value ?? valueFormik}
      width={'117px'}
      onChange={e => onChange(e)}
    >
      <NumberInputField
        {...(isInForm ? field : {})}
        _placeholder={{
          color: 'black.100',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0em',
          opacity: 0.3,
        }}
        height={'48px'}
        placeholder={placeholder}
      />
      {isStep && (
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      )}
    </StyledInputNumber>
  )
}

export default InputNumber

const StyledInputNumber = styled(NumberInput)`
  .chakra-numberinput__field {
    text-align: right;
    padding: 12px 21px;
    background-color: #fefefe;
    box-shadow: 0px 5px 10px -5px #1417181a inset;
    border: 1px solid #e8ecef;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    &:focus-visible {
      border: 2px solid #0084ff;
      box-shadow: none;
    }
  }
`
