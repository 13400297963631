import { errors, regexEmail } from 'constant'
import { regexKana } from 'constant/conmon'
import { yup } from 'libs/yup'

export const initialValuesUser = {
  name: '',
  first_name: '',
  last_name: '',
  furigana_name_first: '',
  furigana_name_last: '',
  email: '',
  user_type: 'advisor',
  department: '',
  position: '',
}

export const MESSAGE_USER = {
  CREATE_USER: 'ユーザー作成が成功されました。',
  UPDATE_USER: 'ユーザー更新が成功されました。',
  DELETE_USER: 'ユーザー削除が成功されました。',
}

export const userRegistrationSchema = yup.object({
  first_name: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('first_name'),
  last_name: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('last_name'),
  furigana_name_first: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('furigana_name_first')
    .matches(regexKana, 'カタカナのみ入力してください。'),
  furigana_name_last: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('furigana_name_last')
    .matches(regexKana, 'カタカナのみ入力してください。'),
  email: yup
    .string()
    .required(errors.required)
    .max(64, '64字数以下で入力してください。')
    .trim()
    .matches(regexEmail, errors.email)
    .label('email'),
  user_type: yup.string().required(errors.required).label('user_type'),
  department: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('department'),
  position: yup
    .string()
    .required(errors.required)
    .max(19, errors.maxCharacters19)
    .trim()
    .label('position'),
})
