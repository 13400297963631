import React, { FC } from 'react'
import { Box, CircularProgress, Flex } from '@chakra-ui/react'

import { SaveAnswerProps } from './SaveAnswer.type'
import { SaveAnswerBox } from './SaveAnswerBox'

/**
 * SaveAnswerContainer component page.
 * @return {JSX.Element} SaveAnswerContainer component.
 */
export const SaveAnswerContainer: FC<SaveAnswerProps> = ({
  isFetching,
  dataSavedAnswer,
  handleDeleteFavouriteAnswer,
  handleAddFavouriteAnswer,
}) => {
  return (
    <Box
      bg={'white.50'}
      borderBottomRadius={4}
      h={'calc(100vh - 217px)'}
      maxW={'calc(100vw - 48px)'}
      w={'full'}
    >
      <Flex
        bg={'gray.600'}
        borderRadius="0 0 4px 4px"
        flexDirection={'column'}
        h={'calc(100% - 24px)'}
        mx={6}
        overflowY={'auto'}
      >
        {isFetching ? (
          <Flex alignItems="center" h={'full'} justifyContent="center" w="full">
            <CircularProgress isIndeterminate color="blue.900" size={10} />
          </Flex>
        ) : (
          <Flex flexDirection={'column'} gap="10px" padding="16px">
            {(dataSavedAnswer || []).map((item, index) => (
              <SaveAnswerBox
                key={index}
                handleAddFavouriteAnswer={() =>
                  handleAddFavouriteAnswer(
                    item.id,
                    item.title,
                    item.ai_conversation_id,
                    item.ai_conversation_history_id,
                  )
                }
                handleDeleteFavouriteAnswer={() =>
                  handleDeleteFavouriteAnswer(item.id)
                }
                index={index}
                item={item}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
