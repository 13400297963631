import React, { FC } from 'react'
import {
  useTheme,
  ModalProps as ModalChakraProps,
  ModalOverlay,
  ModalContent,
  Button,
  ModalBody,
  Modal as ModalChakra,
} from '@chakra-ui/react'
import { CloseWhiteSmallIcon } from './Icons'

type ModalProps = ModalChakraProps & {
  children: React.ReactNode
  maxWidth: string
  isShowIconClose?: boolean
  maxHeight?: string
}

/**
 * Modal component page.
 * @return {JSX.Element} Modal component.
 */
export const Modal: FC<ModalProps> = (props): React.ReactElement => {
  const theme = useTheme()
  return (
    <ModalChakra
      {...props}
      isCentered
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay backdropFilter={'blur(15px)'} bg={'black.500'} />
      <ModalContent
        bg={'white.150'}
        borderRadius={4}
        maxH={props.maxHeight}
        maxW={props.maxWidth}
        w={'full'}
      >
        {props.isShowIconClose && (
          <Button
            backdropFilter={'blur(5px)'}
            bg={theme.colors.gray[850]}
            borderRadius={'full'}
            h={theme.sizes[12.5]}
            position={'absolute'}
            right={theme.space[-6]}
            top={theme.space[-6]}
            w={theme.sizes[12.5]}
            onClick={props.onClose}
          >
            <CloseWhiteSmallIcon />
          </Button>
        )}

        <ModalBody p={0}>{props.children}</ModalBody>
      </ModalContent>
    </ModalChakra>
  )
}
