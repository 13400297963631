import React from 'react'

type TrackIconProps = {
  color: string
}

/**
 * TrackIcon icon
 * @constructor
 */
export const TrackIcon = ({ color }: TrackIconProps) => (
  <svg
    fill={color}
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2308 5.07692H17.5805C16.6564 5.07692 16.6174 4.96 16.3641 4.20102L16.1569 3.57846C15.842 2.63487 14.9631 2 13.9682 2H10.0318C9.03692 2 8.15692 2.63385 7.84308 3.57846L7.6359 4.20102C7.38257 4.96102 7.34359 5.07692 6.41949 5.07692H2.76923C2.34462 5.07692 2 5.42154 2 5.84615C2 6.27077 2.34462 6.61538 2.76923 6.61538H4.10051L4.88718 18.4092C5.03898 20.6913 6.43796 22 8.72514 22H15.2759C17.5621 22 18.961 20.6913 19.1139 18.4092L19.9005 6.61538H21.2308C21.6554 6.61538 22 6.27077 22 5.84615C22 5.42154 21.6554 5.07692 21.2308 5.07692ZM9.30256 4.06462C9.4082 3.74974 9.70051 3.53846 10.0318 3.53846H13.9682C14.2995 3.53846 14.5928 3.74974 14.6974 4.06462L14.9046 4.68717C14.9497 4.82153 14.9949 4.9518 15.0441 5.07692H8.95385C9.00308 4.95077 9.04924 4.82051 9.09437 4.68717L9.30256 4.06462ZM17.5774 18.3067C17.48 19.7764 16.7477 20.4615 15.2749 20.4615H8.7241C7.25128 20.4615 6.52 19.7774 6.42154 18.3067L5.64205 6.61538H6.41846C6.54667 6.61538 6.65334 6.60206 6.76821 6.59385C6.80308 6.59898 6.83487 6.61538 6.87077 6.61538H17.1272C17.1641 6.61538 17.1949 6.59898 17.2297 6.59385C17.3446 6.60206 17.4513 6.61538 17.5795 6.61538H18.3559L17.5774 18.3067ZM14.8205 10.9744V16.1026C14.8205 16.5272 14.4759 16.8718 14.0513 16.8718C13.6267 16.8718 13.2821 16.5272 13.2821 16.1026V10.9744C13.2821 10.5497 13.6267 10.2051 14.0513 10.2051C14.4759 10.2051 14.8205 10.5497 14.8205 10.9744ZM10.7179 10.9744V16.1026C10.7179 16.5272 10.3733 16.8718 9.94872 16.8718C9.5241 16.8718 9.17949 16.5272 9.17949 16.1026V10.9744C9.17949 10.5497 9.5241 10.2051 9.94872 10.2051C10.3733 10.2051 10.7179 10.5497 10.7179 10.9744Z"
      fill={color}
    />
  </svg>
)
