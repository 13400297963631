import { axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import { GetAiSettingResponse } from './settingServices.type'

/**
 * @returns function that handle askAI services
 */
export class SettingServices {
  /**
   * @returns function that get company detail
   */
  async getSetting(): Promise<BasicResponse<GetAiSettingResponse>> {
    return (await axiosWithToken.get(`/v1/admin/ai_condition_settings/`)).data
  }

  /**
   * @returns function that create company
   */
  async changeSetting(
    id: number,
    body: { constraints: string; action: string },
  ) {
    return await axiosWithToken.put(
      `/v1/admin/ai_condition_settings/${id}`,
      body,
    )
  }
}
