import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { AxiosError } from 'axios'
import {
  askAIServices,
  chatAIServices,
  newServices,
  storageServices,
} from 'services'
import {
  convertString,
  convertTargetArticle,
} from 'components/fromAdvisor/templates/chatAI/tabs/ChatWithAI/components/MainChat/MainChat.utils'
import { NewsType } from 'services/newServices'
import { Option } from 'types/conmon'
import Handlebars from 'handlebars'
import { useLocation } from 'react-router-dom'
import { VariableNewsPromptType } from 'services/chatAIServices'

export type AIAdviceProps = {
  newsId?: string
}

export const useAIAdvice = (props: AIAdviceProps) => {
  const { newsId } = props
  const toast = useToast()
  const location = useLocation()
  const accessToken = storageServices.getAccessToken()

  const [expanded, setExpanded] = useState(false)
  const [askAIOptions, setAskAIOptions] = useState<Option[]>([])
  const [AIAnswer, setAIAnswer] = useState<{
    data: string
    loading: boolean
    done: boolean
  }>({
    data: '',
    loading: false,
    done: true,
  })
  const [conversationId, setConversationId] = useState<number | null>(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [renderPrompt, setRenderPrompt] = useState('')
  const [newsDetail, setNewsDetail] = useState<NewsType>()
  const [variablePrompt, setVariablePrompt] = useState<VariableNewsPromptType>()

  const fetchDataNewDetail = useCallback(async () => {
    try {
      if (newsId) {
        const { data } = await newServices.getNewDetail({
          id: newsId,
          list_news_ai_id: location?.state?.newsAiId,
        })
        setNewsDetail(data)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [location?.state?.newsAiId, newsId])

  useEffect(() => {
    fetchDataNewDetail()
  }, [fetchDataNewDetail])

  /**
   * Show all text
   */
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const formik = useFormik<{ askAI: number | null }>({
    initialValues: {
      askAI: null,
    },
    enableReinitialize: true,
    /**
     *
     */
    onSubmit() {},
  })
  const { values, setFieldValue } = formik

  /**
   * handleCloseModal
   */
  const handleCloseModal = () => {
    setExpanded(false)
  }

  const fetchAskOptionData = useCallback(async () => {
    if (!newsDetail) return
    try {
      const { data } = await askAIServices.getOptionPromptNews({
        is_news_reports: Boolean(newsDetail?.company_report?.file_path),
      })
      const convertOption = data.map(prompt => ({
        label: prompt.title,
        value: prompt.id,
      }))
      setAskAIOptions(convertOption)
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [newsDetail])

  useEffect(() => {
    fetchAskOptionData()
  }, [fetchAskOptionData])

  const fetchContentPrompt = useCallback(async () => {
    try {
      if (!values.askAI || !newsId) return

      setAIAnswer(prev => ({ ...prev, data: '', loading: true, done: false }))

      const { data: dataPrompt } = await chatAIServices.getPromptNewsDetail({
        prompt_id: +values.askAI,
        news_id: +newsId,
      })

      setVariablePrompt(dataPrompt.variables)

      let renderedPrompt = dataPrompt.prompt_content.trim()

      try {
        const template = Handlebars.compile(dataPrompt.prompt_content.trim())
        renderedPrompt = template(dataPrompt.variables)
      } catch (error) {
        console.log('error', error)
      }

      setRenderPrompt(renderedPrompt)
    } catch (error) {
      setAIAnswer(prev => ({ ...prev, data: '', loading: false, done: true }))

      toast({
        status: 'error',
        description: '何らかのエラーが発生しました。もう一度お試しください。',
      })
    }
  }, [newsId, toast, values.askAI])

  useEffect(() => {
    if (!accessToken || !renderPrompt || !newsId) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/chat_with_ai/ws_stream`,
    )

    const handleSocketOpen = () => {
      console.log('WS connected')

      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }

      newSocket.send(JSON.stringify(messAuthen))

      const convertedString = convertString(renderPrompt || '')
      const convertArticle = convertTargetArticle(
        renderPrompt,
        newsDetail?.news_body,
      )
      const renderQuestion = convertArticle ? convertArticle : renderPrompt
      const currentPrompt = askAIOptions.find(
        item => item.value === values.askAI,
      )
      let message = {
        type: 'stream_req',
        data: {
          prompt_html: `<p>${renderQuestion}</p>`,
          prompt_text: renderQuestion,
          prompt_question: convertedString ? convertedString : renderQuestion,
          prompt_type: '',
          current_prompt_id: null,
          news_id: newsId,
          prompt_title: currentPrompt?.label ?? '',
          prompt_id: currentPrompt?.value ?? null,
          variables: variablePrompt,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)

      switch (objMess.type) {
        case 'stream_res':
          setAIAnswer(prev => ({
            ...prev,
            data: (prev.data + objMess?.data).replace(/```html/g, ''),
            loading: false,
          }))
          break

        case 'ai_conversation':
          if (!objMess?.data)
            return toast({
              status: 'error',
              description:
                '何らかのエラーが発生しました。もう一度お試しください。',
            })
          setConversationId(objMess?.data.id)
          setAIAnswer(prev => ({
            ...prev,
            data: prev.data.replace(/```/g, ''),
            done: true,
          }))
          break

        default:
          break
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('close', handleSocketClose)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.close()
    }
  }, [
    accessToken,
    askAIOptions,
    newsDetail?.news_body,
    newsId,
    renderPrompt,
    toast,
    values.askAI,
    variablePrompt,
  ])

  useEffect(() => {
    fetchContentPrompt()
  }, [fetchContentPrompt])

  return {
    ...props,
    formik,
    askAIOptions,
    values,
    setAIAnswer,
    setConversationId,
    setFieldValue,
    AIAnswer,
    toggleExpanded,
    conversationId,
    expanded,
    setExpanded,
    setShowConfirmModal,
    handleCloseModal,
    showConfirmModal,
    setRenderPrompt,
  }
}
