export const boxShadows = {
  base: '0px 10px 20px rgba(1, 1, 103, 0.1)',
  medium: '0px 5px 10px rgba(1, 1, 103, 0.1)',
  large: '0px 7px 14px rgba(0, 0, 0, 0.1)',
  extra: '0px 10px 20px 0px rgba(1, 1, 103, 0.10)',
  normal:
    ' 0px 33px 24px -17px rgba(0, 0, 0, 0.08), 0px 0px 25px 4px rgba(0, 0, 0, 0.03)',
  passwordInput: '0px 5px 10px -5px rgba(20, 23, 24, 0.10) inset',
  insetInputBase: '0px 5px 20px -5px rgba(20, 23, 24, 0.05) inset',
  insetInputMedium: 'inset 0px 5px 5px rgba(0, 0, 0, 0.1)',
  buttonBase: '0px 7.44px 14.4px rgba(0, 0, 0, 0.05)',
}
