export const regexEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

export const regexId = /^[A-Z0-9]{9}$/

export const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/

export const errors = {
  email: '※有効なメールアドレスを入力してください。',
  confirmEmail: '※メールアドレスと一致しません。',
  password: '※有効なパスワードを入力してください。',
  confirmPassword: '確認用のパスワードは一致しません。',
  noOptions: 'データはありません。',
  required: '必須項目に入力してください。',
  furiganaName: 'ひらがなかカタカナで入力してください。',
  advisor: 'アドバイザーIDが一致していません。',
  maxCharacters: '500字数以下で入力してください。',
  maxCharacters19: '19字数以下で入力してください。',
}
