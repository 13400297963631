import { round } from 'lodash'

export const fomatPrice = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const fomatPhone = (value: string) => {
  return value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
}

export const fomatPriceToFixed = (value: number) => {
  const fomatValue = value / 1000
  if (fomatValue % 1 === 0) {
    return fomatValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return fomatValue
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const fomatPriceJapan = (value: number) => {
  if (!value) {
    return '-'
  }
  const price =
    value / 10000 > 1
      ? round(value / 10000).toFixed(0)
      : (value / 10000).toFixed(2)
  if (Number(price) < 1) {
    return `${price}万円`
  }
  if (Number(price) > 99999999 && Number(price) % 100000000 === 0) {
    return `${String(price).slice(0, -8)}兆`
  }
  if (Number(price) < 99999999 && Number(price) % 10000 === 0) {
    return `${String(price).slice(0, -4)}億`
  }
  if (Number(price) < 9999) {
    return `${price}万円`
  }
  if (Number(price) > 99999999) {
    return `${String(price).slice(0, -8)}兆${String(price).slice(
      -8,
      -4,
    )}億${String(price).slice(-4)}万円`
  }
  if (Number(price) >= 10000)
    return `${String(price).slice(0, -4)}億${String(price).slice(-4)}万円`
  return
}
