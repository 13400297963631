import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GetProductLogDetailResponse } from 'services/accessLogServices'
import { accessLogServices } from 'services'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from 'components/atoms'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'
import { CompanyForm } from '../Company/components/CompanyForm'

type DataColumnType = {
  product_name: string
  category_name: string
  status: string
}

export const ProductLogDetail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [logDetail, setLogDetail] = useState<{
    data: GetProductLogDetailResponse
    loading: boolean
  }>({ data: {} as GetProductLogDetailResponse, loading: false })

  const columnHelper = createColumnHelper<DataColumnType>()

  const getProductLogDetail = useCallback(async () => {
    if (!id) return
    try {
      setLogDetail(prev => ({ ...prev, loading: true }))
      const data = await accessLogServices.getProductLogDetail(+id)
      dispatch(setBreadcrumb([{ text: data.file_name, link: '' }]))
      setLogDetail(prev => ({ ...prev, data, loading: false }))
    } catch (error) {
      setLogDetail(prev => ({ ...prev, loading: false }))
      console.log('error', error)
    }
  }, [dispatch, id])

  useEffect(() => {
    getProductLogDetail()
  }, [getProductLogDetail])

  const columns = [
    columnHelper.accessor('category_name', {
      id: 'category_name',
      cell: info => {
        return (
          <Text fontSize="sm" fontWeight={'normal'} lineHeight="14px">
            {info.getValue()}
          </Text>
        )
      },
      header: 'カテゴリー',
      size: 100,
      meta: {
        minWidthColumn: '100px',
      },
    }),
    columnHelper.accessor('product_name', {
      id: 'product_name',
      cell: info => {
        return (
          <Text fontSize="sm" fontWeight={'normal'} lineHeight="14px">
            {info.getValue()}
          </Text>
        )
      },
      header: '商品名',
      size: 100,
      meta: {
        minWidthColumn: '100px',
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      cell: info => {
        return (
          <Text fontSize="sm" fontWeight={'normal'} lineHeight="14px">
            {info.getValue()}
          </Text>
        )
      },
      header: 'ステータス',
      size: 100,
      meta: {
        minWidthColumn: '100px',
      },
    }),
  ]

  const convertData: DataColumnType[] = useMemo(() => {
    const detailData = logDetail.data.detail

    const arrSuccess = Array.isArray(detailData?.success)
      ? detailData.success.map(it => ({
          product_name: it.product_name,
          category_name: it.category_name,
          status: 'SUCCESS',
        }))
      : []
    const arrFail = Array.isArray(detailData?.fail)
      ? detailData.fail.map(it => ({
          product_name: it.product_name,
          category_name: it.category_name,
          status: 'FAIL',
        }))
      : []

    return arrSuccess.concat(arrFail)
  }, [logDetail.data.detail])

  if (logDetail.loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <Box
      bgColor="white.50"
      borderRadius={4}
      minHeight="calc(100vh - 88px)"
      padding="24px 32px"
      w="100%"
    >
      <Flex justifyContent="space-between">
        <Text
          fontSize="24px"
          fontWeight="700"
          letterSpacing="-0.48px"
          lineHeight="40px"
          minW="158px"
        >
          {logDetail.data.file_name}
        </Text>
      </Flex>
      <CompanyForm
        fieldform={[
          {
            label: '日付',
            components: (
              <Text fontSize="14px" lineHeight="24px">
                {moment
                  .utc(logDetail.data.created_at)
                  .tz('Asia/Tokyo')
                  .format('YYYY/MM/DD HH:mm:ss')}
              </Text>
            ),
            isBackground: 'white.50',
          },
          {
            label: 'IPアドレス',
            components: (
              <Text fontSize="14px" lineHeight="24px">
                {logDetail.data.IP}
              </Text>
            ),
            isBackground: 'white.50',
          },
          {
            label: 'ステータス',
            components: (
              <Text fontSize="14px" lineHeight="24px">
                {logDetail.data.status}
              </Text>
            ),
            isBackground: 'white.50',
          },
          {
            label: 'エラー',
            components: (
              <Text fontSize="14px" lineHeight="24px">
                {logDetail.data.error_message}
              </Text>
            ),
            isBackground: 'white.50',
          },
        ]}
        marginTop="16px"
      />
      <Box mt={'24px'}>
        <DataTable
          bgColorTable="white.50"
          columns={columns}
          data={convertData}
          isPagination={false}
          isScroll={true}
          isShowAction={false}
          lengthTable={convertData.length}
          loadingData={logDetail.loading}
          maxH="calc(100vh - 381px)"
        />
      </Box>
    </Box>
  )
}
