import { axiosWithToken } from 'libs/configureAxios'
import {
  FavoriteAnswerResponse,
  SaveAnswerRequest,
  SaveAnswerResponse,
  SavedAnswerConverted,
} from './saveBoxServices.type'

/**
 * @returns function that handle save box services
 */
export class SaveBoxServices {
  /**
   * @returns function that handle fetch conversation saved list
   */
  async getSavedConsultations(): Promise<[]> {
    return new Promise(resolve => {
      resolve([])
    })
  }

  /**
   * @returns function that handle fetch answer saved list
   */
  async getSavedAnswerConsultations(): Promise<
    SavedAnswerConverted | undefined
  > {
    return new Promise(resolve => {
      resolve(undefined)
    })
  }

  /**
   * @returns function that handle get favorite answer service
   */
  getListFavoriteAnswer = async (): Promise<FavoriteAnswerResponse> => {
    return (await axiosWithToken.get('/v1/advisor/save_answer_favorite/')).data
  }

  /**
   * @returns function that handle delete favorite
   */
  deleteFavoriteStatus = async (id: number) => {
    return await axiosWithToken.delete(`/v1/advisor/save_answer_favorite/${id}`)
  }

  /**
   * @returns function that handle add favorite
   */
  addFavoriteStatus = async (
    body: SaveAnswerRequest,
  ): Promise<SaveAnswerResponse> => {
    return (
      await axiosWithToken.post(`/v1/advisor/save_answer_favorite/`, body)
    ).data
  }
}
