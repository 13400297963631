import {
  Button as ButtonChakra,
  ButtonProps as ButtonPropsChakra,
  forwardRef,
  useTheme,
} from '@chakra-ui/react'
import React from 'react'

export type ButtonProps = ButtonPropsChakra & {
  text: string
}

/**
 * Button component page.
 * @return {JSX.Element} Button component.
 */
export const Button = forwardRef<ButtonProps, 'button'>(
  (props, ref): React.ReactElement => {
    const theme = useTheme()

    return (
      <ButtonChakra
        ref={ref}
        _focus={{}}
        _focusVisible={{}}
        _hover={{}}
        borderRadius={'xl'}
        borderWidth={theme.space.px}
        fontSize={'md'}
        fontWeight={'semibold'}
        letterSpacing={theme.letterSpacings.tight}
        lineHeight={theme.lineHeights.shorter}
        {...props}
      >
        {props.text}
      </ButtonChakra>
    )
  },
)
