import React, { FC } from 'react'
import {
  Box,
  CircularProgress,
  Flex,
  Grid,
  Text,
  Textarea,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Button } from 'components/fromAdvisor/elements/Button'
import { useSettingRules } from './SettingRules.hooks'

/**
 * SettingRules component page.
 * @return {JSX.Element} SettingRules component.
 */
export const SettingRules: FC = () => {
  const {
    formValue,
    isLoadingConstraints,
    isLoadingOutputConditions,
    onChangeTextArea,
    onSubmitOutputConditions,
    onSubmitConstraints,
    isLoadingFetch,
  } = useSettingRules()

  return (
    <Box
      bg={'white.50'}
      borderRadius={'0 0 20px 20px'}
      h={'calc(100vh - 210px)'}
      overflowY={'auto'}
    >
      {isLoadingFetch ? (
        <Flex alignItems="center" h={'full'} justifyContent="center" w="full">
          <CircularProgress isIndeterminate color="blue.900" size={10} />
        </Flex>
      ) : (
        <Grid
          gap={6}
          gridTemplateColumns={'repeat(auto-fit, minmax(623px, 1fr))'}
          p={6}
          pb={'29px'}
        >
          <ConstrainsWrapper>
            <TextTitle>制約条件</TextTitle>
            <TextCustomer>
              ※言葉遣いなど、会話の特徴を箇条書きで入力しましょう。
            </TextCustomer>
            <TextAreaContent
              id="myTextarea"
              name={'constraints'}
              value={formValue.constraints}
              onChange={e => {
                onChangeTextArea('constraints', e.target.value)
              }}
            />
            <Button
              bg={'blue.900'}
              border={'none'}
              color={'white.50'}
              float={'right'}
              h={12}
              isDisabled={formValue.constraints === formValue.lastConstraints}
              isLoading={isLoadingConstraints}
              mb={7.5}
              mt={4}
              text={'登録する'}
              type={'submit'}
              w={43.5}
              onClick={onSubmitConstraints}
            />
          </ConstrainsWrapper>
          <ConstrainsWrapper>
            <TextTitle>出力条件</TextTitle>
            <TextCustomer>
              ※回答の形式などを箇条書きで入力しましょう。
            </TextCustomer>
            <TextAreaContent
              id="myTextarea"
              name={'outputConditions'}
              value={formValue.outputConditions}
              onChange={e => {
                onChangeTextArea('outputConditions', e.target.value)
              }}
            />
            <Button
              bg={'blue.900'}
              border={'none'}
              color={'white.50'}
              float={'right'}
              h={12}
              isDisabled={
                formValue.outputConditions === formValue.lastOutputConditions
              }
              isLoading={isLoadingOutputConditions}
              mb={7.5}
              mt={4}
              text={'登録する'}
              type={'submit'}
              w={43.5}
              onClick={onSubmitOutputConditions}
            />
          </ConstrainsWrapper>
        </Grid>
      )}
    </Box>
  )
}

const ConstrainsWrapper = styled(Box)`
  border-radius: ${props => props.theme.radii['2xl']};
  background: ${props => props.theme.colors.white[50]};
  height: ${props => props.theme.sizes.full};
  min-height: ${props => props.theme.sizes[119]};
  border: ${props =>
    `${props.theme.space[0.5]} solid ${props.theme.colors.purple[250]}`};
  padding-left: ${props => props.theme.space[8]};
  padding-right: ${props => props.theme.space[8]};
  width: ${props => props.theme.sizes.full};
  padding-top: ${props => props.theme.space[6]};
`

const TextTitle = styled(Text)`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.base};
  color: ${props => props.theme.colors.black[100]};
`

const TextCustomer = styled(Text)`
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.lineHeights.short};
  color: ${props => props.theme.colors.black[100]};
  opacity: 0.3;
  margin-top: ${props => props.theme.space[3]};
  margin-bottom: ${props => props.theme.space[2]};
`

const TextAreaContent = styled(Textarea)`
  resize: none;
  border-radius: ${props => props.theme.radii.xl};
  padding-left: ${props => props.theme.space[6]};
  min-height: ${props => props.theme.sizes[69]};
  padding-right: ${props => props.theme.space[6]};
  padding-top: ${props => props.theme.space[4.5]};
  box-shadow: ${props => props.theme.boxShadows.passwordInput};
  background: ${props => props.theme.colors.white[50]};
  border: ${props =>
    `${props.theme.space.px} solid ${props.theme.colors.purple[250]}`};
  font-size: ${props => props.theme.fontSizes.md};
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: ${props => props.theme.lineHeights.short};
  color: ${props => props.theme.colors.black[100]};
`
