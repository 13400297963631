import { useDisclosure, useToast } from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { productAdminServices } from 'services'
import { ProductResponse } from 'services/productServices'
import { PaginateResponse } from 'types/conmon'

export type ProductListProps = {}

export const useProductList = (props: ProductListProps) => {
  const formik = useFormik({
    initialValues: { product_search: '' },
    /**
     *
     */
    async onSubmit() {},
  })

  const { setFieldValue } = formik
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: openDetele,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()
  const {
    isOpen: openEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure()
  const [selectRecordId, setSelectRecordId] = useState<number[]>([])
  const [productList, setProductList] = useState<{
    loading: boolean
    data: ProductResponse[]
    last_updated?: string
  }>({ loading: false, data: [] })
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const [searchProduct, setSearchProduct] = useState<string>('')

  const fetchDataProductList = useCallback(
    async (page?: number) => {
      try {
        setProductList(prev => ({ ...prev, loading: true }))
        setSelectRecordId([])
        const params = {
          page_size: pagination.page_size,
          page: page || pagination.page,
          sort_by: 'updated_at:desc',
          product_name: searchProduct,
        }
        const res = await productAdminServices.getProductList(params)

        setProductList(prev => ({
          ...prev,
          data: res.data,
          last_updated: res.last_updated,
          loading: false,
        }))
        if (res.paginate) {
          setPagination(res.paginate)
        }
      } catch (error) {
        setProductList(prev => ({ ...prev, loading: false }))
        if (error instanceof AxiosError) {
          console.log('error', error)
        }
      }
    },
    [pagination.page, pagination.page_size, searchProduct],
  )

  /**
   * handle click checkbox all
   */
  const handleDeleteMultiRecord = async () => {
    if (!selectRecordId) return
    try {
      await productAdminServices.removeProduct(selectRecordId)
      setSelectRecordId([])
      fetchDataProductList(1)
      toast({
        status: 'success',
        description: '商品削除が成功されました。',
        position: 'top',
      })
      onCloseDelete()
    } catch (error: any) {
      toast({
        status: 'error',
        description: error.response.data?.message,
        position: 'top',
      })
    }
  }

  useEffect(() => {
    fetchDataProductList()
  }, [fetchDataProductList])

  const columnHelper = createColumnHelper<ProductResponse>()

  /**
   * handle click checkbox
   */
  const handleSelectRecord = (id: number) => {
    const idExist = selectRecordId.findIndex(p => p === id)
    if (idExist === -1) return setSelectRecordId(prev => [...prev, id])
    const newSelectRecordId = [...selectRecordId].filter(p => p !== id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleSelectRecordAll = () => {
    if (selectRecordId.length === productList.data.length && selectRecordId)
      return setSelectRecordId([])
    const newSelectRecordId = productList.data.map(p => p.id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleCancelSelected = () => {
    setSelectRecordId([])
  }

  /**
   *
   */
  const handleUpdateScoreProduct = async (values: { score_target: string }) => {
    try {
      await productAdminServices.updateScoreProduct({
        product_ids: selectRecordId,
        score_target: Number(values.score_target),
      })
      await fetchDataProductList()
      setSelectRecordId([])
      onOpenEmail()
    } catch (error) {}
  }
  return {
    ...props,
    formik,
    setFieldValue,
    setSearchProduct,
    setPagination,
    productList,
    navigate,
    selectRecordId,
    pagination,
    handleCancelSelected,
    onOpenDelete,
    onOpen,
    openDetele,
    onCloseDelete,
    handleDeleteMultiRecord,
    handleUpdateScoreProduct,
    isOpen,
    onClose,
    columnHelper,
    handleSelectRecordAll,
    handleSelectRecord,
    openEmail,
    onCloseEmail,
  }
}
