import React, { FC } from 'react'
import { Avatar, Flex, IconButton, Text, useTheme } from '@chakra-ui/react'
import {
  FavouriteActiveIcon,
  FavouriteInActiveIcon,
} from 'components/fromAdvisor/elements/Icons'
import { TextTooltip } from 'components/fromAdvisor/elements'
import hexToRgba from 'hex-to-rgba'
import { ConsultationContentListProps } from './ConsultationContentList.type'

/**
 * ConsultationContentList component page.
 * @return {JSX.Element} ConsultationContentList component.
 */
export const ConsultationContentList: FC<ConsultationContentListProps> = ({
  consultationList,
  onChangeFavorite,
  onSelectPrompt,
  consultation,
}) => {
  const theme = useTheme()

  return (
    <Flex flex="1" flexDirection="column" maxH="calc(100vh - 426px)" pt={0}>
      {consultationList?.length ? (
        <Text
          color={'black.100'}
          fontSize={'xs'}
          lineHeight={'15.6px'}
          mb={2}
          opacity={0.3}
        >
          タスクを選択してください。
        </Text>
      ) : (
        ''
      )}
      <Flex
        direction="column"
        maxH="calc(100vh - 412px)"
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: theme.space[2],
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.colors.black['150'],
            borderRadius: theme.radii.lg,
          },
        }}
      >
        {consultationList?.map(({ id, title, is_favorite, avatar_url }) => {
          const isSelected = id === consultation?.id
          return (
            <Flex
              key={id}
              _hover={{ cursor: 'pointer' }}
              alignItems="center"
              bg={isSelected ? hexToRgba('#DCEBFA', '0.75') : 'white.50'}
              borderColor={isSelected ? 'blue.900' : 'none'}
              borderRadius="xl"
              borderWidth={isSelected ? '1px' : 'none'}
              gap="4px"
              justifyContent="space-between"
              mb={2}
              pl={4}
              pr={2}
              py={3}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onSelectPrompt(id)
              }}
            >
              <TextTooltip label={title} />

              <Flex gap="4px">
                {avatar_url && (
                  <Avatar bg="teal.500" size="xs" src={avatar_url} />
                )}
                <IconButton
                  _hover={{
                    transform: 'scale(1.2)',
                  }}
                  aria-label="favourite"
                  bg="transparent"
                  h={6}
                  icon={
                    is_favorite ? (
                      <FavouriteActiveIcon />
                    ) : (
                      <FavouriteInActiveIcon fill={theme.colors.blue[900]} />
                    )
                  }
                  minW={6}
                  onClick={e => onChangeFavorite(e, id)}
                />
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}
