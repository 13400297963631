import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
  dashboard: string
  companyDetail: string
}

const initialState: InitialState = {
  dashboard: '',
  companyDetail: '',
}

const searchNewsKeySlice = createSlice({
  name: 'searchNewsKey',
  initialState,
  reducers: {
    saveSearchNewsKey(
      state: InitialState,
      action: PayloadAction<{ key: keyof InitialState; value: string }>,
    ) {
      state[action.payload.key] = action.payload.value
    },
  },
})

export default searchNewsKeySlice

export const { saveSearchNewsKey } = searchNewsKeySlice.actions
