import { Box, Text } from '@chakra-ui/react'
import { Button } from 'components/fromAdvisor/elements'
import React, { FC } from 'react'

type SaveKeyModalProps = {
  isLoadingSaveKey: boolean
  handleSaveConditionFilter: () => void
  setIsShowSaveFilterModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SaveKeyModal: FC<SaveKeyModalProps> = ({
  handleSaveConditionFilter,
  isLoadingSaveKey,
  setIsShowSaveFilterModal,
}) => {
  return (
    <Box bgColor="white.150" borderRadius="20px" padding="40px 32px">
      <Box mb="24px">
        <Text
          color="black.100"
          fontSize="xl"
          fontWeight="bold"
          lineHeight="base"
          textAlign="center"
        >
          この絞込条件を
        </Text>
        <Text
          color="black.100"
          fontSize="xl"
          fontWeight="bold"
          lineHeight="base"
          textAlign="center"
        >
          保存しますか？
        </Text>
      </Box>
      <Box>
        <Button
          bgColor="blue.900"
          border="none"
          borderRadius="12px"
          color="white.50"
          fontSize="md"
          fontWeight="bold"
          height="48px"
          isLoading={isLoadingSaveKey}
          lineHeight="shorter"
          mb="16px"
          padding="12px"
          text="保存する"
          width="100%"
          onClick={handleSaveConditionFilter}
        />
        <Button
          bgColor="white.base"
          border="1px solid"
          borderColor="blue.900"
          borderRadius="12px"
          boxSizing="border-box"
          color="blue.900"
          fontSize="md"
          fontWeight="bold"
          height="48px"
          lineHeight="shorter"
          padding="11px, 24px, 13px, 24px"
          text="キャンセル"
          width="100%"
          onClick={() => setIsShowSaveFilterModal(false)}
        />
      </Box>
    </Box>
  )
}

export default SaveKeyModal
