import React from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const BlinkingIconWrapper = styled.div`
  display: inline-block;
  animation: ${blinkAnimation} 1s infinite;
`

const Icon = styled(Box)`
  height: ${props => props.theme.sizes[5.5]};
  width: ${props => props.theme.sizes[3]};
  background: ${props => props.theme.colors.black[200]};
`

/**
 * BlinkingIcon icon
 * @constructor
 */
export const BlinkingIcon = () => {
  return (
    <BlinkingIconWrapper>
      <Icon />
    </BlinkingIconWrapper>
  )
}
