import { isNull } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { chatAIServices } from 'services'
import { ConsultationConverted } from 'services/chatAIServices'

/**
 * @returns function that handle any function for saved box
 */
export const useSavedConsultations = (favoritePromptTab: string | null) => {
  const [consultations, setConsultations] = useState<ConsultationConverted[]>(
    [],
  )
  const [isLoadingFetchData, setIsLoadingFetchData] = useState<boolean>(false)

  /**
   * @returns function that handle favorite item
   */
  const handleCheckFavouriteContent = async (id: number) => {
    const newState = consultations?.map(consultation =>
      consultation.id === id
        ? { ...consultation, is_favorite: !consultation.is_favorite }
        : consultation,
    )
    setConsultations(newState)

    const findConsultations = consultations?.find(
      consultation => consultation.id === id,
    )
    if (!findConsultations) return
    const body = {
      status: !findConsultations.is_favorite,
    }
    await chatAIServices.toggleFavorite(id, body)
  }

  /**
   * @returns function that handle fetch consultations
   */
  const handleFetchList = useCallback(async () => {
    setIsLoadingFetchData(true)
    if (!isNull(favoritePromptTab) && favoritePromptTab !== '0') return
    try {
      const params = {
        category_id: null,
        sort_by: 'updated_at',
        type_prompt: 'favorite_prompt',
      }
      const { data } = await chatAIServices.getConsultationList(params)
      const favoritePrompts = data.filter(p => p.is_favorite)

      setConsultations(favoritePrompts)
    } finally {
      setIsLoadingFetchData(false)
    }
  }, [favoritePromptTab])

  useEffect(() => {
    handleFetchList()
  }, [handleFetchList])

  return {
    isLoadingFetchData,
    handleCheckFavouriteContent,
    consultations,
  }
}
