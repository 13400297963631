import React, { FC, useEffect, useState } from 'react'
import './index.css'
import 'react-mde/lib/styles/css/react-mde-all.css'
import Markdown from 'markdown-to-jsx'
import MdEditor from 'react-markdown-editor-lite'
import { Button, Flex, Text } from '@chakra-ui/react'
import { CopyIcon } from 'components/atoms/Icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

type ReactMarkdownProps = {
  data: string
  isDone?: boolean
  bgColor?: string
  isCopyBtn?: boolean
}

const ReactMarkdown: FC<ReactMarkdownProps> = ({
  data,
  isDone,
  bgColor = '#F3F5F7',
  isCopyBtn = false,
}) => {
  const [textBtn, setTextBtn] = useState('コピーする')

  const handleCopy = () => {
    setTextBtn('コピー済み')
  }

  useEffect(() => {
    if (textBtn !== 'コピーする') {
      setTimeout(() => {
        setTextBtn('コピーする')
      }, 2000)
    }
  }, [textBtn])

  return (
    <div className="container">
      <MdEditor
        renderHTML={text => Promise.resolve(<Markdown>{text}</Markdown>)}
        style={{ border: 'none', backgroundColor: bgColor, maxWidth: '100%' }}
        value={!isDone ? data + '<span class="blink"></span>' : data}
        view={{ menu: false, md: false, html: true }}
        onChange={() => {}}
      />
      {isCopyBtn && isDone && (
        <Flex justifyContent={'flex-end'}>
          <CopyToClipboard
            text={data
              .replace('<span class="blink"></span>', '')
              ?.replace(/!\[.*?\]\(.*?\)/g, '')
              .replace(/\[.*?\]\(.*?\)/g, '')
              .replace(/[#>*_~`]/g, '')}
            onCopy={handleCopy}
          >
            <Button
              _hover={{ background: '#0084FF1A' }}
              background="#0084FF1A"
              border="1px solid #0084FF"
              borderRadius={'6px'}
              h={'28px'}
              justifyContent={'center'}
              leftIcon={<CopyIcon />}
              w={'113px'}
            >
              <Text
                color={'blue.900'}
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}
              >
                {textBtn}
              </Text>
            </Button>
          </CopyToClipboard>
        </Flex>
      )}
    </div>
  )
}

export default ReactMarkdown
