import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * MoreVertical icon
 * @param props
 * @constructor
 */
export const MoreVertical: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M14 5a2 2 0 11-4 0 2 2 0 014 0zm0 7a2 2 0 11-4 0 2 2 0 014 0zm-2 9a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
          fillOpacity="currentOpacity"
          fillRule="evenodd"
        ></path>
      </svg>
    </Icon>
  )
}
