import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * CopyIcon icon
 * @param props
 * @constructor
 */
export const CopyIcon: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="21"
        viewBox="0 0 12 14"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.57143 0.142578H8.89554C9.23571 0.142578 9.5625 0.279185 9.80357 0.520257L11.6223 2.33901C11.8634 2.58008 12 2.90686 12 3.24704V9.14258C12 9.8524 11.4241 10.4283 10.7143 10.4283H5.57143C4.86161 10.4283 4.28571 9.8524 4.28571 9.14258V1.42829C4.28571 0.718471 4.86161 0.142578 5.57143 0.142578ZM1.28571 3.57115H3.42857V5.28544H1.71429V12.1426H6.85714V11.2854H8.57143V12.5711C8.57143 13.281 7.99554 13.8569 7.28571 13.8569H1.28571C0.575893 13.8569 0 13.281 0 12.5711V4.85686C0 4.14704 0.575893 3.57115 1.28571 3.57115Z"
          fill="#0084FF"
        />
      </svg>
    </Icon>
  )
}
