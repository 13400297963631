/**
 * @returns function that handle convert string to HTML
 */
export const convertStringToHTML = (input: string) => {
  const lines = input.split('\n').filter(line => line.trim() !== '')

  let html = '<pre>\n'

  lines.forEach(line => {
    if (line.startsWith('＃')) {
      const heading = line.replace('＃', '').trim()
      html += `<span>＃${heading}</span>\n`
    } else if (line.startsWith('・')) {
      const listItem = line.replace('・', '').trim()
      html += `<li>${listItem}</li>\n`
    } else {
      html += `<span>${line}</span>\n`
    }
  })

  html += '</pre>'

  return html
}

/**
 * @returns function that handle convert string to payload
 */
export const convertString = (inputString: string): string => {
  const lines = inputString.trim().split('\n')

  let promptQuestion = ''

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('＃相談内容')) {
      const nextLine = lines[i + 1]
      promptQuestion = nextLine.trim()
      break
    }
  }

  return promptQuestion
}

/**
 * @returns function that handle convert target article to payload
 */
export const convertTargetArticle = (
  inputString: string,
  content: string | undefined,
): string => {
  if (!content) return inputString
  const inputLines = inputString.trim().split('\n')
  const contentLines = content.trim().split('\n')
  const titleIndex = inputLines.findIndex((i: string) =>
    i.startsWith('＃対象記事'),
  )
  if (titleIndex < 0) return inputString
  inputLines.splice(titleIndex + 1)
  const newContent = inputLines.concat(contentLines)
  return newContent.join('\n')
}
