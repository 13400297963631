import {
  Box,
  Text,
  Divider,
  FormLabel,
  Flex,
  CircularProgress,
} from '@chakra-ui/react'
import { TextareaField, TextareaHightlightField } from 'components/atoms'
import { PenLight, QuestionCircle, TrashIcon } from 'components/atoms/Icons'
import { InputField } from 'components/atoms/InputField'
import { SelectField } from 'components/atoms/SelectField'
import { Button } from 'components/fromAdvisor/elements/Button'
import { FormikProvider } from 'formik'
import { FC } from 'react'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant/router'
import moment from 'moment'
import { ModalDeletePrompt } from './components/ModalDeletePrompt'
import { PromptRegistrationProps, usePromptRegistration } from './hook'

import { AddVariableModal } from './components/AddVariableModal'
/**
 * PromptRegistration page
 * @constructor
 */
export const PromptRegistration: FC<PromptRegistrationProps> = props => {
  const {
    state,
    location,
    id,
    formik,
    touched,
    values,
    isDisable,
    errors,
    setFieldValue,
    setFieldTouched,
    optionsCategory,
    MAX_LENGTH,
    handleRemovePrompt,
    handleSubmit,
    isLoading,
    resetForm,
    navigate,
    title,
    openDeletePrompt,
    onOpenDeletePrompt,
    onCloseDeletePrompt,
    openAddField,
    onOpenAddField,
    onCloseAddField,
    size,
    handleAddVariable,
    handleInputFocus,
    isDisableAddVariable,
  } = usePromptRegistration(props)

  if (state.loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <Box
      bgColor="white.50"
      borderRadius="4px"
      minHeight="calc(100vh - 88px)"
      padding="24px 32px"
      w="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        paddingBottom="16px"
      >
        {location.pathname.includes('prompt-registration') && (
          <Text
            fontSize="24px"
            fontWeight="700"
            letterSpacing="-0.48px"
            lineHeight="40px"
            minW="158px"
          >
            {title}
          </Text>
        )}
        {!location.pathname.includes('prompt-registration') &&
          state.promptDetail && (
            <Text
              color="#6C7275"
              fontSize="14px"
              fontWeight="400"
              lineHeight="14px"
              minW="158px"
            >
              登録日：
              {moment(state.promptDetail.created_at).format('YYYY/MM/DD')}
            </Text>
          )}
        {location.pathname.includes('prompt-registration') && (
          <Text
            alignItems="center"
            color="blue.900"
            cursor="pointer"
            display="flex"
            fontSize="14px"
            fontWeight="500"
            letterSpacing="-0.28px"
            lineHeight="24px"
            minW="158px"
            onClick={() =>
              window.open('https://ai-databank.com/tips-features/prompt-tips/')
            }
          >
            <QuestionCircle />
            プロンプト作成のコツを知る
          </Text>
        )}
        {location.pathname.includes('prompt/') && (
          <Button
            backgroundColor="white.50"
            border="1px solid #0084FF"
            borderRadius="12px"
            color="blue.900"
            fontSize="14px"
            fontWeight="bold"
            height="40px"
            leftIcon={<PenLight />}
            text="編集する"
            width="116px"
            onClick={() =>
              navigate(
                `${
                  size === ROLE.ADVISOR ? ROUTES.CHAT_AI : ADMIN_ROUTES.ROOT
                }/prompt-edit/${id}`,
              )
            }
          />
        )}
      </Box>
      <Divider color="#E8ECEF" />
      <Box marginTop="24px">
        <FormikProvider value={formik}>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box>
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  alignItems="center"
                  display="flex"
                  fontSize="18px"
                  fontWeight="700"
                  gap="8px"
                  lineHeight="24px"
                  margin="0 0 8px 0"
                >
                  タイトル
                </FormLabel>
                {touched.title === false && values.title && (
                  <Text color="blue.900">
                    残{255 - Number(values.title.length)}文字
                  </Text>
                )}
              </Box>
              <InputField
                isFocus
                disabled={isDisable}
                error={Boolean(errors.title)}
                errorText={errors.title}
                height="48px"
                maxLength={255}
                name="title"
                placeholder="255文字以内で入力してください"
                width="100%"
                onChange={e => setFieldValue('title', e.target.value)}
                onFocus={() => setFieldTouched('title', false)}
              />
            </Box>
            <SelectField
              error={Boolean(errors.category_id)}
              errorText={errors.category_id}
              height="48px"
              isDisabled={isDisable}
              label="カテゴリー"
              maxWidth="272px"
              name="category_id"
              options={optionsCategory ?? []}
              placeholder="選択してください"
              sizeLabel="18px"
              valueOption={
                optionsCategory &&
                optionsCategory.find(it => it.value === values.category_id)
              }
              onChange={e => setFieldValue('category_id', e?.value)}
            />
          </Box>
          <Box
            backgroundColor={isDisable ? 'white.50' : '#F4F7F9'}
            border={isDisable ? '1px solid #E8ECEF' : ''}
            borderRadius="12px"
            marginY="24px"
            padding="24px"
            w="100%"
          >
            <Text
              fontSize="18px"
              fontWeight="700"
              lineHeight="24px"
              margin="0 0 8px 0"
            >
              プロンプト
            </Text>
            <Box
              display="flex"
              flexDirection="column"
              gap="24px"
              marginTop="24px"
            >
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Flex gap={'8px'}>
                    <FormLabel
                      alignItems="center"
                      color={'gray.800'}
                      display="flex"
                      fontWeight="700"
                      gap="8px"
                      lineHeight="24px"
                      margin="0 0 8px 0"
                    >
                      ###指示文
                      {!isDisable && <QuestionCircle />}
                    </FormLabel>
                    {!isDisableAddVariable && (
                      <Button
                        backgroundColor="blue.900"
                        border="1px solid #0084FF"
                        borderRadius="12px"
                        color="white.50"
                        fontWeight="700"
                        height="24px"
                        isDisabled={isDisableAddVariable}
                        isLoading={isLoading}
                        mb={'8px'}
                        text="変数追加"
                        width="130px"
                        onClick={onOpenAddField}
                      />
                    )}
                  </Flex>

                  {touched.prompt === false && values.prompt && (
                    <Text color="blue.900">
                      残{500 - Number(values.prompt.length)}文字
                    </Text>
                  )}
                </Box>

                {/* <TextareaField
                  resizeField
                  colorLabel="gray.800"
                  disabled={isDisable}
                  error={Boolean(errors.prompt)}
                  errorText={errors.prompt}
                  // handleFocus={handleInputFocus}
                  height="180px"
                  maxLength={MAX_LENGTH}
                  name="prompt"
                  placeholder="500文字以内で入力してください"
                  touched={true}
                  value={values.prompt}
                  width="100%"
                  onChange={e => {
                    if (e.target.value.length <= MAX_LENGTH) {
                      setFieldValue('prompt', e.target.value)
                    }
                  }}
                  onFocus={() => setFieldTouched('prompt', false)}
                  // iconLable={!isDisable}
                  // label="##制約条件"
                /> */}

                <TextareaHightlightField
                  resizeField
                  colorLabel="gray.800"
                  disabled={isDisable}
                  error={Boolean(errors.prompt)}
                  errorText={errors.prompt}
                  handleFocus={handleInputFocus}
                  height="180px"
                  maxLength={MAX_LENGTH}
                  name="prompt"
                  placeholder="500文字以内で入力してください"
                  touched={true}
                  value={values.prompt}
                  width="100%"
                  onChange={e => {
                    if (e.target.value.length <= MAX_LENGTH) {
                      setFieldValue('prompt', e.target.value)
                    }
                  }}
                  onFocus={() => setFieldTouched('prompt', false)}
                  // iconLable={!isDisable}
                  // label="##制約条件"
                />
              </Box>

              <TextareaField
                resizeField
                colorLabel="gray.800"
                disabled={isDisable}
                error={Boolean(errors.constraint_prompt_first)}
                errorText={errors.constraint_prompt_first}
                height="180px"
                iconLable={!isDisable}
                label="##制約条件"
                maxLength={MAX_LENGTH}
                name="constraint_prompt_first"
                placeholder="500文字以内で入力してください（任意）"
                touched={touched.constraint_prompt_first}
                value={values.constraint_prompt_first}
                width="100%"
                onChange={e => {
                  if (e.target.value.length <= MAX_LENGTH) {
                    setFieldValue('constraint_prompt_first', e.target.value)
                  }
                }}
                onFocus={() =>
                  setFieldTouched('constraint_prompt_first', false)
                }
              />
              <TextareaField
                resizeField
                colorLabel="gray.800"
                disabled={isDisable}
                error={Boolean(errors.constraint_prompt_second)}
                errorText={errors.constraint_prompt_second}
                height="180px"
                iconLable={!isDisable}
                label="##出力形式"
                maxLength={MAX_LENGTH}
                name="constraint_prompt_second"
                placeholder="500文字以内で入力してください（任意）"
                touched={touched.constraint_prompt_second}
                value={values.constraint_prompt_second}
                width="100%"
                onChange={e => {
                  if (e.target.value.length <= MAX_LENGTH) {
                    setFieldValue('constraint_prompt_second', e.target.value)
                  }
                }}
                onFocus={() =>
                  setFieldTouched('constraint_prompt_second', false)
                }
              />
            </Box>
          </Box>
          <TextareaField
            resizeField
            disabled={isDisable}
            error={Boolean(errors.note)}
            errorText={errors.note}
            height="180px"
            maxLength={2000}
            name="note"
            placeholder="2000文字以内で入力してください（任意）"
            prefix="Memo"
            subPrefix="(プロンプトには反映されません)"
            touched={touched.note}
            value={values.note}
            width="100%"
            onChange={e => {
              if (e.target.value.length <= 2000) {
                setFieldValue('note', e.target.value)
              }
            }}
            onFocus={() => setFieldTouched('note', false)}
          />
          <Box display="flex" gap="16px" justifyContent="end" marginTop="24px">
            {isDisable ? (
              <Button
                backgroundColor="white.50"
                border="1px solid #6C7275"
                borderRadius="12px"
                color="#6C7275"
                fontSize="14px"
                fontWeight={'bold'}
                height="40px"
                leftIcon={<TrashIcon />}
                text="このプロンプトを削除する"
                width="230px"
                onClick={onOpenDeletePrompt}
              />
            ) : (
              <>
                <Button
                  backgroundColor="white.50"
                  border="1px solid #0084FF"
                  borderRadius="12px"
                  color="blue.900"
                  fontWeight="700"
                  height="48px"
                  text="キャンセル"
                  width="127px"
                  onClick={() => {
                    resetForm()
                    navigate(-1)
                  }}
                />
                <Button
                  backgroundColor="blue.900"
                  border="1px solid #0084FF"
                  borderRadius="12px"
                  color="white.50"
                  fontWeight="700"
                  height="48px"
                  isLoading={isLoading}
                  text="登録する"
                  width="206px"
                  onClick={() => {
                    handleSubmit()
                  }}
                />
              </>
            )}
          </Box>
        </FormikProvider>
        <ModalDeletePrompt
          handleRemovePrompt={handleRemovePrompt}
          isLoading={isLoading}
          isOpen={openDeletePrompt}
          onClose={onCloseDeletePrompt}
        />
        <AddVariableModal
          isOpen={openAddField}
          isTab={values.is_tab}
          onAddVariable={handleAddVariable}
          onClose={onCloseAddField}
        />
      </Box>
    </Box>
  )
}
