import React, { FC } from 'react'
import {
  Text,
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  useTheme,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { OFFSET_INDEX } from 'constant/conmon'
import { useSelector } from 'react-redux'
import { auth } from 'context/Auth/auth.selector'
// import ModalSelectArticle from 'components/fromAdvisor/modules/Modals/ModalSelectArticle'
import { HeaderMainChat } from './HeaderMainChat'
import { ChatBox } from './ChatBox'
import { MainChatProps } from './MainChat.types'
import { QuestionBox } from './QuestionBox'
import { AnswerBox } from './AnswerBox'
import { EditAnswerBox } from './EditAswerBox'
import { AskMoreBox } from './AskMoreBox'
import { AnswerBoxTyping } from './AnswerBoxTyping'

/**
 * MainChatContainer component page.
 * @return {JSX.Element} MainChatContainer component.
 */
export const MainChatContainer: FC<MainChatProps> = ({
  question,
  onEnter,
  onSubmit,
  isEditAnswer,
  onClickIconEditAnswer,
  onChangeInputEdit,
  onCancelEdit,
  isLoadingEdit,
  onEditAnswer,
  isLoadingSendMessage,
  isLoadingFetchDetail,
  onRatingAnswer,
  onAskMore,
  isAskMore,
  conversation,
  isLoadingSendAskMore,
  onSelectHistory,
  answerEdit,
  onNewChat,
  onSubmitAskMore,
  onChangeInputAskMore,
  onEnterAskMore,
  questionAskMore,
  messageTyping,
  // isShowModalSelectArticle,
  // setIsShowModalSelectArticle,
  setQuestion,
  // oldQuestion,
  // setQuestionAskMore,
  handleDeleteFavouriteAnswer,
}) => {
  const theme = useTheme()
  const currentUser = useSelector(auth)
  const avatar = currentUser?.avatar_url

  /**
   * @returns function that handle render loading fetch
   */
  const renderLoadingFetch = () => (
    <Box mt={10} w={'full'}>
      <SkeletonCircle size="10" />
      <SkeletonText
        borderRadius={theme.radii.xl}
        mt={4}
        noOfLines={6}
        skeletonHeight={3}
        spacing={6}
        speed={0.6}
      />
    </Box>
  )
  /**
   * @returns function that handle render loading main
   */
  const renderMain = () => {
    if (conversation?.chatHistories?.length) {
      if (isEditAnswer) {
        return (
          <EditAnswerBox
            answer={answerEdit}
            avatar={avatar}
            isLoadingEdit={isLoadingEdit}
            onCancelEdit={onCancelEdit}
            onChangeInputEdit={onChangeInputEdit}
            onEditAnswer={onEditAnswer}
          />
        )
      } else if (isLoadingFetchDetail) {
        return renderLoadingFetch()
      } else {
        return (
          <>
            {conversation?.chatHistories?.map((history, index) => {
              const isLast =
                index ===
                (conversation.chatHistories &&
                  conversation?.chatHistories?.length - OFFSET_INDEX)
              return (
                <Flex
                  key={history.id}
                  flexDirection="column"
                  gap={10}
                  mb={8}
                  w={'full'}
                >
                  <QuestionBox
                    avatar={avatar}
                    questionHistory={history.questionHtml}
                  />
                  <AnswerBox
                    answerHistory={history.answerData}
                    conversation={conversation}
                    conversationId={history.conversationId}
                    favoriteId={history.save_answer_favorite_id}
                    handleDeleteFavouriteAnswer={handleDeleteFavouriteAnswer}
                    historyId={history.id}
                    isAskMore={isAskMore}
                    isFavorite={history.is_favorite}
                    isLast={isLast}
                    isLoadingSendAskMore={isLoadingSendAskMore}
                    isOverMaxTimesChat={conversation.isOverMaxTimesChat}
                    rating={history.rating}
                    onAskMore={onAskMore}
                    onClickIconEditAnswer={onClickIconEditAnswer}
                    onRatingAnswer={onRatingAnswer}
                    onSelectHistory={onSelectHistory}
                  />
                </Flex>
              )
            })}
            {conversation.isOverMaxTimesChat && (
              <Text
                as={'span'}
                color={'black.100'}
                fontSize={'md'}
                fontWeight={'normal'}
                lineHeight={'short'}
                mb={20}
              >
                これ以降の返信は保存できないため、
                <Text
                  as={'span'}
                  color={'blue.900'}
                  cursor={'pointer'}
                  onClick={onNewChat}
                >
                  NewChat
                </Text>
                で新たに会話を始めてください。
              </Text>
            )}
            {isLoadingSendAskMore && (
              <Flex flexDirection={'column'} gap={10} mb={10} w={'full'}>
                <QuestionBox
                  avatar={avatar}
                  questionHistory={questionAskMore}
                />
                <AnswerBoxTyping
                  isLoadingSendMessage={isLoadingSendAskMore}
                  messageTyping={messageTyping}
                />
              </Flex>
            )}
            {isAskMore &&
              !isLoadingSendAskMore &&
              !conversation.isOverMaxTimesChat && (
                <AskMoreBox
                  avatar={avatar}
                  questionAskMore={questionAskMore}
                  onChangeInputAskMore={onChangeInputAskMore}
                  onEnterAskMore={onEnterAskMore}
                  onSubmitAskMore={onSubmitAskMore}
                />
              )}
          </>
        )
      }
    } else if (isLoadingSendMessage) {
      return (
        <Flex flexDirection={'column'} gap={10} mb={10} w={'full'}>
          <QuestionBox avatar={avatar} questionHistory={question} />
          <AnswerBoxTyping
            isLoadingSendMessage={isLoadingSendMessage}
            messageTyping={messageTyping}
          />
        </Flex>
      )
    } else if (isLoadingFetchDetail) {
      return renderLoadingFetch()
    } else {
      return (
        <ChatBox
          avatar={avatar}
          question={question}
          setQuestion={setQuestion}
          onEnter={onEnter}
          onSubmit={onSubmit}
        />
      )
    }
  }

  return (
    <Flex
      alignItems={'center'}
      flexDirection={'column'}
      h={'full'}
      overflowY={'auto'}
      px={10}
      sx={{
        '&::-webkit-scrollbar': {
          width: theme.space[2],
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.colors.black['150'],
          borderRadius: theme.radii.lg,
        },
      }}
      w={'full'}
    >
      <HeaderMainChat isHaveAnswer={!isEmpty(conversation?.chatHistories)} />
      {renderMain()}
      {/* {isShowModalSelectArticle && (
        <ModalSelectArticle
          isAskMore={isAskMore}
          isOpen={isShowModalSelectArticle}
          oldQuestion={oldQuestion}
          question={question}
          setQuestion={setQuestion}
          setQuestionAskMore={setQuestionAskMore}
          onClose={() => {
            setIsShowModalSelectArticle(false)
          }}
          onSubmitAskMore={onSubmitAskMore}
          onSubmitChatAI={onSubmit}
        />
      )} */}
    </Flex>
  )
}
