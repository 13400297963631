import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { ChangeEvent, FC, useState } from 'react'
import { QuestionCircle } from 'components/atoms/Icons'
import { EyeOff, EyeOn } from '../Icons'

export type InputFieldProps = InputProps & {
  name: string
  type?: string
  placeholder?: string
  width?: string
  label?: string
  height?: string
  maxWidth?: string
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  helperText?: string | false | undefined
  error?: boolean | undefined
  disabled?: boolean | undefined
  errorText?: string | false | undefined
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  minWidth?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  isFocus?: boolean
  iconLable?: boolean
  sizeLabel?: string
  fontWeightLabel?: string
  colorLabel?: string
  isRequired?: boolean
  maxLength?: number
  onKeyDown?: (e: KeyboardEvent) => void
  subLabel?: string
  textAlign?: string
  sizeInput?: string
  isInForm?: boolean
  value?: string | number
}

/**
 * InputField component
 * @constructor
 */
export const InputField: FC<InputFieldProps> = ({
  name,
  type = 'text',
  placeholder,
  label,
  width,
  height,
  maxWidth,
  leftIcon,
  rightIcon,
  helperText,
  error,
  errorText,
  onFocus,
  minWidth,
  onChange,
  sizeLabel,
  colorLabel,
  fontWeightLabel,
  maxLength,
  disabled = false,
  isFocus = false,
  iconLable = false,
  isRequired = false,
  onKeyDown,
  subLabel,
  textAlign = 'left',
  sizeInput,
  isInForm = true,
  value,
}) => {
  const [field, meta] = useField(name)
  const { value: valueFormik, touched } = meta
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const isPassword = type === 'password'
  /**
   * handle show password
   */
  const handleClick = () => setShowPassword(!showPassword)
  return (
    <FormControl
      isInvalid={error}
      isRequired={isRequired}
      maxWidth={maxWidth}
      minWidth={minWidth}
      width={width}
    >
      {label && (
        <FormLabel
          alignItems="center"
          color={colorLabel}
          display="flex"
          fontSize={sizeLabel}
          fontWeight={fontWeightLabel ? fontWeightLabel : '700'}
          gap={iconLable ? '8px' : '0px'}
          lineHeight="24px"
          margin="0 0 8px 0"
        >
          {label}
          {subLabel && <Text color={'gray.800'}>{subLabel}</Text>}
          {iconLable && <QuestionCircle />}
        </FormLabel>
      )}
      <InputGroup height={height}>
        <InputLeftElement
          bottom={0}
          left="8px"
          margin="auto"
          pointerEvents="none"
          top={0}
        >
          {leftIcon}
        </InputLeftElement>
        <Input
          {...(isInForm ? field : {})}
          _disabled={{ backgroundColor: '#F3F5F7', color: 'gray.800' }}
          _focus={{
            color: isFocus ? '#0084FF' : '',
            border: '0.5px solid #0084FF !important',
          }}
          _invalid={{
            border: touched ? '1px solid #F75555' : '1px solid #E8ECEF',
          }}
          _placeholder={{ color: 'black.100', opacity: 0.3 }}
          autoComplete="off"
          backgroundColor="#FEFEFE"
          border="1px solid"
          borderColor=" purple.250"
          borderRadius="xl"
          boxShadow="0px 5px 10px -5px rgba(20, 23, 24, 0.10) inset"
          color={error && touched ? '#F75555' : ''}
          disabled={disabled}
          focusBorderColor="blue.900"
          fontSize={sizeInput ? sizeInput : '16px'}
          height={height}
          lineHeight={'24px'}
          maxLength={maxLength}
          maxWidth={maxWidth}
          minWidth={minWidth}
          paddingX="14px"
          pl={leftIcon ? 13 : '12px'}
          placeholder={placeholder}
          pr={rightIcon || isPassword ? 13 : '12px'}
          textAlign={textAlign}
          type={isPassword ? (showPassword ? 'text' : 'password') : type}
          value={value ?? valueFormik}
          width={width}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        <InputRightElement
          bottom={0}
          margin="auto"
          pointerEvents={isPassword ? 'unset' : 'none'}
          pr={2}
          top={0}
        >
          {isPassword ? (
            showPassword ? (
              <IconButton
                _hover={{ bgColor: 'transparent' }}
                aria-label="Hide password"
                bgColor="transparent"
                onClick={handleClick}
              >
                <EyeOff color={touched && error ? 'red.700' : 'blue.900'} />
              </IconButton>
            ) : (
              <IconButton
                _hover={{ bgColor: 'transparent' }}
                aria-label="Show password"
                bgColor="transparent"
                onClick={handleClick}
              >
                <EyeOn color={touched && error ? 'red.700' : 'blue.900'} />
              </IconButton>
            )
          ) : (
            rightIcon
          )}
        </InputRightElement>
      </InputGroup>
      {!(error && touched) ? (
        <FormHelperText mt={0}>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage color={'#F75555'}>{errorText}</FormErrorMessage>
      )}
    </FormControl>
  )
}
