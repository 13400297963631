import React, { FC } from 'react'
import { Box, Flex, Stack, useTheme } from '@chakra-ui/react'
import { Avatar, Button } from 'components/fromAdvisor/elements'
import { EditAnswerBoxProps } from './MainChat.types'
import { Editor } from './Editor'

/**
 * EditAnswerBox component page.
 * @return {JSX.Element} EditAnswerBox component.
 */
export const EditAnswerBox: FC<EditAnswerBoxProps> = ({
  answer = '',
  isLoadingEdit,
  onEditAnswer,
  onCancelEdit,
  onChangeInputEdit,
  avatar,
}) => {
  const theme = useTheme()

  return (
    <Flex
      bg={'white.50'}
      borderColor={'purple.250'}
      borderRadius={'xl'}
      borderWidth={'3px'}
      boxShadow={theme.boxShadows.insetInputBase}
      gap={3.5}
      mb={12}
      p={6}
      w={'full'}
    >
      <Box maxH={15} minW={15} w={15}>
        <Avatar src={avatar} />
      </Box>
      <Box w={'calc(100% - 60px - 14px)'}>
        <Editor
          name={'description'}
          value={answer}
          onChange={onChangeInputEdit}
        />
        <Stack
          direction="row"
          justifyContent={'flex-end'}
          mb={11.5}
          mt={8}
          spacing={4}
          w={'full'}
        >
          <Button
            bg={'white.base'}
            borderColor={'blue.900'}
            color={'blue.900'}
            h={12}
            maxW={32}
            text={'キャンセル'}
            variant={'outline'}
            w={'full'}
            onClick={onCancelEdit}
          />
          <Button
            bg={'blue.900'}
            borderColor={'blue.900'}
            color={'white.50'}
            h={12}
            isLoading={isLoadingEdit}
            maxW={47.5}
            text={'変更内容を保存する'}
            w={'full'}
            onClick={() => {
              onEditAnswer()
              onCancelEdit()
            }}
          />
        </Stack>
      </Box>
    </Flex>
  )
}
