import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { SelectField } from 'components/atoms/SelectField'
import { InputField } from 'components/atoms/InputField'
import { useFormikContext } from 'formik'
import { icons } from 'constant'
import { optionsOld } from 'utils'
import { CompanyRegistrationType } from 'services/companyServices'
import { get } from 'lodash'
import { CompanyForm } from './CompanyForm'
import { ButtonAddField } from './ButtonAddField'

type SelectFieldProps = {
  handleAddField: (value: string) => void
  optionsPosition: {
    label: string
    value: number | string
  }[]
}
/**
 * PersonnelIntelligence components
 * @constructor
 */
export const PersonnelIntelligence: FC<SelectFieldProps> = ({
  handleAddField,
  optionsPosition,
}) => {
  const { values, setFieldValue, handleChange, errors } =
    useFormikContext<CompanyRegistrationType>()

  /**
   * @returns function that handle next input
   */
  const handleChangeCompanyEmployeeField = (
    value: string | number | undefined,
    name: string,
    index: number,
  ) => {
    const valuesFiled: any = [...values.company_employees]
    valuesFiled[index][name] = value
    setFieldValue('company_employees', valuesFiled)
  }
  return (
    <Box marginTop="32px">
      <Text
        alignItems="center"
        display="flex"
        fontSize="18px"
        fontWeight="700"
        gap="8px"
        lineHeight="24px"
      >
        <Image src={icons.USER} />
        人事情報
      </Text>
      <CompanyForm
        fieldform={[
          {
            label: '役員',
            components: (
              <Flex flexDirection="column" gap="16px" mb={'8px'}>
                {values.company_employees.map((field, index, currentData) => (
                  <Flex
                    key={index}
                    flexDirection="column"
                    gap="8px"
                    paddingLeft="8px"
                  >
                    <SelectField
                      error={Boolean(
                        get(errors, `company_employees.${index}.position_id`),
                      )}
                      errorText={get(
                        errors,
                        `company_employees.${index}.position_id`,
                      )}
                      fontWeightLabel="500"
                      height="48px"
                      label="役職名"
                      name="position_id"
                      options={optionsPosition ?? []}
                      placeholder="選択してください"
                      sizeLabel="14px"
                      valueOption={
                        field.position_id
                          ? optionsPosition.find(
                              it => it.value === field.position_id,
                            )
                          : null
                      }
                      width="327px"
                      onChange={e =>
                        handleChangeCompanyEmployeeField(
                          e?.value,
                          'position_id',
                          index,
                        )
                      }
                    />
                    <Flex gap="16px">
                      <InputField
                        // error={Boolean(
                        //   get(
                        //     errors,
                        //     `company_employees.${index}.first_name_kanji`,
                        //   ),
                        // )}
                        // errorText={get(
                        //   errors,
                        //   `company_employees.${index}.first_name_kanji`,
                        // )}
                        fontWeightLabel="500"
                        height="48px"
                        label="姓"
                        maxWidth="327px"
                        name="first_name_kanji"
                        placeholder="例：未来"
                        sizeLabel="14px"
                        value={field.first_name_kanji}
                        onChange={e =>
                          handleChangeCompanyEmployeeField(
                            e.target.value,
                            'first_name_kanji',
                            index,
                          )
                        }
                      />
                      <InputField
                        // error={Boolean(
                        //   get(
                        //     errors,
                        //     `company_employees.${index}.last_name_kanji`,
                        //   ),
                        // )}
                        // errorText={get(
                        //   errors,
                        //   `company_employees.${index}.last_name_kanji`,
                        // )}
                        fontWeightLabel="500"
                        height="48px"
                        label="名"
                        maxWidth="327px"
                        name="last_name_kanji"
                        placeholder="例：太郎"
                        sizeLabel="14px"
                        value={field.last_name_kanji}
                        onChange={e =>
                          handleChangeCompanyEmployeeField(
                            e.target.value,
                            'last_name_kanji',
                            index,
                          )
                        }
                      />
                    </Flex>
                    <Flex gap="16px">
                      <InputField
                        fontWeightLabel="500"
                        height="48px"
                        label="セイ"
                        maxWidth="327px"
                        name="first_name_kata"
                        placeholder="例：ミライ"
                        sizeLabel="14px"
                        value={field.first_name_kata}
                        onChange={e =>
                          handleChangeCompanyEmployeeField(
                            e.target.value,
                            'first_name_kata',
                            index,
                          )
                        }
                      />
                      <InputField
                        fontWeightLabel="500"
                        height="48px"
                        label="メイ"
                        maxWidth="327px"
                        name="last_name_kata"
                        placeholder="例：タロウ"
                        sizeLabel="14px"
                        value={field.last_name_kata}
                        onChange={e =>
                          handleChangeCompanyEmployeeField(
                            e.target.value,
                            'last_name_kata',
                            index,
                          )
                        }
                      />
                      {currentData.length === index + 1 && (
                        <Flex
                          alignItems="end"
                          flex="1"
                          justifyContent="end"
                          marginTop="8px"
                        >
                          <ButtonAddField
                            onClick={() => handleAddField('company_employees')}
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            ),
          },
          {
            label: '従業員数',
            components: (
              <Flex gap="8px" my="8px" paddingLeft="8px">
                <InputField
                  fontWeightLabel="500"
                  height="48px"
                  label="従業員数"
                  maxWidth="213px"
                  name="number_employees"
                  placeholder="0"
                  sizeLabel="14px"
                  textAlign={'right'}
                  type="number"
                  onChange={handleChange}
                />
                <InputField
                  fontWeightLabel="500"
                  height="48px"
                  label="グループ総数"
                  maxWidth="213px"
                  name="total_groups"
                  placeholder="0"
                  sizeLabel="14px"
                  textAlign={'right'}
                  type="number"
                  onChange={handleChange}
                />
              </Flex>
            ),
          },
          {
            label: '平均年齢',
            components: (
              <Flex
                alignItems="center"
                gap="4px"
                mb="8px"
                paddingLeft="8px"
                paddingTop="8px"
              >
                <SelectField
                  height="48px"
                  name="average_age"
                  options={optionsOld}
                  placeholder="--"
                  valueOption={
                    optionsOld
                      ? optionsOld.find(
                          it => it.value === Number(values.average_age),
                        )
                      : null
                  }
                  width="88px"
                  onChange={e => {
                    setFieldValue('average_age', e?.value)
                  }}
                />
                歳
              </Flex>
            ),
          },
          {
            label: '平均年収',
            components: (
              <Flex
                alignItems="center"
                gap="4px"
                mb="8px"
                paddingLeft="8px"
                paddingTop="8px"
              >
                <InputField
                  height="48px"
                  maxWidth="303px"
                  name="average_annual_income"
                  placeholder="0"
                  sizeLabel="14px"
                  textAlign={'right'}
                  onChange={e => {
                    const value = e.target.value
                    const withoutDot = value.replace(/\./g, '')
                    const onlyNums = withoutDot.replace(/\D/g, '')
                    setFieldValue('average_annual_income', onlyNums)
                  }}
                />
                円
              </Flex>
            ),
          },
        ]}
        marginTop="16px"
        mt={'12px'}
      />
    </Box>
  )
}
