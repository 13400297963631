import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Close icon
 * @param props
 * @constructor
 */
export const Close: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="32"
        viewBox="4 4 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6715 24.1025C26.1055 24.5365 26.1055 25.2401 25.6715 25.6742C25.4552 25.8904 25.1708 26 24.8864 26C24.602 26 24.3176 25.8919 24.1013 25.6742L15.9985 17.5714L7.89571 25.6742C7.67944 25.8904 7.39503 26 7.11062 26C6.8262 26 6.54179 25.8919 6.32552 25.6742C5.89149 25.2401 5.89149 24.5365 6.32552 24.1025L14.4283 15.9998L6.32552 7.89722C5.89149 7.4632 5.89149 6.75953 6.32552 6.32551C6.75955 5.8915 7.46318 5.8915 7.8972 6.32551L16 14.4282L24.1028 6.32551C24.5368 5.8915 25.2405 5.8915 25.6745 6.32551C26.1085 6.75953 26.1085 7.4632 25.6745 7.89722L17.5717 15.9998L25.6715 24.1025Z"
          fill="currentColor"
        ></path>
      </svg>
    </Icon>
  )
}
