import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { chatAIServices } from 'services'
import { Option } from 'types/conmon'
import * as yup from 'yup'
import { ConsultationConverted } from 'services/chatAIServices'
import { configs } from 'configs'

const regexUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

export const validationSchema = yup.object().shape({
  url: yup
    .string()
    .test('url', '適当なURL形式を入力してください', value =>
      regexUrl.test(value ?? ''),
    ),
})

/**
 * @returns function that handle any function for consultation list
 */
export const useConsultationContentList = (
  setQuestion: Dispatch<SetStateAction<string>>,
  setIsAgreeConsult: Dispatch<SetStateAction<boolean>>,
  consultationId: number | null,
  setConsultationId: Dispatch<SetStateAction<number | null>>,
) => {
  const [consultationList, setConsultationList] =
    useState<ConsultationConverted[]>()
  const [consultation, setConsultation] = useState<ConsultationConverted>()
  const [selectedCategory, setSelectedCategory] = useState<Option>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    isOpen: isOpenDrawerRight,
    onOpen: onOpenDrawerRight,
    onClose: onCloseDrawerRight,
  } = useDisclosure({ defaultIsOpen: true })
  const [isShowModalSelectArticle, setIsShowModalSelectArticle] =
    useState(false)
  const [optionCategory, setOptionCategory] = useState<Option[]>([])

  /**
   * @returns function that handle open select category
   */
  const handleOpenSelectCategory = useCallback(async () => {
    try {
      const { data } = await chatAIServices.getOptionCategory()
      const options = data.map(o => ({
        label: o.ja_name,
        value: o.id,
        icon: configs.apiURL + '/' + o.icons,
      }))
      setOptionCategory(options)
    } catch (error) {}
  }, [])

  useEffect(() => {
    handleOpenSelectCategory()
  }, [handleOpenSelectCategory])

  /**
   * @returns function that handle change favorite consultation
   */
  const handleChangeFavorite = async (
    event: MouseEvent<HTMLButtonElement>,
    id: number,
  ) => {
    event.stopPropagation()
    const newState = consultationList?.map(consultation =>
      consultation.id === id
        ? { ...consultation, is_favorite: !consultation.is_favorite }
        : consultation,
    )
    setConsultationList(newState)

    const findConsultations = consultationList?.find(
      consultation => consultation.id === id,
    )
    if (!findConsultations) return
    const body = {
      status: !findConsultations.is_favorite,
    }
    await chatAIServices.toggleFavorite(id, body)
  }

  /**
   * @returns function that handle get consultation
   */
  const handleGetConsultation = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true)
        const params = {
          category_id: id,
          type_prompt: 'all_prompt',
        }
        const { data } = await chatAIServices.getConsultationList(params)
        setConsultationList(data)
      } finally {
        setIsLoading(false)
        setConsultation(undefined)
        setConsultationId(null)
      }
    },
    [setConsultationId],
  )

  /**
   * @returns function that handle select category
   */
  const handleSelectCategory = (option: Option | unknown) => {
    const selectedOption = option as Option
    if (selectedOption.value === selectedCategory?.value) return
    setSelectedCategory(selectedOption)
    handleGetConsultation(Number(selectedOption.value))
  }

  /**
   * @returns function that handle select prompt
   */
  const handleSelectPrompt = (id: number) => {
    const findConsultation = consultationList?.find(item => item.id === id)
    if (!findConsultation) return
    setConsultation(findConsultation)
    setConsultationId(id)
    setIsAgreeConsult(true)
    setQuestion(findConsultation.prompt)
  }

  return {
    isLoading,
    consultation,
    consultationId,
    consultationList,
    selectedCategory,
    isOpenDrawerRight,
    isShowModalSelectArticle,
    optionCategory,
    setConsultation,
    onOpenDrawerRight,
    handleSelectPrompt,
    onCloseDrawerRight,
    handleChangeFavorite,
    handleSelectCategory,
    setIsShowModalSelectArticle,
    setConsultationList,
    setSelectedCategory,
  }
}
