import {
  Box,
  Text,
  Flex,
  Image,
  useToast,
  useDisclosure,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
  Tab,
  List,
  ListItem,
} from '@chakra-ui/react'
import { InputField, SelectField, TextareaField } from 'components/atoms'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { icons, errors as cusErr, ADMIN_ROUTES } from 'constant'
import { FormikProvider, useFormik } from 'formik'
import { CompanyForm } from 'pages/admin/Company/components/CompanyForm'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { productAdminServices } from 'services'
import { importanceOption } from 'utils'
import { Option } from 'types/conmon'
import ProductRegistrationModal from 'components/molecules/ProductRegistrationModal'
import UploadProductPdfModal from 'components/molecules/UploadProductPdfModal'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import { CreateProductRequest, ProductCsv } from 'services/productServices'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { get, isEmpty, omitBy, pick, uniq } from 'lodash'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { theme } from 'theme'
import { initialValuesProduct, productRegistrationSchema } from '../validation'
import { ViewProductCsv } from '../components/ViewProductCsv'

const fieldsCheck = [
  'product_name',
  'product_short_name',
  'description',
  'category_name',
]

type TabsType = {
  id: number
  label?: string
  component?: ReactNode
  isDisabled?: boolean
  isError: boolean
}
/**
 * ProductRegistration Page
 * @constructor
 */
export const ProductRegistration: FC = () => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()
  const [listCategory, setListCategory] = useState<Option[]>()
  const [file, setFile] = useState<{ name: string; isError: boolean }[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fileProduct, setFileProduct] = useState<File | null>(null)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const {
    isOpen: openModalUploadPdf,
    onOpen: onOpenModalUploadPdf,
    onClose: onCloseModalUploadPdf,
  } = useDisclosure()

  const formik = useFormik({
    initialValues: initialValuesProduct,
    validationSchema: productRegistrationSchema,
    /**
     *
     */
    async onSubmit(values: CreateProductRequest) {
      handleCreateProduct(values)
    },
  })

  const {
    setFieldValue,
    values,
    setValues,
    errors,
    dirty,
    isValid,
    resetForm,
    handleSubmit,
  } = formik

  const onDrop = async (event: File[]) => {
    const file = event[0]
    setFileProduct(file)
  }

  const onSubmitCsv = () => {
    resetForm()
    setFile([])
    if (fileProduct)
      Papa.parse(fileProduct, {
        header: true,
        skipEmptyLines: true,
        complete: function ({ data }: any) {
          if (!data.length) {
            return toast({
              status: 'error',
              description:
                'CSVファイルはブランクなので、再度ご確認お願いいたします。',
            })
          }
          const checkFieldInFile = data.every(
            (item: any) =>
              Object.keys(pick(item, fieldsCheck)).length ===
              fieldsCheck.length,
          )

          if (!checkFieldInFile) {
            return toast({
              status: 'error',
              description: 'ファイルの形式再度ご確認お願いいたします。',
            })
          }

          const result = data.filter(
            (item: any) =>
              Object.keys(omitBy(pick(item, fieldsCheck), isEmpty)).length ===
              fieldsCheck.length,
          )

          if (!result.length) {
            return toast({
              status: 'error',
              description: 'ファイルの形式再度ご確認お願いいたします。',
            })
          }
          if (result.length > 10) {
            return toast({
              status: 'error',
              description: '最大１０レコードだけインポートできます。',
            })
          }
          setFile(
            result.map((it: ProductCsv) => ({
              name: it?.product_name,
              isError: false,
            })),
          )
          const convertData = result.map((it: ProductCsv) => ({
            product_name: it?.product_name ?? '',
            product_short_name: it?.product_short_name ?? '',
            description: it?.description ?? '',
            score_target:
              typeof Number(it.score_target) === 'number' &&
              Number(it.score_target) > 0
                ? Number(it.score_target)
                : 0,
            state_score:
              typeof Number(it?.score_target) === 'number' &&
              Number(it.score_target) > 0
                ? true
                : false,
            url: it.url ? it.url : '',
            category_id:
              listCategory &&
              listCategory.find(i => i.label === it?.category_name)?.value,
          }))
          setValues({ products: convertData })
        },
      })
    onCloseModalUploadPdf()
    setFileProduct(null)
  }

  const onDropPdf = async (files: File[]) => {
    setFieldValue(
      'products[0].files',
      (values.products[0].files ?? []).concat(files),
    )
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
  })
  const { getRootProps: getRootPdfProps, getInputProps: getInputPdfProps } =
    useDropzone({ onDrop: onDropPdf })
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const tab = queryParams.get('tab')
  const indexTab = Number(tab) ? Number(tab) : 0

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(
      navigate,
      `${ADMIN_ROUTES.PRODUCT_REGISTRATION}`,
      'tab',
      index,
    )
  }

  const handleCheckDuplicateProductNames = (values: CreateProductRequest) => {
    const names = values.products.map(item => item.product_name)
    const uniqNames = uniq(names)
    return names.length !== uniqNames.length
  }

  /**
   *
   */
  const handleCreateProduct = async (values: CreateProductRequest) => {
    try {
      setLoading(true)
      if (handleCheckDuplicateProductNames(values)) {
        toast({
          status: 'error',
          description: '他のタブで、同じ商品名が既に入力されました。',
        })
      } else {
        const newData = values.products.map(i => ({
          category_id: i.category_id,
          product_name: i.product_name,
          product_short_name: i.product_short_name,
          description: i.description,
          url: i.url,
          product_code: '1000',
          score_target: i.score_target,
        }))
        const formData = new FormData()
        formData.append('products', JSON.stringify(newData))
        for (let index = 0; index < values.products.length; index++) {
          const { files, ...rest } = values.products[index]
          if (files) {
            for (let i = 0; i < files.length; i++) {
              const element = files[i]
              formData.append(rest.product_name, element)
            }
          }
        }

        const { data } = await productAdminServices.createProduct(formData)
        if (isEmpty(data.errors)) {
          resetForm()
          setFile([])
        } else if (!isEmpty(file)) {
          const productsErr = data.errors.map(
            (item: string) => Object.keys(item)[0],
          )
          if (productsErr) {
            const valuesData = values.products.filter(it =>
              productsErr.includes(it.product_name),
            )
            setFile(
              productsErr.map((it: string) => ({ name: it, isError: true })),
            )
            setFieldValue('products', valuesData)
            handleChangeTab(0)
          }
        }
        if (!isEmpty(data.successes)) {
          onOpen()
          setIsSubmit(true)
        } else {
          const errors = []
          for (let index = 0; index < data.errors.length; index++) {
            const element = data.errors[index]

            for (const property in element) {
              errors.push({
                name: property,
                value: element[property].join(' '),
              })
            }
          }

          toast({
            status: 'error',
            description: (
              <Box>
                {errors.map((it: { name: string; value: string }) => (
                  <Text fontSize="14px">
                    {it.name}: {it.value}
                  </Text>
                ))}
              </Box>
            ),
          })
        }
      }

      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast({
        status: 'error',
        description: error.response.data?.message,
      })
    }
  }
  const handleRemoveFile = (key: number) => {
    if (values.products[0].files) {
      const files = [...values.products[0].files]
      files.splice(key, 1)
      setFieldValue('products[0].files', files)
    }
  }

  const TabItems: TabsType[] | undefined =
    file &&
    file.map((it, key) => ({
      id: key + 1,
      label: it.name,
      isError: it.isError,
      component: (
        <ViewProductCsv
          isSubmit={isSubmit}
          keyNumber={key}
          listCategory={listCategory}
          value={values.products[key]}
        />
      ),
    }))

  const fetchListCategory = useCallback(async () => {
    try {
      const { data } = await productAdminServices.getListCategoryProduct()
      const covertListCategory = data.map(c => ({
        value: String(c.id),
        label: c.name,
      }))
      setListCategory(covertListCategory)
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetchListCategory()
  }, [fetchListCategory])

  useEffect(() => {
    if (isEmpty(file)) {
      navigate(ADMIN_ROUTES.PRODUCT_REGISTRATION)
    }
  }, [file, navigate])

  return (
    <FormikProvider value={formik}>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minHeight="calc(100vh - 88px)"
        w="100%"
      >
        <Flex justifyContent="space-between" pt="24px" px=" 32px">
          <Flex alignItems={'center'} gap={'16px'}>
            <Text
              fontSize="24px"
              fontWeight="700"
              letterSpacing="-0.48px"
              lineHeight="40px"
              minW="142px"
            >
              新規商品登録
            </Text>
            <Flex alignItems="center" gap={'4px'}>
              <Text
                color={'red.700'}
                fontSize="14px"
                fontWeight={700}
                lineHeight="14px"
              >
                *
              </Text>
              <Text fontSize="14px" lineHeight="24px">
                マークは入力必須です。
              </Text>
            </Flex>
          </Flex>
          <Button
            bgColor={'white.50'}
            border="1px solid #0084FF"
            borderRadius="12px"
            color="blue.900"
            fontSize={'14px'}
            fontWeight={700}
            height="40px"
            leftIcon={<Image src={icons.IMPORT} />}
            lineHeight={'shorter'}
            text="csv一括登録"
            width="141px"
            onClick={onOpenModalUploadPdf}
          />
        </Flex>
        <Box>
          {!isEmpty(file) ? (
            <>
              <TabsChakra index={indexTab} onChange={handleChangeTab}>
                <TabList borderBottom="none" padding="0 24px">
                  {values.products &&
                    TabItems &&
                    TabItems.map(({ id, label, isDisabled, isError }) => (
                      <Tab
                        key={id}
                        _selected={{
                          bg: '#E8ECEF',
                          color: '#141718',
                          fontWeight: 600,
                          borderTopRightRadius: '24px',
                        }}
                        borderTopLeftRadius={'3xl'}
                        color={'#0084FF'}
                        fontSize={'sm'}
                        fontWeight={'normal'}
                        h="48px"
                        isDisabled={isDisabled}
                        w={34}
                      >
                        {isError && (
                          <Image mr="5px" mt="5px" src={icons.ERROR} w="20px" />
                        )}
                        <TextOverflowTooltip value={label ?? ''} />
                      </Tab>
                    ))}
                </TabList>
                <TabPanels>
                  {values.products &&
                    TabItems &&
                    TabItems.map(({ id, component }) => (
                      <TabPanel key={id} p={0}>
                        {component}
                      </TabPanel>
                    ))}
                </TabPanels>
              </TabsChakra>
            </>
          ) : (
            <Box px=" 32px">
              <CompanyForm
                borderTopRightRadius="none"
                fieldform={[
                  {
                    label: 'カテゴリー',
                    isRequired: true,
                    components: (
                      <SelectField
                        error={Boolean(
                          get(errors, `products.${0}.category_id`),
                        )}
                        errorText={get(errors, `products.${0}.category_id`)}
                        height="48px"
                        name="products[0].category_id"
                        options={listCategory ?? []}
                        placeholder="例：預金"
                        valueOption={(listCategory || []).find(
                          it => it.value === values.products[0].category_id,
                        )}
                        width="327px"
                        onChange={e =>
                          setFieldValue('products[0].category_id', e?.value)
                        }
                      />
                    ),
                  },
                  {
                    label: '商品名',
                    isRequired: true,
                    components: (
                      <InputField
                        isFocus
                        error={Boolean(
                          get(errors, `products.${0}.product_name`),
                        )}
                        errorText={get(errors, `products.${0}.product_name`)}
                        height="48px"
                        maxWidth="327px"
                        name="products[0].product_name"
                        placeholder="例：法人向け定期預金"
                        onChange={e =>
                          setFieldValue(
                            'products[0].product_name',
                            e.target.value,
                          )
                        }
                      />
                    ),
                  },
                  {
                    label: '商品の略称',
                    isRequired: true,
                    components: (
                      <InputField
                        isFocus
                        error={Boolean(
                          get(errors, `products.${0}.product_short_name`),
                        )}
                        errorText={get(
                          errors,
                          `products.${0}.product_short_name`,
                        )}
                        height="48px"
                        maxWidth="327px"
                        name="products[0].product_short_name"
                        placeholder="例：法定"
                        onChange={e =>
                          setFieldValue(
                            'products[0].product_short_name',
                            e.target.value,
                          )
                        }
                      />
                    ),
                  },
                  {
                    label: '概要',
                    isRequired: true,
                    components: (
                      <TextareaField
                        error={Boolean(
                          get(errors, `products.${0}.description`),
                        )}
                        errorText={get(errors, `products.${0}.description`)}
                        height={'128px'}
                        name={'products[0].description'}
                        placeholder="入力してください"
                        resizeField={true}
                        value={values.products[0].description}
                        onChange={e =>
                          setFieldValue(
                            'products[0].description',
                            e.target.value,
                          )
                        }
                      />
                    ),
                  },
                  {
                    label: '参考URL',
                    components: (
                      <InputField
                        isFocus
                        error={Boolean(get(errors, `products.${0}.url`))}
                        errorText={get(errors, `products.${0}.url`)}
                        height="48px"
                        maxWidth="full"
                        name="products[0].url"
                        placeholder="https://sample.co.jp/"
                        onChange={e =>
                          setFieldValue('products[0].url', e.target.value)
                        }
                      />
                    ),
                  },
                  {
                    label: '参考資料',
                    components: (
                      <Flex alignItems="center" gap="10px">
                        <Button
                          {...getRootPdfProps()}
                          backgroundColor="#FEFEFE"
                          border="1px solid #0084FF"
                          borderRadius="12px"
                          color="#0084FF"
                          fontSize={'14px'}
                          height="40px"
                          text="ファイル添付"
                          w="115px"
                        />
                        <input {...getInputPdfProps()} name={'0'} type="file" />
                        <List
                          display="flex"
                          gap="24px"
                          overflowX="auto"
                          sx={{
                            '&::-webkit-scrollbar': {
                              width: theme.space[2],
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: theme.colors.black['150'],
                              borderRadius: theme.radii.lg,
                              border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                            },
                          }}
                          width="100%"
                        >
                          {values.products[0].files?.map((it, index) => (
                            <ListItem
                              key={index}
                              color="blue.900"
                              cursor="pointer"
                              display="flex"
                              fontSize="14px"
                              fontWeight="normal"
                              lineHeight="24px"
                              maxW="200px"
                            >
                              <TextOverflowTooltip
                                color="#0084FF"
                                fontSize="14px"
                                value={it.name}
                                width="100%"
                              />
                              <Text
                                alignItems="center"
                                bgColor="#BDBDBD"
                                color="#000"
                                display="flex"
                                h="20px"
                                justifyContent="center"
                                w="20px"
                                onClick={() => handleRemoveFile(index)}
                              >
                                x
                              </Text>
                            </ListItem>
                          ))}
                        </List>
                      </Flex>
                    ),
                  },
                ]}
                marginTop="16px"
              />
              <CompanyForm
                borderTopRightRadius="none"
                fieldform={[
                  {
                    label: 'スコア対象',
                    isRequired: true,
                    components: (
                      <Flex alignItems={'center'} gap={'8px'}>
                        <Switch
                          isChecked={values.products[0].state_score}
                          islabelinswitch={true}
                          offtext="非対象"
                          offtextswitch="対象"
                          onChange={() =>
                            setFieldValue(
                              'products[0].state_score',
                              !values.products[0].state_score,
                            )
                          }
                        />
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          lineHeight="22px"
                          minW="205px"
                        >
                          ※スコア対象の登録上限は10個です。
                        </Text>
                      </Flex>
                    ),
                    isBackground: 'white.50',
                  },
                  {
                    label: '重要度',
                    isRequired: true,
                    components: (
                      <SelectField
                        error={Boolean(
                          values.products[0].state_score &&
                            !values.products[0].score_target,
                        )}
                        errorText={cusErr.required}
                        height="48px"
                        isDisabled={!values.products[0]?.state_score}
                        menuPlacement="top"
                        name="products[0].score_target"
                        options={importanceOption}
                        placeholder="選択してください"
                        valueOption={
                          values.products[0].score_target
                            ? importanceOption.find(
                                it =>
                                  it.value ===
                                  values?.products[0].score_target?.toString(),
                              )
                            : null
                        }
                        width="327px"
                        onChange={e =>
                          setFieldValue('products[0].score_target', e?.value)
                        }
                      />
                    ),
                  },
                ]}
                marginTop="16px"
              />
            </Box>
          )}
          <Box
            bg={values.products.length > 1 ? '#E8ECEF' : ''}
            borderBottomRadius={values.products.length > 1 ? '12px' : ''}
            display="flex"
            gap="16px"
            height={values.products.length > 1 ? 'calc(100vh - 681px)' : ''}
            justifyContent="end"
            my={values.products.length > 1 ? '' : '24px'}
            px="32px"
          >
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              height="48px"
              text="キャンセル"
              width="127px"
              onClick={() => navigate(ADMIN_ROUTES.PRODUCT_LIST)}
            />
            <Button
              _hover={{ bgColor: 'blue.900' }}
              backgroundColor="#0084FF"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="white.50"
              height="48px"
              isDisabled={!isEmpty(errors) || !dirty || !isValid}
              isLoading={loading}
              text="登録を申請する"
              width="206px"
              onClick={() => {
                handleSubmit()
              }}
            />
          </Box>
        </Box>
      </Box>
      <ProductRegistrationModal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          // navigate(ADMIN_ROUTES.PRODUCT_LIST)
        }}
      />
      {/* <RegistrationLimitModal
        isOpen={openModalLimit}
        onClose={() => {
          onCloseModalLimit()
          // navigate(ADMIN_ROUTES.PRODUCT_LIST)
        }}
      /> */}
      <UploadProductPdfModal
        fileName={fileProduct?.name}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        isOpen={openModalUploadPdf}
        onClose={() => {
          setFileProduct(null)
          onCloseModalUploadPdf()
        }}
        onSubmit={onSubmitCsv}
      />
    </FormikProvider>
  )
}
