import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant/router'
import { chatAIServices, consultationsServices } from 'services'
import { BodyCreatePrompt } from 'services/consultationsServices/consultationsServices.type'
import { pick } from 'lodash'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'
import { errors as constantErr } from 'constant'
import {
  MESSAGE_PROMPT,
  PromptDetail,
  ValuePrompt,
  initialValues,
  promptRegistrationSchema,
} from './validation'

export type PromptRegistrationProps = {
  title?: string
  size: string
}

export const usePromptRegistration = (props: PromptRegistrationProps) => {
  const { size } = props
  const MAX_LENGTH = 500
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const dispatsh = useDispatch()
  const location = useLocation()
  const pathname = location.pathname
  const isDisable = location.pathname.includes('prompt/')

  const isDisableAddVariable = ![
    ADMIN_ROUTES.PROMPT_REGISTRATION_COMPANY,
    ADMIN_ROUTES.PROMPT_REGISTRATION_NEWS,
    `${ADMIN_ROUTES.PROMPT_EDIT}/${id}`,
  ].includes(pathname)

  const [isLoading, setIsLoading] = useState(false)

  const {
    isOpen: openDeletePrompt,
    onOpen: onOpenDeletePrompt,
    onClose: onCloseDeletePrompt,
  } = useDisclosure()

  const {
    isOpen: openAddField,
    onOpen: onOpenAddField,
    onClose: onCloseAddField,
  } = useDisclosure()

  const [state, setState] = useState<{
    loading: boolean
    promptDetail: PromptDetail | null
  }>({
    loading: false,
    promptDetail: null,
  })

  const [optionsCategory, setOptionCategory] = useState<
    {
      label: string
      value: string | number
    }[]
  >()

  const formik = useFormik({
    initialValues: state.promptDetail ? state.promptDetail : initialValues,
    validationSchema: promptRegistrationSchema,
    enableReinitialize: true,

    /**
     *
     */
    async onSubmit(values: ValuePrompt) {
      handleCreatePrompt(values)
    },
  })

  const {
    setFieldValue,
    values,
    errors,
    handleSubmit,
    touched,
    setFieldTouched,
    resetForm,
  } = formik

  const [indexFocusPromptContent, setIndexFocusPromptContent] = useState(
    String(values.prompt.length ?? 0),
  )

  const getValueTab = (type?: boolean) => {
    if (size === ROLE.ADVISOR) {
      return 0
    }

    if (pathname.includes(ADMIN_ROUTES.PROMPT_REGISTRATION_COMPANY)) {
      return type ? 1 : 2
    }

    if (pathname.includes(ADMIN_ROUTES.PROMPT_REGISTRATION_NEWS)) {
      return type ? 2 : 3
    }

    return type ? 0 : 1
  }

  /**
   *
   */
  const handleCreatePrompt = async (values: BodyCreatePrompt) => {
    setIsLoading(true)
    const isTab = getValueTab() ?? ''
    const payload = {
      ...values,
      is_tab: isTab,
    }

    try {
      if (id) {
        await consultationsServices.updatePromptDetail(size, id, values)
        await getPromptDetail(id)
      } else {
        await consultationsServices.createPrompt(size, payload, isTab)
      }

      setIsLoading(false)

      toast({
        status: 'success',
        description: id
          ? MESSAGE_PROMPT.UPDATE_PROMPT
          : MESSAGE_PROMPT.CREATE_PROMPT,
      })

      if (id) {
        navigate(
          `${
            size === ROLE.ADVISOR
              ? ROUTES.PROMPT_DEATAIL
              : ADMIN_ROUTES.PROMPT_DEATAIL
          }/${id}`,
        )
      } else if (size === ROLE.ADMIN) {
        navigateWithQueryParams(
          navigate,
          `${ADMIN_ROUTES.PROMPT_SETTINGS}`,
          'tab',
          getValueTab(true),
        )
      } else {
        navigateWithQueryParams(navigate, `${ROUTES.CHAT_AI}`, 'tab', 2)
      }
    } catch (error: any) {
      setIsLoading(false)
      toast({
        status: 'error',
        description: error.response
          ? error.response.data?.message ?? error.response.data?.detail
          : error.message,
      })
    }
  }
  /**
   *
   */
  const getPromptDetail = useCallback(
    async (id: string) => {
      try {
        setState(prev => ({
          ...prev,
          loading: true,
        }))

        const { data } = await consultationsServices.getPromptDetail(size, id)
        const payload = pick(data.data, [
          'title',
          'prompt',
          'category_id',
          'constraint_prompt_first',
          'constraint_prompt_second',
          'note',
          'created_at',
          'is_tab',
        ])

        dispatsh(setBreadcrumb([{ text: data.data.title, link: '' }]))

        setState(prev => ({
          ...prev,
          loading: false,
          promptDetail: payload,
        }))
      } catch (error: any) {
        setState(prev => ({
          ...prev,
          loading: false,
        }))
        toast({
          status: 'error',
          description: error.response
            ? error.response.data?.message
            : error.message,
        })
      }
    },
    [dispatsh, size, toast],
  )

  /**
   * @returns function that handle remove prompt
   */
  const handleRemovePrompt = useCallback(async () => {
    if (!id) {
      return
    }

    setIsLoading(true)

    try {
      if (size === ROLE.ADVISOR) {
        await consultationsServices.removePromptDetail(id)
      } else {
        await consultationsServices.removePromptAdmin({ ids: [Number(id)] })
      }

      setIsLoading(false)
      onCloseDeletePrompt()
      toast({
        status: 'success',
        description: MESSAGE_PROMPT.DELETE_PROMPT,
      })

      if (size === ROLE.ADVISOR) {
        navigateWithQueryParams(navigate, `${ROUTES.CHAT_AI}`, 'tab', 2)
      } else {
        navigate(ADMIN_ROUTES.PROMPT_SETTINGS)
      }
    } catch (error: any) {
      setIsLoading(false)
      toast({
        status: 'error',
        description: error.response
          ? error.response.data?.message
          : error.message,
      })
    }
  }, [id, navigate, onCloseDeletePrompt, size, toast])

  /**
   * @returns function that handle open select category
   */
  const handleOpenSelectCategory = useCallback(async () => {
    try {
      const { data } = await chatAIServices.getOptionCategory()
      const options = data.map(o => ({
        label: o.ja_name,
        value: o.id,
      }))

      setOptionCategory(options)
    } catch (error) {}
  }, [])

  const handleAddVariable = (e: string) => {
    if (values.prompt.length + e.length > 500) {
      toast({
        status: 'error',
        description: constantErr.maxCharacters,
      })
    } else {
      setFieldValue(
        'prompt',
        values.prompt.slice(0, +indexFocusPromptContent) +
          e +
          values.prompt.slice(+indexFocusPromptContent),
      )
    }
    onCloseAddField()
  }

  const handleInputFocus = (index: string) => {
    setIndexFocusPromptContent(index)
  }

  useEffect(() => {
    handleOpenSelectCategory()
  }, [handleOpenSelectCategory])

  useEffect(() => {
    if (!id) return
    getPromptDetail(id)
  }, [getPromptDetail, id])

  return {
    ...props,
    state,
    location,
    id,
    formik,
    touched,
    values,
    isDisable,
    errors,
    setFieldValue,
    setFieldTouched,
    optionsCategory,
    MAX_LENGTH,
    handleRemovePrompt,
    handleSubmit,
    isLoading,
    resetForm,
    navigate,
    openDeletePrompt,
    onOpenDeletePrompt,
    onCloseDeletePrompt,
    openAddField,
    onOpenAddField,
    onCloseAddField,
    handleAddVariable,
    handleInputFocus,
    isDisableAddVariable,
  }
}
