import { axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import {
  AIAdviceReponse,
  AIAnswerResponse,
  GetAIAdviceCompanyResponse,
  PromptCompanyType,
} from './askAIServices.type'

/**
 * @returns function that handle askAI services
 */
export class AskAIServices {
  /**
   * @returns function that handle getAIAdvice
   */
  async getAIAdvice(page: number): Promise<AIAdviceReponse> {
    return (
      await axiosWithToken.get(`/v1/advisor/news/prompts/`, {
        params: { page, page_size: 5 },
      })
    ).data
  }

  /**
   * @returns function that handle getAnswerAI
   */
  async getAnswerAI(prompt_question: string): Promise<AIAnswerResponse> {
    return (
      await axiosWithToken.post(`/v1/advisor/news/ask_ai`, { prompt_question })
    ).data
  }

  /**
   * @returns function that handle getOptionPromptCompany
   */
  async getOptionPromptCompany(): Promise<BasicResponse<PromptCompanyType[]>> {
    return (await axiosWithToken.get(`/v1/advisor/prompt/company_detail`)).data
  }

  /**
   * @returns function that handle get AI advice company
   */
  async getAIAdviceCompany(): Promise<
    BasicResponse<GetAIAdviceCompanyResponse>
  > {
    return (await axiosWithToken.get(`/v1/advisor/company/ai_recommend/`)).data
  }

  /**
   * @returns function that handle getOptionPromptNews
   */
  async getOptionPromptNews(params: {
    is_news_reports: boolean
  }): Promise<BasicResponse<PromptCompanyType[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/prompt/new_detail`, { params })
    ).data
  }
}
