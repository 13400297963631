import React, { FC, ReactElement, ReactNode } from 'react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
  TabListProps as TabListChakraProps,
  TabIndicator,
} from '@chakra-ui/tabs'
import { Box, useTheme } from '@chakra-ui/react'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { ROUTES } from 'constant/router'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { useLocation, useNavigate } from 'react-router-dom'

export type TabsType = {
  id: number
  label?: string
  component?: ReactNode
  isDisabled?: boolean
}

type TabsProps = TabListChakraProps & {
  tabs: TabsType[]
  notifyElement?: ReactElement
}

const DEFAULT_TAB = 0

/**
 * CustomTabs component page.
 * @return {JSX.Element} CustomTabs component.
 */
export const CustomTabs: FC<TabsProps> = (props): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = useGetQueryParams(location)
  const tab = queryParams.get('tab')
  const indexTab = Number(tab) ? Number(tab) : DEFAULT_TAB
  const theme = useTheme()

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(navigate, `${ROUTES.CHAT_AI}`, 'tab', index)
  }

  return (
    <TabsChakra
      isLazy
      index={indexTab}
      variant="unstyled"
      onChange={handleChangeTab}
    >
      <Box
        bg={'white.50'}
        borderBottomColor={'purple.250'}
        borderBottomWidth={theme.space.px}
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        pl={6}
      >
        <TabList {...props}>
          {props.tabs.map(({ id, label, isDisabled }) => (
            <Tab
              key={id}
              _selected={{
                color: 'black.100',
                bg: 'white.50',
                fontWeight: 'semibold',
              }}
              borderBottom={'none'}
              borderTopRadius={'3xl'}
              color={'blue.900'}
              fontSize={'sm'}
              fontWeight={'normal'}
              h={14}
              isDisabled={isDisabled}
              lineHeight={theme.lineHeights.shorter}
              w={34}
            >
              {label}
            </Tab>
          ))}
        </TabList>
      </Box>
      <TabIndicator bg="blue.900" height={0.5} mt={-0.5} />
      {props.notifyElement}
      <TabPanels>
        {props.tabs.map(({ id, component }) => (
          <TabPanel key={id} p={0}>
            {component}
          </TabPanel>
        ))}
      </TabPanels>
    </TabsChakra>
  )
}
