// import React, { FC, useState } from 'react'
// import { createColumnHelper } from '@tanstack/react-table'
// import { Box, Center, Text, useDisclosure, useToast } from '@chakra-ui/react'
// import {
//   Button,
//   Checkbox,
//   TextOverflowTooltip,
// } from 'components/fromAdvisor/elements'
// import moment from 'moment'
// import { DataTable } from 'components/atoms'
// import { PaginateResponse } from 'types/conmon'
// import { ModalConfirm, RattingImportance } from 'components/molecules'
// import { DeleteIcons } from 'components/atoms/Icons'

// type AnswerType = {
//   id: number
//   title: string
//   title_answer: string
//   date_save: Date
//   rate: number
// }

// /**
//  * SaveBox component page.
//  * @return {JSX.Element} SaveBox component.
//  */
// export const SaveBox: FC = () => {
//   const { isOpen, onClose, onOpen } = useDisclosure()
//   const toast = useToast()
//   const [pagination, setPagination] = useState<PaginateResponse>({
//     page_size: 15,
//     page: 1,
//     page_count: 1,
//     total_count: 0,
//   })
//   const [selectRecordId, setSelectRecordId] = useState<number[]>([])

//   /**
//    * handle click checkbox
//    */
//   const handleSelectRecord = (id: number) => {
//     const idExist = selectRecordId.findIndex(r => r === id)
//     if (idExist === -1) return setSelectRecordId(prev => [...prev, id])
//     const newSelectRecordId = [...selectRecordId].filter(r => r !== id)
//     return setSelectRecordId(newSelectRecordId)
//   }

//   /**
//    * handle click checkbox all
//    */
//   const handleSelectRecordAll = () => {
//     if (selectRecordId.length === dummyData.length) return setSelectRecordId([])
//     const newSelectRecordId = dummyData.map(u => u.id)
//     return setSelectRecordId(newSelectRecordId)
//   }

//   /**
//    * handle click checkbox all
//    */
//   const handleCancelSelected = () => {
//     setSelectRecordId([])
//   }

//   /**
//    * handle click checkbox all
//    */
//   const handleDeleteMultiRecord = async () => {
//     if (!selectRecordId) return
//     try {
//       // await userAdminServices.removeUser(selectRecordId)
//       setSelectRecordId([])
//       // fetchDataUserList(1)
//       toast({
//         status: 'success',
//         description: 'Delete success',
//         position: 'top',
//       })
//       onClose()
//     } catch (error: any) {
//       toast({
//         status: 'error',
//         description: error?.response?.data?.message,
//         position: 'top',
//       })
//     }
//   }

//   /**
//    * @returns function that handle change tab
//    */
//   const columnHelper = createColumnHelper<AnswerType>()
//   const dummyData = [
//     {
//       id: 5,
//       title: 'title5',
//       title_answer: 'title_answer5',
//       date_save: new Date('2023/07/01'),
//       rate: 5,
//     },
//     {
//       id: 5,
//       title: 'title5',
//       title_answer: 'title_answer5',
//       date_save: new Date('2023/07/01'),
//       rate: 5,
//     },
//     {
//       id: 5,
//       title: 'title5',
//       title_answer: 'title_answer5',
//       date_save: new Date('2023/07/01'),
//       rate: 5,
//     },
//     {
//       id: 5,
//       title: 'title5',
//       title_answer: 'title_answer5',
//       date_save: new Date('2023/07/01'),
//       rate: 5,
//     },
//     {
//       id: 5,
//       title: 'title5',
//       title_answer: 'title_answer5',
//       date_save: new Date('2023/07/01'),
//       rate: 5,
//     },
//     {
//       id: 1,
//       title: 'title1',
//       title_answer: 'title_answer1',
//       date_save: new Date('2023/07/01'),
//       rate: 1,
//     },
//     {
//       id: 2,
//       title: 'title2',
//       title_answer: 'title_answer2',
//       date_save: new Date('2023/07/01'),
//       rate: 2,
//     },
//     {
//       id: 3,
//       title: 'title3',
//       title_answer: 'title_answer3',
//       date_save: new Date('2023/07/01'),
//       rate: 3,
//     },
//     {
//       id: 4,
//       title: 'title4',
//       title_answer: 'title_answer4',
//       date_save: new Date('2023/07/01'),
//       rate: 4,
//     },
//     {
//       id: 1,
//       title: 'title1',
//       title_answer: 'title_answer1',
//       date_save: new Date('2023/07/01'),
//       rate: 1,
//     },
//     {
//       id: 2,
//       title: 'title2',
//       title_answer: 'title_answer2',
//       date_save: new Date('2023/07/01'),
//       rate: 2,
//     },
//     {
//       id: 3,
//       title: 'title3',
//       title_answer: 'title_answer3',
//       date_save: new Date('2023/07/01'),
//       rate: 3,
//     },
//     {
//       id: 4,
//       title: 'title4',
//       title_answer: 'title_answer4',
//       date_save: new Date('2023/07/01'),
//       rate: 4,
//     },
//     {
//       id: 5,
//       title: 'title5',
//       title_answer: 'title_answer5',
//       date_save: new Date('2023/07/01'),
//       rate: 5,
//     },
//   ]
//   const columns = [
//     columnHelper.accessor('id', {
//       id: 'id',
//       size: 48,
//       cell: info => {
//         const checked = selectRecordId.find(
//           recordId => recordId === info.row.original.id,
//         )
//         return (
//           <Center>
//             <Checkbox
//               isChecked={checked !== undefined ? true : false}
//               onChange={() => {
//                 handleSelectRecord(info.row.original.id)
//               }}
//             />
//           </Center>
//         )
//       },
//       header: () => {
//         return (
//           <Center>
//             <Checkbox
//               isChecked={
//                 selectRecordId.length &&
//                 selectRecordId.length === dummyData.length
//                   ? true
//                   : false
//               }
//               isDisabled={!dummyData.length}
//               onChange={handleSelectRecordAll}
//             />
//           </Center>
//         )
//       },
//     }),
//     columnHelper.accessor('title', {
//       id: 'title',
//       cell: info => {
//         return (
//           <TextOverflowTooltip
//             fontSize="sm"
//             fontWeight={'normal'}
//             lineHeight="14px"
//             value={info.getValue()}
//             width="428px"
//           />
//         )
//       },
//       header: '保存タイトル',
//       size: 460,
//     }),
//     columnHelper.accessor('title_answer', {
//       id: 'title_answer',
//       cell: info => (
//         <TextOverflowTooltip
//           color="blue.900"
//           fontSize="sm"
//           fontWeight={'bold'}
//           lineHeight="14px"
//           value={info.getValue()}
//           width="464px"
//         />
//       ),
//       header: '保存した回答',
//       size: 496,
//     }),
//     columnHelper.accessor('date_save', {
//       id: 'date_save',
//       cell: info => (
//         <Text fontSize="sm" fontWeight="normal" lineHeight="14px">
//           {moment(info.getValue()).format('YYYY/MM/DD')}
//         </Text>
//       ),
//       header: '保存日',
//       size: 100,
//     }),
//     columnHelper.accessor('rate', {
//       id: 'rate',
//       cell: info => <RattingImportance type="star" value={info.getValue()} />,
//       header: 'この回答への評価',
//       size: 168,
//       meta: {
//         minWidthColumn: '168px',
//       },
//     }),
//   ]
//   return (
//     <Box bgColor={'#FEFEFE'} minH={'calc(100vh - 145px)'}>
//       <Box p={'24px'}>
//         <DataTable
//           columns={columns}
//           data={dummyData}
//           height={
//             selectRecordId.length
//               ? 'calc(100vh - 321px)'
//               : 'calc(100vh - 245px)'
//           }
//           isPagination={true}
//           isScroll={true}
//           isShowAction={false}
//           loadingData={false}
//           maxH={
//             selectRecordId.length
//               ? 'calc(100vh - 321px)'
//               : 'calc(100vh - 245px)'
//           }
//           pagination={pagination}
//           rangePage={[15, 30, 50]}
//           setPagination={setPagination}
//           onCancel={handleCancelSelected}
//           onDelete={onOpen}
//         />
//       </Box>
//       <ModalConfirm
//         isOpen={isOpen}
//         title={`このユーザーは削除され、\n
//               ログインできなくなります。\n
//               よろしいですか？`}
//         onClose={onClose}
//         onSubmit={handleDeleteMultiRecord}
//       />
//       {Boolean(selectRecordId.length) && (
//         <Box
//           backdropFilter="blur(4px)"
//           background="rgba(0, 0, 0, 0.10)"
//           bottom={dummyData.length <= 15 ? '24px' : '0'}
//           display="flex"
//           gap="16px"
//           justifyContent="end"
//           left="0"
//           padding="16px 48px"
//           position={dummyData.length <= 15 ? 'static' : 'sticky'}
//           right="0"
//           w="100%"
//         >
//           <Button
//             backgroundColor="#FEFEFE"
//             border="1px solid #0084FF"
//             borderRadius="12px"
//             color="#0084FF"
//             height="48px"
//             text="キャンセル"
//             width="127px"
//             onClick={handleCancelSelected}
//           />
//           <Button
//             backgroundColor="#0084FF"
//             border="1px solid #0084FF"
//             borderRadius="12px"
//             color="white.50"
//             height="48px"
//             leftIcon={<DeleteIcons />}
//             text="保存ボックスから削除"
//             width="232px"
//             onClick={onOpen}
//           />
//         </Box>
//       )}
//     </Box>
//   )
// }
import React, { FC } from 'react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
} from '@chakra-ui/tabs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant'
import {
  SavedConsultationsContainer,
  useSavedConsultations,
} from './tabs/SavedConsultations'
import { SaveAnswerContainer, useSaveAnswer } from './tabs/SaveAnswer'

const DEFAULT_TAB = 0

const arrSave = [
  { id: 0, title: 'プロンプト' },
  { id: 1, title: 'AI回答' },
]
/**
 * SaveBox component page.
 * @return {JSX.Element} SaveBox component.
 */
export const SaveBox: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const favoritePromptTab = queryParams.get('favoritePromptTab')
  const defaultIndex = favoritePromptTab
    ? Number(favoritePromptTab)
    : DEFAULT_TAB

  const {
    dataSavedAnswer,
    isFetchingSaved,
    handleDeleteFavouriteAnswer,
    handleAddFavouriteAnswer,
  } = useSaveAnswer(favoritePromptTab)
  const { isLoadingFetchData, handleCheckFavouriteContent, consultations } =
    useSavedConsultations(favoritePromptTab)

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(
      navigate,
      `${ROUTES.CHAT_AI}`,
      'favoritePromptTab',
      index,
    )
  }

  return (
    <TabsChakra
      defaultIndex={defaultIndex}
      index={defaultIndex}
      variant="unstyled"
    >
      <TabList bg={'white.50'} pt={6} px={6}>
        {arrSave.map(({ id, title }, index) => (
          <Tab
            key={id}
            _selected={{
              color: 'black.100',
              bg: 'gray.600',
              borderRadius: '4px 4px 0 0',
              fontWeight: 'semibold',
            }}
            color={'blue.900'}
            fontSize={'sm'}
            fontWeight={'normal'}
            gap={2}
            h={12}
            letterSpacing={'tighter'}
            lineHeight={'shorter'}
            minW={20}
            padding="12px 32px"
            onClick={() => handleChangeTab(index)}
          >
            {title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <SavedConsultationsContainer
            consultations={consultations}
            handleCheckFavouriteContent={handleCheckFavouriteContent}
            isLoadingFetchData={isLoadingFetchData}
          />
        </TabPanel>
        <TabPanel p={0}>
          <SaveAnswerContainer
            dataSavedAnswer={dataSavedAnswer}
            handleAddFavouriteAnswer={handleAddFavouriteAnswer}
            handleDeleteFavouriteAnswer={handleDeleteFavouriteAnswer}
            isFetching={isFetchingSaved}
          />
        </TabPanel>
      </TabPanels>
    </TabsChakra>
  )
}
