import { Box, Flex } from '@chakra-ui/react'
import { ChatBotIcon } from 'components/fromAdvisor/elements/Icons'
import React, { FC } from 'react'
import ReactMarkdown from 'components/molecules/ReactMarkdown'
import { AnswerBoxTypingProps } from './MainChat.types'
import './styles.css'

/**
 * AnswerBoxTyping component page.
 * @return {JSX.Element} AnswerBoxTyping component.
 */
export const AnswerBoxTyping: FC<AnswerBoxTypingProps> = ({
  messageTyping = '',
  isLoadingSendMessage,
}) => {
  return (
    <Flex bg={'gray.600'} borderRadius={'xl'} gap={6} w={'full'}>
      <Box minW={15} p={6} w={15}>
        <ChatBotIcon />
      </Box>
      <Box alignItems={'center'} gap={1} w={'calc(100% - 86px)'}>
        <Box
          color={'black.100'}
          fontWeight={'normal'}
          lineHeight={'30px'}
          w={'full'}
        >
          <Box p={6} w={'full'}>
            <ReactMarkdown
              data={messageTyping}
              isCopyBtn={false}
              isDone={!isLoadingSendMessage}
            />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
