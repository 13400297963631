import { useEffect, useRef, useState } from 'react'
import { Layout } from 'react-grid-layout'

const layoutConfig = {
  moved: false,
  static: false,
}

export type DashboardContainerProps = {}

export const useDashboardContainer = (props: DashboardContainerProps) => {
  const observedDiv = useRef<HTMLDivElement>(null)
  const element = window.localStorage.getItem('element') || ''

  const [height, setHeight] = useState<number>(195)
  const [parentLayoutSize, setParentLayoutSize] = useState<Layout[]>(
    element
      ? JSON.parse(element)
      : Array.from(Array(2).keys()).map((_, index) => ({
          w: 12,
          h: index === 0 ? (height + 16) / 46 : (320 + 16) / 46,
          x: 0,
          y: 0,
          i: index.toString(),
          maxH: index === 0 ? (height + 16) / 46 : (320 + 16) / 46,
          ...layoutConfig,
        })),
  )
  const [childLayoutSize] = useState<Layout[]>(
    Array.from(Array(2).keys()).map((_, index) => ({
      w: 6,
      h: (320 + 16) / 46,
      x: 0,
      y: 0,
      i: index.toString(),
      maxH: (320 + 16) / 46,
      ...layoutConfig,
    })),
  )

  useEffect(() => {
    if (!observedDiv?.current) return

    const resizeObserver = new ResizeObserver(() => {
      if (!observedDiv?.current) return

      const innerHeight = observedDiv.current.offsetHeight

      setHeight(innerHeight)
      setParentLayoutSize(
        parentLayoutSize.map((item, index) => ({
          ...item,
          h: index === 0 ? (innerHeight + 16) / 46 : (320 + 16) / 46,
          maxH: index === 0 ? (innerHeight + 16) / 46 : (320 + 16) / 46,
        })),
      )
    })

    resizeObserver.observe(observedDiv.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observedDiv?.current])

  useEffect(() => {
    if (element) {
      const result = JSON.parse(element).map((item: Layout) => ({
        ...item,
        h: item.i === '0' ? (height + 16) / 46 : (320 + 16) / 46,
        maxH: item.i === '0' ? (height + 16) / 46 : (320 + 16) / 46,
      }))

      setParentLayoutSize(result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element])
  return { ...props, observedDiv, childLayoutSize, parentLayoutSize }
}
