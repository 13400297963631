import { Flex, Text, Tooltip } from '@chakra-ui/react'
import React, { FC } from 'react'
// import { MoreVertical } from 'components/atoms/Icons'
import { FormikProvider } from 'formik'
import { SelectField } from 'components/atoms'
import { optionsDate } from 'constant/conmon'
import { TradingRanking } from '../TradingRanking'
import { RankingCorporateProps, useRankingCorporate } from './hook'

/**
 * RankingCorporate molecules
 * @constructor
 */
export const RankingCorporate: FC<RankingCorporateProps> = props => {
  const {
    formik,
    values,
    industryOptions,
    setFieldValue,
    handleScrollBottomProduct,
    productOptions,
    handleScrollBottomContactPerson,
    contactPersonOptions,
    dataRanking,
  } = useRankingCorporate(props)

  return (
    <FormikProvider value={formik}>
      <Flex
        bgColor="white.base"
        borderRadius={4}
        direction="column"
        h="100%"
        padding="12px 24px"
        w="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="4px"
          w="100%"
        >
          <Tooltip
            bgColor="black"
            color="white"
            label={'一定期間内の記事ごとの商品評価を平均した値です'}
            placement="top-start"
          >
            <Text
              className="drag-horizontal"
              color="black.100"
              cursor="pointer"
              display="inline-block"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
            >
              企業スコアランキング
            </Text>
          </Tooltip>
          {/* <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} /> */}
        </Flex>
        <Flex gap="16px" mb="16px">
          <SelectField
            heightListOptions="224px"
            minHeight="40px"
            name="date_range_type"
            options={optionsDate}
            placeholder="対象期間"
            sizePlaceholder="14px"
            valueOption={optionsDate.find(
              it => it.value === values.date_range_type,
            )}
            width="100%"
            onChange={e => setFieldValue('date_range_type', e?.value)}
          />
          <SelectField
            isResetValue
            heightListOptions="224px"
            minHeight="40px"
            name="industry_name"
            options={industryOptions}
            placeholder="自動車"
            sizePlaceholder="14px"
            valueOption={industryOptions.find(
              it => it.value === values.industry_name,
            )}
            width="100%"
            onChange={e => setFieldValue('industry_name', e?.value)}
          />
          <SelectField
            isResetValue
            handleScrollBottom={handleScrollBottomProduct}
            heightListOptions="224px"
            minHeight="40px"
            name="product_name"
            options={productOptions.data}
            placeholder="対象商品"
            sizePlaceholder="14px"
            valueOption={productOptions.data.find(
              it => it.value === values.product_name,
            )}
            width="100%"
            onChange={e => setFieldValue('product_name', e?.value)}
          />
          <SelectField
            isResetValue
            handleScrollBottom={handleScrollBottomContactPerson}
            heightListOptions="224px"
            minHeight="40px"
            name="contact_person_name"
            options={contactPersonOptions.data}
            placeholder="担当者"
            sizePlaceholder="14px"
            valueOption={contactPersonOptions.data.find(
              it => it.value === values.contact_person_name,
            )}
            width="100%"
            onChange={e => setFieldValue('contact_person_name', e?.value)}
          />
        </Flex>
        <TradingRanking
          dataRanking={dataRanking}
          isNavigate={true}
          rowsPerColumn={5}
          separator={true}
          showScoreChange={true}
        />
      </Flex>
    </FormikProvider>
  )
}
