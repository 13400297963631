import React from 'react'

type FavouriteInActiveIconProps = {
  fill: string
}

/**
 * FavouriteInActiveIcon icon
 * @constructor
 */
export const FavouriteInActiveIcon: React.FC<FavouriteInActiveIconProps> = ({
  fill,
}) => (
  <svg
    fill={fill}
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.002 21C11.9042 21 11.8063 20.9798 11.7148 20.9384C11.4129 20.8015 4.30742 17.5217 3.16809 11.6808C2.72769 9.42105 3.16994 7.21644 4.35082 5.78469C5.30641 4.62496 6.68021 4.00832 8.32457 4.00005C8.33288 4.00005 8.34119 4.00005 8.34858 4.00005C10.2247 4.00005 11.3687 5.06332 12.0011 5.9685C12.6363 5.05964 13.7895 3.99178 15.6776 4.00005C17.3228 4.00832 18.6976 4.62496 19.6541 5.78469C20.8332 7.21552 21.2745 9.42011 20.8331 11.6817C19.6957 17.5226 12.5892 20.8034 12.2873 20.9394C12.1977 20.9798 12.0999 21 12.002 21ZM8.34767 5.3776C8.34213 5.3776 8.33755 5.3776 8.33201 5.3776C7.09666 5.38311 6.118 5.81409 5.42185 6.65862C4.4995 7.777 4.16618 9.55613 4.5281 11.418C5.40983 15.9429 10.703 18.8836 12.002 19.5434C13.3011 18.8836 18.5942 15.9429 19.475 11.418C19.8388 9.55521 19.5055 7.77608 18.585 6.65862C17.8889 5.81501 16.9102 5.38493 15.672 5.3785C15.6665 5.3785 15.661 5.3785 15.6564 5.3785C13.4664 5.3785 12.6899 7.5629 12.6585 7.65572C12.5625 7.93416 12.2984 8.12344 12.0029 8.12344C12.0011 8.12344 12.0001 8.12344 11.9992 8.12344C11.7028 8.12252 11.4388 7.93415 11.3446 7.65386C11.3141 7.56197 10.5368 5.3776 8.34767 5.3776Z"
      fill={fill}
    />
  </svg>
)
