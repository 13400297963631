import React from 'react'
import {
  Box,
  Flex,
  IconButton,
  useTheme,
  Tooltip,
  Text,
} from '@chakra-ui/react'
import {
  FavouriteActiveIcon,
  FavouriteInActiveIcon,
  HashTagIcon,
} from 'components/fromAdvisor/elements/Icons'
import { SaveAnswerBoxProps } from './SaveAnswer.type'

/**
 * SaveAnswerBox component page.
 * @return {JSX.Element} SaveAnswerBox component.
 */
export const SaveAnswerBox = (props: SaveAnswerBoxProps) => {
  const { item, index, handleDeleteFavouriteAnswer, handleAddFavouriteAnswer } =
    props
  const theme = useTheme()
  /**
   * @returns function that handle click remove favorite item
   */
  const handleClickRemoveFavourite = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    handleDeleteFavouriteAnswer()
  }

  /**
   * @returns function that handle click add favorite item
   */
  const handleClickAddFavourite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleAddFavouriteAnswer()
  }

  /**
   * @returns function that handle click item
   */
  const handleClick = () => {}

  return (
    <Flex
      key={index}
      _hover={{
        background: 'blue.200',
      }}
      alignItems="center"
      bg={'white.50'}
      borderRadius="xl"
      cursor={'pointer'}
      gap={8}
      h={12}
      justifyContent="space-between"
      padding="12px 16px"
      onClick={handleClick}
    >
      <Flex alignItems="center" gap={1}>
        <Box h={5} minW={5}>
          <HashTagIcon fill={theme.colors.blue[900]} />
        </Box>
        <Tooltip
          bgColor="black"
          color="white"
          label={item.title}
          maxW="calc(100vw - 212px)"
          placement="top-start"
        >
          <Text
            color="blue.900"
            cursor="pointer"
            fontSize="sm"
            fontWeight="normal"
            lineHeight="shorter"
            overflow="hidden"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.title}
          </Text>
        </Tooltip>
      </Flex>
      <Flex alignItems="center" gap={2} justifyContent="space-between">
        <IconButton
          _hover={{
            transform: 'scale(1.2)',
          }}
          aria-label="favourite"
          bg="transparent"
          h={6}
          icon={
            item?.is_favorite ? (
              <FavouriteActiveIcon />
            ) : (
              <FavouriteInActiveIcon fill={theme.colors.blue[900]} />
            )
          }
          minW={6}
          onClick={e => {
            if (item.is_favorite) {
              handleClickRemoveFavourite(e)
            } else {
              handleClickAddFavourite(e)
            }
          }}
        />
      </Flex>
    </Flex>
  )
}
