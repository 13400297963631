import { useDisclosure, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'
import { auth } from 'context/Auth/auth.selector'
import { useDispatch, useSelector } from 'react-redux'
import { userAdminServices } from 'services'
import { useDropzone } from 'react-dropzone'
import { userLogin } from 'context/Auth/auth.slice'
import { MESSAGE_PROFILE, changeProfileSchema } from './validation'

export type MyPageProps = { size: string }

interface TypeValues {
  first_name: string | null
  last_name: string | null
  furigana_name_first: string | null
  furigana_name_last: string | null
  email: string | null
  department: string | null
  position: string | null
  avatar_url: string
}

export interface FormValues {}

export const useMyPage = (props: MyPageProps) => {
  const dispatch = useDispatch()
  const { size } = props
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const currentUser = useSelector(auth)
  const dataUser = {
    first_name: currentUser?.first_name ? currentUser?.first_name : '',
    last_name: currentUser?.last_name ? currentUser?.last_name : '',
    furigana_name_first: currentUser?.furigana_name_first
      ? currentUser?.furigana_name_first
      : '',
    furigana_name_last: currentUser?.furigana_name_last
      ? currentUser?.furigana_name_last
      : '',
    avatar_url: currentUser?.avatar_url ? currentUser?.avatar_url : '',
    email: currentUser?.email ? currentUser?.email : '',
    department: currentUser?.department ? currentUser?.department : '',
    position: currentUser?.position ? currentUser?.position : '',
  }
  const [img, setImg] = useState(currentUser?.avatar_url)

  // const fetchImage = async (imageUrl: string) => {
  //   const res = await fetch(imageUrl)
  //   console.log('res', res)
  //   const imageBlob = await res.blob()
  //   const imageObjectURL = URL.createObjectURL(imageBlob)
  //   console.log('imageObjectURL', imageObjectURL)
  //   setImg(imageObjectURL)
  // }

  /**
   *
   */
  const onDrop = async (file: File[]) => {
    if (!file[0]) {
      return toast({
        status: 'error',
        description: 'JPG、PNGかGIFファイルをアップロードしてください',
      })
    }
    if (file[0].size >= 2000000) {
      return toast({
        status: 'error',
        description: '2MB以下の画像ファイルをアップロードしてください',
      })
    }
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', file[0])
    try {
      const { data } = await userAdminServices.uploadAvatar(size, formData)
      dispatch(
        userLogin({
          user: {
            ...data.data,
            avatar_url: `${data.data?.avatar_url}?random=${Math.random()}`,
          },
        }),
      )
      // await fetchImage(data.data?.avatar_url)
      setImg(`${data.data?.avatar_url}?random=${Math.random()}`)
      setIsLoading(false)
    } catch (error: any) {
      toast({
        status: 'error',
        description: error.response
          ? error.response?.data?.message
          : error.message,
      })
      setIsLoading(false)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
    },
  })

  const {
    isOpen: isOpenModalChangeEmail,
    onOpen: onOpenModalChangeEmail,
    onClose: onCloseModalChangeEmail,
  } = useDisclosure()
  const {
    isOpen: isOpenModalChangePassword,
    onOpen: onOpenModalChangePassword,
    onClose: onCloseModalChangePassword,
  } = useDisclosure()

  const [disabledTop, setDisabledTop] = useState<boolean>(true)
  const [disabledBottom, setDisabledBottom] = useState<boolean>(true)

  /**
   *
   */
  const handleUpdateMyProfile = async (values: any) => {
    setIsLoading(true)
    try {
      const { data } = await userAdminServices.updateMyProfile(size, {
        ...values,
      })
      dispatch(userLogin({ user: data.data }))
      toast({
        status: 'success',
        description: MESSAGE_PROFILE.UPDATE_PROFILE_SUCCESS,
      })
      setIsLoading(false)
      setDisabledBottom(true)
      setDisabledTop(true)
    } catch (error: any) {
      setIsLoading(false)
      toast({
        status: 'error',
        description: error.response
          ? error.response?.data?.message
          : error.message,
      })
    }
  }
  const formik = useFormik({
    initialValues: dataUser,
    validationSchema: changeProfileSchema,
    enableReinitialize: true,
    validateOnChange: false,

    /**
     *
     */
    async onSubmit(values: TypeValues) {
      handleUpdateMyProfile(values)
    },
  })

  const { setFieldValue, resetForm, handleSubmit, errors, handleChange } =
    formik

  return {
    ...props,
    currentUser,
    getRootProps,
    getInputProps,
    formik,
    disabledTop,
    errors,
    setFieldValue,
    disabledBottom,
    resetForm,
    setDisabledTop,
    isLoading,
    handleSubmit,
    handleChange,
    setDisabledBottom,
    onOpenModalChangeEmail,
    onOpenModalChangePassword,
    isOpenModalChangeEmail,
    onCloseModalChangeEmail,
    isOpenModalChangePassword,
    onCloseModalChangePassword,
    img,
  }
}
