import { Box, Flex, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constant'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'

type ModalConfirmAIAdviceProps = {
  isOpen: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
  conversationId: number | null
}

/**
 * ModalConfirmAIAdvice molecules
 * @constructor
 */
const ModalConfirmAIAdvice: FC<ModalConfirmAIAdviceProps> = ({
  isOpen,
  setExpanded,
  setShowConfirmModal,
  conversationId,
}) => {
  const navigate = useNavigate()
  return (
    <Modal
      isOpen={isOpen}
      isShowIconClose={false}
      maxWidth="344px"
      onClose={() => {}}
    >
      <Box
        bg="white.base"
        borderRadius="20px"
        boxSizing="border-box"
        padding="40px 32px"
        w="100%"
      >
        <Flex alignItems="center" direction="column">
          <Text
            color="black100"
            fontSize="xl"
            fontWeight="bold"
            lineHeight="base"
          >
            AIチャットモードへ
          </Text>
          <Text
            color="black100"
            fontSize="xl"
            fontWeight="bold"
            lineHeight="base"
          >
            切り替えます
          </Text>
          <Button
            bgColor="blue.900"
            border="none"
            color="white.50"
            fontSize="md"
            fontWeight="bold"
            h="48px"
            letterSpacing="-0.2px"
            lineHeight="shorter"
            m="24px 0 16px 0"
            text="OK"
            w="100%"
            onClick={() => {
              if (conversationId) {
                navigateWithQueryParams(
                  navigate,
                  `${ROUTES.CHAT_AI}`,
                  'conversationId',
                  conversationId,
                )
              }
            }}
          />
          <Button
            bgColor="white.50"
            borderColor="blue.900"
            borderStyle="solid"
            borderWidth="1px"
            color="blue.900"
            fontSize="md"
            fontWeight="bold"
            h="48px"
            letterSpacing="-0.2px"
            lineHeight="shorter"
            text="キャンセル"
            w="100%"
            onClick={() => {
              setShowConfirmModal(false)
              setExpanded(true)
            }}
          />
        </Flex>
      </Box>
    </Modal>
  )
}

export default ModalConfirmAIAdvice
