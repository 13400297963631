import React from 'react'
import { Box, BoxProps, useTheme } from '@chakra-ui/react'

export type LabelFormProps = BoxProps & {
  label: string | React.ReactNode
  id?: string
  labelWidth?: string | number
  isRequired?: boolean
}

/**
 * LabelForm component page.
 * @return {JSX.Element} LabelForm component.
 */
export const LabelForm: React.FC<LabelFormProps> = ({
  id,
  label,
  labelWidth,
  isRequired,
  ...props
}): React.ReactElement => {
  const theme = useTheme()
  return (
    <Box
      _after={
        isRequired
          ? {
              marginLeft: theme.space[1],
              color: 'red.700',
              content: '"*"',
            }
          : undefined
      }
      as={'label'}
      color={'black.100'}
      fontSize={'sm'}
      fontWeight={'bold'}
      htmlFor={id}
      lineHeight={theme.lineHeights.shorter}
      maxW={labelWidth}
      minW={labelWidth}
      w={labelWidth}
      {...props}
    >
      {label}
    </Box>
  )
}
