import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import { DataTable } from 'components/atoms'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constant'
import { NewsType } from 'services/newServices'
import ShowIcon from 'utils/showIconTable'
import { newServices } from 'services'
import { AxiosError } from 'axios'

type NewsRelatedProps = { newsId: string | undefined }

/**
 * NewsRelated molecules
 * @constructor
 */
export const NewsRelated: FC<NewsRelatedProps> = ({ newsId }) => {
  const navigate = useNavigate()
  const [relatedArticle, setRelatedArticle] = useState<{
    data: NewsType[]
    isLoading: boolean
  }>({
    data: [],
    isLoading: false,
  })

  const fetchDataNewsRelated = useCallback(async () => {
    try {
      if (newsId) {
        setRelatedArticle(prev => ({ ...prev, isLoading: true }))
        const { data } = await newServices.getRelatedArticle(newsId)
        setRelatedArticle(prev => ({ ...prev, data, isLoading: false }))
      }
    } catch (error) {
      setRelatedArticle(prev => ({ ...prev, data: [], isLoading: false }))
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [newsId])

  useEffect(() => {
    fetchDataNewsRelated()
  }, [fetchDataNewsRelated])

  const columnHelper = createColumnHelper<NewsType>()

  const columns = [
    columnHelper.accessor('news_body', {
      cell: info => {
        const { news_date, news_body, news_title, list_news_id } =
          info.row.original
        return (
          <Flex direction="column" gap={2}>
            <Text
              color="black.100"
              fontSize="xs"
              fontWeight="normal"
              lineHeight="12px"
              opacity={0.5}
            >
              {moment(news_date).format('YYYY/MM/DD')}
            </Text>
            <Tooltip
              bgColor="black"
              color="white"
              label={news_title}
              placement="top-start"
            >
              <Text
                color="blue.900"
                cursor="pointer"
                fontSize="sm"
                fontWeight="bold"
                lineHeight="14px"
                overflow="hidden"
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                }}
                onClick={() => {
                  if (list_news_id) {
                    handleNewsTitleClick(+list_news_id)
                  }
                }}
              >
                {news_title}
              </Text>
            </Tooltip>
            <Text
              color="black.100"
              fontSize="xs"
              fontWeight="normal"
              lineHeight="16.8px"
              overflow="hidden"
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {news_body}
            </Text>
          </Flex>
        )
      },
      header: '記事タイトル',
      size: 447,
    }),

    columnHelper.accessor('score_article', {
      cell: info => {
        let valueString = info.getValue()
          ? String(info.getValue()?.toFixed(1))
          : '0'
        let valueIntegerPart = valueString.split('.')[0]
        let valueDecimalPart = valueString.split('.')[1] || '0'
        return (
          <Text
            color="black.100"
            fontSize="2xl"
            lineHeight="shorter"
            textAlign="center"
          >
            {valueIntegerPart}
            {'.'}
            <Text
              color="black.100"
              display="inline-block"
              fontSize="lg"
              lineHeight="18px"
            >
              {valueDecimalPart}
            </Text>
          </Text>
        )
      },
      header: '記事スコア',
      size: 91,
    }),
    columnHelper.accessor('sentiment', {
      cell: info => (
        <Text textAlign="center">
          <ShowIcon changeColor={false} value={info.getValue()} />
        </Text>
      ),
      header: '判定',
      size: 65,
    }),
  ]

  /**
   * handleNewsTitleClick
   */
  const handleNewsTitleClick = (id: number) => {
    navigate(`${ROUTES.POST}/${id}`)
  }

  return (
    <Box padding="12px 16px">
      <DataTable
        columns={columns}
        data={relatedArticle.data || []}
        isPagination={false}
        isScroll={true}
        isShowAction={false}
        loadingData={relatedArticle.isLoading}
      />
    </Box>
  )
}
