import React, { FC } from 'react'
import { useMyPromptTabContent } from './components/MyPromptTabContent/MyPromptTabContent.hooks'
import { MyPromptTabContent } from './components/MyPromptTabContent/MyPromptTabContent'

/**
 * MyPromptTab component page.
 * @return {JSX.Element} MyPromptTab component.
 */
export const MyPromptTab: FC = () => {
  const {
    categories,
    handleOnChangeTabCategory,
    myConsultations,
    isLoadingFetchData,
    handleCheckFavouriteContent,
    handleSearchPrompt,
    handleSortPrompt,
  } = useMyPromptTabContent()
  return (
    <MyPromptTabContent
      categories={categories}
      handleCheckFavouriteContent={handleCheckFavouriteContent}
      handleOnChangeTabCategory={handleOnChangeTabCategory}
      handleSearchPrompt={handleSearchPrompt}
      handleSortPrompt={handleSortPrompt}
      isLoadingFetchData={isLoadingFetchData}
      myConsultations={myConsultations}
    />
  )
}
