import { FormikErrors } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { companyServices } from 'services'
import { Option } from 'types/conmon'
import { FormValues } from '../hook'

export type RenderActionOpenDrawerProps = {
  values: FormValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>
  isOpenDrawer: boolean
  year?: number
  quarter: number
  companyId?: number
  handleOffSwitch: () => void
  reportType: number
}

export const useRenderActionOpenDrawer = (
  props: RenderActionOpenDrawerProps,
) => {
  const { year, quarter, companyId, reportType } = props
  const [termOptions, setTermOptions] = useState<Option[]>([])

  const fetchReportYearQuarters = useCallback(async () => {
    try {
      if (!companyId) return

      const { data } = await companyServices.getReportYearQuarters({
        company_id: +companyId,
        type_of_process: reportType,
      })

      let arrayYear = Object.keys(data)
      let term: Option[] = []
      let total = 0
      let stop = false

      if (arrayYear.length === 0 || !year) {
        return
      }

      if (quarter === 0) {
        arrayYear.forEach(item => {
          if (stop) return

          if (String(year) === item) {
            let indexYear = arrayYear.findIndex(val => val === item)
            total += indexYear + 1
            stop = true
            return
          }
        })
      } else {
        arrayYear.forEach(item => {
          if (stop) return

          if (String(year) === item) {
            let indexQuarter = data[year].findIndex(val => val === +quarter)
            total += indexQuarter + 1
            stop = true

            return
          } else {
            total += data[item].length
          }
        })
      }

      term = Array.from(Array(total).keys()).map(o => ({
        label: `${o + 1}期分`,
        value: o + 1,
      }))

      setTermOptions(term)
    } catch (error) {
      console.log('error', error)
    }
  }, [companyId, quarter, reportType, year])

  useEffect(() => {
    fetchReportYearQuarters()
  }, [fetchReportYearQuarters])

  return { ...props, termOptions }
}
