/**
 * StarLightInActive icon
 * @constructor
 */
export const StarLightInActive = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4822 21C16.2154 21 15.9477 20.9328 15.6993 20.7957L12.1099 18.8158C12.0416 18.7779 11.9603 18.7779 11.8919 18.8158L8.3052 20.7938C7.73373 21.109 7.05433 21.0564 6.53178 20.6556C6.01201 20.2557 5.75533 19.5932 5.86427 18.9248L6.54835 14.7402C6.56128 14.6614 6.53631 14.5816 6.48276 14.5262L3.49062 11.4654C3.04655 11.011 2.88873 10.3426 3.07984 9.7209C3.27095 9.09921 3.77126 8.65459 4.38612 8.56021L8.52406 7.92975C8.59977 7.91808 8.66438 7.86847 8.69762 7.79745L10.4914 3.98557C10.7776 3.37749 11.3556 3 12 3C12.6444 3 13.2224 3.37749 13.5086 3.98557L15.3014 7.7965C15.3355 7.86849 15.4003 7.91808 15.476 7.92975L19.6138 8.56021C20.2287 8.65361 20.7291 9.09823 20.9202 9.7209C21.1113 10.3426 20.9534 11.011 20.5093 11.4654L17.5181 14.5252C17.4627 14.5816 17.4387 14.6614 17.4517 14.7402L18.1357 18.9267C18.2447 19.5941 17.9898 20.2567 17.4691 20.6556C17.1756 20.8842 16.8294 21 16.4822 21ZM12.0009 17.3282C12.2585 17.3282 12.517 17.3924 12.7515 17.5218L16.341 19.5017C16.4841 19.5815 16.6004 19.5202 16.6558 19.4774C16.7112 19.4355 16.7989 19.3392 16.7721 19.1758L16.088 14.9883C15.9975 14.4337 16.1729 13.8694 16.5561 13.4783L19.5464 10.4195C19.6359 10.328 19.6212 10.2249 19.6045 10.1723C19.5879 10.1188 19.5418 10.0255 19.4171 10.006L15.2793 9.37553C14.7521 9.29575 14.297 8.94745 14.0616 8.44543L12.2696 4.63545C12.1985 4.48368 12.0711 4.46128 12.0018 4.46128C11.9326 4.46128 11.8052 4.48368 11.7342 4.63545L9.94124 8.44638C9.70674 8.94743 9.2516 9.29575 8.72444 9.37553L4.5865 10.006C4.46187 10.0255 4.41478 10.1178 4.39908 10.1723C4.38247 10.2258 4.36861 10.328 4.45724 10.4195L7.44847 13.4793C7.83068 13.8695 8.00608 14.4337 7.9156 14.9883L7.23153 19.1729C7.20475 19.3373 7.29345 19.4336 7.34885 19.4764C7.40609 19.5192 7.51862 19.5795 7.66541 19.5007L11.2521 17.5228C11.4848 17.3934 11.7433 17.3282 12.0009 17.3282Z"
      fill="#0084FF"
    />
  </svg>
)
