/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Center,
  Flex,
  Text,
  useDisclosure,
  useToast,
  Image,
  Spacer,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { DataTable, InputField } from 'components/atoms'
import { Plus } from 'components/atoms/Icons'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Checkbox } from 'components/fromAdvisor/elements/CheckBox'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { ModalConfirm } from 'components/molecules'
import LinkageSourceModal from 'components/molecules/LinkageSourceModal'
import { ADMIN_ROUTES, ROLE, ROUTES, icons } from 'constant'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { companyServices } from 'services'
import { CompanyResponse } from 'services/companyServices'
import { PaginateResponse } from 'types/conmon'
import { TableCompany } from './TableCompany'

/**
 * CompanyList Page
 * @constructor
 */
export const CompanyList: FC<{ size: string }> = ({ size }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const formik = useFormik({
    initialValues: { company_search: '' },
    /**
     *
     */
    async onSubmit() {},
  })
  const { setFieldValue } = formik
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: openDetele,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  const [selectRecordId, setSelectRecordId] = useState<number[]>([])
  const [companyList, setCompanyList] = useState<{
    loading: boolean
    data: CompanyResponse[]
    last_updated?: string
  }>({ loading: false, data: [] })
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const [searchCompany, setSearchCompany] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const fetchDataCompanyList = useCallback(
    async (page?: number) => {
      try {
        setCompanyList(prev => ({ ...prev, loading: true }))
        setSelectRecordId([])
        const params = {
          page_size: pagination.page_size,
          page: page || pagination.page,
          sort_by: 'updated_at:desc',
          company_name: searchCompany,
        }
        const res = await companyServices.getCompanyList(size, params)
        setCompanyList(prev => ({
          ...prev,
          data: res.data,
          loading: false,
          last_updated: res.last_updated,
        }))

        if (res.paginate) {
          setPagination(res.paginate)
        }
      } catch (error) {
        setCompanyList(prev => ({ ...prev, loading: false }))
        if (error instanceof AxiosError) {
          console.log('error', error)
        }
      }
    },
    [pagination.page, pagination.page_size, searchCompany, size],
  )

  /**
   * handle click checkbox all
   */
  const handleDeleteMultiRecord = async () => {
    if (!selectRecordId) return
    try {
      await companyServices.deleteMultiCompany(selectRecordId)
      setSelectRecordId([])
      fetchDataCompanyList(1)
      toast({
        status: 'success',
        description: '企業削除が成功されました。',
        position: 'top',
      })
      onCloseDelete()
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
        position: 'top',
      })
    }
  }

  const linkingSFA = async (override: boolean) => {
    if (!selectRecordId) return
    setLoading(true)
    try {
      const { data } = await companyServices.linkSFA(selectRecordId, override)
      setSelectRecordId([])
      if (!isEmpty(data?.ok)) {
        fetchDataCompanyList(1)
      }
      const dataError = data.err
        ? companyList.data.filter(it => data.err.includes(it.id))
        : []
      toast({
        status: !isEmpty(data.err) ? 'error' : 'success',
        description: (
          <Box height="auto">
            <Text>
              {!isEmpty(data.err)
                ? '以下の企業はSalesforceで存在しません。'
                : '連携成功されました。'}
            </Text>
            {!isEmpty(data.err) && (
              <Flex flexDirection="column">
                {dataError.map(it => (
                  <Text>・{it.company_name}</Text>
                ))}
              </Flex>
            )}
          </Box>
        ),
        position: 'top',
      })
      setLoading(false)
      onClose()
    } catch (error: any) {
      setLoading(false)
      toast({
        status: 'error',
        description: '以下の企業はSalesforceで存在しません。',
        position: 'top',
      })
    }
  }

  const isDisabledBtn = loading || !selectRecordId

  useEffect(() => {
    fetchDataCompanyList()
  }, [fetchDataCompanyList])

  const columnHelper = createColumnHelper<CompanyResponse>()

  /**
   * handle click checkbox
   */
  const handleSelectRecord = (id: number) => {
    const idExist = selectRecordId.findIndex(r => r === id)
    if (idExist === -1) return setSelectRecordId(prev => [...prev, id])
    const newSelectRecordId = [...selectRecordId].filter(r => r !== id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleSelectRecordAll = () => {
    if (selectRecordId.length === companyList.data.length)
      return setSelectRecordId([])
    const newSelectRecordId = companyList.data.map(r => r.id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleCancelSelected = () => {
    setSelectRecordId([])
  }

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      size: 48,
      meta: {
        minWidthColumn: '48px',
      },
      cell: info => {
        const checked = selectRecordId.find(
          recordId => recordId === info.row.original.id,
        )
        return (
          <Center>
            <Checkbox
              isChecked={checked !== undefined ? true : false}
              onChange={() => {
                handleSelectRecord(info.row.original.id)
              }}
            />
          </Center>
        )
      },
      header: () => {
        return (
          <Center>
            <Checkbox
              isChecked={
                selectRecordId.length &&
                selectRecordId.length === companyList.data.length
                  ? true
                  : false
              }
              isDisabled={!companyList.data.length}
              onChange={handleSelectRecordAll}
            />
          </Center>
        )
      },
    }),
    columnHelper.accessor('stock_code', {
      id: 'stock_code',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {info.getValue() ?? '-'}
        </Text>
      ),
      header: 'コード',
      size: 94,
      meta: {
        minWidthColumn: '94px',
      },
    }),
    columnHelper.accessor('company_name', {
      id: 'company_name',
      cell: info => {
        const companyId = info.row.original.id
        const urlSFA = info.row.original.salesforce_lightning
        return (
          <Flex>
            <Box w="90%">
              <TextOverflowTooltip
                isCursor
                color="#0084FF"
                fontSize="sm"
                fontWeight="700"
                value={info.getValue()}
                onClick={() => navigate(ADMIN_ROUTES.COMPANY + `/${companyId}`)}
              />
            </Box>
            <Spacer />
            {urlSFA && (
              <Box cursor="pointer">
                <Image
                  src={icons.BUILDING}
                  onClick={() => {
                    window.open(urlSFA)
                  }}
                />
              </Box>
            )}
          </Flex>
        )
      },
      header: '会社名',
      size: 263,
      meta: {
        minWidthColumn: '263px',
      },
    }),
    columnHelper.accessor('category', {
      id: 'category',
      cell: info => {
        const value = info.getValue()?.name ?? '-'
        return (
          <TextOverflowTooltip
            fontSize="sm"
            opacity={value ? '' : '0.3'}
            value={value ?? '-'}
          />
        )
      },
      header: '業界',
      size: 147,
      meta: {
        minWidthColumn: '147px',
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {moment(info.getValue()).format('YYYY/MM/DD')}
        </Text>
      ),
      header: '登録日',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('updated_at', {
      id: 'updated_at',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {moment(info.getValue()).format('YYYY/MM/DD') ?? '----/--/--'}
        </Text>
      ),
      header: '更新日',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('department', {
      id: 'department',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {info.getValue()}
        </Text>
      ),
      header: '担当部店',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('contact_person_name', {
      id: 'contact_person_name',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {info.getValue()}
        </Text>
      ),
      header: '担当者',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('salesforce_lightning', {
      id: 'salesforce_lightning',
      cell: info => {
        return (
          <Flex gap="6px" justifyContent="end">
            <Text fontSize="14px">{info.getValue() ? '反映済' : '未'}</Text>
            <Switch
              isReadOnly
              defaultChecked={info.getValue() ? true : false}
            />
          </Flex>
        )
      },
      header: 'SFA連携',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
  ]
  const columnsAdvisorCompany = [
    columnHelper.accessor('stock_code', {
      id: 'stock_code',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {info.getValue() ?? '-'}
        </Text>
      ),
      header: 'コード',
      size: 94,
      meta: {
        minWidthColumn: '94px',
      },
    }),
    columnHelper.accessor('company_name', {
      id: 'company_name',
      cell: info => {
        const companyId = info.row.original.id
        const urlSFA = info.row.original.salesforce_lightning
        return (
          <Flex>
            <Box w="90%">
              <TextOverflowTooltip
                isCursor
                color="#0084FF"
                fontSize="sm"
                fontWeight="700"
                value={info.getValue()}
                onClick={() =>
                  navigate(ROUTES.COMPANY_DEATAIL + `/${companyId}`)
                }
              />
            </Box>
            <Spacer />
            {urlSFA && (
              <Box cursor="pointer">
                <Image
                  src={icons.BUILDING}
                  onClick={() => {
                    window.open(urlSFA)
                  }}
                />
              </Box>
            )}
          </Flex>
        )
      },
      header: '会社名',
      size: 263,
      meta: {
        minWidthColumn: '263px',
      },
    }),
    columnHelper.accessor('category', {
      id: 'category',
      cell: info => {
        const value = info.getValue()?.name ?? '-'
        return (
          <TextOverflowTooltip
            fontSize="sm"
            opacity={value ? '' : '0.3'}
            value={value ?? '-'}
          />
        )
      },
      header: '業界',
      size: 147,
      meta: {
        minWidthColumn: '147px',
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {moment(info.getValue()).format('YYYY/MM/DD')}
        </Text>
      ),
      header: '登録日',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('updated_at', {
      id: 'updated_at',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {moment(info.getValue()).format('YYYY/MM/DD') ?? '----/--/--'}
        </Text>
      ),
      header: '更新日',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('department', {
      id: 'department',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {info.getValue()}
        </Text>
      ),
      header: '担当部店',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('contact_person_name', {
      id: 'contact_person_name',
      cell: info => (
        <Text fontSize="sm" lineHeight="14px">
          {info.getValue()}
        </Text>
      ),
      header: '担当者',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
    columnHelper.accessor('salesforce_lightning', {
      id: 'salesforce_lightning',
      cell: info => {
        return (
          <Flex gap="6px" justifyContent="end">
            <Text fontSize="14px">{info.getValue() ? '反映済' : '未'}</Text>
            <Switch
              isReadOnly
              defaultChecked={info.getValue() ? true : false}
            />
          </Flex>
        )
      },
      header: 'SFA連携',
      size: 98,
      meta: {
        minWidthColumn: '98px',
      },
    }),
  ]

  return (
    <>
      <FormikProvider value={formik}>
        <Box
          bgColor="white.50"
          borderRadius={4}
          minH="calc(100vh - 88px)"
          padding="12px 24px"
          w="100%"
        >
          <Flex align={'center'} justifyContent="space-between">
            <Flex alignItems="center" gap="24px">
              <Text
                fontSize="24px"
                fontWeight="700"
                letterSpacing="tighter"
                lineHeight="40px"
                minW="111px"
                padding="0 8px"
              >
                企業一覧
              </Text>
              <InputField
                height="40px"
                name="company_search"
                placeholder="企業名検索"
                width="242px"
                onChange={e => {
                  setFieldValue('company_search', e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearchCompany(formik.values.company_search.trim())
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }
                }}
              />
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="12px"
                minW="135px"
                opacity="0.5"
              >
                最終更新日：
                {moment(companyList?.last_updated).format('YYYY/MM/DD')}
              </Text>
            </Flex>
            <Box>
              {size === ROLE.ADMIN && (
                <Button
                  backgroundColor="#FEFEFE"
                  border="1px solid #0084FF"
                  borderRadius="12px"
                  color="#0084FF"
                  fontSize="14px"
                  fontWeight={700}
                  height="40px"
                  leftIcon={<Plus fontSize="20px" />}
                  text="新規企業登録"
                  w="143px"
                  onClick={() => navigate(ADMIN_ROUTES.COMPANY_REGISTRATION)}
                />
              )}
            </Box>
          </Flex>
          <Box marginTop="16px">
            <DataTable
              columns={size === ROLE.ADMIN ? columns : columnsAdvisorCompany}
              data={companyList.data}
              isPagination={true}
              isScroll={true}
              isShowAction={selectRecordId.length ? true : false}
              loadingData={companyList.loading}
              maxH={
                selectRecordId.length
                  ? 'calc(100vh - 280px)'
                  : 'calc(100vh - 216px)'
              }
              pagination={pagination}
              rangePage={[15, 30, 50]}
              setPagination={setPagination}
              titleOpenModal="取引先に登録する"
              onCancel={handleCancelSelected}
              onDelete={onOpenDelete}
              onOpenModal={onOpen}
            />
            <ModalConfirm
              isOpen={openDetele}
              title="本当にこちらの企業を削除しますか。"
              onClose={onCloseDelete}
              onSubmit={handleDeleteMultiRecord}
            />
          </Box>
        </Box>
      </FormikProvider>
      <LinkageSourceModal
        isDisabledBtn={isDisabledBtn}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={linkingSFA}
      />
    </>
  )
}
