import { NavigateFunction } from 'react-router-dom'

type NavigateWithQueryParams = (
  navigate: NavigateFunction,
  path: string,
  queryParamName: string,
  queryParamValue: string | number | undefined,
  queryParamName2?: string,
  queryParamValue2?: string | number | undefined,
) => void

/**
 * @returns function that handle navigate with params
 */
export const navigateWithQueryParams: NavigateWithQueryParams = (
  navigate,
  path,
  queryParamName,
  queryParamValue,
  queryParamName2,
  queryParamValue2,
) => {
  const currentUrl = window.location.href
  const url = new URL(currentUrl)
  if (queryParamValue !== undefined) {
    url.searchParams.set(queryParamName, queryParamValue.toString())
  } else {
    url.searchParams.delete(queryParamName)
  }
  if (queryParamName2) {
    if (queryParamValue2 !== undefined) {
      url.searchParams.set(queryParamName2, queryParamValue2.toString())
    } else {
      url.searchParams.delete(queryParamName2)
    }
  }

  navigate(path + '?' + url.searchParams.toString())
}
