import { Box, Text, Flex, List, theme, ListItem } from '@chakra-ui/react'
import { InputField, SelectField, TextareaField } from 'components/atoms'
import { PenLightEdit } from 'components/atoms/Icons'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { RattingImportance } from 'components/molecules'
import { CompanyForm } from 'pages/admin/Company/components/CompanyForm'
import { FC, useEffect, useState } from 'react'
import {
  CreateProductRequest,
  Product,
  ProductCsv,
} from 'services/productServices'
import { get } from 'lodash'
import { importanceOption } from 'utils'
import { Option } from 'types/conmon'
import { errors as cusErr } from 'constant'
import { useFormikContext } from 'formik'
import { useDropzone } from 'react-dropzone'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'

/**
 * ViewProductCsv Page
 * @constructor
 */
type SelectFieldProps = {
  keyNumber: number
  value: Product
  isSubmit: boolean
  listCategory: Option[] | undefined
}
export const ViewProductCsv: FC<SelectFieldProps> = ({
  keyNumber,
  value,
  listCategory,
  isSubmit,
}) => {
  const [stateField, setStateFiled] = useState({
    category: false,
    product_name: false,
    product_short_name: false,
    overview: false,
    importance: false,
    switch: false,
    url: false,
    file: false,
  })
  const onDrop = async (files: File[]) => {
    setFieldValue(`products[${keyNumber}].files`, files)
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const [detailProduct] = useState<ProductCsv>(value)
  const { setFieldValue, errors, values } =
    useFormikContext<CreateProductRequest>()

  /**
   * @returns function that handle next input
   */
  const handleChangeValue = (
    value: string | number | undefined,
    name: string,
  ) => {
    const valuesFiled: any = [...values.products]
    valuesFiled[keyNumber][name] = value
    setFieldValue('products', valuesFiled)
  }

  const handleRemoveFile = (key: number) => {
    if (value.files) {
      const files = [...value.files]
      files.splice(key, 1)
      setFieldValue(`products[${keyNumber}].files`, files)
    }
  }

  useEffect(() => {
    if (isSubmit) {
      setStateFiled({
        category: false,
        product_name: false,
        product_short_name: false,
        overview: false,
        importance: false,
        switch: false,
        url: false,
        file: false,
      })
    }
  }, [isSubmit])

  return (
    <Box bgColor="#E8ECEF" padding="32px" w="100%">
      <CompanyForm
        borderTopRightRadius="none"
        fieldform={[
          {
            label: 'カテゴリー',
            isRequired: true,
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                {stateField.category ? (
                  <SelectField
                    error={Boolean(
                      get(errors, `products.${keyNumber}.category_id`),
                    )}
                    errorText={get(errors, `products.${keyNumber}.category_id`)}
                    height="48px"
                    name="category_id"
                    options={listCategory ?? []}
                    placeholder="例：預金"
                    valueOption={(listCategory || []).find(
                      it => it.value === Number(value.category_id),
                    )}
                    width="327px"
                    onChange={e => handleChangeValue(e?.value, 'category_id')}
                  />
                ) : (
                  <>
                    <Text fontSize="14px" lineHeight="24px">
                      {listCategory
                        ? listCategory.find(
                            it => it.value === value?.category_id,
                          )?.label
                        : ''}
                    </Text>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, category: true })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.category ? 'white.50' : '',
          },
          {
            label: '商品名',
            isRequired: true,
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                {stateField.product_name ? (
                  <InputField
                    isFocus
                    error={Boolean(
                      get(errors, `products.${keyNumber}.product_name`),
                    )}
                    errorText={get(
                      errors,
                      `products.${keyNumber}.product_name`,
                    )}
                    height="48px"
                    maxWidth="327px"
                    name="product_name"
                    placeholder="例：法人向け定期預金"
                    value={value.product_name}
                    onChange={e =>
                      handleChangeValue(e.target.value, 'product_name')
                    }
                  />
                ) : (
                  <>
                    <Text
                      fontSize="14px"
                      lineHeight="24px"
                      overflow="hidden"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {detailProduct?.product_name}
                    </Text>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, product_name: true })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.product_name ? 'white.50' : '',
          },
          {
            label: '商品略称',
            isRequired: true,
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                {stateField.product_short_name ? (
                  <InputField
                    isFocus
                    error={Boolean(
                      get(errors, `products.${keyNumber}.product_short_name`),
                    )}
                    errorText={get(
                      errors,
                      `products.${keyNumber}.product_short_name`,
                    )}
                    height="48px"
                    maxWidth="327px"
                    name="product_short_name"
                    placeholder="例：法人向け定期預金"
                    value={value.product_short_name}
                    onChange={e =>
                      handleChangeValue(e.target.value, 'product_short_name')
                    }
                  />
                ) : (
                  <>
                    <Text
                      fontSize="14px"
                      lineHeight="24px"
                      overflow="hidden"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {detailProduct?.product_short_name}
                    </Text>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({
                          ...stateField,
                          product_short_name: true,
                        })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.product_short_name ? 'white.50' : '',
          },
          {
            label: '概要',
            isRequired: true,
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                {stateField.overview ? (
                  <TextareaField
                    error={Boolean(
                      get(errors, `products.${keyNumber}.description`),
                    )}
                    errorText={get(errors, `products.${keyNumber}.description`)}
                    height={'128px'}
                    maxWidth="full"
                    name="description"
                    placeholder="入力してください"
                    value={value.description}
                    onChange={e => {
                      handleChangeValue(e.target.value, 'description')
                    }}
                  />
                ) : (
                  <>
                    <Text
                      fontSize="14px"
                      lineHeight="24px"
                      overflow="hidden"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {detailProduct?.description}
                    </Text>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, overview: true })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.overview ? 'white.50' : '',
          },
          {
            label: '参考URL',
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                {stateField.url ? (
                  <InputField
                    isFocus
                    error={Boolean(get(errors, `products.${keyNumber}.url`))}
                    errorText={get(errors, `products.${keyNumber}.url`)}
                    height="48px"
                    maxWidth="full"
                    name="url"
                    placeholder="https://sample.co.jp/"
                    value={value.url}
                    onChange={e => handleChangeValue(e.target.value, 'url')}
                  />
                ) : (
                  <>
                    <Text
                      fontSize="14px"
                      lineHeight="24px"
                      overflow="hidden"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {detailProduct?.url ?? ''}
                    </Text>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, url: true })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.switch ? 'white.50' : '',
          },
          {
            label: '参考資料',
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                {stateField.file ? (
                  <Flex alignItems="center" gap="4px">
                    <Button
                      {...getRootProps()}
                      backgroundColor="#FEFEFE"
                      border="1px solid #0084FF"
                      borderRadius="12px"
                      color="#0084FF"
                      fontSize={'14px'}
                      height="40px"
                      minW="115px"
                      text="ファイル添付"
                    />
                    <input
                      {...getInputProps()}
                      name={keyNumber.toString()}
                      type="file"
                    />
                    <List
                      display="flex"
                      gap="24px"
                      overflowX="auto"
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: theme.space[2],
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: theme.colors.black['150'],
                          borderRadius: theme.radii.lg,
                          border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                        },
                      }}
                      width="100%"
                    >
                      {values.products[keyNumber].files?.map((it, index) => (
                        <ListItem
                          key={index}
                          color="blue.900"
                          cursor="pointer"
                          display="flex"
                          fontSize="14px"
                          fontWeight="normal"
                          lineHeight="24px"
                          maxW="84px"
                        >
                          <TextOverflowTooltip
                            color="#0084FF"
                            fontSize="14px"
                            value={it.name}
                            width="100%"
                          />
                          <Text
                            alignItems="center"
                            bgColor="#BDBDBD"
                            color="#000"
                            display="flex"
                            h="20px"
                            justifyContent="center"
                            w="20px"
                            onClick={() => handleRemoveFile(index)}
                          >
                            x
                          </Text>
                        </ListItem>
                      ))}
                    </List>
                  </Flex>
                ) : (
                  <>
                    <List
                      display="flex"
                      gap="24px"
                      overflowX="auto"
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: theme.space[2],
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: theme.colors.black['150'],
                          borderRadius: theme.radii.lg,
                          border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                        },
                      }}
                      width="100%"
                    >
                      {values.products[keyNumber].files?.map((it, index) => (
                        <ListItem
                          key={index}
                          color="blue.900"
                          cursor="pointer"
                          display="flex"
                          fontSize="14px"
                          fontWeight="normal"
                          lineHeight="24px"
                          maxW="200px"
                        >
                          <TextOverflowTooltip
                            color="#0084FF"
                            fontSize="14px"
                            value={it.name}
                            width="100%"
                          />
                        </ListItem>
                      ))}
                    </List>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, file: true })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.switch ? 'white.50' : '',
          },
        ]}
        marginTop="16px"
      />
      <CompanyForm
        borderTopRightRadius="none"
        fieldform={[
          {
            label: 'スコア対象',
            isRequired: true,
            components: (
              <Flex alignItems="center" justifyContent="space-between">
                <Switch
                  isChecked={value?.state_score}
                  islabelinswitch={true}
                  isReadOnly={!stateField.switch}
                  offtext="非対象"
                  offtextswitch="対象"
                  onChange={() => {
                    setFieldValue(
                      `products[${keyNumber}].state_score`,
                      !value.state_score,
                    )
                  }}
                />
                {!stateField.switch && (
                  <PenLightEdit
                    cursor="pointer"
                    onClick={() =>
                      setStateFiled({ ...stateField, switch: true })
                    }
                  />
                )}
              </Flex>
            ),
            isBackground: !stateField.switch ? 'white.50' : '',
          },
          {
            label: '重要度',
            isRequired: true,
            components: (
              <Flex
                alignItems="center"
                height="100%"
                justifyContent="space-between"
              >
                {stateField.importance ? (
                  <SelectField
                    error={Boolean(
                      get(errors, `products.${keyNumber}.score_target`),
                    )}
                    errorText={cusErr.required}
                    height="48px"
                    isDisabled={!value?.state_score}
                    name="score_target"
                    options={importanceOption}
                    placeholder="選択してください"
                    valueOption={importanceOption.find(
                      it => it.value === String(value.score_target),
                    )}
                    width="327px"
                    onChange={e =>
                      handleChangeValue(Number(e?.value), 'score_target')
                    }
                  />
                ) : (
                  <>
                    <Flex alignItems="center" gap="8px">
                      <Text fontSize="14px" lineHeight="24px">
                        {`レベル${detailProduct?.score_target ?? 0}`}
                      </Text>
                      <RattingImportance
                        isDisable={!value?.state_score}
                        value={Number(detailProduct?.score_target) ?? 0}
                      />
                    </Flex>
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, importance: true })
                      }
                    />
                  </>
                )}
              </Flex>
            ),
            isBackground: !stateField.importance ? 'white.50' : '',
          },
        ]}
        marginTop="32px"
      />
    </Box>
  )
}
