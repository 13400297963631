/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  RankingCorporate,
  SearchCompany,
  SearchPost,
  TalkStory,
  Dnd,
  CompanyFavorite,
} from 'components/molecules'
import { FC } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { useDashboardContainer } from './hook'

/**
 * DashboardContainer organisms
 * @constructor
 */
export const DashboardContainer: FC = props => {
  const { observedDiv, childLayoutSize, parentLayoutSize } =
    useDashboardContainer(props)

  return (
    <Box boxSizing="border-box" w="100%">
      <Flex flexDirection="column" gap="16px" w="100%">
        <SearchCompany />
        <CompanyFavorite />
        <Dnd
          component={{
            '0': (
              <Box ref={observedDiv}>
                <SearchPost page="dashboard" />
              </Box>
            ),
            '1': (
              <Dnd
                component={{
                  '0': <RankingCorporate />,
                  '1': <TalkStory />,
                }}
                draggableHandle=".drag-horizontal"
                isBounded={true}
                lg={childLayoutSize}
                type="horizontal"
              />
            ),
          }}
          draggableHandle=".drag-vertical"
          isBounded={false}
          lg={parentLayoutSize}
          type="vertical"
          onDragStop={layout => {
            window.localStorage.setItem('element', JSON.stringify(layout))
          }}
        />
      </Flex>
    </Box>
  )
}
