import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { ROLE, ROUTES } from 'constant'
import { useNavigate } from 'react-router-dom'
import { companyServices } from 'services'
import { CompanySearchResponse } from 'services/companyServices'

export type SearchCompanyProps = {}

export const NUMBER_COMPANY_SEARCH = 5

export const useSearchCompany = (props: SearchCompanyProps) => {
  const [optionsCompany, setOptionsCompany] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [companySearchLog, setCompanySearchLog] = useState<
    CompanySearchResponse[]
  >([])

  const [keySearchCompany, setKeySearchCompany] = useState('')

  const [pagination, setPagination] = useState<{
    page_size: number
    page: number
    page_count: number
    total_count: number
  }>({
    page_size: 20,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      selectCompany: '',
    },
    /**
     *
     */
    onSubmit() {},
  })
  const navigate = useNavigate()

  const handleChangeHistorySearchLog = async (id: string | number) => {
    try {
      await companyServices.changeHistorySearchCompany({
        company_id: id,
      })
      navigate(`${ROUTES.COMPANY}/${id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleScrollBottom = () => {
    if (pagination.page >= pagination.page_count) return
    setPagination(prev => ({ ...prev, page: prev.page + 1 }))
  }

  const handleSearchCmpany = (company: string) => {
    setKeySearchCompany(company)
    if (keySearchCompany && !company) {
      setOptionsCompany([])
    }
    if (company) {
      setPagination({
        page_size: 20,
        page: 1,
        page_count: 1,
        total_count: 0,
      })
    }
  }

  const fetchListCompanySearchLog = useCallback(async () => {
    try {
      const { data } = await companyServices.getCompanySearch({
        page_size: NUMBER_COMPANY_SEARCH,
      })
      setCompanySearchLog(data)
    } catch (error) {}
  }, [])

  const fetchDataCompanyWithScroll = useCallback(async () => {
    if (keySearchCompany) return
    setIsLoading(true)

    try {
      const size = ROLE.ADVISOR

      const { data, paginate: resPaginate } =
        await companyServices.getCompanyList(size, {
          is_paginate: true,
          page: pagination?.page,
        })

      setOptionsCompany(prev =>
        [...prev].concat(
          data.map(it => ({
            label: it.company_name,
            value: it.id.toString(),
          })),
        ),
      )
      setPagination(prev => ({ ...prev, ...resPaginate }))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [keySearchCompany, pagination?.page])

  const fetchDataCompanyWithKey = useCallback(async (key: string) => {
    if (!key) return
    setOptionsCompany([])

    setIsLoading(true)

    try {
      const size = ROLE.ADVISOR

      const data = await companyServices
        .getCompanyList(size, {
          is_paginate: false,
          company_name: key,
        })
        .then(res =>
          res.data.map(item => ({
            label: item.company_name,
            value: item.id.toString(),
          })),
        )
      setOptionsCompany(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchDataCompanyWithScroll()
  }, [fetchDataCompanyWithScroll])

  useEffect(() => {
    fetchListCompanySearchLog()
  }, [fetchListCompanySearchLog])

  return {
    ...props,
    formik,
    optionsCompany,
    handleChangeHistorySearchLog,
    companySearchLog,
    navigate,
    NUMBER_COMPANY_SEARCH,
    handleScrollBottom,
    setPagination,
    handleSearchCmpany,
    fetchDataCompanyWithKey,
    isLoading,
  }
}
