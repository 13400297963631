import React, { FC } from 'react'
import { Box, Flex, SkeletonText, Text } from '@chakra-ui/react'
import { FormikProvider } from 'formik'
// import { MoreVertical } from 'components/atoms/Icons'
import { SelectField } from 'components/atoms'
import { Button } from 'components/fromAdvisor/elements'
import ModalTalkStory from '../ModalTalkStory'
import ReactMarkdown from '../ReactMarkdown'
import { TalkStoryProps, useTalkStory } from './hook'

/**
 * TalkStory molecules
 * @constructor
 */
export const TalkStory: FC<TalkStoryProps> = props => {
  const {
    formik,
    options,
    AIAnswer,
    toggleExpanded,
    expanded,
    setExpanded,
    stateAIAnswer,
    isShow,
    ref,
    isDisableSelect,
    handleSendChatAi,
    valueSelected,
    setValueSelected,
    isDisableButton,
  } = useTalkStory(props)
  return (
    <FormikProvider value={formik}>
      <Flex
        bgColor="white.base"
        borderRadius={4}
        direction="column"
        h="100%"
        padding="12px 24px 16px 24px"
        w="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="4px"
          w="100%"
        >
          <Text
            className="drag-horizontal"
            color="black.100"
            cursor="pointer"
            display="inline-block"
            fontSize="lg"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="tall"
            minW={'fit-content'}
            px="8px"
          >
            トークネタ
          </Text>
          {/* <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} /> */}
        </Flex>
        <Flex gap="16px" mb="16px">
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            isInForm={false}
            minHeight="40px"
            name="selectIndustry"
            options={options.industry}
            placeholder="業界"
            sizePlaceholder="14px"
            valueOption={options.industry.find(
              it => it.value === valueSelected.industry,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== valueSelected.industry) {
                setValueSelected(prev => ({
                  ...prev,
                  industry: Number(e?.value),
                }))
              }
            }}
          />
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            isInForm={false}
            minHeight="40px"
            name="selectTalkStory"
            options={options.story}
            placeholder="トークテーマ"
            sizePlaceholder="14px"
            valueOption={options.story.find(
              it => it.value === valueSelected.story,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== valueSelected.story) {
                setValueSelected(prev => ({ ...prev, story: Number(e?.value) }))
              }
            }}
          />
          <Button
            bgColor={'blue.900'}
            border={'none'}
            color="white.50"
            fontSize={'16px'}
            fontWeight={'bold'}
            h={'40px'}
            isDisabled={isDisableSelect || isDisableButton}
            letterSpacing={'-0.02px'}
            lineHeight={'shorter'}
            minW={'fit-content'}
            px={5}
            text="実行"
            onClick={handleSendChatAi}
          />
        </Flex>
        <>
          <Box ref={ref} w="100%">
            {stateAIAnswer.loading ? (
              <SkeletonText noOfLines={6} skeletonHeight="4" spacing="4" />
            ) : Boolean(AIAnswer) ? (
              <Box maxHeight={'168px'} overflow={'auto'}>
                <ReactMarkdown
                  bgColor="#FEFEFE"
                  data={AIAnswer}
                  isCopyBtn={true}
                  isDone={stateAIAnswer.done}
                />
              </Box>
            ) : (
              <Flex justify={'center'}>
                <Text
                  color="black.100"
                  fontSize={'16px'}
                  fontWeight="normal"
                  id="text-element"
                  lineHeight="shorter"
                  maxHeight={'168px'}
                  overflow={'hidden'}
                  textAlign="justify"
                  whiteSpace={'pre-wrap'}
                >
                  {'データがありません。'}
                </Text>
              </Flex>
            )}
            {AIAnswer && isShow && (
              <Flex justifyContent="flex-end">
                <Text
                  color="blue.900"
                  cursor="pointer"
                  display="inline-block"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  textAlign="end"
                  onClick={toggleExpanded}
                >
                  別ウィンドウで表示
                </Text>
              </Flex>
            )}
          </Box>
        </>
      </Flex>
      <ModalTalkStory
        isDisableButton={isDisableButton}
        isDisableSelect={isDisableSelect}
        isOpen={expanded}
        options={options}
        setValueSelected={setValueSelected}
        stateAIAnswer={stateAIAnswer}
        story={AIAnswer}
        valueSelected={valueSelected}
        onClose={() => {
          setExpanded(false)
        }}
        onSendChatAi={handleSendChatAi}
      />
    </FormikProvider>
  )
}
