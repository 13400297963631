import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

import { useFormik } from 'formik'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import { Option, RankingProps } from 'types/conmon'
import { companyServices, productAdminServices } from 'services'
import moment from 'moment'
import { CompanyInfoResponse } from 'services/companyServices'
import { useLocation } from 'react-router-dom'

export type CardRankingProps = {
  showScoreChange: boolean
  id?: string
  separator?: boolean
  isColumn?: boolean
  showFilterDate?: boolean
  width?: string
}

export const useCardRanking = (props: CardRankingProps) => {
  const { id, showFilterDate = false } = props
  const location = useLocation()
  const { search } = location
  const queryParams = new URLSearchParams(search)

  const companyId = queryParams.get('company_id') ?? ''

  const toast = useToast()
  const formik = useFormik({
    initialValues: {
      date_range_type: !showFilterDate ? '0' : '',
      product_type: '',
    },
    /**
     *
     */
    onSubmit() {},
  })
  const { values, setFieldValue } = formik

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([])
  const [dataRanking, setDataRanking] = useState<{
    loading: boolean
    data: RankingProps[]
  }>({ loading: false, data: [] })
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoResponse>()

  /**
   * fetch industry options
   */
  const fetchOptionsCategory = useCallback(async () => {
    try {
      const { data } = await productAdminServices.getOptionsCategory()
      const options = data.map(d => ({
        label: d.name,
        value: !showFilterDate ? d.name : d.id,
      }))

      setCategoryOptions(options)
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message ?? 'Error',
      })
    }
  }, [showFilterDate, toast])

  useEffect(() => {
    fetchOptionsCategory()
  }, [fetchOptionsCategory])
  /**
   * fetch data ranking
   */
  const fetchDataRanking = useCallback(async () => {
    if (!id) return
    try {
      setDataRanking(prev => ({ ...prev, loading: true, data: [] }))
      let convertData: {
        title: string
        score: string
        old_score: string
      }[] = []
      if (!showFilterDate) {
        if (!companyInfo) {
          const { data: infoCompany } = await companyServices.getCompanyInfo({
            company_id: id,
            mkt_date: moment().format('YYYY-MM-DD'),
          })
          const { data } = await productAdminServices.getCorporateRanking({
            ...values,
            company_name: infoCompany.company_name,
          })
          convertData =
            data &&
            data.map(d => ({
              title: d.product_name ?? d.product ?? '',
              score: d.score,
              old_score: d.old_score,
            }))
          setCompanyInfo(infoCompany)
        } else {
          const { data } = await productAdminServices.getCorporateRanking({
            ...values,
            company_name: companyInfo.company_name,
          })
          convertData =
            data &&
            data.map(d => ({
              title: d.product_name ?? d.product ?? '',
              score: d.score,
              old_score: d.old_score,
            }))
        }
      } else {
        let params = {
          news_id: id,
          product_type: values.product_type,
        }
        if (companyId) {
          Object.assign(params, { company_id: Number(companyId) })
        }
        const { data } =
          await productAdminServices.getCorporateRankingNews(params)
        convertData =
          data &&
          data.map(d => ({
            title: d.product_name ?? d.product ?? '',
            score: d.score,
            old_score: d.old_score,
            score_reason: d.score_reason ?? '',
          }))
      }
      setDataRanking(prev => ({ ...prev, loading: false, data: convertData }))
    } catch (error: any) {
      setDataRanking(prev => ({ ...prev, loading: false, data: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, showFilterDate, toast, values])

  useEffect(() => {
    fetchDataRanking()
  }, [fetchDataRanking])
  return {
    ...props,
    formik,
    values,
    setFieldValue,
    categoryOptions,
    dataRanking,
  }
}
