import React, { FC } from 'react'
import { Avatar } from 'components/fromAdvisor/elements/Avatar'
import { Box, Flex, IconButton, useTheme } from '@chakra-ui/react'
import { PaperPlaneIcon } from 'components/fromAdvisor/elements/Icons'
import { AutoResizeTextarea } from 'components/fromAdvisor/elements/AutoResizeTextarea'
import { AskMoreBoxProps } from './MainChat.types'

/**
 * AskMoreBox component page.
 * @return {JSX.Element} AskMoreBox component.
 */
export const AskMoreBox: FC<AskMoreBoxProps> = ({
  questionAskMore,
  onSubmitAskMore,
  onChangeInputAskMore,
  onEnterAskMore,
  avatar,
}) => {
  const theme = useTheme()

  return (
    <Flex
      bg={'white.50'}
      borderColor={'purple.250'}
      borderRadius={'xl'}
      borderWidth={'3px'}
      boxShadow={theme.boxShadows.insetInputBase}
      gap={3.5}
      mb={10}
      minH={'156px'}
      px={6}
      py={8}
      w={'full'}
    >
      <Box maxH={15} minW={15} w={15}>
        <Avatar src={avatar} />
      </Box>
      <Flex
        alignItems={'flex-end'}
        flexDirection={'column'}
        h={'full'}
        w={'full'}
      >
        <AutoResizeTextarea
          _focusVisible={{
            border: 'none',
          }}
          _placeholder={{
            opacity: 0.3,
            color: 'black.100',
          }}
          border={'none'}
          color={'black.100'}
          fontSize={'md'}
          fontWeight={'normal'}
          h={'full'}
          lineHeight={'30px'}
          overflow={'auto'}
          pl={0}
          placeholder={'AIの回答に返信する'}
          pt={0}
          resize={'none'}
          value={questionAskMore}
          w={'full'}
          onChange={onChangeInputAskMore}
          onKeyDown={onEnterAskMore}
        />
        <IconButton
          _hover={{
            background: 'transparent',
          }}
          aria-label="send message"
          bg={'transparent'}
          icon={<PaperPlaneIcon />}
          zIndex={theme.zIndices.docked}
          onClick={onSubmitAskMore}
        />
      </Flex>
    </Flex>
  )
}
