import { axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import { ROLE } from 'constant'
import { Consultation } from '../chatAIServices/mockData'
import {
  BodyCreatePrompt,
  BodySaveConsultation,
  BodyTogglePublicPrompt,
  ConsultationsConverted,
  ConsultationsResponse,
  DetailPromptAdminResponse,
  FavoriteStatus,
  GetListPromptAdminRequest,
} from './consultationsServices.type'

/**
 * @returns function that handle consultations services
 */
export class ConsultationsServices {
  /**
   * @returns function that handle convert response consultation
   */
  convertConsultationsResponse(
    response: ConsultationsResponse[],
  ): ConsultationsConverted[] {
    return response.map(
      ({
        id,
        prompt,
        theme_id,
        created_at,
        updated_at,
        title,
        category_id,
        is_favorite,
      }) => ({
        id: id,
        themeId: theme_id,
        updatedAt: updated_at,
        title: title,
        categoryId: category_id,
        createdAt: created_at,
        prompt: prompt,
        isFavorite: is_favorite,
      }),
    )
  }

  /**
   * @returns function that handle get list consultation
   */
  async getConsultations(): Promise<ConsultationsConverted[]> {
    return this.convertConsultationsResponse(Consultation)
  }

  /**
   * @returns function that handle toggle favorite
   */
  async toggleFavoriteStatus(id: number, body: FavoriteStatus) {
    return await axiosWithToken.put(
      `/v1/consultations/${id}/toggle_favorite_status`,
      body,
    )
  }

  /**
   * @returns function that handle save consultation service
   */
  async saveConsultation(body: BodySaveConsultation) {
    return await axiosWithToken.post(
      '/v1/consultations/create_new_collection',
      body,
    )
  }

  /**
   * @returns function that handle save create prompt service
   */
  async createPrompt(
    size: string,
    body: BodyCreatePrompt,
    isTab: number | string,
  ) {
    return await axiosWithToken.post(
      size === ROLE.ADVISOR
        ? '/v1/advisor/consultations/prompt/'
        : `/v1/admin/consultation/?is_tab=${isTab}`,
      body,
    )
  }

  /**
   * @returns function that handle save get id prompt service
   */
  async getPromptDetail(size: string, id: string) {
    return await axiosWithToken.get(
      size === ROLE.ADVISOR
        ? `/v1/advisor/consultations/prompt/${id}`
        : `/v1/admin/consultation/${id}`,
    )
  }

  /**
   * @returns function that handle save consultation service
   */
  async removePromptDetail(id: string) {
    return await axiosWithToken.delete(`/v1/advisor/consultations/prompt/${id}`)
  }

  /**
   * @returns function that handle save consultation service
   */
  async removePromptAdmin(params: { ids: number[] }) {
    return await axiosWithToken.delete(`/v1/admin/consultation/`, {
      data: params,
    })
  }

  /**
   * @returns function that handle save consultation service
   */
  async updatePromptDetail(size: string, id: string, body: BodyCreatePrompt) {
    return await axiosWithToken.put(
      size === ROLE.ADVISOR
        ? `/v1/advisor/consultations/prompt/${id}`
        : `/v1/admin/consultation/${id}`,
      body,
    )
  }

  /**
   * @returns function that handle get list prompt admin
   */
  async getListPromptAdmin(
    params: GetListPromptAdminRequest,
  ): Promise<BasicResponse<DetailPromptAdminResponse[]>> {
    return (await axiosWithToken.get(`/v1/admin/consultation`, { params })).data
  }

  /**
   * @returns function that handle toggle public prompt
   */
  async togglePublicPrompt(id: number, body: BodyTogglePublicPrompt) {
    return (
      await axiosWithToken.put(`/v1/admin/consultation/${id}/public`, body)
    ).data
  }
}
