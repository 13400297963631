import React, { FC } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
// import { MoreVertical } from 'components/atoms/Icons'
import { SelectField } from 'components/atoms'
import { FormikProvider } from 'formik'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import { optionsDate } from 'constant/conmon'
import { TradingRanking } from '../TradingRanking'
import { CardRankingProps, useCardRanking } from './hook'

/**
 * CardRanking molecules
 * @constructor
 */
export const CardRanking: FC<CardRankingProps> = props => {
  const {
    showScoreChange,
    separator,
    isColumn,
    showFilterDate = false,
    formik,
    values,
    setFieldValue,
    categoryOptions,
    dataRanking,
    width = '100%',
  } = useCardRanking(props)

  return (
    <FormikProvider value={formik}>
      <Box
        bg="white.base"
        borderRadius={4}
        boxSizing="border-box"
        padding="12px 16px"
        w={width}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="16px"
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            {!showFilterDate && (
              <>
                <Text
                  color="black.100"
                  display="inline-block"
                  fontSize="lg"
                  fontWeight="bold"
                  letterSpacing="-0.02em"
                  lineHeight="tall"
                  minW={'fit-content'}
                  padding={'0px 8px'}
                >
                  取引可能性ランキング
                </Text>
                <SelectField
                  maxWidth="190px"
                  minHeight="40px"
                  name="date_range_type"
                  options={optionsDate}
                  placeholder="対象期間"
                  valueOption={optionsDate.find(
                    it => it.value === values.date_range_type,
                  )}
                  onChange={e => setFieldValue('date_range_type', e?.value)}
                />
              </>
            )}
            <SelectField
              isResetValue
              maxWidth="272px"
              minHeight="40px"
              name="product_type"
              options={categoryOptions}
              placeholder={false ? '商品カテゴリー' : '商品種類'}
              sizePlaceholder="14px"
              valueOption={categoryOptions.find(
                it => it.value === values.product_type,
              )}
              onChange={e => setFieldValue('product_type', e?.value)}
            />
          </Flex>
          {/* <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} /> */}
        </Flex>
        <TradingRanking
          dataRanking={dataRanking}
          isColumn={isColumn}
          rowsPerColumn={5}
          separator={separator ? separator : false}
          showScoreChange={showScoreChange}
        />
      </Box>
    </FormikProvider>
  )
}
