import { Button } from 'components/fromAdvisor/elements'
import React, { FC } from 'react'

type PaginationButtonProps = {
  handleClick: () => void
  text: string
  isDisabled?: boolean
  color?: string
  padding?: string
}
/**
 * PaginationButton component
 * @constructor
 */
const PaginationButton: FC<PaginationButtonProps> = ({
  handleClick,
  text,
  isDisabled,
  color,
  padding,
}) => {
  return (
    <Button
      _hover={{ borderRadius: 8 }}
      bgColor="transparent"
      border={'none'}
      color={color || 'blue.900'}
      fontSize="sm"
      fontWeight={'normal'}
      h={'28px'}
      isDisabled={isDisabled}
      maxW="28px"
      minW="28px"
      p={padding || 0}
      text={text}
      onClick={handleClick}
    />
  )
}

export default PaginationButton
