import React from 'react'

/**
 * FavouriteActiveIcon icon
 * @constructor
 */
export const FavouriteActiveIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8301 11.4651C19.6571 17.5151 12.0001 21.0001 12.0001 21.0001C12.0001 21.0001 4.34304 17.5151 3.16905 11.4651C2.44904 7.75308 4.0221 4.01906 8.0211 4.00006C11.0001 3.98606 12.0001 6.98803 12.0001 6.98803C12.0001 6.98803 13.0001 3.98506 15.9781 4.00006C19.9861 4.01906 21.5501 7.75408 20.8301 11.4651Z"
      fill="#0084FF"
    />
    <path
      d="M12.001 21C11.9032 21 11.8053 20.9798 11.7139 20.9384C11.412 20.8015 4.30644 17.5217 3.16711 11.6808C2.72671 9.42105 3.16896 7.21644 4.34984 5.78469C5.30543 4.62496 6.67923 4.00832 8.32359 4.00005C8.3319 4.00005 8.34021 4.00005 8.3476 4.00005C10.2237 4.00005 11.3677 5.06332 12.0001 5.9685C12.6354 5.05964 13.7885 3.99178 15.6766 4.00005C17.3219 4.00832 18.6966 4.62496 19.6532 5.78469C20.8322 7.21552 21.2735 9.42011 20.8322 11.6817C19.6947 17.5226 12.5882 20.8034 12.2863 20.9394C12.1967 20.9798 12.0989 21 12.001 21ZM8.3467 5.3776C8.34116 5.3776 8.33657 5.3776 8.33103 5.3776C7.09568 5.38311 6.11703 5.81409 5.42088 6.65862C4.49852 7.777 4.1652 9.55613 4.52712 11.418C5.40886 15.9429 10.702 18.8836 12.001 19.5434C13.3001 18.8836 18.5933 15.9429 19.4741 11.418C19.8378 9.55521 19.5045 7.77608 18.584 6.65862C17.8879 5.81501 16.9092 5.38493 15.6711 5.3785C15.6655 5.3785 15.66 5.3785 15.6554 5.3785C13.4654 5.3785 12.6889 7.5629 12.6576 7.65572C12.5615 7.93416 12.2974 8.12344 12.0019 8.12344C12.0001 8.12344 11.9992 8.12344 11.9982 8.12344C11.7019 8.12252 11.4378 7.93415 11.3436 7.65386C11.3132 7.56197 10.5358 5.3776 8.3467 5.3776Z"
      fill="#0084FF"
    />
  </svg>
)
