import { Box, Flex, Text } from '@chakra-ui/react'
// import { InputField, SelectField } from 'components/atoms'
import { CheckboxField } from 'components/atoms/CheckboxField'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { FormikProvider, useFormik } from 'formik'
import React, { FC } from 'react'

type LinkageSourceProps = {
  isOpen: boolean
  isDisabledBtn?: boolean
  onClose: () => void
  onSubmit: (override: boolean) => void
}

/**
 * LinkageSourceModal molecules
 * @constructor
 */
const LinkageSourceModal: FC<LinkageSourceProps> = ({
  isOpen,
  isDisabledBtn,
  onClose,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      search_key: '',
      source: '',
      override: false,
    },
    /**
     *
     */
    async onSubmit(values: {
      search_key: string
      source: ''
      override: boolean
    }) {
      onSubmit(values.override)
    },
  })

  const { setFieldValue, handleSubmit, values } = formik

  /**
   *
   */
  const handleCloseModal = () => {
    setFieldValue('search_key', '')
    setFieldValue('source', '')
    onClose()
  }

  return (
    <FormikProvider value={formik}>
      <Modal
        isShowIconClose
        closeOnOverlayClick={true}
        isOpen={isOpen}
        maxWidth={'440px'}
        onClose={handleCloseModal}
      >
        <Box bgColor={'white.150'} borderRadius={'20px'} p="32px 40px">
          <Text
            align={'center'}
            color="black.100"
            fontSize="xl"
            fontWeight="bold"
            lineHeight="base"
          >
            選択した企業を
          </Text>
          <Text
            align={'center'}
            color="black.100"
            fontSize="xl"
            fontWeight="bold"
            lineHeight="base"
            mb={'40px'}
          >
            取引先に登録します
          </Text>
          {/* <Text
            align={'center'}
            color="black.100"
            fontSize="md"
            fontWeight="normal"
            lineHeight="base"
            mb={'24px'}
          >
            SFA連携する場合は下記の情報も入力ください
          </Text> */}
          {/* <Box mb={'24px'}>
            <SelectField
              colorLabel="black.100"
              height="48px"
              label="データ連携元"
              name="source"
              options={[{ label: 'options 1', value: 1 }]}
              placeholder="選択してください"
              sizeLabel="14px"
              subLabel="（任意）"
              onChange={e => setFieldValue('source', e)}
            />
          </Box>
          <InputField
            colorLabel="black.100"
            height={'48px'}
            label="データ連携元のID"
            name="sourceId"
            placeholder="入力してください"
            sizeLabel="14px"
            subLabel="（任意）"
            onChange={e => setFieldValue('sourceId', e.target.value)}
          /> */}

          <CheckboxField
            label={
              'Salesforceで、新しい情報があります。 システムの情報を上書しますか。'
            }
            name={'overide'}
            value={values.override}
            onChange={() => setFieldValue('override', !values.override)}
          />
          <Flex direction={'column'} gap={6} mt={'40px'}>
            <Button
              bgColor={'blue.900'}
              border="solid 1px"
              borderColor="blue.900"
              borderRadius={'12px'}
              color="white.50"
              fontSize={'md'}
              fontWeight={'bold'}
              h={'48px'}
              isDisabled={isDisabledBtn}
              letterSpacing={'tighter'}
              lineHeight={'shorter'}
              p="12px"
              text="取引先に登録する"
              w={'full'}
              onClick={() => handleSubmit()}
            />
            <Button
              bgColor={'white.base'}
              border="solid 1px"
              borderColor="blue.900"
              borderRadius={'12px'}
              color="blue.900"
              fontSize={'md'}
              fontWeight={'bold'}
              h={'48px'}
              letterSpacing={'tighter'}
              lineHeight={'shorter'}
              p="11px, 24px, 13px, 24px"
              text="キャンセル"
              w={'full'}
              onClick={handleCloseModal}
            />
          </Flex>
        </Box>
      </Modal>
    </FormikProvider>
  )
}

export default LinkageSourceModal
