import { IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * SendMailIcon icon
 * @param props
 * @constructor
 */
export const SendMailIcon: FC<IconProps> = () => {
  return (
    <svg
      fill="none"
      height="120"
      viewBox="0 0 121 120"
      width="121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5 100H92.5C103.167 100 108.5 94.6667 108.5 84V36C108.5 25.3333 103.167 20 92.5 20H28.5C17.8333 20 12.5 25.3333 12.5 36V84C12.5 94.6667 17.8333 100 28.5 100Z"
        fill="#0084FF"
        opacity="0.4"
      />
      <path
        d="M60.4974 65C58.5967 65 56.6851 64.4043 55.0642 63.2184L29.1306 44.2165C27.3619 42.9242 26.971 40.4247 28.2592 38.6431C29.5422 36.8668 32.0071 36.4627 33.7917 37.7656L59.7266 56.7621C60.1912 57.0971 60.8088 57.1024 61.2734 56.7621L87.2083 37.7656C88.9876 36.4627 91.4578 36.8668 92.7408 38.6431C94.029 40.4247 93.6381 42.9189 91.8694 44.2165L65.9358 63.213C64.3096 64.4096 62.3981 65 60.4974 65Z"
        fill="#0084FF"
      />
    </svg>
  )
}
