/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, memo } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { InputField } from 'components/atoms'

type PdfPaginationProps = {
  page: number
  setCunrentPage: (pageIndex: number) => void
  value: number
  handleSetPagePdf: (e: string, type?: boolean) => void
}

/**
 * PdfPagination molecules
 * @constructor
 */
const PdfPagination: FC<PdfPaginationProps> = ({
  page,
  setCunrentPage,
  value,
  handleSetPagePdf,
}) => {
  return (
    <Flex alignItems="center" gap="4px">
      <InputField
        maxLength={page}
        name="page"
        sizeInput={'14px'}
        textAlign="center"
        type="number"
        width="50px"
        onChange={e => {
          if (e.target.value && Number(e.target.value) === 0) return
          if (Number(e.target.value) > page) return
          handleSetPagePdf(e.target.value)
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setCunrentPage(value)
          }
        }}
      />
      <Text color={'#6C7275'} fontSize={'14px'}>
        /
      </Text>
      <Text color={'#6C7275'} fontSize={'14px'}>
        {page}
      </Text>
    </Flex>
  )
}

export default memo(PdfPagination)
