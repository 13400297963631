import React from 'react'

/**
 * LeftDrawerIcon icon
 * @constructor
 */
export const LeftDrawerIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#0084FF" height="20" rx="2.5" width="20" x="2" y="2" />
    <rect fill="#F4F7F9" height="16" rx="2" width="8" x="4" y="4" />
  </svg>
)
