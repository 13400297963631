import { FC } from 'react'
import { useLocation, Navigate } from 'react-router-dom'

/**
 * Redirect page
 * @constructor
 */
const Redirect: FC<{ to: string }> = ({ to }) => {
  const location = useLocation()
  return <Navigate replace state={{ from: location }} to={to} />
}

export default Redirect
