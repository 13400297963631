/* eslint-disable @typescript-eslint/no-unused-vars */
import Markdown from 'markdown-to-jsx'
import { useState } from 'react'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'
import * as Showdown from 'showdown'

export type EditorProps = {
  value: string
  onChange: (newAnswer: string) => void
  name: string
}

/**
 * Editor component page.
 * @return {JSX.Element} Editor component.
 */
export const Editor = (props: EditorProps) => {
  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  })
  const [content, setContent] = useState(props.value)

  const handleEditorChange = ({ text }: { text: any }) => {
    setContent(text)
    if (props.onChange) {
      props.onChange(text)
    }
  }

  return (
    <MdEditor
      renderHTML={text => Promise.resolve(<Markdown>{text}</Markdown>)}
      style={{ height: '500px' }}
      value={content}
      onChange={handleEditorChange}
    />
  )
}
