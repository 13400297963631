import { Header, MenuDrawer } from 'components/molecules'
import { FC, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

/**
 * CommonLayoutContainer organisms
 * @constructor
 */
export const CommonLayoutContainer: FC = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false)

  return (
    <Box
      bgGradient="linear(142.8deg, #4968B6 3.25%, #213873 78.42%)"
      boxSizing="border-box"
      minH="100vh"
      padding="0 24px 24px 24px"
      width="100%"
    >
      <Header setShowDrawer={setShowDrawer} />
      <Outlet />

      <MenuDrawer setShowDrawer={setShowDrawer} showDrawer={showDrawer} />
    </Box>
  )
}
