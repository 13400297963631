import { Box, Center, CircularProgress, Flex, Text } from '@chakra-ui/react'
import { GptIcon } from 'components/atoms/Icons'
import { FC } from 'react'
import { ContentPdfAIProps, useContentPdfAI } from './hook'

/**
 * AIAdvice molecules
 * @constructor
 */
export const ContentPdfAI: FC<ContentPdfAIProps> = props => {
  const { data, handleSetPagePdf, loading } = useContentPdfAI(props)

  if (loading) {
    return (
      <Center my="50px">
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Center>
    )
  } else if (!data.length) {
    return (
      <Center>
        <Text>データがありません。</Text>
      </Center>
    )
  }

  return (
    <Box bg="white.base" borderRadius="20px" boxSizing="border-box" w="100%">
      <Flex
        bgColor="gray.600"
        borderRadius={12}
        gap={3}
        minH={'auto'}
        padding={3}
      >
        <Box w={10}>
          <Flex
            alignItems="center"
            bgGradient="linear(160.99deg, #AFA4D0 0.9%, #4474CD 30.15%, #3452A4 92.96%)"
            borderRadius={12}
            h={10}
            justifyContent="center"
            w={10}
          >
            <GptIcon fontSize={24} />
          </Flex>
        </Box>
        <Box w="100%">
          <Text
            color="black.100"
            fontSize="sm"
            fontWeight="normal"
            id="text-element"
            lineHeight="shorter"
            maxH={'calc(100vh - 184px)'}
            overflow={'auto'}
            textAlign="justify"
            whiteSpace={'pre-wrap'}
          >
            {data &&
              data.map((it, key) => (
                <Text key={key} lineHeight="24.5px">
                  <strong>{key + 1}. </strong>
                  <strong>{it?.important_point} </strong>
                  {it?.summary}
                  {it?.pages &&
                    it.pages.map((i: any) => (
                      <>
                        {i && (
                          <Text
                            alignItems="center"
                            as={'span'}
                            backgroundColor="#6FB0ED"
                            borderRadius="24px"
                            color="#fff"
                            cursor="pointer"
                            display="inline-flex"
                            height="20px"
                            justifyContent="center"
                            ml="4px"
                            w="24px"
                            onClick={() => handleSetPagePdf(i, true)}
                          >
                            {i}
                          </Text>
                        )}
                      </>
                    ))}
                </Text>
              ))}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}
