import React, { FC, ReactElement, useRef } from 'react'
import {
  Box,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ModalConfirmDelete } from 'components/fromAdvisor/modules/Modals/ModalConfirmDelete'
import {
  BookmarkActiveIcon,
  BookmarkInActiveIcon,
  TrackIcon,
} from 'components/fromAdvisor/elements/Icons'
import { ListProps } from './ChatHistory.types'

/**
 * HistoryList component page.
 * @return {JSX.Element} HistoryList component.
 */
export const HistoryList: FC<ListProps> = ({
  conversationList,
  onDeleteConversation,
  onChangeBookmark,
  selectedConversation,
  onChangePageSize,
  onSelectConversation,
}): ReactElement => {
  const listRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const {
    isOpen: isOpenModalConfirm,
    onOpen: onOpenModalConfirm,
    onClose: onCloseModalConfirm,
  } = useDisclosure()

  /**
   * @returns function that handle check render icon in list
   */
  const renderIcon = (
    isSelected: boolean,
    isBookmark?: boolean,
  ): ReactElement => {
    if (isSelected) return <TrackIcon color={theme.colors.blue[900]} />
    if (isBookmark) return <BookmarkActiveIcon />
    return <BookmarkInActiveIcon />
  }

  /**
   * @returns function that handle delete item in list and close modal confirm
   */
  const handleDeleteConversation = () => {
    onDeleteConversation()
    onCloseModalConfirm()
  }

  /**
   * @returns function that handle scroll list
   */
  const handleScroll = () => {
    if (listRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = listRef.current
      if (scrollTop + clientHeight >= scrollHeight) {
        onChangePageSize()
      }
    }
  }

  return (
    <>
      <Flex
        ref={listRef}
        flex={1}
        flexDirection={'column'}
        gap={6}
        maxH={'656px'}
        mt={6}
        overflowY={'auto'}
        sx={{
          '&::-webkit-scrollbar': {
            width: theme.space[2],
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.colors.black['150'],
            borderRadius: theme.radii.lg,
          },
        }}
        onScroll={handleScroll}
      >
        {conversationList &&
          conversationList.map(({ date, conversationDetail }) => (
            <Flex key={date} flexDirection={'column'} gap={2}>
              <TextTimeStyled px={6}>{date}</TextTimeStyled>
              <Box>
                {conversationDetail &&
                  conversationDetail.map(
                    ({ conversationId, title, bookmarked }) => {
                      const isSelected = conversationId === selectedConversation
                      return (
                        <Box
                          key={conversationId}
                          _hover={{
                            div: {
                              background: 'white.50',
                              cursor: 'pointer',
                              marginLeft: theme.space[6],
                              marginRight: theme.space[6],
                              borderRadius: theme.radii.xl,
                              paddingRight: theme.space[3],
                            },
                          }}
                        >
                          <Flex
                            alignItems={'center'}
                            bg={isSelected ? 'white.50' : 'transparent'}
                            borderRadius={isSelected ? 'xl' : 'none'}
                            h={10}
                            justifyContent={'space-between'}
                            mx={isSelected ? 6 : 0}
                            pl={6}
                            pr={isSelected ? 3 : 6}
                            onClick={() => onSelectConversation(conversationId)}
                          >
                            <TitleMessageStyled isselected={isSelected} py={2}>
                              {title}
                            </TitleMessageStyled>
                            <IconButton
                              _hover={{
                                transform: 'scale(1.2)',
                              }}
                              aria-label="filter list"
                              bg={'transparent'}
                              h={6}
                              icon={renderIcon(isSelected, bookmarked)}
                              minW={6}
                              onClick={
                                isSelected
                                  ? onOpenModalConfirm
                                  : e => onChangeBookmark(e, conversationId)
                              }
                            />
                          </Flex>
                        </Box>
                      )
                    },
                  )}
              </Box>
            </Flex>
          ))}
      </Flex>
      <ModalConfirmDelete
        isOpen={isOpenModalConfirm}
        title={'この履歴を削除しますか？'}
        onClose={onCloseModalConfirm}
        onSubmit={handleDeleteConversation}
      />
    </>
  )
}

const TextTimeStyled = styled(Text)`
  color: ${props => props.theme.colors.gray[800]};
  opacity: 0.75;
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.lineHeights.shorter};
  font-weight: ${props => props.theme.fontWeights.semibold};
`

const TitleMessageStyled = styled(Text, {
  shouldForwardProp: props => props !== 'isselected',
})<{ isselected?: boolean }>`
  color: ${props => props.theme.colors.black[100]};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.lineHeights.shorter};
  font-weight: ${props =>
    props.isselected
      ? props.theme.fontWeights.semibold
      : props.theme.fontWeights.normal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${props => props.theme.sizes.full};
  width: ${props => props.theme.sizes.full};

  &:hover {
    font-weight: ${props => props.theme.fontWeights.semibold};
  }
`
