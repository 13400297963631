import { useCallback, useEffect, useState } from 'react'
import { chatAIServices } from 'services'

import { useLocation, useNavigate } from 'react-router-dom'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant'
import { CategoryType, ConsultationConverted } from 'services/chatAIServices'
import { useGetQueryParams } from 'navigations/getQueryParams'

/**
 * @returns function that handle any function for consultation list
 */
export const useMyPromptTabContent = () => {
  const navigate = useNavigate()
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [categorySelected, setCategorySelected] = useState<number | null>(null)
  const [myConsultations, setMyConsultations] = useState<
    ConsultationConverted[]
  >([])
  const [listConsultationParams, setListConsultationParams] = useState<{
    free_word: string
    sort_by: string
  }>({
    free_word: '',
    sort_by: 'created_at',
  })
  const [isLoadingFetchData, setIsLoadingFetchData] = useState<boolean>(false)
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const myPromptTab = queryParams.get('myPromptTab')

  /**
   * @returns function that handle fetch categories
   */
  const handleFetchCategories = async () => {
    try {
      const { data } = await chatAIServices.getOptionCategory()
      setCategories(data)
    } catch (error) {}
  }

  useEffect(() => {
    handleFetchCategories()
  }, [])

  /**
   * @returns function that handle favorite item
   */
  const handleCheckFavouriteContent = async (id: number) => {
    const newState = myConsultations?.map(consultation =>
      consultation.id === id
        ? { ...consultation, is_favorite: !consultation.is_favorite }
        : consultation,
    )
    setMyConsultations(newState)

    const findConsultations = myConsultations?.find(
      consultation => consultation.id === id,
    )
    if (!findConsultations) return
    const body = {
      status: !findConsultations.is_favorite,
    }
    await chatAIServices.toggleFavorite(id, body)
  }

  /**
   * @returns function that handle set value search prompt
   */
  const handleSearchPrompt = async (value: string) => {
    setListConsultationParams({
      ...listConsultationParams,
      free_word: value.trim(),
    })
  }

  /**
   * @returns function that handle set value sort prompt
   */
  const handleSortPrompt = async (value: string) => {
    setListConsultationParams({ ...listConsultationParams, sort_by: value })
  }

  /**
   * @returns function that handle fetch consultations
   */
  const handleFetchList = useCallback(async () => {
    const categoryId = categorySelected || categories[Number(myPromptTab)]?.id
    if (!categoryId) return
    setIsLoadingFetchData(true)
    try {
      const params = {
        category_id: categoryId,
        free_word: listConsultationParams.free_word,
        sort_by: listConsultationParams.sort_by,
        type_prompt: 'advisor',
      }
      const { data } = await chatAIServices.getConsultationList(params)

      setMyConsultations(data)
    } finally {
      setIsLoadingFetchData(false)
    }
  }, [categories, categorySelected, myPromptTab, listConsultationParams])

  useEffect(() => {
    handleFetchList()
  }, [handleFetchList])

  /**
   * @returns function that handle push categoryId and myPromptTab on params
   */
  const handleOnChangeTabCategory = (id: number, index: number) => {
    setCategorySelected(id)
    navigateWithQueryParams(navigate, `${ROUTES.CHAT_AI}`, 'myPromptTab', index)
  }

  return {
    isLoadingFetchData,
    handleOnChangeTabCategory,
    handleCheckFavouriteContent,
    categories,
    myConsultations,
    handleSearchPrompt,
    handleSortPrompt,
  }
}
