export enum ROUTES {
  ROOT = '/',
  ID = ':id',
  LOGIN = '/login',
  MY_PAGE = '/chatAI/my-page',
  CHAT_AI = '/chatAI',
  DASHBOARD = '/dashboard',
  COMPANY = '/dashboard/company',
  POST = '/dashboard/post',
  POST_DETAIL = '/dashboard/post',
  CARD_NEWS = '/dashboard/card-news',
  PROMPT_REGISTRATION = '/chatAI/prompt-registration',
  PROMPT_DEATAIL = '/chatAI/prompt',
  PROMPT_EDIT = '/chatAI/prompt-edit',
  COMPANY_LIST = '/chatAI/company-list',
  COMPANY_DEATAIL = '/chatAI/company',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  PASSWORD_SETTING = '/password-setting',
  STOCK_PRICE = '/dashboard/stock-price',
  VERIFY_EMAIL = '/verify-email',
  EXTERNAL_AUTH = '/external-auth',
}

export enum ADMIN_ROUTES {
  ROOT = '/admin',
  ID = ':id',
  LOGIN = '/admin/login',
  USER_LIST = '/admin/user-list',
  USER_DETAIL = '/admin/user',
  USER_EDIT = '/admin/user-edit',
  USER_REGISTRATION = '/admin/user-registration',
  AI_SETTING = '/admin/AI-setting',
  PROMPT_SETTINGS = '/admin/prompt-settings',
  COMPANY_REGISTRATION = '/admin/company-registration',
  COMPANY_EDIT = '/admin/company-edit',
  COMPANY = '/admin/company',
  COMPANY_LIST = '/admin/company-list',
  PRODUCT_REGISTRATION = '/admin/product-registration',
  PRODUCT_DETAIL = '/admin/product',
  PRODUCT_LIST = '/admin/product-list',
  MY_PAGE = '/admin/my-page',
  FORGOT_PASSWORD = '/admin/forgot-password',
  RESET_PASSWORD = '/admin/reset-password',
  PASSWORD_SETTING = '/admin/password-setting',
  VERIFY_EMAIL = '/admin/verify-email',
  PROMPT_REGISTRATION = '/admin/prompt-registration',
  PROMPT_REGISTRATION_COMPANY = '/admin/prompt-registration-company',
  PROMPT_REGISTRATION_NEWS = '/admin/prompt-registration-news',
  PROMPT_DEATAIL = '/admin/prompt',
  PROMPT_EDIT = '/admin/prompt-edit',
  ACCESS_LOG = '/admin/access-log',
  PRODUCT_LOG = '/admin/product-list/product-log',
  CHANGE_LOGS = '/admin/change-logs',
}

export enum ROLE {
  ADVISOR = 'advisor',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}
