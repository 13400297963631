import { errors } from 'constant'
import { yup } from 'libs/yup'

export const initialValues = {
  title: '',
  prompt: '',
  category_id: '',
  constraint_prompt_first: '',
  constraint_prompt_second: '',
  note: '',
  is_tab: '',
}

export const MESSAGE_PROMPT = {
  CREATE_PROMPT: 'プロンプト作成が成功されました。',
  UPDATE_PROMPT: 'プロンプト更新が成功されました。',
  DELETE_PROMPT: 'プロンプト削除が成功されました。',
}
export interface ValuePrompt {
  title: string
  category_id: string
  prompt: string
  constraint_prompt_first: string
  constraint_prompt_second: string
  note: string
  is_tab: string
}

export interface PromptDetail {
  title: string
  category_id: string
  prompt: string
  constraint_prompt_first: string
  constraint_prompt_second: string
  note: string
  created_at: string
  is_tab: string
}

export const promptRegistrationSchema = yup.object({
  title: yup
    .string()
    .required(errors.required)
    .max(255, errors.maxCharacters)
    .label('title'),
  category_id: yup.string().required(errors.required).label('category_id'),
  prompt: yup
    .string()
    .required(errors.required)
    .max(500, errors.maxCharacters)
    .label('prompt'),
  note: yup.string().max(2000, errors.maxCharacters).label('note'),
  // constraint_prompt_first: yup
  //   .string()
  //   .required(errors.required)
  //   .max(500, errors.maxCharacters)
  //   .label('constraint_prompt_first'),
  // constraint_prompt_second: yup
  //   .string()
  //   .required(errors.required)
  //   .max(500, errors.maxCharacters)
  //   .label('constraint_prompt_second'),
})
