import { Plus } from 'components/atoms/Icons'
import { Button } from 'components/fromAdvisor/elements/Button'
import { FC } from 'react'

type SelectFieldProps = {
  onClick?: () => void
}
/**
 * ButtonAddField components
 * @constructor
 */
export const ButtonAddField: FC<SelectFieldProps> = ({ onClick }) => {
  return (
    <Button
      backgroundColor="white.50"
      border="1px solid #0084FF"
      borderRadius="12px"
      color="blue.900"
      fontSize={'14px'}
      fontWeight="700"
      height="40px"
      leftIcon={<Plus fontSize="20px" />}
      mr={'3px'}
      text="追加する"
      width="110px"
      onClick={onClick}
    />
  )
}
