import { Flex, Text } from '@chakra-ui/react'
import InputNumber from 'components/atoms/InputNumber'
import { InitialValuesType } from 'components/molecules/SearchPost/hook'
import React, { Dispatch, SetStateAction } from 'react'

type RangeNumberPopoverProps = {
  title: string
  name: string
  name2: string
  fromNumber: number
  toNumber: number
  setValue: Dispatch<SetStateAction<InitialValuesType>>
}
/**
 * RangeNumberPopover component
 */
const RangeNumberPopover = ({
  title,
  name,
  name2,
  fromNumber,
  toNumber,
  setValue,
}: RangeNumberPopoverProps) => {
  return (
    <>
      <Text
        fontSize="14px"
        fontWeight="bold"
        letterSpacing="0em"
        lineHeight="shorter"
        mb={'8px'}
      >
        {title}
      </Text>
      <Flex alignItems="center">
        <InputNumber
          isInForm={false}
          name={name}
          placeholder={'0.1'}
          value={fromNumber}
          onChange={e => setValue(prev => ({ ...prev, [name]: e }))}
        />
        <Text
          color="black.100"
          display="inline-block"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
          mx="8px"
        >
          〜
        </Text>
        <InputNumber
          isInForm={false}
          name={name2}
          placeholder={'100.0'}
          value={toNumber}
          onChange={e => setValue(prev => ({ ...prev, [name2]: e }))}
        />
      </Flex>
    </>
  )
}

export default RangeNumberPopover
