import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import { Button } from 'components/fromAdvisor/elements/Button'
import { FormikProvider } from 'formik'
import { FC } from 'react'
import { icons } from 'constant'
import moment from 'moment'
import _ from 'lodash'
import { CompanyInformation } from '../components/CompanyInformation'
import { BusinessInfoRegistration } from '../components/BusinessInfoRegistration'
import { PersonnelIntelligence } from '../components/PersonnelIntelligence'
import { FinancialIntelligence } from '../components/FinancialIntelligence'
import { useCompanyRegistration } from './hook'

/**
 * CompanyRegistration Page
 * @constructor
 */
export const CompanyRegistration: FC<{ size: string }> = ({ size }) => {
  const {
    handleAddField,
    handleNextInput,
    getRootProps,
    getInputProps,
    navigate,
    handleGetCities,
    getLocation,
    location,
    loading,
    loadingData,
    loadingUpload,
    options,
    formik,
  } = useCompanyRegistration({ size })
  const { handleSubmit, values, errors, dirty, isValid } = formik

  if (loadingData) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <Box
      bgColor="white.50"
      borderRadius={4}
      minHeight="calc(100vh - 88px)"
      padding="24px 32px 32px 32px"
      w="100%"
    >
      {loadingUpload ? (
        <Center flexDirection={'column'} h={'calc(100vh - 136px)'}>
          <CircularProgress isIndeterminate color="blue.900" size={10} />
          <Text
            fontSize="24px"
            fontWeight="700"
            letterSpacing="-0.48px"
            lineHeight="40px"
          >
            Uploading csv file
          </Text>
        </Center>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingBottom="8px"
          >
            {location.pathname.includes('company-registration') ? (
              <>
                <Text
                  fontSize="24px"
                  fontWeight="700"
                  letterSpacing="-0.48px"
                  lineHeight="40px"
                  minW="158px"
                  px={'8px'}
                >
                  新規企業登録
                </Text>
                <Button
                  {...getRootProps()}
                  bgColor={'white.50'}
                  border="1px solid #0084FF"
                  borderRadius="12px"
                  color="blue.900"
                  fontSize={'14px'}
                  fontWeight={700}
                  height="40px"
                  leftIcon={<Image src={icons.IMPORT} />}
                  lineHeight={'shorter'}
                  text="csv一括登録"
                  width="141px"
                />
                <input {...getInputProps()} type="file" />
              </>
            ) : (
              <Text
                color="#6C7275"
                fontSize="14px"
                fontWeight="400"
                height="40px"
                lineHeight="40px"
                minW="158px"
              >
                登録日：
                {moment(values.created_at).format('YYYY/MM/DD')}
              </Text>
            )}
          </Box>

          <FormikProvider value={formik}>
            <CompanyInformation
              getLocation={getLocation}
              handleGetCities={handleGetCities}
              handleNextInput={handleNextInput}
              optionsCities={options.optionsAllCities}
              optionsStates={options.optionsStates}
            />
            <BusinessInfoRegistration
              handleAddField={handleAddField}
              optionsAllCities={options.optionsAllCities}
              optionsCategories={options.optionsCategory}
              optionsKindOffice={options.optionsKindOffice}
            />
            <PersonnelIntelligence
              handleAddField={handleAddField}
              optionsPosition={options.optionsPosition}
            />
            <FinancialIntelligence handleAddField={handleAddField} />
            <Flex gap="16px" justifyContent="end" marginTop="24px">
              <Button
                backgroundColor="white.50"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="blue.900"
                fontWeight="700"
                height="48px"
                text="キャンセル"
                width="127px"
                onClick={() => navigate(-1)}
              />
              <Button
                backgroundColor="blue.900"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="white.50"
                fontWeight="700"
                height="48px"
                isDisabled={!_.isEmpty(errors) || !dirty || !isValid}
                isLoading={loading}
                text="登録する"
                width="206px"
                onClick={() => handleSubmit()}
              />
            </Flex>
          </FormikProvider>
        </>
      )}
    </Box>
  )
}
