import React, { FC, ReactElement, useState } from 'react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { Box, Text, useToast } from '@chakra-ui/react'
import { InputField } from 'components/atoms/InputField'
import { FormikProvider, useFormik } from 'formik'
import { BodySaveFavorite } from 'services/chatAIServices'
import { chatAIServices } from 'services'
import { useNavigate } from 'react-router-dom'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { ROUTES } from 'constant'

/**
 * FavoriteSaveModal component page.
 * @return {JSX.Element} FavoriteSaveModal component.
 */
export const FavoriteSaveModal: FC<{
  isOpen: boolean
  onClose: () => void
  conversationId?: string | number
  historyId?: string | number
}> = ({ isOpen, onClose, conversationId, historyId }): ReactElement => {
  const toast = useToast()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  /**
   *
   */
  const handleSaveFavorite = async (values: BodySaveFavorite) => {
    if (!values.title.trim()) return
    setIsLoading(true)
    try {
      await chatAIServices.saveFavorite(values)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      toast({
        status: 'error',
        description: error.message,
      })
    }
  }
  const formik = useFormik({
    initialValues: {
      title: '',
      ai_conversation_id: conversationId ?? '',
      ai_conversation_history_id: historyId ?? '',
    },
    /**
     *
     */
    async onSubmit(values: BodySaveFavorite) {
      await handleSaveFavorite(values)
      navigateWithQueryParams(
        navigate,
        `${ROUTES.CHAT_AI}`,
        'tab',
        3,
        'favoritePromptTab',
        1,
      )
    },
  })

  const { setFieldValue, handleSubmit, values } = formik
  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxWidth={'440px'}
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <Box p="32px 40px">
          <Text
            fontSize="20px"
            fontWeight="700"
            lineHeight="32px"
            marginBottom="24px"
          >
            お気に入りに保存
          </Text>
          <InputField
            height="48px"
            label="保存タイトル"
            name="title"
            sizeLabel="14px"
            width="100%"
            onChange={e => setFieldValue('title', e.target.value)}
          />
          <Button
            backgroundColor="#0084FF"
            border="1px solid #0084FF"
            borderRadius="12px"
            color="white.50"
            fontWeight={'bold'}
            height="48px"
            isDisabled={!values.title.trim()}
            isLoading={isLoading}
            marginTop="24px"
            text="保存する"
            width="100%"
            onClick={() => handleSubmit()}
          />
        </Box>
      </FormikProvider>
    </Modal>
  )
}
