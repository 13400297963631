import React, { FC } from 'react'
import { Box, CircularProgress, Flex } from '@chakra-ui/react'
import { SavedConsultationsProps } from './SavedConsultations.type'
import { SavedConsultationBox } from './SavedConsultationBox'

/**
 * SavedConsultationsContainer component page.
 * @return {JSX.Element} SavedConsultationsContainer component.
 */
export const SavedConsultationsContainer: FC<SavedConsultationsProps> = ({
  isLoadingFetchData,
  handleCheckFavouriteContent,
  consultations,
}) => {
  return (
    <Box
      bg={'white.50'}
      borderBottomRadius={4}
      h={'calc(100vh - 217px)'}
      maxW={'calc(100vw - 48px)'}
      w={'full'}
    >
      <Flex
        bg={'gray.600'}
        borderRadius="0 0 4px 4px"
        flexDirection={'column'}
        h={'calc(100% - 24px)'}
        mx={6}
        overflowY={'auto'}
      >
        {isLoadingFetchData ? (
          <Flex alignItems="center" h={'full'} justifyContent="center" w="full">
            <CircularProgress isIndeterminate color="blue.900" size={10} />
          </Flex>
        ) : (
          <Flex flexDirection={'column'} gap="10px" padding="16px">
            {(consultations || []).map((item, index) => (
              <SavedConsultationBox
                key={index}
                handleCheckFavouriteContent={() =>
                  handleCheckFavouriteContent(item.id)
                }
                index={index}
                item={item}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
