import { Center, CircularProgress } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * CardLoading molecules
 * @constructor
 */
export const CardLoading: FC = () => {
  return (
    <Center my="50px">
      <CircularProgress isIndeterminate color="blue.900" size={10} />
    </Center>
  )
}
