import React, { FC, ReactElement } from 'react'
import {
  Modal,
  Checkbox,
  Button,
  DatePicker,
} from 'components/fromAdvisor/elements'
import styled from '@emotion/styled'
import { InputForm, ErrorForm } from 'components/fromAdvisor/modules/Form'
import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import { SearchIcon } from 'components/fromAdvisor/elements/Icons'
import { useFormFilterHistory } from './ModalFilterHistory.hooks'
import { ModalFilterConversationProps } from './ChatHistory.types'

/**
 * ModalFilterHistory component page.
 * @return {JSX.Element} ModalFilterHistory component.
 */
export const ModalFilterHistory: FC<ModalFilterConversationProps> = ({
  isOpenModalFilter,
  onFilterConversation,
  onCloseModalFilter,
  isLoading,
  paramsFilterHistory,
  setParamsFilterHistory,
  setPageCount,
  setConversationList,
}): ReactElement => {
  const theme = useTheme()
  const {
    errors,
    formValue,
    handleSubmit,
    handleInputChange,
    handleInputDateChange,
    handleCloseModal,
  } = useFormFilterHistory(
    onFilterConversation,
    paramsFilterHistory,
    setParamsFilterHistory,
    onCloseModalFilter,
    setPageCount,
    setConversationList,
  )

  return (
    <Modal
      isShowIconClose
      closeOnOverlayClick={false}
      isOpen={isOpenModalFilter}
      maxWidth={'440px'}
      onClose={handleCloseModal}
    >
      <Box p={10}>
        <TextStyled>Chat履歴 絞り込み検索</TextStyled>

        <Flex flexDirection={'column'} gap={4} mb={10}>
          <InputFormStyled
            label={'検索ワード'}
            name={'freeWord'}
            placeholder={'検索ワード'}
            value={formValue?.freeWord}
            onChange={handleInputChange}
          />
          <Box mb={4}>
            <TextLabel>日付</TextLabel>
            <Flex alignItems={'center'}>
              <DatePicker
                selectsStart
                clear={!!formValue?.startDate}
                endDate={formValue?.endDate}
                isInvalid={!!errors?.startDate?.message}
                maxDate={formValue?.endDate}
                name={'startDate'}
                placeholder={'2023/04/15'}
                startDate={formValue?.startDate}
                value={formValue?.startDate}
                onChange={value => {
                  handleInputDateChange('startDate', value)
                }}
                onKeyDown={e => e.preventDefault()}
              />
              <Tilde>〜</Tilde>
              <DatePicker
                selectsEnd
                clear={!!formValue?.endDate}
                endDate={formValue?.endDate}
                isInvalid={!!errors?.endDate?.message}
                minDate={formValue?.startDate}
                name={'endDate'}
                placeholder={'2023/04/15'}
                startDate={formValue?.startDate}
                value={formValue?.endDate}
                onChange={value => {
                  handleInputDateChange('endDate', value)
                }}
                onKeyDown={e => e.preventDefault()}
              />
            </Flex>
            <ErrorForm
              error={errors?.startDate?.message || errors?.endDate?.message}
              lineHeight={'12px'}
              mt={1}
            ></ErrorForm>
          </Box>
          <Flex alignItems={'center'} gap={2}>
            <Checkbox
              isChecked={formValue?.bookmark}
              name={'bookmark'}
              onChange={handleInputChange}
            />
            <LabelText>ブックマークあり</LabelText>
          </Flex>
        </Flex>

        <Flex justifyContent={'center'}>
          <Button
            bg={'blue.900'}
            color={'white.base'}
            h={12}
            isLoading={isLoading}
            leftIcon={<SearchIcon color={theme.colors.white.base} />}
            text={'この条件で検索'}
            w={'full'}
            onClick={handleSubmit}
          />
        </Flex>
      </Box>
    </Modal>
  )
}

const TextStyled = styled(Text)`
  color: ${props => props.theme.colors.black[100]};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.semibold};
  line-height: ${props => props.theme.lineHeights.base};
  margin-bottom: ${props => props.theme.space[6]};
`

const InputFormStyled = styled(InputForm)`
  background: ${props => props.theme.colors.white[50]};
  max-width: ${props => props.theme.sizes[90]};
  height: ${props => props.theme.sizes[12]};
`

const Tilde = styled(Text)`
  color: ${props => props.theme.colors.blue[500]};
  font-size: ${props => props.theme.fontSizes.lg};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: ${props => props.theme.lineHeights[4]};
  letter-spacing: ${props => props.theme.letterSpacings[9]};
  margin-left: ${props => props.theme.space[2]};
  margin-right: ${props => props.theme.space[2]};
`

const TextLabel = styled(Text)`
  color: ${props => props.theme.colors.black[100]};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.lineHeights.shorter};
  font-weight: ${props => props.theme.fontWeights.semibold};
  margin-bottom: ${props => props.theme.space[2]};
`

const LabelText = styled(Text)`
  color: ${props => props.theme.colors.black.base};
  font-size: ${props => props.theme.fontSizes.md};
  font-weight: ${props => props.theme.fontWeights.light};
  letter-spacing: ${props => props.theme.letterSpacings[8]};
  line-height: ${props => props.theme.lineHeights[4]};
`
