import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Plus icon
 * @param props
 * @constructor
 */
export const Plus: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5V19M5 12H19"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        ></path>
      </svg>
    </Icon>
  )
}
