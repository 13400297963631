import { Flex, List, Text } from '@chakra-ui/react'

import { FC } from 'react'
import { RankingProps } from 'types/conmon'
import RenderListItem from './RenderListItem'
import SkeletonLoading from './SkeletonLoading'

type TradingRankingProps = {
  dataRanking: { loading: boolean; data: RankingProps[] }
  rowsPerColumn: number
  separator: boolean
  showScoreChange?: boolean
  isNavigate?: boolean
  isColumn?: boolean
}

/**
 * TradingRanking molecules
 * @constructor
 */
export const TradingRanking: FC<TradingRankingProps> = ({
  dataRanking,
  rowsPerColumn,
  separator,
  showScoreChange = true,
  isNavigate = false,
  isColumn = false,
}) => {
  const leftColumn = dataRanking.data.slice(0, rowsPerColumn)
  const rightColum = dataRanking.data.slice(rowsPerColumn)

  if (dataRanking.loading) {
    return <SkeletonLoading isColumn={isColumn} />
  }
  return (
    <Flex gap={8} pb="4px" w="100%">
      {!dataRanking.data.length ? (
        <Text
          color={'black.100'}
          fontSize={'16px'}
          textAlign={'center'}
          w="full"
        >
          データがありません。
        </Text>
      ) : (
        <>
          {isColumn ? (
            <List display="flex" flexDirection="column" gap={2} w="100%">
              {dataRanking.data.map((item, index) => {
                const stt = index + 1
                const isPositive = Number(item.score) >= Number(item.old_score)
                const scoreChange = Number(item.score) - Number(item.old_score)

                return (
                  <RenderListItem
                    isColumn={true}
                    isNavigate={isNavigate}
                    isPositive={isPositive}
                    item={item}
                    scoreChange={scoreChange}
                    separator={separator}
                    showScoreChange={showScoreChange}
                    stt={stt}
                  />
                )
              })}
            </List>
          ) : (
            <Flex gap="32px" w={'100%'}>
              <List
                display="flex"
                flexDirection="column"
                gap={2}
                w="calc(50% - 16px)"
              >
                {leftColumn.map((item, index) => {
                  const stt = index + 1
                  const isPositive =
                    Number(item.score) >= Number(item.old_score)
                  const scoreChange =
                    Number(item.score) - Number(item.old_score)

                  return (
                    <RenderListItem
                      isNavigate={isNavigate}
                      isPositive={isPositive}
                      item={item}
                      scoreChange={scoreChange}
                      separator={separator}
                      showScoreChange={showScoreChange}
                      stt={stt}
                    />
                  )
                })}
              </List>
              <List
                display="flex"
                flexDirection="column"
                gap={2}
                w="calc(50% - 16px)"
              >
                {rightColum.map((item, index) => {
                  const stt = leftColumn.length + index + 1
                  const isPositive =
                    Number(item.score) >= Number(item.old_score)
                  const scoreChange =
                    Number(item.score) - Number(item.old_score)

                  return (
                    <RenderListItem
                      isNavigate={isNavigate}
                      isPositive={isPositive}
                      item={item}
                      scoreChange={scoreChange}
                      separator={separator}
                      showScoreChange={showScoreChange}
                      stt={stt}
                    />
                  )
                })}
              </List>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}
