import React, { FC, ReactElement } from 'react'
import { Flex, Text, useTheme } from '@chakra-ui/react'
import { Button, Modal } from 'components/fromAdvisor/elements'
import { TrackLargeIcon } from 'components/fromAdvisor/elements/Icons'

export type ModalConfirmDeleteProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  title: string
  textOK?: string
  textCancel?: string
}

/**
 * ModalConfirmDelete component page.
 * @return {JSX.Element} ModalConfirmDelete component.
 */
export const ModalConfirmDelete: FC<ModalConfirmDeleteProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  textOK = 'OK',
  textCancel = 'キャンセル',
}): ReactElement => {
  const theme = useTheme()
  return (
    <Modal isShowIconClose isOpen={isOpen} maxWidth={'360px'} onClose={onClose}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={14} p={10}>
        <Flex alignItems={'center'} flexDirection={'column'} gap={2}>
          <TrackLargeIcon />
          <Text
            color={'blue.500'}
            fontSize={'lg'}
            fontWeight={'semibold'}
            letterSpacing={theme.letterSpacings[9]}
            lineHeight={theme.lineHeights[6]}
            textAlign={'center'}
          >
            {title}
          </Text>
        </Flex>

        <Flex flexDirection={'column'} gap={4} w={'full'}>
          <Button
            bg={'blue.900'}
            borderColor={'blue.900'}
            color={'white.50'}
            h={12}
            text={textOK}
            w={'full'}
            onClick={onSubmit}
          />
          <Button
            borderColor={'blue.900'}
            color={'blue.900'}
            h={12}
            text={textCancel}
            variant="outline"
            w={'full'}
            onClick={onClose}
          />
        </Flex>
      </Flex>
    </Modal>
  )
}
