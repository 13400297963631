import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { forwardRef } from 'react'

export type SwitchProps = Omit<ChakraSwitchProps, 'colorScheme'>
type CusSwitchProps = {
  offtext?: string
  offtextswitch?: string
  islabelinswitch?: boolean
  cusbgcolor?: string
}

/**
 * Switch component page.
 * @return {JSX.Element} Switch component.
 */
export const Switch = forwardRef<
  HTMLInputElement,
  SwitchProps & CusSwitchProps
>(({ ...props }, ref) => {
  const {
    offtext,
    offtextswitch,
    islabelinswitch,
    isReadOnly = false,
    ...rest
  } = props

  const mergedProps: Omit<ChakraSwitchProps, 'colorScheme'> = {
    ...rest,
  }

  return (
    <StyledSwitch
      ref={ref}
      isReadOnly={isReadOnly}
      offtext={offtext}
      offtextswitch={offtextswitch}
      {...mergedProps}
      islabelinswitch={islabelinswitch}
    >
      {islabelinswitch && <div className="status"></div>}
    </StyledSwitch>
  )
})

const StyledSwitch = styled(ChakraSwitch)<CusSwitchProps>`
  .chakra-switch__track {
    width: ${props =>
      props.islabelinswitch ? props.theme.sizes[15] : props.theme.sizes[11]};
    height: ${props => props.theme.sizes[5]};
    background: #e8ecef;
    align-items: center;

    &[aria-checked='true'],
    &[data-checked] {
      background: ${props => props.cusbgcolor ?? props.theme.colors.green[500]};
    }
    box-shadow: none;
    &:focus-visible {
      outline: 0;
      outline-color: transparent;
      box-shadow: none;
    }
  }
  .chakra-switch__thumb {
    width: ${props => props.theme.sizes[5]};
    height: ${props => props.theme.sizes[5]};
    background: ${props => props.theme.colors.white[50]};

    &[data-checked] {
      transform: translateX(
        ${props => (props.islabelinswitch ? '40px' : '24px')}
      );
    }
  }
  .chakra-switch__label {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    text-align: center;
    font-weight: 700;

    &::before {
      content: '${props => (props.isChecked ? props.offtextswitch : '')}';
      margin-left: 8px;
      width: ${props => (props.isChecked ? 30 : 0)}px;
    }
    &::after {
      content: '${props => (!props.isChecked ? props.offtext : '')}';
      margin-right: 6px;
      width: ${props => (!props.isChecked ? 30 : 0)}px;
    }
  }
`
