import { Textarea, TextareaProps, useTheme } from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'
import React from 'react'

export const AutoResizeTextarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>((props, ref) => {
  const theme = useTheme()
  return (
    <Textarea
      ref={ref}
      as={ResizeTextarea}
      minH="unset"
      minRows={1}
      overflow="hidden"
      resize="none"
      sx={{
        '&::-webkit-scrollbar': {
          width: theme.space[4],
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.colors.black['150'],
          borderRadius: theme.radii.lg,
          border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
        },
      }}
      w="full"
      {...props}
    />
  )
})
