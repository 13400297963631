import { Box, Divider, Flex } from '@chakra-ui/react'
import { GptIcon } from 'components/atoms/Icons'
import React, { FC, Fragment } from 'react'

import SkeletonAI from '../SkeletonAI'
import ReactMarkdown from '../ReactMarkdown'

type AIExplanationProps = {
  valueExplanation: { loading: boolean; data: string; done: boolean }
  showContent: boolean
  showDivider?: boolean
}

const AIExplanation: FC<AIExplanationProps> = ({
  showContent,
  showDivider = false,
  valueExplanation,
}) => {
  const { loading, data, done } = valueExplanation
  return (
    <Fragment>
      {showContent ? (
        <>
          {loading ? (
            <>
              <SkeletonAI />
              {showDivider && (
                <Divider
                  borderColor={'#D5D7D8'}
                  my={'16px'}
                  orientation="horizontal"
                />
              )}
            </>
          ) : (
            <>
              <Box
                bg="white.base"
                borderRadius="20px"
                boxSizing="border-box"
                w="100%"
              >
                <Flex bgColor="gray.600" borderRadius={12} gap={3} padding={3}>
                  <Box w={10}>
                    <Flex
                      alignItems="center"
                      bgGradient="linear(160.99deg, #AFA4D0 0.9%, #4474CD 30.15%, #3452A4 92.96%)"
                      borderRadius={12}
                      h={10}
                      justifyContent="center"
                      w={10}
                    >
                      <GptIcon fontSize={24} />
                    </Flex>
                  </Box>
                  <Box>
                    <ReactMarkdown data={data} isCopyBtn={true} isDone={done} />
                  </Box>
                </Flex>
              </Box>
              {showDivider && (
                <Divider
                  borderColor={'#D5D7D8'}
                  my={'16px'}
                  orientation="horizontal"
                />
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default AIExplanation
