import { Box, Flex, Image, Select, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { PaginateResponse } from 'types/conmon'
import { Button } from 'components/fromAdvisor/elements'
import { ArrowDown } from 'components/atoms/Icons'
import { Updater } from '@tanstack/react-table'
import { icons } from 'constant'
import PaginationButton from '../PaginationButton'

type PaginationTableProps = {
  pagination?: PaginateResponse
  setPagination?: React.Dispatch<React.SetStateAction<PaginateResponse>>
  pageIndex: number
  getPageNumber: () => number[]
  rangePage?: number[]
  setPageSize: (updater: Updater<number>) => void
}
/**
 * PaginationTable component
 * @constructor
 */
const PaginationTable: FC<PaginationTableProps> = ({
  pagination,
  setPagination,
  pageIndex,
  getPageNumber,
  rangePage,
  setPageSize,
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" mt="8px">
      <Flex gap={3}>
        <Text
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
        >
          検索結果
        </Text>
        <Text
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
        >
          {pagination?.total_count || 0}件
        </Text>
      </Flex>
      <Flex gap="16px">
        <Box
          alignItems="center"
          cursor={pageIndex <= 1 ? 'not-allowed' : 'pointer'}
          display="flex"
          justifyContent="center"
          width="28px"
          onClick={() => {
            if (pageIndex <= 1) {
              return
            }
            setPagination &&
              setPagination(prev => ({ ...prev, page: prev.page - 1 }))
          }}
        >
          <Image src={icons.NEXT_ICON} />
        </Box>
        <Flex gap="8px">
          {pageIndex >= 3 && (
            <PaginationButton
              handleClick={() => {
                setPagination && setPagination(prev => ({ ...prev, page: 1 }))
              }}
              text="1"
            />
          )}
          {pageIndex > 4 && (
            <PaginationButton
              color="black.100"
              handleClick={() => {}}
              padding="0 0 6px 0"
              text="..."
            />
          )}
          {pageIndex > 1 && (
            <>
              {pageIndex > 3 && (
                <PaginationButton
                  handleClick={() => {
                    setPagination &&
                      setPagination(prev => ({
                        ...prev,
                        page: pageIndex - 2,
                      }))
                  }}
                  text={String(pageIndex - 2)}
                />
              )}
              <PaginationButton
                handleClick={() => {
                  setPagination &&
                    setPagination(prev => ({
                      ...prev,
                      page: pageIndex - 1,
                    }))
                }}
                text={String(pageIndex - 1)}
              />
            </>
          )}
          <Button
            _active={{
              bgColor: '#EDEDED',
              borderRadius: 8,
              color: 'black.100',
              fontWeight: 'bold',
            }}
            _hover={{ borderRadius: 8 }}
            bgColor="transparent"
            border={'none'}
            color="blue.900"
            fontSize="sm"
            h={'28px'}
            isActive={true}
            lineHeight="shorter"
            maxW="28px"
            minW="28px"
            p={0}
            text={String(pageIndex)}
          />

          {pageIndex < getPageNumber().length - 1 && (
            <>
              <PaginationButton
                handleClick={() => {
                  setPagination &&
                    setPagination(prev => ({
                      ...prev,
                      page: pageIndex + 1,
                    }))
                }}
                text={String(pageIndex + 1)}
              />
              {pageIndex !== getPageNumber().length - 2 && (
                <PaginationButton
                  handleClick={() => {
                    setPagination &&
                      setPagination(prev => ({
                        ...prev,
                        page: pageIndex + 2,
                      }))
                  }}
                  text={String(pageIndex + 2)}
                />
              )}
            </>
          )}
          {pageIndex < getPageNumber().length - 3 && (
            <PaginationButton
              color="black.100"
              handleClick={() => {}}
              padding="0 0 6px 0"
              text="..."
            />
          )}
          {pageIndex !== getPageNumber().length &&
            getPageNumber().length !== 0 && (
              <PaginationButton
                handleClick={() => {
                  setPagination &&
                    setPagination(prev => ({
                      ...prev,
                      page: getPageNumber().length,
                    }))
                }}
                text={String(getPageNumber().length)}
              />
            )}
        </Flex>
        <Box
          alignItems="center"
          cursor={
            pageIndex > getPageNumber().length - 1 ? 'not-allowed' : 'pointer'
          }
          display="flex"
          justifyContent="center"
          width="28px"
          onClick={() => {
            if (pageIndex > getPageNumber().length - 1) {
              return
            }
            setPagination &&
              setPagination(prev => ({ ...prev, page: prev.page + 1 }))
          }}
        >
          <Image src={icons.BACK_ICON} />
        </Box>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Text
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="shorter"
        >
          表示件数
        </Text>
        <Select
          _focusVisible={{
            borderColor: 'purple.250',
          }}
          _hover={{
            borderColor: 'blue.900',
          }}
          backgroundColor="white.50"
          border="1px solid #E8ECEF"
          borderRadius="12px"
          boxShadow="0px 5px 10px -5px #1417181A inset"
          cursor="pointer"
          gap="10px"
          h={'40px'}
          icon={<ArrowDown fontSize="24px" />}
          size={'sm'}
          value={pagination?.page_size}
          w="85px"
          onChange={e => {
            setPagination &&
              setPagination(prev => ({
                ...prev,
                page_size: Number(e.target.value),
                page: 1,
              }))
            setPageSize(Number(e.target.value))
          }}
        >
          {rangePage &&
            rangePage.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}件
              </option>
            ))}
        </Select>
      </Flex>
    </Flex>
  )
}

export default PaginationTable
