const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const ROLE = 'role'

/**
 * @return TokenStorage service
 */
export class StorageServices {
  /**
   * @returns function handle set token in cookies
   */
  setAccessToken(accessToken: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
  /**
   * @returns function handle get token in cookies
   */
  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }

  /**
   * @returns function handle set refreshToken in cookies
   */
  setRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }

  /**
   * @returns function handle get refreshToken in cookies
   */
  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  }

  /**
   * @returns function handle remove refreshToken in cookies
   */
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  }
  /**
   * @returns function handle set role user in cookies
   */
  setRoleUser(role: string) {
    localStorage.setItem(ROLE, role)
  }
  /**
   * @returns function handle get role user in cookies
   */
  getRoleUser(): string | null {
    return localStorage.getItem(ROLE)
  }

  /**
   * @returns function handle remove role user in cookies
   */
  removeRoleUser() {
    localStorage.removeItem(ROLE)
  }
}
