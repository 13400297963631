/**
 * BookmarkActiveIcon icon
 * @constructor
 */
export const BookmarkActiveIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.25H8C5.582 2.25 4.25 3.582 4.25 6V21C4.25 21.268 4.39302 21.5149 4.62402 21.6479C4.74002 21.716 4.87 21.75 5 21.75C5.128 21.75 5.25707 21.717 5.37207 21.651L12 17.863L18.6279 21.65C18.8609 21.783 19.147 21.782 19.376 21.647C19.607 21.513 19.75 21.266 19.75 20.999V5.99902C19.75 3.58202 18.418 2.25 16 2.25ZM18.25 19.707L12.3721 16.349C12.1421 16.217 11.8579 16.217 11.6279 16.349L5.75 19.708V6C5.75 4.423 6.423 3.75 8 3.75H16C17.577 3.75 18.25 4.423 18.25 6V19.707Z"
      fill="#0084FF"
    />
    <path
      d="M16 3H8C6 3 5 4 5 6V21L12 17L19 21V6C19 4 18 3 16 3Z"
      fill="#0084FF"
    />
  </svg>
)
