import HOME from '../assets/images/house-blank-light 1.svg'
import BRIEFCASE from '../assets/images/briefcase.svg'
import USER_SETTING from '../assets/images/user-setting.svg'
import FILE_CIRCLE from '../assets/images/file-circle.svg'
import WINDOW_RESTORE from '../assets/images/window-restore.svg'
import DOWNLOAD from '../assets/images/download.svg'
import FILTER_LIST from '../assets/images/filter-list.svg'
import EMPTY_STAR from '../assets/images/empty-star.svg'
import USER_LIST from '../assets/images/user-list.svg'
import AI_SETTING from '../assets/images/ai-setting.svg'
import PROMPT_SETTING from '../assets/images/prompt-setting.svg'
import COMPANY_SETTING from '../assets/images/company-setting.svg'
import PRODUCT_SETTING from '../assets/images/product-setting.svg'
import ACCESS_ANALYSIS from '../assets/images/access-analysis.svg'
import ADD_USER from '../assets/images/add-user.svg'
import BUILDING from '../assets/images/building.svg'
import IMPORT from '../assets/images/import.svg'
import USER from '../assets/images/user.svg'
import MONEY_BILL from '../assets/images/money-bill.svg'
import COMPANY from '../assets/images/company.svg'
import CHECK_CIRCLE_DONE from '../assets/images/check-circle-done.svg'
import CHECK_CIRCLE_FALSE from '../assets/images/check-circle-false.svg'
import ERROR from '../assets/images/error.svg'
import PAPER_CLIP from '../assets/images/paper-clip.svg'
import EMPTY_STAR_COMPANY from '../assets/images/empty-star-company.svg'
import STAR from '../assets/images/star.svg'
import FAVORITE from '../assets/images/favorite-icon.svg'
import HISTORY from '../assets/images/history-icon.svg'
import GLASSES from '../assets/images/glasses.svg'
import NEXT_ICON from '../assets/images/next-icon.svg'
import BACK_ICON from '../assets/images/back-icon.svg'
import EDIT_ICON from '../assets/images/edit-icon.svg'
import ICON_DRAWER from '../assets/images/icons-drawer.svg'
import ICON_DRAWER_OPEN from '../assets/images/icons-drawer-open.svg'

export const icons = {
  HOME,
  BRIEFCASE,
  USER_SETTING,
  FILE_CIRCLE,
  WINDOW_RESTORE,
  DOWNLOAD,
  FILTER_LIST,
  EMPTY_STAR,
  USER_LIST,
  AI_SETTING,
  PROMPT_SETTING,
  COMPANY_SETTING,
  PRODUCT_SETTING,
  ACCESS_ANALYSIS,
  ADD_USER,
  BUILDING,
  IMPORT,
  USER,
  MONEY_BILL,
  COMPANY,
  CHECK_CIRCLE_DONE,
  CHECK_CIRCLE_FALSE,
  ERROR,
  PAPER_CLIP,
  EMPTY_STAR_COMPANY,
  STAR,
  FAVORITE,
  HISTORY,
  GLASSES,
  NEXT_ICON,
  BACK_ICON,
  EDIT_ICON,
  ICON_DRAWER,
  ICON_DRAWER_OPEN,
}
