const optionAnalysisMenu = [
  {
    label: '重要なワードを見える化する',
    value: '0',
  },
  {
    label: '同業種・競合他社と比較する',
    value: '1',
  },
  {
    label: '時系列で分析する',
    value: '2',
  },
]

const optionTargetDocument = [
  { label: '決算短信', value: '1' },
  { label: '有価証券報告書', value: '2' },
  // { label: '四半期報告書', value: '3' },
  // { label: '中期経営計画書', value: '4' },
  { label: '統合報告書', value: '5' },
]

const optionQuarters = [
  {
    label: '第１四半期',
    value: '1',
  },
  {
    label: '第２四半期',
    value: '2',
  },
  {
    label: '第３四半期',
    value: '3',
  },
  {
    label: '第４四半期',
    value: '4',
  },
]

const optionDisplaySize = [
  {
    label: '大',
    value: '0',
  },
  {
    label: '中',
    value: '1',
  },
  {
    label: '小',
    value: '2',
  },
]

const optionNumberOfWord = [
  {
    label: '1語',
    value: '1',
  },
  {
    label: '2語',
    value: '2',
  },
  {
    label: '3語',
    value: '3',
  },
]

const optionsSortBy = [
  {
    label: '昇順',
    value: 1,
  },
  {
    label: '降順',
    value: 0,
  },
]

const optionTerm = [{ label: '5期分', value: '5' }]

const dummyDataAI = `DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。
  
  DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。DALL-E3で画像解析した解説文が入ります。`

export {
  optionAnalysisMenu,
  optionTargetDocument,
  optionQuarters,
  optionDisplaySize,
  optionNumberOfWord,
  optionTerm,
  dummyDataAI,
  optionsSortBy,
}
