import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
  Textarea,
  Text,
  Flex,
} from '@chakra-ui/react'
import React, { ChangeEvent, FC, useEffect, useRef } from 'react'
import { QuestionCircle } from 'components/atoms/Icons'
import { useField } from 'formik'
import './index.css'

export type TextareaHightlightFieldProps = InputProps & {
  name: string
  placeholder?: string
  width?: string
  label?: string
  height?: string
  minHeight?: string
  maxWidth?: string
  error?: boolean | undefined
  disabled?: boolean | undefined
  errorText?: string | false | undefined
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  minWidth?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  iconLable?: boolean
  sizeLabel?: string
  colorLabel?: string
  size?: string
  helperText?: string | false | undefined
  value?: string
  prefix?: string
  subPrefix?: string
  resizeField?: boolean
  touched?: boolean
  maxLength?: number
  fontWeightLabel?: string
  handleFocus?: (index: string) => void
}

/**
 * TextareaField component
 * @constructor
 */
export const TextareaHightlightField: FC<TextareaHightlightFieldProps> = ({
  name,
  label,
  width,
  height,
  minHeight,
  maxWidth,
  error,
  minWidth,
  onChange,
  sizeLabel,
  colorLabel,
  disabled = false,
  iconLable = false,
  resizeField = false,
  placeholder,
  size,
  helperText,
  errorText,
  value,
  prefix,
  subPrefix,
  touched,
  maxLength,
  onFocus,
  fontWeightLabel,
  handleFocus,
}) => {
  const [field, { touched: touchedError }] = useField(name)

  const containerRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<any>(null)
  const mirroredEle = useRef<HTMLDivElement | null>(null)

  const handleInputFocus = () => {
    const currentPosition = textareaRef?.current?.selectionStart

    handleFocus && handleFocus(currentPosition)
  }

  useEffect(() => {
    if (!resizeField || !textareaRef.current) return

    textareaRef.current.style.height = height ?? '180px'

    const scrollHeight = textareaRef.current.scrollHeight + 2

    textareaRef.current.style.height = scrollHeight + 'px'
  }, [value, height, resizeField])

  useEffect(() => {
    if (!textareaRef.current || !containerRef.current) return

    const textarea = textareaRef.current
    const containerEle = containerRef.current

    if (!mirroredEle.current) {
      mirroredEle.current = document.createElement('div')
      mirroredEle.current.classList.add('container__mirror')
      containerEle.prepend(mirroredEle.current)
    }

    mirroredEle.current.textContent = textarea.value

    const textareaStyles = window.getComputedStyle(textarea)
    ;[
      'border',
      'boxSizing',
      'fontFamily',
      'fontSize',
      'fontWeight',
      'letterSpacing',
      'lineHeight',
      'padding',
      'textDecoration',
      'textIndent',
      'textTransform',
      'whiteSpace',
      'wordSpacing',
      'wordWrap',
    ].forEach(property => {
      if (mirroredEle.current) {
        mirroredEle.current.style[property as any] =
          textareaStyles[property as any]
      }
    })

    if (mirroredEle.current) {
      mirroredEle.current.style.borderColor = 'transparent'
    }

    const parseValue = (v: string) =>
      v.endsWith('px') ? parseInt(v.slice(0, -2), 10) : 0

    const borderWidth = parseValue(textareaStyles.borderWidth)

    const ro = new ResizeObserver(() => {
      if (mirroredEle.current) {
        mirroredEle.current.style.width = `${
          textarea.clientWidth + 2 * borderWidth
        }px`
        mirroredEle.current.style.height = `${
          textarea.clientHeight + 2 * borderWidth
        }px`
      }
    })
    ro.observe(textarea)

    const handleScroll = () => {
      if (mirroredEle.current) {
        mirroredEle.current!.scrollTop = textarea.scrollTop
      }
    }

    const handleInput = () => {
      if (mirroredEle.current) {
        const regexp = new RegExp('\\{\\{.*?\\}\\}', 'gi')
        mirroredEle.current.innerHTML = textarea.value.replace(
          regexp,
          '<mark class="container__mark">$&</mark>',
        )
      }
    }

    textarea.addEventListener('scroll', handleScroll)
    textarea.addEventListener('input', handleInput)

    handleInput()

    return () => {
      ro.disconnect()
      textarea.removeEventListener('scroll', handleScroll)
      textarea.removeEventListener('input', handleInput)
    }
  }, [value])

  return (
    <FormControl isInvalid={error}>
      <Box display="flex" justifyContent="space-between">
        <FormLabel
          alignItems="center"
          color={colorLabel}
          display="flex"
          fontSize={sizeLabel}
          fontWeight={fontWeightLabel ? fontWeightLabel : '700'}
          gap="8px"
          lineHeight="24px"
          margin="0 0 8px 0"
        >
          {label}
          {iconLable && <QuestionCircle />}
        </FormLabel>
        {touched === false && value && maxLength && (
          <>
            <Text color="blue.900">
              残{maxLength - Number(value.length)}文字
            </Text>
          </>
        )}
      </Box>
      <Box position="relative">
        {prefix && (
          <Flex
            alignItems="center"
            gap="6px"
            left="16px"
            position="absolute"
            top="8px"
            zIndex="9"
          >
            <Text
              color="#141718"
              fontSize="18px"
              fontWeight="700"
              lineHeight="24px"
            >
              {prefix}
            </Text>
            {prefix && (
              <Text
                color="#141718"
                fontSize="14px"
                lineHeight="24px"
                opacity="0.3"
              >
                {subPrefix}
              </Text>
            )}
          </Flex>
        )}
        <div ref={containerRef} className="container" id="container">
          <Textarea
            id="textarea"
            {...field}
            ref={textareaRef}
            _disabled={{ backgroundColor: '#F3F5F7', color: 'gray.800' }}
            _invalid={{
              border: touchedError ? '1px solid #F75555' : '1px solid #E8ECEF',
            }}
            _placeholder={{ color: 'gray.800', opacity: 0.5 }}
            backgroundColor="transparent"
            borderColor="purple.250"
            borderRadius="xl"
            boxShadow="0px 5px 10px -5px rgba(20, 23, 24, 0.10) inset"
            className="container__textarea"
            contentEditable={false}
            disabled={disabled}
            focusBorderColor="blue.900"
            height={height}
            lineHeight="24px"
            maxLength={maxLength}
            maxWidth={maxWidth}
            minHeight={minHeight}
            minW={minWidth}
            name={name}
            overflow="hidden"
            paddingX="14px"
            placeholder={placeholder}
            resize="none"
            size={size}
            sx={{
              paddingTop: prefix ? '36px' : '8px',
            }}
            value={value}
            width={width}
            onChange={onChange}
            onFocus={onFocus}
            onKeyUp={handleInputFocus}
            onMouseLeave={handleInputFocus}
            onMouseUp={handleInputFocus}
          />
        </div>
      </Box>
      {!(error && touchedError) ? (
        <FormHelperText mt={0}>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage color={'#F75555'}>{errorText}</FormErrorMessage>
      )}
    </FormControl>
  )
}
