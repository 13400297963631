import React, { FC } from 'react'
import { ImageProps, Image } from '@chakra-ui/react'

type AvatarProps = ImageProps

/**
 * Avatar component page.
 * @return {JSX.Element} Avatar component.
 */
export const Avatar: FC<AvatarProps> = props => (
  <Image
    alt={'avatar'}
    borderRadius={'xl'}
    height={'full'}
    objectFit="cover"
    width={'full'}
    {...props}
  />
)
