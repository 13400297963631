import React, { FC } from 'react'
import { Flex, Image, List, ListItem, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import { FormikProvider } from 'formik'
import { ROUTES, icons } from 'constant'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import {
  NUMBER_COMPANY_SEARCH,
  SearchCompanyProps,
  useSearchCompany,
} from './hook'

/**
 * SearchCompany molecules
 * @constructor
 */
export const SearchCompany: FC<SearchCompanyProps> = props => {
  const {
    formik,
    optionsCompany,
    handleChangeHistorySearchLog,
    companySearchLog,
    navigate,
    handleScrollBottom,
    handleSearchCmpany,
    fetchDataCompanyWithKey,
    isLoading,
  } = useSearchCompany(props)

  return (
    <FormikProvider value={formik}>
      <Flex
        alignItems="center"
        bgColor="white.base"
        borderRadius={4}
        gap="16px"
        justifyContent="space-between"
        padding="12px 24px"
        width="100%"
        zIndex={3}
      >
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Flex alignItems="center" gap="32px" width="calc(100% - 24px)">
            <Text
              color="black.100"
              display="inline-block"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
              minW={'fit-content'}
              px="8px"
            >
              企業検索
            </Text>
            <Flex alignItems="center" gap="10px">
              <SelectField
                isSearchable
                handleScrollBottom={handleScrollBottom}
                height="40px"
                isLoading={isLoading}
                name="selectCompany"
                options={optionsCompany ?? []}
                placeholder="会社名"
                width="242px"
                onChange={e => {
                  formik.setFieldValue('selectCompany', e?.value)
                  if (e?.value) {
                    handleChangeHistorySearchLog(e.value)
                  }
                }}
                onInputChange={key => {
                  handleSearchCmpany(key)
                  fetchDataCompanyWithKey(key)
                }}
              />
              <Image src={icons.HISTORY} />
              <List display="flex" gap="24px" width="calc(100% - 258px)">
                {companySearchLog.map((company, index) => (
                  <ListItem
                    key={index}
                    color="blue.900"
                    cursor="pointer"
                    display="inline-block"
                    fontSize="sm"
                    fontWeight="normal"
                    lineHeight="24px"
                    maxW={`calc((100vw - 560px) / ${NUMBER_COMPANY_SEARCH})`}
                    onClick={() =>
                      navigate(`${ROUTES.COMPANY}/${company.company_id}`)
                    }
                  >
                    <TextOverflowTooltip
                      isCursor
                      value={company.company_name}
                    />
                  </ListItem>
                ))}
              </List>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </FormikProvider>
  )
}
