import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * GptIcon icon
 * @param props
 * @constructor
 */
export const GptIcon: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="20"
        viewBox="0 0 18 16"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.194 2.139c.034.263.07.526.098.791.047.42.042.416.377.174a131.5 131.5 0 011.467-1.028c.098-.07.145-.117.085-.258a1.203 1.203 0 010-.975.785.785 0 01.46-.46.767.767 0 01.645.054c.552.26.839 1.112.554 1.646a.808.808 0 01-.605.462.79.79 0 01-.715-.258c-.126-.13-.198-.147-.34-.02a32.314 32.314 0 00-1.664 1.564.203.203 0 00-.053.212c.438 1.917 1.175 3.7 2.384 5.264a10.974 10.974 0 003.205 2.791c.4.23.815.439 1.243.643-.133.07-.277.12-.425.147-.849.253-1.73.382-2.615.382-1.092-.023-2.091-.308-2.904-1.088a4.784 4.784 0 01-1.24-2.173c-.402-1.357-.468-2.751-.411-4.156.017-.412.043-.824.066-1.26a4.032 4.032 0 00-.483.465 73.255 73.255 0 00-4.946 5.454 24.095 24.095 0 00-2.534 3.7 7.018 7.018 0 00-.754 2.002c-.102.454-.07.928.088 1.366.01.029.016.059.02.09-.785-.115-1.532-.276-2.094-.882-.422-.452-.47-1.011-.418-1.59.08-.954.488-1.784.993-2.571a18.195 18.195 0 012.938-3.374 47.031 47.031 0 013.948-3.266 86.475 86.475 0 013.228-2.301.29.29 0 00.144-.23c.062-.438.137-.875.207-1.312l.051-.005z"
          fill="#fff"
        ></path>
        <path
          d="M10.565 7.027c-.133.481-.29.954-.421 1.43a7.663 7.663 0 00-.305 2.195c.026 1.004.324 1.891 1.13 2.534a4.32 4.32 0 001.954.81c.322.06.647.098.973.116-.118.079-.25.134-.388.162-.918.323-1.866.554-2.828.69-.674.08-1.345.107-1.99-.162s-.943-.763-1.01-1.436c-.097-.916.177-1.758.539-2.574a17.668 17.668 0 012.033-3.345l.313-.42z"
          fill="#fff"
        ></path>
      </svg>
    </Icon>
  )
}
