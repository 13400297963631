import { ADMIN_ROUTES } from 'constant'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export type AddVariableModalProps = {
  isOpen: boolean
  onClose: () => void
  onAddVariable: (e: string) => void
  isTab: string
}

const dataVariableNews = [
  'current_news.news_id',
  'current_news.news_title',
  'current_news.news_body',
  'current_news.company.company_name',
  'current_news.company.company_name_en',
  'current_news.company.category.name',
]

const dataVariableCompany = [
  'current_company.company_id',
  'current_company.company_name',
  'current_company.company_name_en',
  // 'current_company.category_name.id',
  // 'current_company.category_name.name',
  'current_company.category_name',
  'current_company.company_news.0.news_id',
  'current_company.company_news.0.news_title',
  'current_company.company_news.0.news_body',
  'current_company.company_news.1.news_id',
  'current_company.company_news.1.news_title',
  'current_company.company_news.1.news_body',
  'current_company.company_news.2.news_id',
  'current_company.company_news.2.news_title',
  'current_company.company_news.2.news_body',
  'current_company.company_news.3.news_id',
  'current_company.company_news.3.news_title',
  'current_company.company_news.3.news_body',
  'current_company.company_news.4.news_id',
  'current_company.company_news.4.news_title',
  'current_company.company_news.4.news_body',
  'current_company.category_news.0.news_id',
  'current_company.category_news.0.news_title',
  'current_company.category_news.0.news_body',
  'current_company.category_news.1.news_id',
  'current_company.category_news.1.news_title',
  'current_company.category_news.1.news_body',
  'current_company.category_news.2.news_id',
  'current_company.category_news.2.news_title',
  'current_company.category_news.2.news_body',
  'current_company.category_news.3.news_id',
  'current_company.category_news.3.news_title',
  'current_company.category_news.3.news_body',
  'current_company.category_news.4.news_id',
  'current_company.category_news.4.news_title',
  'current_company.category_news.4.news_body',
]

export const useAddVariableModal = (props: AddVariableModalProps) => {
  const { isTab } = props
  const { id } = useParams()
  const { pathname } = useLocation()
  const formik = useFormik({
    initialValues: {
      variable_name: '',
    },
    /**
     *
     */
    async onSubmit(values: { variable_name: string }) {
      console.log('values', values)
    },
  })

  const dummyVariable = useMemo(() => {
    switch (pathname) {
      case ADMIN_ROUTES.PROMPT_REGISTRATION_COMPANY:
        return dataVariableCompany

      case ADMIN_ROUTES.PROMPT_REGISTRATION_NEWS:
        return dataVariableNews

      case `${ADMIN_ROUTES.PROMPT_DEATAIL}/${id}`:
      case `${ADMIN_ROUTES.PROMPT_EDIT}/${id}`:
        if (String(isTab) === '2') return dataVariableCompany
        if (String(isTab) === '3') return dataVariableNews
        return []

      default:
        return []
    }
  }, [id, isTab, pathname])

  const dummyOption = dummyVariable.map(option => ({
    label: option,
    value: `{{${option}}}`,
  }))

  const { values, setFieldValue } = formik
  return { ...props, values, setFieldValue, formik, dummyOption }
}
