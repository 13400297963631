import React, { FC } from 'react'
import { Box, Flex, IconButton } from '@chakra-ui/react'
import {
  LeftDrawerIcon,
  RightDrawerIcon,
} from 'components/fromAdvisor/elements/Icons'
import styled from '@emotion/styled'
import {
  ChatHistoryContainer,
  useChatHistory,
} from './components/ChatHistoryList'
import {
  ConsultationContentContainer,
  useConsultationContentList,
} from './components/ConsultationContentList'
import { MainChatContainer, useMainChat } from './components/MainChat'

/**
 * ChatAI component page.
 * @return {JSX.Element} ChatAI component.
 */
export const ChatAIContainer: FC = () => {
  const {
    answerEdit,
    conversation,
    conversationList,
    isLoadingEdit,
    isOpenDrawerLeft,
    isOpenModalFilter,
    isLoadingFetchDetail,
    paramsFilterHistory,
    setParamsFilterHistory,
    handleNewChat,
    setConversation,
    handleEditAnswer,
    setConversationList,
    onOpenDrawerLeft,
    onCloseDrawerLeft,
    onOpenModalFilter,
    handleRatingAnswer,
    onCloseModalFilter,
    handleSelectHistory,
    handleChangeBookmark,
    handleChangePageSize,
    handleDeleteConversation,
    handleSelectConversation,
    handleFilterConversation,
    handleOnChangeInputEditAnswer,
    handleDeleteFavouriteAnswer,
    setPageCount,
  } = useChatHistory()
  const {
    question,
    isAskMore,
    isEditAnswer,
    messageTyping,
    questionAskMore,
    isLoadingSendMessage,
    isLoadingSendAskMore,
    setQuestion,
    handleAskMore,
    handlePressEnter,
    handleCancelEdit,
    handleSendQuestion,
    handlePressEnterAskMore,
    handleClickIconEditAnswer,
    handleSendQuestionAskMore,
    handleOnChangeInputAskMore,
    setQuestionAskMore,
    setIsAgreeConsult,
    consultationId,
    setConsultationId,
    handlePreventLoadingAnswer,
  } = useMainChat(
    setConversation,
    setConversationList,
    conversation?.conversationId,
  )
  const {
    isLoading,
    consultationList,
    selectedCategory,
    isOpenDrawerRight,
    isShowModalSelectArticle,
    consultation,
    onOpenDrawerRight,
    handleSelectPrompt,
    onCloseDrawerRight,
    handleChangeFavorite,
    handleSelectCategory,
    setIsShowModalSelectArticle,
    optionCategory,
    setConsultation,
  } = useConsultationContentList(
    setQuestion,
    setIsAgreeConsult,
    consultationId,
    setConsultationId,
  )

  return (
    <Flex
      bg={'white.50'}
      borderBottomLeftRadius={'2xl'}
      borderBottomRightRadius={'2xl'}
      h={`calc(100vh - 146px)`}
    >
      {!isOpenDrawerLeft && (
        <WrapperButtonIconLeft>
          <IconButton
            _hover={{
              background: 'transparent',
            }}
            aria-label="left drawer"
            bg={'transparent'}
            h={6}
            icon={<LeftDrawerIcon />}
            minW={6}
            onClick={onOpenDrawerLeft}
          />
        </WrapperButtonIconLeft>
      )}
      <WrapperDrawer isopen={isOpenDrawerLeft}>
        <ChatHistoryContainer
          conversationList={conversationList}
          isLoading={isLoadingEdit}
          isOpenModalFilter={isOpenModalFilter}
          paramsFilterHistory={paramsFilterHistory}
          selectedConversation={conversation?.conversationId}
          setConversationList={setConversationList}
          setPageCount={setPageCount}
          setParamsFilterHistory={setParamsFilterHistory}
          onChangeBookmark={handleChangeBookmark}
          onChangePageSize={handleChangePageSize}
          onCloseDrawerLeft={onCloseDrawerLeft}
          onCloseModalFilter={onCloseModalFilter}
          onDeleteConversation={() => {
            handleDeleteConversation().then(() => setQuestion(''))
          }}
          onFilterConversation={handleFilterConversation}
          onNewChat={() => {
            setQuestion('')
            handlePreventLoadingAnswer()
            handleNewChat()
            setConsultationId(null)
            setConsultation(undefined)
            if (isAskMore) handleAskMore()
          }}
          onOpenModalFilter={() => {
            if (isLoadingSendMessage || isLoadingSendAskMore) return
            onOpenModalFilter()
          }}
          onSelectConversation={conversationId => {
            if (isAskMore) handleAskMore()
            setQuestion('')

            setConsultationId(null)
            setConsultation(undefined)
            handleSelectConversation(conversationId)
            handleCancelEdit()
          }}
        />
      </WrapperDrawer>

      <MainChatContainer
        answerEdit={answerEdit}
        conversation={conversation}
        handleDeleteFavouriteAnswer={handleDeleteFavouriteAnswer}
        isAskMore={isAskMore}
        isEditAnswer={isEditAnswer}
        isLoadingEdit={isLoadingEdit}
        isLoadingFetchDetail={isLoadingFetchDetail}
        isLoadingSendAskMore={isLoadingSendAskMore}
        isLoadingSendMessage={isLoadingSendMessage}
        isShowModalSelectArticle={isShowModalSelectArticle}
        messageTyping={messageTyping}
        question={question}
        questionAskMore={questionAskMore}
        setIsShowModalSelectArticle={setIsShowModalSelectArticle}
        setQuestion={setQuestion}
        setQuestionAskMore={setQuestionAskMore}
        onAskMore={handleAskMore}
        onCancelEdit={handleCancelEdit}
        onChangeInputAskMore={handleOnChangeInputAskMore}
        onChangeInputEdit={handleOnChangeInputEditAnswer}
        onClickIconEditAnswer={handleClickIconEditAnswer}
        onEditAnswer={handleEditAnswer}
        onEnter={handlePressEnter}
        onEnterAskMore={handlePressEnterAskMore}
        onNewChat={() => {
          setQuestion('')
          handleNewChat()
          if (isAskMore) handleAskMore()
        }}
        onRatingAnswer={handleRatingAnswer}
        onSelectHistory={handleSelectHistory}
        onSubmit={handleSendQuestion}
        onSubmitAskMore={handleSendQuestionAskMore}
      />

      <WrapperDrawer isopen={isOpenDrawerRight}>
        <ConsultationContentContainer
          consultation={consultation}
          consultationList={consultationList}
          isLoading={isLoading}
          optionCategory={optionCategory}
          selectedCategory={selectedCategory}
          onChangeFavorite={handleChangeFavorite}
          onCloseDrawerRight={onCloseDrawerRight}
          onSelectCategory={handleSelectCategory}
          onSelectPrompt={id => {
            if (isLoadingSendMessage || isLoadingSendAskMore) return
            handleNewChat()
            handleSelectPrompt(id)
          }}
        />
      </WrapperDrawer>
      {!isOpenDrawerRight && (
        <WrapperButtonIconRight>
          <IconButton
            _hover={{
              background: 'transparent',
            }}
            aria-label="left drawer"
            bg={'transparent'}
            h={6}
            icon={<RightDrawerIcon />}
            minW={6}
            onClick={onOpenDrawerRight}
          />
        </WrapperButtonIconRight>
      )}
    </Flex>
  )
}

const WrapperDrawer = styled(Box, {
  shouldForwardProp: props => props !== 'isopen',
})<{ isopen: boolean }>`
  height: ${props => props.theme.sizes.full};
  max-width: ${props => props.theme.sizes[80]};
  overflow: hidden;
  transition: width 0.3s ease;
  width: ${props => (props.isopen ? props.theme.sizes.full : 0)};
`

const WrapperButtonIcon = styled(Flex)`
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.blue[800]};
  border-radius: ${props => props.theme.radii.xl};
  height: ${props => props.theme.sizes[12]};
  min-width: ${props => props.theme.sizes[12]};
  margin-top: ${props => props.theme.space[8]};
  margin-bottom: ${props => props.theme.space[8]};
`

const WrapperButtonIconLeft = styled(WrapperButtonIcon)`
  margin-left: ${props => props.theme.space[6]};
`

const WrapperButtonIconRight = styled(WrapperButtonIcon)`
  margin-right: ${props => props.theme.space[6]};
`
