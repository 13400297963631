import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
  Textarea,
  Text,
  Flex,
} from '@chakra-ui/react'
import React, { ChangeEvent, FC, useEffect } from 'react'
import { QuestionCircle } from 'components/atoms/Icons'
import { useField } from 'formik'

export type TextareaFieldProps = InputProps & {
  name: string
  placeholder?: string
  width?: string
  label?: string
  height?: string
  minHeight?: string
  maxWidth?: string
  error?: boolean | undefined
  disabled?: boolean | undefined
  errorText?: string | false | undefined
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  minWidth?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  iconLable?: boolean
  sizeLabel?: string
  colorLabel?: string
  size?: string
  helperText?: string | false | undefined
  value?: string
  prefix?: string
  subPrefix?: string
  resizeField?: boolean
  touched?: boolean
  maxLength?: number
  fontWeightLabel?: string
}

/**
 * TextareaField component
 * @constructor
 */
export const TextareaField: FC<TextareaFieldProps> = ({
  name,
  label,
  width,
  height,
  minHeight,
  maxWidth,
  error,
  minWidth,
  onChange,
  sizeLabel,
  colorLabel,
  disabled = false,
  iconLable = false,
  resizeField = false,
  placeholder,
  size,
  helperText,
  errorText,
  value,
  prefix,
  subPrefix,
  touched,
  maxLength,
  onFocus,
  fontWeightLabel,
}) => {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  const [field, { touched: touchedError }] = useField(name)

  useEffect(() => {
    if (!resizeField || !textareaRef.current) return
    textareaRef.current.style.height = height ?? '180px'
    const scrollHeight = textareaRef.current.scrollHeight + 2
    textareaRef.current.style.height = scrollHeight + 'px'
  }, [value, height, resizeField])

  return (
    <FormControl isInvalid={error}>
      <Box display="flex" justifyContent="space-between">
        <FormLabel
          alignItems="center"
          color={colorLabel}
          display="flex"
          fontSize={sizeLabel}
          fontWeight={fontWeightLabel ? fontWeightLabel : '700'}
          gap="8px"
          lineHeight="24px"
          margin="0 0 8px 0"
        >
          {label}
          {iconLable && <QuestionCircle />}
        </FormLabel>
        {touched === false && value && maxLength && (
          <>
            <Text color="blue.900">
              残{maxLength - Number(value.length)}文字
            </Text>
          </>
        )}
      </Box>
      <Box position="relative">
        {prefix && (
          <Flex
            alignItems="center"
            gap="6px"
            left="16px"
            position="absolute"
            top="8px"
            zIndex="9"
          >
            <Text
              color="#141718"
              fontSize="18px"
              fontWeight="700"
              lineHeight="24px"
            >
              {prefix}
            </Text>
            {prefix && (
              <Text
                color="#141718"
                fontSize="14px"
                lineHeight="24px"
                opacity="0.3"
              >
                {subPrefix}
              </Text>
            )}
          </Flex>
        )}
        <Textarea
          {...field}
          ref={textareaRef}
          _disabled={{ backgroundColor: '#F3F5F7', color: 'gray.800' }}
          _invalid={{
            border: touchedError ? '1px solid #F75555' : '1px solid #E8ECEF',
          }}
          _placeholder={{ color: 'gray.800', opacity: 0.5 }}
          backgroundColor="white.50"
          borderColor=" purple.250"
          borderRadius="xl"
          boxShadow="0px 5px 10px -5px rgba(20, 23, 24, 0.10) inset"
          className="withprefix"
          contentEditable={false}
          disabled={disabled}
          focusBorderColor="blue.900"
          height={height}
          lineHeight="24px"
          maxLength={maxLength}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minW={minWidth}
          name={name}
          overflow="hidden"
          paddingX="14px"
          placeholder={placeholder}
          resize="none"
          size={size}
          sx={{
            paddingTop: prefix ? '36px' : '8px',
          }}
          value={value}
          width={width}
          onChange={onChange}
          onFocus={onFocus}
        />
      </Box>
      {!(error && touchedError) ? (
        <FormHelperText mt={0}>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage color={'#F75555'}>{errorText}</FormErrorMessage>
      )}
    </FormControl>
  )
}
