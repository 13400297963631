export const optionsPdf = [
  { label: '重要なワードを可視化する', value: '0' },
  { label: '同業種・競合他社と比較する', value: '1' },
  { label: '時系列で分析する', value: '2' },
]

export const options = [{ label: '重要ワードを可視化する', value: '0' }]

export const optionQuarters = [
  {
    label: '第１四半期',
    value: '1',
  },
  {
    label: '第２四半期',
    value: '2',
  },
  {
    label: '第３四半期',
    value: '3',
  },
  {
    label: '第４四半期',
    value: '4',
  },
]

export const optionsSortBy = [
  {
    label: '昇順',
    value: 'asc',
  },
  {
    label: '降順',
    value: 'desc',
  },
]

export const optionTerm = [{ label: '5期分', value: '5' }]
