import { BasicResponse } from 'types/conmon'
import { axiosWithFile, axiosWithToken } from 'libs/configureAxios'
import {
  GetListUserRequest,
  UserResponse,
  BodyCreateUser,
  ChangePasswordType,
} from './userServices.type'

/**
 * @returns function that handle news services
 */
export class UserAdminServices {
  /**
   * @returns function that handle save create user service
   */
  async createUser(
    body: BodyCreateUser,
    params: {
      call_back: string
    },
  ) {
    return await axiosWithToken.post('/v1/admin/advisor/create-account', body, {
      params,
    })
  }

  /**
   * @returns function that handle save get id user service
   */
  async getUsertDetail(id: string): Promise<BasicResponse<UserResponse>> {
    return (await axiosWithToken.get(`/v1/admin/advisor/${id}`)).data
  }

  /**
   * @returns function that handle save user service
   */
  async updateUser(
    id: string,
    body: BodyCreateUser,
    params: {
      call_back: string
    },
  ) {
    return (
      await axiosWithToken.put(`/v1/admin/advisor/${id}`, body, {
        params,
      })
    ).data
  }

  /**
   * @returns function that handle save user service
   */
  async removeUser(ids: number[]) {
    return await axiosWithToken.delete(`/v1/admin/advisor/`, {
      data: { ids },
    })
  }

  /**
   * @returns function that handle get list user service
   */
  async getListUser(
    params: GetListUserRequest,
  ): Promise<BasicResponse<UserResponse[]>> {
    return (await axiosWithToken.get(`/v1/admin/advisor`, { params })).data
  }

  /**
   * @returns function that handle get list user service
   */
  async updateMyProfile(size: string, body: BodyCreateUser) {
    return await axiosWithToken.put(`/v1/${size}/profile/me`, body)
  }

  /**
   * @returns function that handle upload file
   */
  uploadAvatar = async (size: string, body: FormData) =>
    await axiosWithFile.patch(`/v1/${size}/profile/upload_avatar`, body)

  /**
   * @returns function that handle save user service
   */
  async changePassword(size: string, body: ChangePasswordType) {
    return await axiosWithToken.patch(`v1/${size}/oauth/change_password`, body)
  }

  /**
   * @returns function that handle save user service
   */
  async logout(size: string) {
    return await axiosWithToken.post(`v1/${size}/oauth/logout`)
  }
}
