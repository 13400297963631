import { PostDetailContainer } from 'components/organisms'
import { FC } from 'react'

/**
 * PostDetail page
 * @constructor
 */
export const PostDetail: FC = () => {
  return <PostDetailContainer />
}
