import { ExtractPdfItemType } from 'services/newServices'

export type ContentPdfAIProps = {
  handleSetPagePdf: (e: string, type?: boolean) => void
  data: ExtractPdfItemType[]
  loading: boolean
}

export const useContentPdfAI = (props: ContentPdfAIProps) => {
  return {
    ...props,
  }
}
