import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * QuestionCircle icon
 * @param props
 * @constructor
 */
export const QuestionCircle: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.326C6.306 1.674 1.674 6.306 1.674 12c0 5.694 4.632 10.326 10.326 10.326 5.694 0 10.326-4.632 10.326-10.326 0-5.694-4.632-10.326-10.326-10.326zm.794 11.849c.026-.08.178-.386.995-.932 1.318-.883 1.907-2.145 1.663-3.554-.248-1.423-1.408-2.592-2.821-2.84a3.507 3.507 0 00-2.874.76 3.528 3.528 0 00-1.262 2.711.838.838 0 001.674 0c0-.554.243-1.075.665-1.429.42-.351.955-.495 1.505-.395.721.127 1.336.75 1.464 1.48.048.275.194 1.112-.946 1.874-.923.62-1.447 1.188-1.65 1.791a.837.837 0 001.587.534zm.345 3.5c0-.616-.5-1.116-1.117-1.116h-.01c-.617 0-1.112.5-1.112 1.116 0 .616.506 1.117 1.122 1.117.617 0 1.117-.5 1.117-1.117z"
          fill="#0084FF"
        ></path>
      </svg>
    </Icon>
  )
}
