import moment from 'moment'

/**
 * @returns function  get options year
 */
export const yearOptions = (start: number, stop: number, step: number) => {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => ({
    value: (start + i * step).toString(),
    label: (start + i * step).toString(),
  }))
}

/**
 * @returns function get options month
 */
export const monthOptionsCompany = Array.from(Array(12).keys()).map(item => ({
  label: (item + 1).toString(),
  value: String(item + 1).padStart(2, '0'),
}))

/**
 * @returns function get options month
 */
export const optionsOld = Array.from(Array(100).keys()).map(item => ({
  label: (item + 1).toString(),
  value: item + 1,
}))

/**
 * @returns function get options month
 */
export const monthOptions = (year?: string) => {
  const currenYear = moment().year()
  const currenMonth = moment().month() + 1
  if (!year) {
    return []
  }
  if (Number(year) < currenYear)
    return Array.from(Array(12).keys()).map(item => ({
      label: (item + 1).toString(),
      value: String(item + 1).padStart(2, '0'),
    }))
  else {
    return Array.from(Array(currenMonth).keys()).map(item => ({
      label: (item + 1).toString(),
      value: String(item + 1).padStart(2, '0'),
    }))
  }
}

/**
 * @returns function get options day
 */
export const dayInMonthOptions = (
  year?: number | string,
  month?: number | string,
) => {
  const days = moment(
    `${year ? year : moment().year()}-${month ? month : moment().month() + 1}`,
    'YYYY-MM',
  ).daysInMonth()

  const currenYear = moment().year()
  const currenMonth = moment().month() + 1
  if (!month) {
    return []
  }
  const isCheckDate =
    Number(year) === currenYear && Number(month) === currenMonth

  return Array.from(Array(isCheckDate ? moment().date() : days).keys()).map(
    item => ({
      label: (item + 1).toString(),
      value: String(item + 1).padStart(2, '0'),
    }),
  )
}

export const optionsTypeUser: {
  value: string
  label: string
}[] = [
  { value: 'advisor', label: '担当者' },
  { value: 'admin', label: 'マネージャー' },
]

/**
 * @returns function get options
 */
export const importanceOption = Array.from(Array(5).keys()).map(item => ({
  label: `レベル${(item + 1).toString()}`,
  value: (item + 1).toString(),
}))
