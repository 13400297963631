import {
  Box,
  Flex,
  Tab,
  Text,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
} from '@chakra-ui/react'
import { InputField } from 'components/atoms'
import { Button } from 'components/fromAdvisor/elements/Button'
import { ADMIN_ROUTES } from 'constant'
import { FormikProvider, useFormik } from 'formik'
import { FC, ReactNode, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { PaginateResponse } from 'types/conmon'
import { ChatAI } from './components/ChatAI'

export type TabsType = {
  id: number
  label?: string
  component?: ReactNode
  isDisabled?: boolean
}
/**
 * ListSetting Page
 * @constructor
 */
export const ListSetting: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const [state, setState] = useState<{
    free_word: string
    last_updated: string
  }>({
    free_word: '',
    last_updated: '',
  })
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const tab = queryParams.get('tab')
  const indexTab = Number(tab) ? Number(tab) : 0

  const formik = useFormik({
    initialValues: { free_word: '' },
    /**
     *
     */
    async onSubmit() {},
  })

  const { setFieldValue, values } = formik
  const handleNavigate = () => {
    if (!tab || Number(tab) === 0) {
      return navigate(ADMIN_ROUTES.PROMPT_REGISTRATION)
    }
    if (Number(tab) === 1) {
      return navigate(ADMIN_ROUTES.PROMPT_REGISTRATION_COMPANY)
    }
    if (Number(tab) === 2) {
      return navigate(ADMIN_ROUTES.PROMPT_REGISTRATION_NEWS)
    }
  }
  const TabItems: TabsType[] = [
    {
      id: 1,
      label: 'AIチャット',
      component: (
        <ChatAI
          isTab={1}
          pagination={pagination}
          setPagination={setPagination}
          setState={setState}
          values={values}
        />
      ),
    },
    {
      id: 2,
      label: '個社詳細',
      component: (
        <ChatAI
          isTab={2}
          pagination={pagination}
          setPagination={setPagination}
          setState={setState}
          values={values}
        />
      ),
    },
    {
      id: 3,
      label: '記事詳細',
      component: (
        <ChatAI
          isTab={3}
          pagination={pagination}
          setPagination={setPagination}
          setState={setState}
          values={values}
        />
      ),
    },
  ]

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(
      navigate,
      `${ADMIN_ROUTES.PROMPT_SETTINGS}`,
      'tab',
      index,
    )
  }

  return (
    <FormikProvider value={formik}>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minHeight="calc(100vh - 88px)"
        w="100%"
      >
        <Flex justifyContent="space-between" padding="12px 24px">
          <Flex alignItems="center" gap="24px">
            <Text
              fontSize="24px"
              fontWeight="700"
              letterSpacing="-0.48px"
              lineHeight="40px"
              minW="158px"
              padding="0 8px"
            >
              定形プロンプト設定
            </Text>
            <InputField
              height="40px"
              name="name"
              placeholder="プロンプト名検索"
              sizeInput="14px"
              width="242px"
              onChange={e => {
                setState(prev => ({ ...prev, free_word: e.target.value }))
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setFieldValue('free_word', state.free_word.trim())
                  setPagination(prev => ({ ...prev, page: 1 }))
                }
              }}
            />
            <Text fontSize="12px" fontWeight="400" minW="135px" opacity="0.5">
              最終更新日：{state.last_updated}
            </Text>
          </Flex>
          <Box>
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              fontSize="14px"
              height="40px"
              leftIcon={
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.98958 4.16602V15.8327M4.15625 9.99935H15.8229"
                    stroke="#0084FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              }
              text="定形プロンプト登録"
              onClick={handleNavigate}
            />
          </Box>
        </Flex>
        <TabsChakra
          isLazy
          index={indexTab}
          marginTop="16px"
          variant="unstyled"
          onChange={handleChangeTab}
        >
          <TabList padding="0 24px">
            {TabItems.map(({ id, label, isDisabled }) => (
              <Tab
                key={id}
                _selected={{
                  bg: '#E8ECEF',
                  color: '#141718',
                  fontWeight: 600,
                  borderTopRightRadius: '4px',
                }}
                borderBottom={'none'}
                borderTopLeftRadius={4}
                color={'#0084FF'}
                fontSize={'sm'}
                fontWeight={'normal'}
                h="48px"
                isDisabled={isDisabled}
                w={34}
              >
                {label}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {TabItems.map(({ id, component }) => (
              <TabPanel key={id} p={0}>
                {component}
              </TabPanel>
            ))}
          </TabPanels>
        </TabsChakra>
      </Box>
    </FormikProvider>
  )
}
