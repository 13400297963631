import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Lock icon
 * @param props
 * @constructor
 */
export const Lock: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.75 9.53578V7.56C16.75 5.0448 14.62 3 12 3C9.38 3 7.25 5.0448 7.25 7.56V9.53578C5.752 9.75946 5 10.6982 5 12.36V18.12C5 20.04 6 21 8 21H16C18 21 19 20.04 19 18.12V12.36C19 10.6982 18.248 9.75946 16.75 9.53578ZM12.75 15.2276V17.16C12.75 17.5574 12.414 17.88 12 17.88C11.586 17.88 11.25 17.5574 11.25 17.16V15.2034C10.962 14.9836 10.7649 14.6592 10.7649 14.28C10.7649 13.6176 11.32 13.08 12.01 13.08H12.02C12.71 13.08 13.27 13.6176 13.27 14.28C13.27 14.6755 13.057 15.0097 12.75 15.2276ZM15.25 9.48H8.75V7.56C8.75 5.8416 10.21 4.44 12 4.44C13.79 4.44 15.25 5.8416 15.25 7.56V9.48Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}
