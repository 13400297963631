import { axiosWithToken } from 'libs/configureAxios'
import {
  GetAuthRequest,
  LoginRequest,
  LoginResponse,
} from './authServices.type'

/**
 * @returns function that handle auth services
 */
export class AuthServices {
  /**
   * @returns function that handle login
   */
  async login(body: LoginRequest): Promise<LoginResponse> {
    return (
      await axiosWithToken.post(`/v1/${body.role}/oauth/login`, {
        email: body.email,
        password: body.password,
        role: body.role,
      })
    ).data
  }

  /**
   * @returns function that handle getAuth
   */
  async getAuth(body: GetAuthRequest): Promise<LoginResponse> {
    return (
      await axiosWithToken.post(`/v1/${body.role}/oauth/refresh`, {
        refresh_token: body.refresh_token,
      })
    ).data
  }

  /**
   * @returns function that handle forgot password
   */
  async forgotPassword(
    size: string,
    body: { email: string; user_type: string },
    params: {
      call_back: string
    },
  ) {
    return await axiosWithToken.post(
      `v1/${size}/oauth/recovery_password/send_mail`,
      body,
      { params },
    )
  }

  /**
   * @returns function that handle forgot password
   */
  async verifyToken(body: {
    email: string
    token: string
    token_type: string
  }) {
    return await axiosWithToken.post(`/v1/advisor/oauth/verify_token`, body)
  }

  /**
   * @returns function that handle forgot password
   */
  async resetPassword(
    size: string,
    body: { email: string; token: string; password: string },
  ) {
    return await axiosWithToken.post(
      `/v1/${size}/oauth/recovery_password`,
      body,
    )
  }

  /**
   * @returns function that handle forgot password
   */
  async changeEmail(
    size: string,
    body: { new_email: string },
    params: {
      call_back: string
    },
  ) {
    return await axiosWithToken.post(`/v1/${size}/profile/email`, body, {
      params,
    })
  }

  /**
   * @returns function that handle forgot password
   */
  async verifyEmail(size: string, body: { email: string }) {
    return await axiosWithToken.put(`/v1/${size}/profile/confirm_email`, body)
  }
}
