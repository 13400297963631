import { Box, Flex, Text } from '@chakra-ui/react'
import { InputField } from 'components/atoms'
import { useFormikContext } from 'formik'
import { FC } from 'react'

type SelectFieldProps = {}
/**
 * PerformanceChange components
 * @constructor
 */
export const PerformanceChange: FC<SelectFieldProps> = () => {
  const { handleChange } = useFormikContext()
  const fieldData = [
    {
      label: '売上高',
      name_1: 'performance_trends.Sales_Ordinance_2_3__c',
      name_2: 'performance_trends.Sales_Ordinance_3_3__c',
      name_3: 'performance_trends.Sales_Ordinance_4_3__c',
    },
    {
      label: '営業利益',
      name_1: 'performance_trends.Operating_Profit_Ordinance_2_3__c',
      name_2: 'performance_trends.Operating_Profit_Ordinance_3_3__c',
      name_3: 'performance_trends.Operating_Profit_Ordinance_4_3__c',
    },
    {
      label: '経常利益',
      name_1: 'performance_trends.Ordinary_Profit_Ordinance_2_3__c',
      name_2: 'performance_trends.Ordinary_Profit_Ordinance_3_3__c',
      name_3: 'performance_trends.Ordinary_Profit_Ordinance_4_3__c',
    },
    {
      label: '当期純利益',
      name_1: 'performance_trends.Net_Income_Ordinance_2_3__c',
      name_2: 'performance_trends.Net_Income_Ordinance_3_3__c',
      name_3: 'performance_trends.Net_Income_Ordinance_4_3__c',
    },
    {
      label: '申告所得',
      name_1: 'performance_trends.Declared_Income_Ordinance_2_3__c',
      name_2: 'performance_trends.Declared_Income_Ordinance_3_3__c',
      name_3: 'performance_trends.Declared_Income_Ordinance_4_3__c',
    },
  ]
  return (
    <Flex flexDirection="column">
      <Flex gap="4px">
        <Box>
          <Text
            borderBottom="1px solid"
            lineHeight="24px"
            p="4px 28px"
            textAlign="center"
          >
            決算期
          </Text>
        </Box>
        <Box w="192px">
          <Text
            borderBottom="1px solid"
            lineHeight="24px"
            p="4px 0px"
            textAlign="center"
            w="170px"
          >
            令2.3
          </Text>
        </Box>
        <Box w="192px">
          <Text
            borderBottom="1px solid"
            lineHeight="24px"
            p="4px 0px"
            textAlign="center"
            w="170px"
          >
            令3.3
          </Text>
        </Box>
        <Box w="192px">
          <Text
            borderBottom="1px solid"
            lineHeight="24px"
            p="4px 0px"
            textAlign="center"
            w="170px"
          >
            令4.3
          </Text>
        </Box>
      </Flex>
      {fieldData.map((it, key) => {
        const checkBg = key % 2 === 0
        return (
          <Flex key={key} gap="4px">
            <Text
              alignItems="center"
              bgColor={checkBg ? '' : '#F4F4F4'}
              borderBottom="1px solid #E8ECEF"
              display="flex"
              justifyContent="center"
              lineHeight="24px"
              p="16px 0"
              w="104px"
            >
              {it.label}
            </Text>
            <Flex
              alignItems="center"
              bgColor={checkBg ? '' : '#F4F4F4'}
              borderBottom="1px solid #E8ECEF"
              gap="5px"
              maxW="190px"
              p="10px"
            >
              <InputField
                height="48px"
                name={it.name_1}
                placeholder="0"
                textAlign={'right'}
                type="number"
                width="130px"
                onChange={handleChange}
              />
              <Text fontSize="16px" lineHeight="16px" w="45px">
                千円
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              bgColor={checkBg ? '' : '#F4F4F4'}
              borderBottom="1px solid #E8ECEF"
              gap="5px"
              maxW="190px"
              p="10px"
            >
              <InputField
                height="48px"
                name={it.name_2}
                placeholder="0"
                textAlign={'right'}
                type="number"
                width="130px"
                onChange={handleChange}
              />
              <Text fontSize="16px" lineHeight="16px" w="45px">
                千円
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              bgColor={checkBg ? '' : '#F4F4F4'}
              borderBottom="1px solid #E8ECEF"
              gap="5px"
              maxW="190px"
              p="10px"
            >
              <InputField
                height="48px"
                name={it.name_3}
                placeholder="0"
                textAlign={'right'}
                type="number"
                width="130px"
                onChange={handleChange}
              />
              <Text fontSize="16px" lineHeight="16px" w="45px">
                千円
              </Text>
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}
