import {
  Box,
  Text,
  Flex,
  CircularProgress,
  useToast,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react'
import { InputField, SelectField, TextareaField } from 'components/atoms'
import { PenLightEdit } from 'components/atoms/Icons'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { RattingImportance } from 'components/molecules'
import { FormikProvider, useFormik } from 'formik'
import { CompanyForm } from 'pages/admin/Company/components/CompanyForm'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { productAdminServices } from 'services'
import {
  GetDetailProductResponse,
  UpdateProductRequest,
} from 'services/productServices'
import moment from 'moment'
import _, { isEmpty } from 'lodash'
import { importanceOption } from 'utils'
import { Option } from 'types/conmon'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'
import { errors as cusErr } from 'constant'
import { useDropzone } from 'react-dropzone'
import { theme } from 'theme'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import ProductRegistrationModal from 'components/molecules/ProductRegistrationModal'
import { productRegistrationSchema } from '../validation'

type InitialValuesFormik = {
  category_id: number
  product_name: string
  description: string
  score_target: number
  product_code: string
  created_at: string
  updated_at: string
  product_short_name: string
  url: string
}

/**
 * ProductDetail Page
 * @constructor
 */
export const ProductDetail: FC = () => {
  const { id } = useParams()
  const toast = useToast()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [stateField, setStateFiled] = useState({
    category: false,
    product_name: false,
    product_short_name: false,
    overview: false,
    importance: false,
    switch: false,
    url: false,
    file: false,
  })
  const [detailProduct, setDetailProduct] = useState<GetDetailProductResponse>()
  const isScoreTarget = Boolean(detailProduct?.score_target)
  const [listCategory, setListCategory] = useState<Option[]>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialValuesFormik = detailProduct
    ? {
        category_id: detailProduct?.category_id,
        product_name: detailProduct?.product_name,
        product_short_name: detailProduct?.product_short_name,
        description: detailProduct?.description ?? '',
        score_target: detailProduct?.score_target ?? 0,
        product_code: detailProduct?.product_code,
        created_at: detailProduct?.created_at,
        updated_at: detailProduct?.updated_at,
        url: detailProduct?.url,
        files: detailProduct?.files,
        fileProducts: [],
      }
    : ({} as InitialValuesFormik)

  /**
   *
   */
  const handleUpdateProduct = async (values: UpdateProductRequest) => {
    if (!id) {
      return
    }
    try {
      setLoadingUpdate(true)
      const formData = new FormData()
      if (values.fileProducts?.length) {
        for (let index = 0; index < values.fileProducts.length; index++) {
          const element = values.fileProducts[index]
          formData.append('files', element)
        }
        const { data } = await productAdminServices.uploadfiles(
          formData,
          Number(id),
        )
        if (isEmpty(data.errors)) {
          setFieldValue(`fileProducts`, [])
        }
        setFieldValue('files', (values.files ?? []).concat(data.successes))
        await productAdminServices.updateProduct(id, {
          category_id: Number(values.category_id),
          product_name: values.product_name.trim(),
          product_short_name: values.product_short_name.trim(),
          description: values.description.trim(),
          score_target: values.score_target,
          product_code: values.product_code,
          url: values.url,
          files: values.files?.concat(data.successes).map(it => Number(it.id)),
        })
      } else {
        await productAdminServices.updateProduct(id, {
          category_id: Number(values.category_id),
          product_name: values.product_name.trim(),
          product_short_name: values.product_short_name.trim(),
          description: values.description.trim(),
          score_target: values.score_target,
          product_code: values.product_code,
          url: values.url,
          files: values.files?.map(it => Number(it.id)),
        })
      }

      // toast({
      //   status: 'success',
      //   description: '商品情報が更新されました',
      // })
      setStateFiled({
        category: false,
        product_name: false,
        overview: false,
        importance: false,
        switch: false,
        product_short_name: false,
        url: false,
        file: false,
      })
      await getProductDetail()
      setLoadingUpdate(false)
      onOpen()
    } catch (error: any) {
      setLoadingUpdate(false)
      toast({
        status: 'error',
        description: error?.response?.data?.message,
      })
    }
  }

  const checkHiddenButton =
    stateField.category ||
    stateField.product_name ||
    stateField.product_short_name ||
    stateField.overview ||
    stateField.importance ||
    stateField.switch ||
    stateField.url ||
    stateField.file

  const formik = useFormik({
    initialValues: initialValuesFormik,
    validationSchema: productRegistrationSchema,
    /**
     *
     */
    async onSubmit(values: UpdateProductRequest) {
      handleUpdateProduct(values)
    },
    enableReinitialize: true,
  })

  const {
    setFieldValue,
    handleSubmit,
    values,
    isValid,
    errors,
    dirty,
    setValues,
  } = formik

  const [stateScore, setStateScore] = useState(isScoreTarget)
  /**
   *
   */
  const getProductDetail = useCallback(async () => {
    if (!id) return
    try {
      setLoading(true)
      const { data } = await productAdminServices.getProductDetail(id)
      setDetailProduct(data)
      setStateScore(Boolean(data.score_target))
      dispatch(setBreadcrumb([{ text: data.product_name, link: '' }]))
      setLoading(false)
      setValues(data)
    } catch (error: any) {
      setLoading(false)
      toast({
        status: 'error',
        description: error.message,
        position: 'top',
      })
    }
  }, [dispatch, id, setValues, toast])

  const onDrop = async (files: File[]) => {
    setFieldValue('fileProducts', (values.fileProducts ?? []).concat(files))
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  useEffect(() => {
    getProductDetail()
  }, [getProductDetail])

  const fetchListCategory = useCallback(async () => {
    const { data } = await productAdminServices.getListCategoryProduct()
    const covertListCategory = data.map(c => ({
      value: String(c.id),
      label: c.name,
    }))
    setListCategory(covertListCategory)
  }, [])

  useEffect(() => {
    fetchListCategory()
  }, [fetchListCategory])

  /**
   *
   */
  const handleToggleSwitch = () => {
    if (stateScore) {
      setStateScore(prev => !prev)
      if (stateScore === isScoreTarget) {
        setFieldValue('score_target', 0)
      }
    } else {
      setStateScore(prev => !prev)
      setFieldValue('score_target', detailProduct?.score_target ?? 0)
    }
  }

  /**
   *
   */
  const handleCancel = () => {
    setStateFiled({
      category: false,
      product_name: false,
      overview: false,
      importance: false,
      switch: false,
      product_short_name: false,
      url: false,
      file: false,
    })
    setValues(formik.initialValues)
    setStateScore(isScoreTarget)
  }

  const handleRemoveFile = (key: number) => {
    if (values.files) {
      const files = [...values.files]
      files.splice(key, 1)
      setFieldValue(`files`, files)
    }
  }

  const handleRemoveFileForm = (key: number) => {
    if (values.fileProducts) {
      const files = [...values.fileProducts]
      files.splice(key, 1)
      setFieldValue(`fileProducts`, files)
    }
  }

  if (loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <FormikProvider value={formik}>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minHeight="calc(100vh - 88px)"
        padding="24px 32px"
        w="100%"
      >
        <Flex justifyContent="space-between">
          <Text
            fontSize="24px"
            fontWeight="700"
            letterSpacing="-0.48px"
            lineHeight="40px"
            minW="158px"
          >
            {detailProduct?.product_name}
          </Text>
          <Box>
            <Text
              fontSize="12px"
              lineHeight="100%"
              opacity="0.5"
              textAlign="right"
            >
              登録日：
              {moment(values.created_at ?? '2023/07/01').format('YYYY/MM/DD')}
            </Text>
            <Text
              fontSize="12px"
              lineHeight="100%"
              mt="16px"
              opacity="0.5"
              textAlign="right"
            >
              更新日：
              {moment(values.updated_at).format('YYYY/MM/DD') ?? '----/--/--'}
            </Text>
          </Box>
        </Flex>
        <CompanyForm
          borderTopRightRadius="none"
          fieldform={[
            {
              label: '商品コード',
              components: (
                <Text fontSize="14px" lineHeight="24px">
                  {detailProduct?.product_code}
                </Text>
              ),
              isBackground: 'white.50',
            },
            {
              label: 'カテゴリー',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  {stateField.category ? (
                    <SelectField
                      error={Boolean(errors.category_id)}
                      errorText={errors.category_id}
                      height="48px"
                      name="category_id"
                      options={listCategory ?? []}
                      placeholder="例：預金"
                      valueOption={(listCategory || []).find(
                        it => it.value === String(values.category_id),
                      )}
                      width="327px"
                      onChange={e => setFieldValue('category_id', e?.value)}
                    />
                  ) : (
                    <>
                      <Text fontSize="14px" lineHeight="24px">
                        {
                          (listCategory || []).find(
                            c => c.value === String(detailProduct?.category_id),
                          )?.label
                        }
                      </Text>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({ ...stateField, category: true })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.category ? 'white.50' : '',
            },
            {
              label: '商品名',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  {stateField.product_name ? (
                    <InputField
                      isFocus
                      error={Boolean(errors.product_name)}
                      errorText={errors.product_name}
                      height="48px"
                      maxWidth="327px"
                      name="product_name"
                      placeholder="例：法人向け定期預金"
                      onChange={e =>
                        setFieldValue('product_name', e.target.value)
                      }
                    />
                  ) : (
                    <>
                      <Text
                        fontSize="14px"
                        lineHeight="24px"
                        overflow="hidden"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {detailProduct?.product_name}
                      </Text>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({ ...stateField, product_name: true })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.product_name ? 'white.50' : '',
            },
            {
              label: '商品の略称',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  {stateField.product_short_name ? (
                    <InputField
                      isFocus
                      error={Boolean(errors.product_short_name)}
                      errorText={errors.product_short_name}
                      height="48px"
                      maxWidth="327px"
                      name="product_short_name"
                      placeholder="例：法人向け定期預金"
                      onChange={e =>
                        setFieldValue('product_short_name', e.target.value)
                      }
                    />
                  ) : (
                    <>
                      <Text
                        fontSize="14px"
                        lineHeight="24px"
                        overflow="hidden"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {detailProduct?.product_short_name}
                      </Text>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({
                            ...stateField,
                            product_short_name: true,
                          })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.product_short_name ? 'white.50' : '',
            },
            {
              label: '概要',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  {stateField.overview ? (
                    <TextareaField
                      error={Boolean(errors.description)}
                      errorText={errors.description}
                      height={'128px'}
                      maxWidth="full"
                      name="description"
                      placeholder="入力してください"
                      value={values.description}
                      onChange={e => {
                        setFieldValue('description', e.target.value)
                      }}
                    />
                  ) : (
                    <>
                      <Text
                        fontSize="14px"
                        lineHeight="24px"
                        overflow="hidden"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {detailProduct?.description}
                      </Text>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({ ...stateField, overview: true })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.overview ? 'white.50' : '',
            },
            {
              label: '参考URL',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  {stateField.url ? (
                    <InputField
                      isFocus
                      error={Boolean(errors.url)}
                      errorText={errors.url}
                      height="48px"
                      maxWidth="full"
                      name="url"
                      placeholder="https://sample.co.jp/"
                      onChange={e => setFieldValue('url', e.target.value)}
                    />
                  ) : (
                    <>
                      <Text
                        fontSize="14px"
                        lineHeight="24px"
                        overflow="hidden"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {detailProduct?.url ?? ''}
                      </Text>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({ ...stateField, url: true })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.switch ? 'white.50' : '',
            },
            {
              label: '参考資料',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  {stateField.file ? (
                    <Flex alignItems="center" gap="10px">
                      <Button
                        {...getRootProps()}
                        backgroundColor="#FEFEFE"
                        border="1px solid #0084FF"
                        borderRadius="12px"
                        color="#0084FF"
                        fontSize={'14px'}
                        height="40px"
                        minW="115px"
                        text="ファイル添付"
                      />
                      <input {...getInputProps()} name={'0'} type="file" />
                      <List
                        display="flex"
                        gap="24px"
                        overflowX="auto"
                        sx={{
                          '&::-webkit-scrollbar': {
                            width: theme.space[2],
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: theme.colors.black['150'],
                            borderRadius: theme.radii.lg,
                            border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                          },
                        }}
                        width="100%"
                      >
                        {values?.files?.map((it, index) => (
                          <ListItem
                            key={index}
                            color="blue.900"
                            cursor="pointer"
                            display="flex"
                            fontSize="14px"
                            fontWeight="normal"
                            lineHeight="24px"
                            maxW="200px"
                          >
                            <TextOverflowTooltip
                              color="#0084FF"
                              fontSize="14px"
                              value={it.file_name}
                              width="100%"
                            />
                            <Text
                              alignItems="center"
                              bgColor="#BDBDBD"
                              color="#000"
                              display="flex"
                              h="20px"
                              justifyContent="center"
                              w="20px"
                              onClick={() => handleRemoveFile(index)}
                            >
                              x
                            </Text>
                          </ListItem>
                        ))}
                        {values?.fileProducts?.map((it, index) => (
                          <ListItem
                            key={index}
                            color="blue.900"
                            cursor="pointer"
                            display="flex"
                            fontSize="14px"
                            fontWeight="normal"
                            lineHeight="24px"
                            maxW="200px"
                          >
                            <TextOverflowTooltip
                              color="#0084FF"
                              fontSize="14px"
                              value={it.name}
                              width="100%"
                            />
                            <Text
                              alignItems="center"
                              bgColor="#BDBDBD"
                              color="#000"
                              display="flex"
                              h="20px"
                              justifyContent="center"
                              w="20px"
                              onClick={() => handleRemoveFileForm(index)}
                            >
                              x
                            </Text>
                          </ListItem>
                        ))}
                      </List>
                    </Flex>
                  ) : (
                    <>
                      <List
                        display="flex"
                        gap="24px"
                        overflowX="auto"
                        sx={{
                          '&::-webkit-scrollbar': {
                            width: theme.space[2],
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: theme.colors.black['150'],
                            borderRadius: theme.radii.lg,
                            border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                          },
                        }}
                        width="100%"
                      >
                        {values.files?.map((it, index) => (
                          <ListItem
                            key={index}
                            color="blue.900"
                            cursor="pointer"
                            display="flex"
                            fontSize="14px"
                            fontWeight="normal"
                            lineHeight="24px"
                            maxW="200px"
                          >
                            <TextOverflowTooltip
                              color="#0084FF"
                              fontSize="14px"
                              value={it.file_name}
                              width="100%"
                            />
                          </ListItem>
                        ))}
                      </List>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({ ...stateField, file: true })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.switch ? 'white.50' : '',
            },
          ]}
          marginTop="16px"
        />
        <CompanyForm
          borderTopRightRadius="none"
          fieldform={[
            {
              label: 'スコア対象',
              components: (
                <Flex alignItems="center" justifyContent="space-between">
                  <Switch
                    isChecked={stateScore}
                    islabelinswitch={true}
                    isReadOnly={!stateField.switch}
                    offtext="非対象"
                    offtextswitch="対象"
                    onChange={handleToggleSwitch}
                  />
                  {!stateField.switch && (
                    <PenLightEdit
                      cursor="pointer"
                      onClick={() =>
                        setStateFiled({ ...stateField, switch: true })
                      }
                    />
                  )}
                </Flex>
              ),
              isBackground: !stateField.switch ? 'white.50' : '',
            },
            {
              label: '重要度',
              components: (
                <Flex
                  alignItems="center"
                  height="100%"
                  justifyContent="space-between"
                >
                  {stateField.importance ? (
                    <SelectField
                      error={Boolean(stateScore && !values.score_target)}
                      errorText={cusErr.required}
                      height="48px"
                      isDisabled={!stateScore}
                      name="score_target"
                      options={importanceOption}
                      placeholder="選択してください"
                      valueOption={importanceOption.find(
                        it => it.value === String(values.score_target),
                      )}
                      width="327px"
                      onChange={e =>
                        setFieldValue('score_target', Number(e?.value))
                      }
                    />
                  ) : (
                    <>
                      <Flex alignItems="center" gap="8px">
                        <Text fontSize="14px" lineHeight="24px">
                          {`レベル${detailProduct?.score_target ?? 0}`}
                        </Text>
                        <RattingImportance
                          isDisable={!stateScore}
                          value={detailProduct?.score_target ?? 0}
                        />
                      </Flex>
                      <PenLightEdit
                        cursor="pointer"
                        onClick={() =>
                          setStateFiled({ ...stateField, importance: true })
                        }
                      />
                    </>
                  )}
                </Flex>
              ),
              isBackground: !stateField.importance ? 'white.50' : '',
            },
          ]}
          marginTop="32px"
        />

        {checkHiddenButton && (
          <Box display="flex" gap="16px" justifyContent="end" marginTop="24px">
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              height="48px"
              text="キャンセル"
              width="127px"
              onClick={handleCancel}
            />
            <Button
              backgroundColor="#0084FF"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="white.50"
              height="48px"
              isDisabled={!_.isEmpty(errors) || !dirty || !isValid}
              isLoading={loadingUpdate}
              text="登録する"
              width="206px"
              onClick={() => handleSubmit()}
            />
          </Box>
        )}
      </Box>
      <ProductRegistrationModal
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
      />
    </FormikProvider>
  )
}
