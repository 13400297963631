import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
  Textarea,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react'
import React, { ChangeEvent, FC, ReactNode, useState } from 'react'
import { QuestionCircle } from 'components/atoms/Icons'
import { useField } from 'formik'
import { icons } from 'constant'
import PopoverCustom from 'components/atoms/Popover'

export type ConditionSettingFieldProps = InputProps & {
  name: string
  placeholder?: string
  width?: string
  label?: string
  height?: string
  minHeight?: string
  maxWidth?: string
  error?: boolean | undefined
  disabled?: boolean | undefined
  errorText?: string | false | undefined
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  minWidth?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  iconLable?: boolean
  sizeLabel?: string
  colorLabel?: string
  helperText?: string | false | undefined
  touched?: boolean
  maxLength?: number
  changeDisableField?: () => void
  contentPopover?: ReactNode
  maxWidthPopover?: string
}

/**
 * ConditionSettingField component
 * @constructor
 */
export const ConditionSettingField: FC<ConditionSettingFieldProps> = ({
  name,
  label,
  width,
  height,
  minHeight,
  maxWidth,
  error,
  minWidth,
  onChange,
  sizeLabel,
  colorLabel,
  disabled = false,
  iconLable = false,
  placeholder,
  helperText,
  errorText,
  prefix,
  maxLength,
  changeDisableField,
  contentPopover,
  maxWidthPopover,
}) => {
  const [field, { value }] = useField(name)
  const [colorLength, setColorLength] = useState<boolean>(false)
  const changeStateColor = () => {
    setColorLength(prev => !prev)
  }
  return (
    <FormControl isInvalid={error}>
      {label && (
        <FormLabel
          alignItems="center"
          color={colorLabel}
          display="flex"
          fontSize={sizeLabel}
          fontWeight="700"
          gap="8px"
          lineHeight="32px"
          margin="0 0 8px 0"
        >
          {label}
          {iconLable ? (
            contentPopover ? (
              <PopoverCustom
                content={contentPopover}
                isArrow={false}
                isClose={false}
                maxWidthPopover={maxWidthPopover}
              >
                <Box>
                  <QuestionCircle />
                </Box>
              </PopoverCustom>
            ) : (
              <QuestionCircle />
            )
          ) : (
            <></>
          )}
        </FormLabel>
      )}
      <Box position="relative">
        <Box
          backgroundColor={disabled ? '#F3F5F7' : 'white.50'}
          border={`1px solid ${
            colorLength ? '#0084FF' : error ? 'red' : '#E8ECEF'
          }`}
          borderRadius="12px"
          boxShadow="0px 5px 10px -5px rgba(20, 23, 24, 0.10) inset"
          height={height}
          maxWidth={maxWidth}
          minHeight={minHeight}
          padding="24px"
          pb="46px"
        >
          <Textarea
            {...field}
            _disabled={{ backgroundColor: '#F3F5F7', color: '#141718' }}
            _invalid={{
              border: 'none',
            }}
            _placeholder={{ color: 'gray.800', opacity: 0.5 }}
            backgroundColor="white.50"
            border="none"
            boxShadow="none !important"
            className="withprefix"
            color={colorLength ? '#0084FF' : '#141718'}
            contentEditable={false}
            disabled={disabled}
            focusBorderColor="red"
            fontWeight={400}
            height={`calc(${height} - 70px)`}
            lineHeight="30px"
            maxLength={maxLength}
            minW={minWidth}
            name={name}
            overflow="hidden"
            p="0"
            placeholder={placeholder}
            resize="none"
            sx={{
              paddingTop: prefix ? '36px' : '8px',
            }}
            value={value}
            width={width}
            onBlur={changeStateColor}
            onChange={onChange}
            onFocus={changeStateColor}
          />
          <Flex
            alignItems="center"
            bottom="20px"
            gap="6px"
            position="absolute"
            right="24px"
            zIndex="9"
          >
            {disabled ? (
              <Image
                cursor="pointer"
                src={icons.EDIT_ICON}
                onClick={changeDisableField}
              />
            ) : (
              <>
                {value && maxLength && (
                  <Text
                    color={colorLength ? 'blue.900' : '#141718'}
                    opacity={!colorLength ? '0.3' : ''}
                  >
                    残{maxLength - Number(value.length)}文字
                  </Text>
                )}
              </>
            )}
          </Flex>
        </Box>
      </Box>
      {!error ? (
        <FormHelperText mt={0}>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage color={'#F75555'}>{errorText}</FormErrorMessage>
      )}
    </FormControl>
  )
}
