import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import {
  ConversationConverted,
  ListConversationConverted,
} from 'services/chatAIServices'
import { chatAIServices, storageServices } from 'services'
import { useToast } from '@chakra-ui/react'
import { convertString } from './MainChat.utils'

const KEY_CODE_ENTER = 13

/**
 * @returns function that handle any function for main chat
 */
export const useMainChat = (
  setConversation: Dispatch<SetStateAction<ConversationConverted | undefined>>,
  setConversationList: Dispatch<SetStateAction<ListConversationConverted>>,
  conversationId?: number,
) => {
  const toast = useToast()
  const accessToken = storageServices.getAccessToken()
  const [isEditAnswer, setIsEditAnswer] = useState<boolean>(false)
  const [isLoadingSendMessage, setIsLoadingSendMessage] =
    useState<boolean>(false)
  const [isLoadingSendAskMore, setIsLoadingSendAskMore] =
    useState<boolean>(false)
  const [isAskMore, setIsAskMore] = useState<boolean>(false)
  const [question, setQuestion] = useState<string>('')
  const [questionAskMore, setQuestionAskMore] = useState<string>('')
  const [messageTyping, setMessageTyping] = useState<string>('')
  const [consultationId, setConsultationId] = useState<number | null>(null)
  const [isAgreeConsult, setIsAgreeConsult] = useState<boolean>(false)
  const [isSendQuestion, setIsSendQuestion] = useState<boolean>(false)

  /**
   * @returns function that handle change input
   */
  const handleOnChangeInputAskMore = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setQuestionAskMore(event.target.value)
  }

  /**
   * @returns function that handle submit question
   */
  const handleSendQuestion = async () => {
    if (!question.trim()) return
    setIsSendQuestion(true)
    setMessageTyping('')
    setIsLoadingSendMessage(true)
  }

  /**
   * @returns function that handle question more
   */
  const handleSendQuestionAskMore = async () => {
    if (!questionAskMore.trim()) return
    setIsSendQuestion(true)
    setMessageTyping('')
    setIsAskMore(false)
    setIsLoadingSendAskMore(true)
  }

  /**
   * @returns function that handle press enter send question
   */
  const handlePressEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === KEY_CODE_ENTER && !event.shiftKey) {
      event.preventDefault()
      handleSendQuestion()
    }
  }

  /**
   * @returns function that handle press enter send question ask more
   */
  const handlePressEnterAskMore = (
    event: KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.keyCode === KEY_CODE_ENTER && !event.shiftKey) {
      event.preventDefault()
      handleSendQuestionAskMore()
    }
  }

  /**
   * @returns function that handle turn on edit answer
   */
  const handleClickIconEditAnswer = () => setIsEditAnswer(true)

  /**
   * @returns function that handle turn off edit answer
   */
  const handleCancelEdit = () => setIsEditAnswer(false)

  /**
   * @returns function that handle turn on ask more
   */
  const handleAskMore = () => setIsAskMore(!isAskMore)

  const handlePreventLoadingAnswer = () => {
    setIsLoadingSendAskMore(false)
    setIsLoadingSendMessage(false)
  }

  useEffect(() => {
    if (!(question || (questionAskMore && conversationId))) return

    if (!isSendQuestion) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/chat_with_ai/ws_stream`,
    )

    const questionForAI = question || questionAskMore

    const handleSocketOpen = () => {
      console.log('WS connected')

      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }

      newSocket.send(JSON.stringify(messAuthen))

      const questionTrim = questionForAI.trim()
      const convertedString = convertString(questionTrim)
      const objData = {
        prompt_html: `<p>${questionTrim}</p>`,
        prompt_text: questionTrim,
        prompt_question: convertedString ? convertedString : questionTrim,
        prompt_type: '',
      }

      if (consultationId) {
        Object.assign(objData, { prompt_id: consultationId })
      }

      if (isAgreeConsult) {
        Object.assign(objData, { current_prompt_id: consultationId })
      }

      if (questionAskMore) {
        Object.assign(objData, { ai_conversation_id: conversationId })
      }

      let message = {
        type: 'stream_req',
        data: objData,
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)

      switch (objMess.type) {
        case 'stream_res':
          setMessageTyping(prev => prev + objMess?.data)
          break

        case 'ai_conversation':
          if (!objMess?.data)
            return toast({
              status: 'error',
              description:
                '何らかのエラーが発生しました。もう一度お試しください。',
            })

          if (question) {
            setConversationList(prevState => ({
              ...prevState,
              data: [objMess?.data, ...prevState.data],
            }))
          }

          setConversation(chatAIServices.convertConversation(objMess?.data))
          setIsSendQuestion(false)
          setIsLoadingSendMessage(false)
          setQuestion('')
          setIsLoadingSendAskMore(false)
          setQuestionAskMore('')
          break

        default:
          break
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('close', handleSocketClose)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.close()
      console.log('WebSocket is disconnected')
    }
  }, [
    accessToken,
    consultationId,
    conversationId,
    isAgreeConsult,
    isSendQuestion,
    question,
    questionAskMore,
    setConversation,
    setConversationList,
    toast,
  ])

  return {
    question,
    isAskMore,
    isEditAnswer,
    messageTyping,
    questionAskMore,
    isLoadingSendMessage,
    isLoadingSendAskMore,
    setQuestion,
    handleAskMore,
    handlePressEnter,
    handleCancelEdit,
    handleSendQuestion,
    handlePressEnterAskMore,
    handleClickIconEditAnswer,
    handleSendQuestionAskMore,
    handleOnChangeInputAskMore,
    setQuestionAskMore,
    setIsAgreeConsult,
    consultationId,
    setConsultationId,
    handlePreventLoadingAnswer,
  }
}
