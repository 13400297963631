/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { InputField } from 'components/atoms/InputField'
import { dayInMonthOptions, monthOptions, yearOptions } from 'utils'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { SelectField } from 'components/atoms/SelectField'
import { CompanyRegistrationType } from 'services/companyServices'
import { useParams } from 'react-router-dom'
import { Button } from 'components/fromAdvisor/elements'
import { CompanyForm } from './CompanyForm'

type SelectFieldProps = {
  handleNextInput: any
  handleGetCities: (id: number) => void
  getLocation: (value: string) => void
  optionsStates: {
    label: string
    value: number | string
  }[]
  optionsCities: {
    label: string
    value: number | string
  }[]
}
/**
 * CompanyInformation components
 * @constructor
 */
export const CompanyInformation: FC<SelectFieldProps> = ({
  handleNextInput,
  optionsStates,
  optionsCities,
  handleGetCities,
  getLocation,
}) => {
  const { handleChange, setFieldValue, errors, values, touched } =
    useFormikContext<CompanyRegistrationType>()
  const currenYear = moment().year()
  const { id } = useParams()
  const regex = /^[a-zA-Z0-9]+$/

  return (
    <Box>
      <Flex gap={'4px'} ml={'11px'}>
        <Text
          color={'red.700'}
          fontSize="14px"
          fontWeight={700}
          lineHeight="14px"
          pt={'8px'}
        >
          *
        </Text>
        <Text fontSize="14px" lineHeight="24px">
          マークは入力必須です。
        </Text>
      </Flex>
      <CompanyForm
        borderTopRightRadius="none"
        fieldform={[
          {
            label: '企業コード',
            components: <Text>{id}</Text>,
            isBackground: 'white.50',
            isNone: id ? false : true,
          },
          {
            label: '証券コード',
            isRequired: true,
            components: (
              <Box ml="8px">
                <InputField
                  error={Boolean(errors.security_code)}
                  errorText={errors.security_code}
                  fontWeightLabel="500"
                  height="48px"
                  maxWidth="671px"
                  name="security_code"
                  placeholder="例：未来"
                  sizeLabel="14px"
                  onChange={e => {
                    if (regex.test(e.target.value) || e.target.value === '') {
                      setFieldValue('security_code', e.target.value)
                    }
                  }}
                />
              </Box>
            ),
          },
          {
            label: '会社名',
            isRequired: true,
            components: (
              <Flex flexDirection="column" gap="8px" ml="8px">
                <InputField
                  error={Boolean(errors.company_name)}
                  errorText={errors.company_name}
                  fontWeightLabel="500"
                  height="48px"
                  label="会社名"
                  maxWidth="671px"
                  name="company_name"
                  placeholder="例：株式会社MILIZE"
                  sizeLabel="14px"
                  onChange={handleChange}
                />
                <InputField
                  error={Boolean(errors.company_name_kana)}
                  errorText={errors.company_name_kana}
                  fontWeightLabel="500"
                  height="48px"
                  label="フリガナ"
                  maxWidth="671px"
                  name="company_name_kana"
                  placeholder="例：カブシキガイシャミライズ"
                  sizeLabel="14px"
                  onChange={handleChange}
                />
                <InputField
                  error={Boolean(errors.company_name_en)}
                  errorText={errors.company_name_en}
                  fontWeightLabel="500"
                  height="48px"
                  label="英語表記"
                  maxWidth="671px"
                  name="company_name_en"
                  placeholder="例：MILIZE CO.,LTD"
                  sizeLabel="14px"
                  onChange={handleChange}
                />
              </Flex>
            ),
          },
          {
            label: '担当者',
            isRequired: true,
            components: (
              <Flex gap="8px" maxW="671px" ml="8px">
                <InputField
                  error={Boolean(errors.department)}
                  errorText={errors.department}
                  fontWeightLabel="500"
                  height="48px"
                  label="部店"
                  name="department"
                  placeholder="例：未来"
                  sizeLabel="14px"
                  w="50%"
                  onChange={handleChange}
                />
                <InputField
                  error={Boolean(errors.contact_person_name)}
                  errorText={errors.contact_person_name}
                  fontWeightLabel="500"
                  height="48px"
                  label="担当者名"
                  name="contact_person_name"
                  placeholder="例：太郎"
                  sizeLabel="14px"
                  w="50%"
                  onChange={handleChange}
                />
              </Flex>
            ),
          },
        ]}
        marginTop="16px"
      />
      <CompanyForm
        borderTopRightRadius="none"
        fieldform={[
          {
            label: '本社所在地',
            isRequired: true,
            components: (
              <Flex flexDirection="column" gap="8px" mb={'8px'}>
                <Box>
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="24px"
                    marginBottom="8px"
                  >
                    郵便番号
                  </Text>
                  <Box>
                    <Flex alignItems="center" gap="4px">
                      <InputField
                        error={Boolean(errors.postCode_1)}
                        fontWeightLabel="500"
                        height="48px"
                        maxLength={3}
                        name="postCode_1"
                        placeholder="000"
                        sizeLabel="14px"
                        width="65px"
                        onChange={e => {
                          const value = e.target.value
                          const withoutDot = value.replace(/\./g, '')
                          const onlyNums = withoutDot.replace(/\D/g, '')
                          handleNextInput(e, onlyNums)
                        }}
                      />
                      -
                      <InputField
                        error={Boolean(errors.postCode_2)}
                        fontWeightLabel="500"
                        height="48px"
                        maxLength={4}
                        name="postCode_2"
                        placeholder="0000"
                        sizeLabel="14px"
                        width="76px"
                        onChange={e => {
                          const value = e.target.value
                          const withoutDot = value.replace(/\./g, '')
                          const onlyNums = withoutDot.replace(/\D/g, '')
                          setFieldValue('postCode_2', onlyNums)
                        }}
                      />
                      <Box ml="15px">
                        <Button
                          backgroundColor="white.50"
                          border="1px solid #0084FF"
                          borderRadius="12px"
                          color="blue.900"
                          fontWeight="700"
                          height="48px"
                          isDisabled={!values.postCode_1 || !values.postCode_2}
                          text="住所取得"
                          width="100px"
                          onClick={() => {
                            if (!values.postCode_1 || !values.postCode_2) return
                            getLocation(values.postCode_1 + values.postCode_2)
                          }}
                        />
                      </Box>
                    </Flex>
                    {Boolean(errors.postCode_2 || errors.postCode_1) &&
                      Boolean(touched.postCode_1 || touched.postCode_2) && (
                        <Text color="#F75555" fontSize="14px">
                          必須項目に入力してください。
                        </Text>
                      )}
                  </Box>
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="18px"
                    marginTop="6px"
                  >
                    ※入力すると自動的に住所が表示されます。
                  </Text>
                </Box>
                <Flex gap="16px" paddingLeft="8px">
                  <SelectField
                    error={Boolean(errors.state_id)}
                    errorText={errors.state_id}
                    fontWeightLabel="500"
                    height="48px"
                    label="都道府県"
                    name="state_id"
                    options={optionsStates}
                    placeholder="----"
                    sizeLabel="14px"
                    valueOption={
                      optionsStates
                        ? optionsStates.find(it => it.value === values.state_id)
                        : null
                    }
                    width="140px"
                    onChange={e => {
                      setFieldValue('state_id', e?.value)
                      handleGetCities(Number(e?.value))
                    }}
                  />
                  <SelectField
                    error={Boolean(errors.city_id)}
                    errorText={errors.city_id}
                    fontWeightLabel="500"
                    height="48px"
                    label="市区郡"
                    name="city_id"
                    options={values.state_id ? optionsCities : []}
                    placeholder="----"
                    sizeLabel="14px"
                    valueOption={
                      optionsCities
                        ? optionsCities.find(it => it.value === values.city_id)
                        : null
                    }
                    width="140px"
                    onChange={e => {
                      setFieldValue('city_id', e?.value)
                    }}
                  />
                </Flex>
                <Flex gap="16px" paddingLeft="8px">
                  <InputField
                    error={Boolean(errors.address1)}
                    errorText={errors.address1}
                    fontWeightLabel="500"
                    height="48px"
                    label="以下住所"
                    maxWidth="327px"
                    name="address1"
                    placeholder="例：芝浦"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                  <InputField
                    error={Boolean(errors.address2)}
                    errorText={errors.address2}
                    fontWeightLabel="500"
                    height="48px"
                    label="丁目以下"
                    maxWidth="327px"
                    name="address2"
                    placeholder="例：4-12-38 建物名 部屋番号"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                </Flex>
              </Flex>
            ),
          },
          {
            label: '電話番号',
            isRequired: true,
            components: (
              <Flex gap="16px" mb="8px" paddingLeft="8px">
                <InputField
                  error={Boolean(errors.phone)}
                  errorText={errors.phone}
                  fontWeightLabel="500"
                  height="48px"
                  label="代表電話"
                  maxWidth="327px"
                  name="phone"
                  placeholder="例：03-1234-5678"
                  sizeLabel="14px"
                  onChange={e => {
                    let inputPhoneNumber = e.target.value
                    inputPhoneNumber = inputPhoneNumber.replace(/[^\d-]/g, '')
                    inputPhoneNumber = inputPhoneNumber.replace(
                      /(^-|(?<=-)-)/g,
                      '',
                    )
                    if (/^-?(?!.*--)[0-9]*(-[0-9]+)*$/.test(inputPhoneNumber)) {
                    }
                    setFieldValue('phone', inputPhoneNumber)
                  }}
                />
                <InputField
                  error={Boolean(errors.sale_phone)}
                  errorText={errors.sale_phone}
                  fontWeightLabel="500"
                  height="48px"
                  label="営業窓口番号"
                  maxWidth="327px"
                  name="sale_phone"
                  placeholder="例：03-1234-5678"
                  sizeLabel="14px"
                  onChange={e => {
                    let inputPhoneNumber = e.target.value
                    inputPhoneNumber = inputPhoneNumber.replace(/[^\d-]/g, '')
                    inputPhoneNumber = inputPhoneNumber.replace(
                      /(^-|(?<=-)-)/g,
                      '',
                    )
                    if (/^-?(?!.*--)[0-9]*(-[0-9]+)*$/.test(inputPhoneNumber)) {
                    }
                    setFieldValue('sale_phone', inputPhoneNumber)
                  }}
                />
              </Flex>
            ),
          },
          {
            label: '取引先担当者名',
            isRequired: true,
            components: (
              <>
                <Flex gap="16px" paddingLeft="8px">
                  <InputField
                    error={Boolean(errors.contact_first_name_kanji)}
                    errorText={errors.contact_first_name_kanji}
                    fontWeightLabel="500"
                    height="48px"
                    label="姓"
                    maxWidth="327px"
                    name="contact_first_name_kanji"
                    placeholder="例：未来"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                  <InputField
                    error={Boolean(errors.contact_last_name_kanji)}
                    errorText={errors.contact_last_name_kanji}
                    fontWeightLabel="500"
                    height="48px"
                    label="名"
                    maxWidth="327px"
                    name="contact_last_name_kanji"
                    placeholder="例：太郎"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                </Flex>
                <Flex gap="16px" marginTop="8px" mb="8px" paddingLeft="8px">
                  <InputField
                    error={Boolean(errors.contact_first_name_kata)}
                    errorText={errors.contact_first_name_kata}
                    fontWeightLabel="500"
                    height="48px"
                    label="セイ"
                    maxWidth="327px"
                    name="contact_first_name_kata"
                    placeholder="例：ミライ"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                  <InputField
                    error={Boolean(errors.contact_last_name_kata)}
                    errorText={errors.contact_last_name_kata}
                    fontWeightLabel="500"
                    height="48px"
                    label="メイ"
                    maxWidth="327px"
                    name="contact_last_name_kata"
                    placeholder="例：タロウ"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                </Flex>
              </>
            ),
          },
          {
            label: '企業URL',
            components: (
              <Flex mb={'8px'} paddingLeft="8px" paddingTop="8px">
                <InputField
                  height="48px"
                  maxWidth="671px"
                  name="company_url"
                  placeholder="例：https://www.xxxx.co.jp"
                  sizeLabel="14px"
                  onChange={handleChange}
                />
              </Flex>
            ),
          },
          {
            label: '代表者名',
            components: (
              <>
                <Flex gap="16px" paddingLeft="8px">
                  <InputField
                    fontWeightLabel="500"
                    height="48px"
                    label="姓"
                    maxWidth="327px"
                    name="representative_first_name_kanji"
                    placeholder="例：未来"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                  <InputField
                    fontWeightLabel="500"
                    height="48px"
                    label="名"
                    maxWidth="327px"
                    name="representative_last_name_kanji"
                    placeholder="例：太郎"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                </Flex>
                <Flex gap="16px" marginTop="8px" mb="8px" paddingLeft="8px">
                  <InputField
                    fontWeightLabel="500"
                    height="48px"
                    label="セイ"
                    maxWidth="327px"
                    name="representative_first_name_kata"
                    placeholder="例：ミライ"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                  <InputField
                    fontWeightLabel="500"
                    height="48px"
                    label="メイ"
                    maxWidth="327px"
                    name="representative_last_name_kata"
                    placeholder="例：タロウ"
                    sizeLabel="14px"
                    onChange={handleChange}
                  />
                </Flex>
              </>
            ),
          },
          {
            label: '設立年月日',
            components: (
              <Box paddingLeft="8px">
                <Flex gap="4px" mb="8px">
                  <SelectField
                    isCenter
                    error={Boolean(errors.year)}
                    errorText={errors.year}
                    height="48px"
                    name="year"
                    options={yearOptions(1975, Number(currenYear), 1)}
                    placeholder="----"
                    sizeLabel="14px"
                    valueOption={
                      yearOptions(1975, Number(currenYear), 1).find(
                        it => it.value === values.year,
                      ) ?? null
                    }
                    width="105px"
                    onChange={e => {
                      setFieldValue('year', e?.value)
                      if (values.month || values.day) {
                        setFieldValue('day', '')
                        setFieldValue('month', '')
                      }
                    }}
                  />
                  <Text mt="8px"> 年</Text>
                  <SelectField
                    isCenter
                    error={Boolean(errors.month)}
                    errorText={errors.month}
                    height="48px"
                    name="month"
                    options={monthOptions(values.year)}
                    placeholder="--"
                    sizeLabel="14px"
                    valueOption={
                      monthOptions(values.year).find(
                        it => it.value === values.month,
                      ) ?? null
                    }
                    width="80px"
                    onChange={e => {
                      setFieldValue('month', e?.value)
                    }}
                  />
                  <Text mt="8px">月</Text>
                  <SelectField
                    isCenter
                    error={Boolean(errors.day)}
                    errorText={errors.day}
                    height="48px"
                    name="day"
                    options={dayInMonthOptions(values.year, values.month)}
                    placeholder="--"
                    sizeLabel="14px"
                    valueOption={
                      dayInMonthOptions(values.year, values.month).find(
                        it => it.value === values.day,
                      ) ?? null
                    }
                    width="80px"
                    onChange={e => setFieldValue('day', e?.value)}
                  />
                  <Text mt="8px">日</Text>
                </Flex>
              </Box>
            ),
          },
          {
            label: '資本金',
            components: (
              <Flex
                alignItems="center"
                gap="4px"
                mb="8px"
                paddingLeft="8px"
                paddingTop="8px"
              >
                <InputField
                  fontWeightLabel="500"
                  height="48px"
                  maxWidth="310px"
                  name="funds"
                  placeholder="0"
                  sizeLabel="14px"
                  textAlign="right"
                  onChange={e => {
                    const value = e.target.value
                    const withoutDot = value.replace(/\./g, '')
                    const onlyNums = withoutDot.replace(/\D/g, '')
                    setFieldValue('funds', onlyNums)
                  }}
                />
                円
              </Flex>
            ),
          },
        ]}
        marginTop="16px"
      />
    </Box>
  )
}
