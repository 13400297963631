import React, { FC } from 'react'
import { Box, CircularProgress, Flex, Grid, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { AutoResizeTextarea } from 'components/fromAdvisor/elements/AutoResizeTextarea'
import { useAutomaticReply } from './AutomaticReply.hooks'

/**
 * AutomaticReply component page.
 * @return {JSX.Element} AutomaticReply component.
 */
export const AutomaticReply: FC = () => {
  const {
    formValueReply,
    isLoadingReply,
    isLoadingFetch,
    handleChangeSwitch,
    onChangeTextAreaReply,
    handleSubmit,
  } = useAutomaticReply()
  return (
    <Box
      bg={'white.50'}
      borderRadius={'0 0 20px 20px'}
      h={'calc(100vh - 210px)'}
      overflowY={'auto'}
    >
      {isLoadingFetch ? (
        <Flex alignItems="center" h={'full'} justifyContent="center" w="full">
          <CircularProgress isIndeterminate color="blue.900" size={10} />
        </Flex>
      ) : (
        <Grid
          gap={6}
          gridTemplateColumns={'repeat(auto-fit, 1fr))'}
          p={6}
          pb={'29px'}
        >
          <ReplyWrapper>
            <Flex justify={'space-between'}>
              <TextTitleReply>自動返信内容</TextTitleReply>
              <Flex alignItems={'center'} gap={2}>
                <TextContentReply>自動返信ON／OFF</TextContentReply>
                <Switch
                  isChecked={formValueReply.isUseAutomaticReply}
                  size="lg"
                  onChange={handleChangeSwitch}
                />
              </Flex>
            </Flex>
            <TextReply>
              ※ONにすると、お客様のメッセージに自動で定型文を送信します。
            </TextReply>
            <TextAreaContentReply
              name={'automaticReplyMessage'}
              value={formValueReply.automaticReplyMessage}
              onChange={e => {
                onChangeTextAreaReply('automaticReplyMessage', e.target.value)
              }}
            />
            <Button
              bg={'blue.900'}
              border={'none'}
              color={'white.50'}
              float={'right'}
              h={12}
              isDisabled={
                formValueReply.automaticReplyMessage ===
                  formValueReply.lastAutomaticReplyMessage &&
                formValueReply.isUseAutomaticReply ===
                  formValueReply.lastIsUseAutomaticReply
              }
              isLoading={isLoadingReply}
              mb={8}
              mt={4}
              text={'登録する'}
              w={43.5}
              onClick={handleSubmit}
            />
          </ReplyWrapper>
        </Grid>
      )}
    </Box>
  )
}

const ReplyWrapper = styled(Box)`
  border-radius: ${props => props.theme.radii['2xl']};
  background: ${props => props.theme.colors.white[50]};
  height: ${props => props.theme.sizes.full};
  max-height: ${props => props.theme.sizes[119]};
  height: ${props => props.theme.sizes.full};
  border: ${props =>
    `${props.theme.space[0.5]} solid ${props.theme.colors.purple[250]}`};
  padding-left: ${props => props.theme.space[8]};
  padding-right: ${props => props.theme.space[8]};
  width: ${props => props.theme.sizes.full};
  padding-top: ${props => props.theme.space[6]};
  overflow: hidden;
  overflow-y: auto;
`

const TextTitleReply = styled(Text)`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.base};
  color: ${props => props.theme.colors.black[100]};
`

const TextContentReply = styled(Text)`
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights.shorter};
  color: ${props => props.theme.colors.black[100]};
`

const TextReply = styled(Text)`
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.lineHeights.short};
  color: ${props => props.theme.colors.black[100]};
  opacity: 0.3;
  margin-top: ${props => props.theme.space[1]};
  margin-bottom: ${props => props.theme.space[4.5]};
  float: right;
`

const TextAreaContentReply = styled(AutoResizeTextarea)`
  border-radius: ${props => props.theme.radii.xl};
  padding-left: ${props => props.theme.space[6]};
  min-height: ${props => props.theme.sizes[69]};
  padding-right: ${props => props.theme.space[6]};
  padding-top: ${props => props.theme.space[4.5]};
  box-shadow: ${props => props.theme.boxShadows.passwordInput};
  background: ${props => props.theme.colors.white[50]};
  border: ${props =>
    `${props.theme.space.px} solid ${props.theme.colors.purple[250]}`};
  font-size: ${props => props.theme.fontSizes.md};
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: ${props => props.theme.lineHeights.short};
  color: ${props => props.theme.colors.black[100]};
`
