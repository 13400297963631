import { Text, Tooltip } from '@chakra-ui/react'
import { ROUTES } from 'constant'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

type SubjectCompanyColumnProps = {
  label: string
  isDetailCompany: boolean
  companyId: number
  typeReport: string
}

const SubjectCompanyColumn: FC<SubjectCompanyColumnProps> = props => {
  const { label, isDetailCompany, companyId, typeReport } = props

  /**
   * handleCompanyClick
   */
  return (
    <Tooltip bgColor="black" color="white" label={label} placement="top-start">
      {companyId ? (
        <Link
          to={`${window.location.origin}${ROUTES.COMPANY}/${companyId}?is_news_reports=${typeReport}`}
        >
          <Text
            color={isDetailCompany ? 'black.100' : 'blue.900'}
            cursor={isDetailCompany ? 'auto' : 'pointer'}
            fontSize="sm"
            fontWeight={isDetailCompany ? 'normal' : 'bold'}
            lineHeight="24px"
            overflow="hidden"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {label}
          </Text>
        </Link>
      ) : (
        <Text
          color={isDetailCompany ? 'black.100' : 'blue.900'}
          // cursor={isDetailCompany ? 'auto' : 'pointer'}
          fontSize="sm"
          fontWeight={isDetailCompany ? 'normal' : 'bold'}
          lineHeight="24px"
          overflow="hidden"
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {label}
        </Text>
      )}
    </Tooltip>
  )
}

export default SubjectCompanyColumn
