import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { data } from './data'

const downColor = '#ec0000'
const downBorderColor = '#8A0000'
const upColor = '#00da3c'
const upBorderColor = '#008F28'

const StockChart = () => {
  const echartsDom = useRef(null)

  const data0 = splitData(data)

  function splitData(rawData: string | any[]) {
    const categoryData = []
    const values = []
    for (var i = 0; i < rawData.length; i++) {
      categoryData.push(rawData[i].splice(0, 1)[0])
      values.push(rawData[i])
    }
    return {
      categoryData: categoryData,
      values: values,
    }
  }

  function calculateEMA(dayCount: number, values: string | any[]) {
    const result = []
    const multiplier = 2 / (dayCount + 1)
    let ema = values[0][1] // EMA starts with the first closing price

    for (let i = 1; i < values.length; i++) {
      ema = (values[i][1] - ema) * multiplier + ema
      result.push(ema)
    }

    return result
  }

  const ema20 = calculateEMA(20, data0.values)

  useEffect(() => {
    const myChart = echarts.init(echartsDom.current)
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
      },
      legend: {
        data: ['EMA20', 'CSCO'],
        formatter: function (name: string) {
          return `${name}`
        },
      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: '15%',
      },
      xAxis: {
        type: 'category',
        data: data0.categoryData,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: {
        scale: true,
        splitArea: {
          show: true,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 50,
          end: 100,
        },
        {
          show: true,
          type: 'slider',
          top: '90%',
          start: 50,
          end: 100,
        },
      ],
      series: [
        {
          name: 'EMA20',
          type: 'line',
          data: ema20,
          smooth: true,
          showSymbol: false,
          lineStyle: {
            width: 1,
          },
        },
        {
          name: 'CSCO',
          type: 'candlestick',
          data: data0.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upBorderColor,
            borderColor0: downBorderColor,
          },
        },
      ],
    }
    myChart.setOption(option)

    myChart.on('mouseout', function () {})
  }, [data0.categoryData, data0.values, ema20])

  return <div ref={echartsDom} style={{ height: 500, width: '100%' }} />
}

export default StockChart
