import { Flex, Text } from '@chakra-ui/react'
import { SendMailIcon } from 'components/atoms/Icons'
import { Button, Modal } from 'components/fromAdvisor/elements'
import React, { FC } from 'react'

type ProductRegistrationModalProps = {
  isOpen: boolean
  onClose: () => void
}

const ProductRegistrationModal: FC<ProductRegistrationModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} maxWidth={'440px'} onClose={onClose}>
      <Flex
        alignItems={'center'}
        direction={'column'}
        p={'48px 40px 40px 40px'}
      >
        <SendMailIcon />
        <Text
          fontSize={'20px'}
          fontWeight={700}
          lineHeight={'32px'}
          mb="16px"
          mt="8px"
        >
          登録申請完了
        </Text>
        <Text fontSize={'14px'} lineHeight={'24px'}>
          登録を申請しました。
        </Text>
        <Text fontSize={'14px'} lineHeight={'24px'}>
          承認までしばらくお待ちください。
        </Text>
        <Text fontSize={'14px'} lineHeight={'24px'} mb={'48px'} mt="24px">
          承認されるとメールが届きます。
        </Text>
        <Button
          bgColor={'white.base'}
          border="solid 1px"
          borderColor="blue.900"
          borderRadius={'12px'}
          color="blue.900"
          fontSize={'md'}
          fontWeight={'bold'}
          h={'48px'}
          letterSpacing={'tighter'}
          lineHeight={'shorter'}
          p="11px, 24px, 13px, 24px"
          text="とじる"
          w={'full'}
          onClick={onClose}
        />
      </Flex>
    </Modal>
  )
}

export default ProductRegistrationModal
