import { errors, regexEmail } from 'constant'
import { yup } from 'libs/yup'

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .required(errors.required)
    .max(64, '64字数以下で入力してください。')
    .label('メールアドレス')
    .matches(regexEmail, errors.email),
})
