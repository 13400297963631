import { errors, regexPassword } from 'constant'
import { yup } from 'libs'

export const resetPasswordSchema = yup.object({
  password: yup.string().label('new_password'),
  cf_password: yup
    .string()
    .required(errors.required)
    .label('new_password')
    .matches(regexPassword, errors.password)
    .oneOf(
      [yup.ref('password')],
      '※パスワードと確認用パスワードが一致しません。',
    ),
})
