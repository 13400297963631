import { Box, Flex } from '@chakra-ui/react'
import { GptIcon } from 'components/atoms/Icons'
import React, { FC, Fragment } from 'react'
import SkeletonAI from '../SkeletonAI'
import ReactMarkdown from '../ReactMarkdown'

type ExplanationAiProps = {
  valueExplanation: { loading: boolean; data: string; done: boolean }
  showContent: boolean
}

const ExplanationAi: FC<ExplanationAiProps> = ({
  showContent,
  valueExplanation,
}) => {
  const { data, loading, done } = valueExplanation

  return (
    <Fragment>
      {showContent ? (
        <>
          {loading ? (
            <SkeletonAI />
          ) : (
            <Flex
              bgColor={'#F3F5F7'}
              borderRadius={'12px'}
              gap={'12px'}
              mb={'12px'}
              p={'12px'}
            >
              <Box w={10}>
                <Flex
                  alignItems="center"
                  bgGradient="linear(160.99deg, #AFA4D0 0.9%, #4474CD 30.15%, #3452A4 92.96%)"
                  borderRadius={12}
                  h={10}
                  justifyContent="center"
                  w={10}
                >
                  <GptIcon fontSize={24} />
                </Flex>
              </Box>

              <ReactMarkdown data={data} isCopyBtn={true} isDone={done} />
            </Flex>
          )}
        </>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default ExplanationAi
