import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * PenLight icon
 * @param props
 * @constructor
 */
export const PenLightEdit: FC<IconProps> = props => {
  return (
    <Icon {...props} fontSize="24px">
      <svg
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3822_82881)">
          <path
            d="M15.4598 1.52441C15.827 1.15723 16.4208 1.15723 16.784 1.52441L18.4481 3.18848C18.8153 3.55566 18.8153 4.14941 18.4481 4.5127L16.3114 6.65723L13.3192 3.66504L15.4598 1.52441ZM12.4364 4.54785L15.4286 7.54004L6.23325 16.7314C5.93637 17.0283 5.57309 17.2432 5.17075 17.3604L1.54575 18.4307L2.61216 14.8057C2.72934 14.4033 2.94809 14.04 3.24106 13.7432L12.4364 4.54785ZM17.6707 0.641602C16.8153 -0.213867 15.4325 -0.213867 14.577 0.641602L2.35825 12.8564C1.91294 13.3018 1.58872 13.8486 1.41294 14.4502L0.0262177 19.1768C-0.0401886 19.3955 0.0223114 19.6338 0.182468 19.7939C0.342624 19.9541 0.580905 20.0166 0.799655 19.9502L5.52622 18.5596C6.12778 18.3838 6.67466 18.0557 7.11997 17.6143L19.3348 5.39941C20.1903 4.54395 20.1903 3.16113 19.3348 2.30566L17.6707 0.641602Z"
            fill="#0084FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_3822_82881">
            <rect fill="white" height="20" width="20" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}
