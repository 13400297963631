import { Flex, List, Skeleton } from '@chakra-ui/react'
import React, { FC } from 'react'

type SkeletonLoadingProps = {
  isColumn: boolean
}

const SkeletonLoading: FC<SkeletonLoadingProps> = ({ isColumn }) => {
  return isColumn ? (
    <List display="flex" flexDirection="column" gap={2} w="100%">
      {Array.from({ length: 5 }).map((_, index) => (
        <SkeletonLoadingItem key={index} />
      ))}
    </List>
  ) : (
    <Flex gap={8} pb="4px" w="100%">
      <List display="flex" flexDirection="column" gap={2} w="calc(50% - 16px)">
        {Array.from({ length: 5 }).map((_, index) => (
          <SkeletonLoadingItem key={index} />
        ))}
      </List>
      <List display="flex" flexDirection="column" gap={2} w="calc(50% - 16px)">
        {Array.from({ length: 5 }).map((_, index) => (
          <SkeletonLoadingItem key={index + 5} />
        ))}
      </List>
    </Flex>
  )
}

export default SkeletonLoading

const SkeletonLoadingItem = () => {
  return (
    <Flex alignItems={'center'} gap={4} w={'full'}>
      <Skeleton borderRadius={'12px'} h={'32px'} minW={'32px'}></Skeleton>
      <Skeleton h={'24px'} w={'full'}></Skeleton>
    </Flex>
  )
}
