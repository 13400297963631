/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Text, Button } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import { MoreVertical } from 'components/atoms/Icons'
import React, { FC } from 'react'
import { Modal } from 'components/fromAdvisor/elements'
import { ContentAskAI } from '../ContentAskAI'

type ModalAIAdviceProps = {
  onClose: () => void
  isOpen: boolean
  askAIOptions: { label: string; value: number | string }[]
  AIAnswer: { data: string; loading: boolean; done: boolean }
  isValues: boolean
  setAIAnswer: React.Dispatch<
    React.SetStateAction<{ data: string; loading: boolean; done: boolean }>
  >
  conversationId: number | null
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  setConversationId: React.Dispatch<React.SetStateAction<number | null>>
  setValueAskAI: (value: any) => void
  value: string | number | null
  setRenderPrompt?: React.Dispatch<React.SetStateAction<string>>
}

/**
 * ModalAIAdvice molecules
 * @constructor
 */
const ModalAIAdvice: FC<ModalAIAdviceProps> = ({
  onClose,
  isOpen,
  askAIOptions,
  AIAnswer,
  isValues,
  setAIAnswer,
  conversationId,
  setShowConfirmModal,
  setExpanded,
  setConversationId,
  setValueAskAI,
  value,
  setRenderPrompt,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isShowIconClose={false}
      maxWidth="683px"
      onClose={onClose}
    >
      <Box
        bg="white.base"
        borderRadius="20px"
        boxSizing="border-box"
        padding="24px 32px"
        w="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="12px"
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            <Text
              color="black.100"
              fontSize="lg"
              fontWeight="bold"
              height="100%"
              letterSpacing="-0.02em"
              lineHeight="tall"
              maxH="511px"
            >
              AIアドバイス
            </Text>
            <SelectField
              height="40px"
              isDisabled={!AIAnswer.done}
              maxWidth="412px"
              name="askAI"
              options={askAIOptions}
              placeholder="当社の最新の経営・財務・営業戦略は？ "
              valueOption={askAIOptions.find(it => it.value === value)}
              onChange={e => {
                if (e?.value !== value) {
                  setAIAnswer(prev => ({
                    ...prev,
                    data: '',
                    loading: true,
                    done: false,
                  }))
                  setConversationId(null)
                  setRenderPrompt && setRenderPrompt('')
                  setValueAskAI(e?.value)
                }
              }}
            />
          </Flex>
          <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} />
        </Flex>
        <ContentAskAI
          content={AIAnswer}
          heightContent="425px"
          isCopyBtn={true}
          isDone={AIAnswer.done}
          isHidden={false}
          isOpenModal={isOpen}
          isValues={isValues}
        />
        <Flex gap="12px" justifyContent="center" mt="24px">
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'blue.900',
            }}
            bgColor="blue.900"
            border="none"
            borderRadius="12px"
            gap="8px"
            height="48px"
            isDisabled={!conversationId}
            maxW="280px"
            padding="12px"
            width="100%"
            onClick={() => {
              setExpanded(false)
              setShowConfirmModal(true)
            }}
          >
            <Text
              color="white.50"
              fontSize="md"
              fontWeight="bold"
              letterSpacing="0.2px"
              lineHeight="shorter"
            >
              続けて質問
            </Text>
            <Text
              color="white.50"
              fontSize="xs"
              fontWeight="normal"
              letterSpacing="0.2px"
              lineHeight="shorter"
            >
              (AIチャットへ切替)
            </Text>
          </Button>
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'white.base',
            }}
            bgColor="white.base"
            border="1px solid"
            borderColor="blue.900"
            borderRadius="12px"
            color="blue.900"
            height="48px"
            maxW="280px"
            padding="12px"
            width="100%"
            onClick={onClose}
          >
            とじる
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default ModalAIAdvice
