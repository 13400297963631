import React, { FC } from 'react'
import { useDefaultPromptTabContent } from './components/DefaultPromptTabContent/DefaultPromptTabContent.hooks'
import { DefaultPromptTabContent } from './components/DefaultPromptTabContent/DefaultPromptTabContent'

/**
 * DefaultPromptTab component page.
 * @return {JSX.Element} DefaultPromptTab component.
 */
export const DefaultPromptTab: FC = () => {
  const {
    categories,
    handleOnChangeTabCategory,
    consultations,
    isLoadingFetchData,
    handleCheckFavouriteContent,
    handleSearchPrompt,
    handleSortPrompt,
    listHistorySearch,
  } = useDefaultPromptTabContent()
  return (
    <DefaultPromptTabContent
      categories={categories}
      consultations={consultations}
      handleCheckFavouriteContent={handleCheckFavouriteContent}
      handleOnChangeTabCategory={handleOnChangeTabCategory}
      handleSearchPrompt={handleSearchPrompt}
      handleSortPrompt={handleSortPrompt}
      isLoadingFetchData={isLoadingFetchData}
      listHistorySearch={listHistorySearch}
    />
  )
}
