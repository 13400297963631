import React, { FC } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ChatBotIcon } from 'components/fromAdvisor/elements/Icons'
import styled from '@emotion/styled'

type HeaderMainChatProps = {
  isHaveAnswer: boolean
}

/**
 * HeaderMainChat component page.
 * @return {JSX.Element} HeaderMainChat component.
 */
export const HeaderMainChat: FC<HeaderMainChatProps> = ({ isHaveAnswer }) => {
  return (
    <Flex
      bg={'white.700'}
      justifyContent={'space-between'}
      py={5}
      top={0}
      w={'full'}
    >
      {isHaveAnswer ? (
        <Box />
      ) : (
        <Flex alignItems={'center'} gap={4}>
          <ChatBotIcon />
          <QuestionTitleStyled>
            あなたをサポートします。{'\n'}ご相談は何ですか？
          </QuestionTitleStyled>
        </Flex>
      )}
    </Flex>
  )
}

const QuestionTitleStyled = styled(Text)`
  color: ${props => props.theme.colors.black[100]};
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: ${props => props.theme.lineHeights[6]};
  letter-spacing: ${props => props.theme.letterSpacings[-4]};
  white-space: pre-wrap;
`
