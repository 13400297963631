import { Icon, IconProps } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * Menu icon
 * @param props
 * @constructor
 */
export const Menu: FC<IconProps> = props => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 16"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.143C0 .512.538 0 1.2 0h21.6c.662 0 1.2.512 1.2 1.143 0 .63-.538 1.143-1.2 1.143H1.2c-.662 0-1.2-.512-1.2-1.143zm22.8 5.714H1.2C.538 6.857 0 7.37 0 8c0 .63.538 1.143 1.2 1.143h21.6c.662 0 1.2-.512 1.2-1.143 0-.63-.538-1.143-1.2-1.143zm0 6.857H1.2c-.662 0-1.2.512-1.2 1.143S.538 16 1.2 16h21.6c.662 0 1.2-.512 1.2-1.143 0-.63-.538-1.143-1.2-1.143z"
          fill="currentColor"
        ></path>
      </svg>
    </Icon>
  )
}
