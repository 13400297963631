import React, { FC } from 'react'
import {
  Box,
  Flex,
  Text,
  Button,
  useTheme,
  CircularProgress,
  Image,
} from '@chakra-ui/react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
} from '@chakra-ui/tabs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { Plus } from 'components/atoms/Icons'
import { InputField, SelectField } from 'components/atoms'
import { FormikProvider, useFormik } from 'formik'
import { ROUTES } from 'constant'
import { configs } from 'configs'
import { MyPromptTabContentProps } from './MyPromptTabContent.type'
import { MyPromptTabItem } from './MyPromptTabItem'

const DEFAULT_TAB = 0

/**
 * MyPromptTabContent component page.
 * @return {JSX.Element} MyPromptTabContent component.
 */
export const MyPromptTabContent: FC<MyPromptTabContentProps> = ({
  categories,
  handleOnChangeTabCategory,
  myConsultations,
  isLoadingFetchData,
  handleCheckFavouriteContent,
  handleSearchPrompt,
  handleSortPrompt,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useGetQueryParams(location)
  const myPromptTab = queryParams.get('myPromptTab')
  const defaultIndex = myPromptTab ? Number(myPromptTab) : DEFAULT_TAB

  const formik = useFormik({
    initialValues: {
      search_key: '',
      sort_by: { label: '登録日順', value: 'created_at' },
    },
    /**
     *
     */
    onSubmit() {},
  })

  return (
    <FormikProvider value={formik}>
      <Box
        bg={'white.50'}
        borderBottomRadius={4}
        maxW={'calc(100vw - 48px)'}
        w={'full'}
      >
        <Box h={'calc(100vh - 146px)'} mx={6}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            padding="16px 0"
          >
            <Button
              _hover={{
                bgColor: 'white.50',
              }}
              bgColor="white.50"
              border="solid 1px"
              borderColor="blue.900"
              borderRadius="12px"
              color="blue.900"
              fontSize="sm"
              fontWeight="bold"
              h="40px"
              leftIcon={<Plus color="blue.900" fontSize={24} />}
              lineHeight="shorter"
              padding="8px 16px"
              onClick={() => navigate(ROUTES.PROMPT_REGISTRATION)}
            >
              Myプロンプト登録
            </Button>
            <InputField
              name="search_key"
              placeholder="フリーワード検索"
              width="242px"
              onChange={e => {
                formik.setFieldValue('search_key', e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSearchPrompt(formik.values.search_key)
                }
              }}
            />
          </Flex>
          <TabsChakra index={defaultIndex} variant="unstyled">
            <Flex
              alignItems="flex-start"
              gap={'32px'}
              justifyContent="space-between"
            >
              <TabList
                overflowX={'auto'}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: theme.space[2],
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: theme.colors.black['150'],
                    borderRadius: theme.radii.lg,
                    border: `${theme.sizes[1]} solid ${theme.colors.white['50']}`,
                  },
                }}
                w={'calc(100vw - 221px)'}
              >
                {categories.map((c, index) => (
                  <Tab
                    key={index}
                    _selected={{
                      color: 'black.100',
                      bg: 'gray.600',
                      borderRadius: '4px 4px 0 0',
                      fontWeight: 'semibold',
                    }}
                    color={'blue.900'}
                    fontSize={'sm'}
                    fontWeight={'normal'}
                    gap={2}
                    h={12}
                    letterSpacing={theme.letterSpacings.tighter}
                    lineHeight={theme.lineHeights.shorter}
                    minW="max-content"
                    padding="12px 32px"
                    onClick={() => handleOnChangeTabCategory(c.id, index)}
                  >
                    {myPromptTab && Number(myPromptTab) === index && (
                      <Image src={configs.apiURL + '/' + c.icons} />
                    )}

                    {c.ja_name}
                  </Tab>
                ))}
              </TabList>
              <Flex alignItems="center" gap="10px">
                <Text
                  color="black.100"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  minW="42px"
                >
                  並び順
                </Text>
                <SelectField
                  fontWeight="bold"
                  height="40px"
                  name="sort_by"
                  options={[{ label: '登録日順', value: 'created_at' }]}
                  width="169px"
                  onChange={value => {
                    handleSortPrompt(String(value?.value))
                  }}
                />
              </Flex>
            </Flex>
            {isLoadingFetchData ? (
              <Flex
                alignItems={'center'}
                bg={'gray.600'}
                h={'calc(100vh - 288px)'}
                justifyContent={'center'}
                w={'full'}
              >
                <CircularProgress isIndeterminate color="blue.900" size={10} />
              </Flex>
            ) : (
              <TabPanels>
                {Array.from(Array(categories.length).keys()).map((c, idx) => (
                  <TabPanel key={idx} p={0}>
                    <Flex
                      bg={'gray.600'}
                      borderRadius="0 0 4px 4px"
                      flexDirection={'column'}
                      h={'calc(100vh - 288px)'}
                      overflowY={'auto'}
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: theme.space[2],
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: theme.colors.black['150'],
                          borderRadius: theme.radii.lg,
                        },
                      }}
                    >
                      <Flex flexDirection={'column'} gap="10px" padding="16px">
                        {myConsultations?.map((item, index) => (
                          <MyPromptTabItem
                            key={index}
                            handleCheckFavouriteContent={() =>
                              handleCheckFavouriteContent(item.id)
                            }
                            index={index}
                            item={item}
                          />
                        ))}
                      </Flex>
                    </Flex>
                  </TabPanel>
                ))}
              </TabPanels>
            )}
          </TabsChakra>
        </Box>
      </Box>
    </FormikProvider>
  )
}
