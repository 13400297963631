import {
  Box,
  Text,
  Flex,
  Image,
  CircularProgress,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { PenLight } from 'components/atoms/Icons'
import { Button, TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ADMIN_ROUTES, ROLE, icons } from 'constant'
import { companyServices } from 'services'
import { CompanyResponse } from 'services/companyServices'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'

import { isEmpty } from 'lodash'
import LinkageSourceModal from 'components/molecules/LinkageSourceModal'
import { fomatPriceJapan } from 'utils/fomatNumber'
import { CompanyForm } from '../components/CompanyForm'
import { BusinessInfoDetail } from '../components/BusinessInfoDetail'

interface Options {
  label: string
  value: string | number
}
/**
 * CompanyDetail page
 * @constructor
 */
export const CompanyDetail: FC<{ size: string }> = ({ size }) => {
  const navigate = useNavigate()
  const { id: companyId } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [tab, setTab] = useState<number>(1)
  const dispatch = useDispatch()
  const [options, setOptions] = useState<Options[]>([])
  const [companyDetail, setCompanyDetail] = useState<{
    loading: boolean
    data: CompanyResponse
  }>({
    loading: false,
    data: {} as CompanyResponse,
  })

  /**
   * @returns function that get company detail
   */

  const fetchDataCompanyDetail = useCallback(async () => {
    if (!companyId) return
    try {
      setCompanyDetail(prev => ({ ...prev, loading: true }))
      const { data } = await companyServices.getCompanyDetail(size, {
        id: Number(companyId),
      })
      const { data: options } = await companyServices.getCompanyCategories()
      dispatch(setBreadcrumb([{ text: data.company_name, link: '' }]))

      setCompanyDetail(prev => ({ ...prev, data, loading: false }))
      setOptions(
        options.map(o => ({
          label: o.name,
          value: o.id,
        })),
      )
    } catch (error) {}
    setCompanyDetail(prev => ({ ...prev, loading: false }))
  }, [companyId, dispatch, size])

  useEffect(() => {
    fetchDataCompanyDetail()
  }, [fetchDataCompanyDetail])

  /**
   * @returns function that handle change tab
   */
  const handleGetLocation = async (address: string) => {
    window.open('http://maps.google.com/?q=' + address.replaceAll(' ', '+'))
  }

  const goToViolation = (id: string) => {
    const violation = document.getElementById(id)
    window.scrollTo({
      top: violation?.offsetTop,
      behavior: 'smooth',
    })
  }

  const linkingSFA = async (override: boolean) => {
    if (!companyId) return
    try {
      const { data } = await companyServices.linkSFA(
        [Number(companyId)],
        override,
      )
      toast({
        status: !isEmpty(data.err) ? 'error' : 'success',
        description: (
          <Box height="auto">
            <Text>
              {!isEmpty(data.err)
                ? '以下の企業はSalesforceで存在しません。'
                : '連携成功されました。'}
            </Text>
            {!isEmpty(data.err) && (
              <Flex flexDirection="column">
                <Text>・{companyDetail.data.company_name}</Text>
              </Flex>
            )}
          </Box>
        ),
        position: 'top',
      })
      onClose()
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
        position: 'top',
      })
    }
  }

  const locationCompany = companyDetail.data
    ? `〒${companyDetail.data.post_code ?? ''}${
        companyDetail?.data?.state?.name_jp ?? ''
      }${companyDetail?.data?.city?.name_jp ?? ''}${
        companyDetail.data.address1 ?? ''
      }${companyDetail.data.address2 ?? ''}`
    : ''

  if (companyDetail.loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  const date = companyDetail.data.established_date
    ? `${moment(companyDetail.data.established_date).format('YYYY')}年${moment(
        companyDetail.data.established_date,
      ).format('MM')}月${moment(companyDetail.data.established_date).format(
        'DD',
      )}日`
    : '-'

  return (
    <Box
      bgColor="white.50"
      borderRadius={4}
      minHeight="calc(100vh - 88px)"
      paddingTop="24px"
      w="100%"
    >
      <Box pb="32px" px="32px">
        <Box display="flex" justifyContent="space-between" paddingBottom="8px">
          <Flex flexDirection="column" gap="8px">
            <Flex alignItems="center" gap="16px">
              <Text fontSize="16px" fontWeight="700">
                {companyDetail.data.security_code}
              </Text>
              <Text fontSize="16px">東証プライム</Text>
              {!isEmpty(companyDetail?.data?.business_activity) && (
                <Text
                  backgroundColor="purple.250"
                  fontSize="14px"
                  height="22px"
                  lineHeight="22px"
                  paddingX="7px"
                >
                  {companyDetail?.data?.business_activity
                    .map(
                      it =>
                        options.find(o => o.value === it.category_id)?.label,
                    )
                    .join('・')}
                </Text>
              )}
            </Flex>
            <Flex height="70px">
              <Flex flexDirection="column" gap="8px" pr="24px">
                <TextOverflowTooltip
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="40px"
                  maxW="600px"
                  value={companyDetail.data.company_name}
                />
                <Flex gap="16px">
                  {companyDetail.data.company_name_kana && (
                    <TextOverflowTooltip
                      color="gray.800"
                      fontSize="14px"
                      lineHeight={'24px'}
                      maxW="300px"
                      value={companyDetail.data.company_name_kana}
                    />
                  )}
                  {companyDetail.data.company_name_kana && (
                    <TextOverflowTooltip
                      color="gray.800"
                      fontSize="14px"
                      lineHeight={'24px'}
                      maxW="300px"
                      value={companyDetail.data.company_name_en}
                    />
                  )}
                </Flex>
              </Flex>
              <Flex borderLeft="1px solid #E8ECEF" gap="10" pl="24px">
                <Box>
                  <Text
                    color="rgba(20, 23, 24, 0.30)"
                    fontSize="12px"
                    lineHeight="12px"
                    mb="8px"
                  >
                    担当部店
                  </Text>
                  <TextOverflowTooltip
                    fontSize="16px"
                    lineHeight="24px"
                    maxW="200px"
                    value={companyDetail.data.department}
                  />
                </Box>
                <Box>
                  <Text
                    color="rgba(20, 23, 24, 0.30)"
                    fontSize="12px"
                    lineHeight="12px"
                    mb="8px"
                  >
                    担当者
                  </Text>
                  <TextOverflowTooltip
                    fontSize="16px"
                    lineHeight="24px"
                    maxW="200px"
                    value={companyDetail.data.contact_person_name}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="16px">
            <Flex gap="16px">
              <Text fontSize="12px" opacity="0.5">
                登録日：
                {moment(companyDetail.data.created_at).format('YYYY/MM/DD')}
              </Text>
              <Text fontSize="12px" opacity="0.5">
                更新日：
                {moment(companyDetail.data.updated_at).format('YYYY/MM/DD')}
              </Text>
              {companyDetail.data.salesforce_lightning ? (
                <Text
                  alignItems="center"
                  display="flex"
                  fontSize="14px"
                  gap="2px"
                >
                  <Image src={icons.CHECK_CIRCLE_DONE} />
                  連携済
                </Text>
              ) : (
                <Text
                  alignItems="center"
                  display="flex"
                  fontSize="14px"
                  gap="2px"
                >
                  <Image src={icons.CHECK_CIRCLE_FALSE} />
                  未連携
                </Text>
              )}
            </Flex>
            <Flex justifyContent="end">
              {size === ROLE.ADMIN && (
                <Button
                  backgroundColor="white.50"
                  border="1px solid #0084FF"
                  borderRadius="12px"
                  color="blue.900"
                  fontSize={'sm'}
                  fontWeight="700"
                  height="40px"
                  leftIcon={<PenLight />}
                  text="編集する"
                  width="116px"
                  onClick={() =>
                    navigate(ADMIN_ROUTES.COMPANY_EDIT + `/${companyId}`)
                  }
                />
              )}
            </Flex>
          </Flex>
        </Box>
        <Box>
          <CompanyForm
            fieldform={[
              {
                label: '本社所在地',
                components: (
                  <Flex gap="16px">
                    {locationCompany ? (
                      <>
                        <Text fontSize="14px" lineHeight="24px">
                          {locationCompany}
                        </Text>
                        <Text
                          color="blue.900"
                          cursor="pointer"
                          fontSize="14px"
                          lineHeight="24px"
                          onClick={() => {
                            handleGetLocation(locationCompany)
                          }}
                        >
                          地図表示
                        </Text>
                      </>
                    ) : (
                      '-'
                    )}
                  </Flex>
                ),
                isBackground: 'white.50',
              },
              {
                label: '代表電話',
                components: (
                  <Text fontSize="14px" lineHeight="24px">
                    {companyDetail.data.phone ?? '-'}
                  </Text>
                ),
                isBackground: 'white.50',
              },
              {
                label: '取引先担当者名',
                components: (
                  <Text fontSize="14px" lineHeight="24px">
                    {companyDetail.data.contact_first_name_kanji +
                      companyDetail.data.contact_last_name_kanji}
                  </Text>
                ),
                isBackground: 'white.50',
              },
              {
                label: '企業URL',
                components: (
                  <Text
                    color="blue.900"
                    cursor="pointer"
                    fontSize="14px"
                    lineHeight="24px"
                  >
                    {companyDetail.data.company_url
                      ? companyDetail.data.company_url
                      : '-'}
                  </Text>
                ),
                isBackground: 'white.50',
              },
              {
                label: '代表者',
                components: (
                  <Text fontSize="14px" lineHeight="24px">
                    {!isEmpty(
                      companyDetail.data.representative_first_name_kanji ||
                        companyDetail.data.representative_last_name_kanji,
                    )
                      ? companyDetail.data.representative_first_name_kanji +
                        companyDetail.data.representative_last_name_kanji
                      : '-'}
                  </Text>
                ),
                isBackground: 'white.50',
              },
              {
                label: '設立',
                components: (
                  <Text fontSize="14px" lineHeight="24px">
                    {date}
                  </Text>
                ),
                isBackground: 'white.50',
              },
              {
                label: '資本金',
                components: (
                  <Text fontSize="14px" lineHeight="24px">
                    {fomatPriceJapan(companyDetail.data.funds)}
                  </Text>
                ),
                isBackground: 'white.50',
              },
            ]}
            marginTop="16px"
          />
        </Box>
      </Box>
      <Flex px="32px">
        <Text
          bgColor={tab === 1 ? '#E8ECEF' : ''}
          borderRadius="4px 4px 0px 0px"
          color={tab === 1 ? '' : '#0084FF'}
          cursor="pointer"
          p="12px 10px"
          textAlign="center"
          width="120px"
          onClick={() => {
            setTab(1)
            goToViolation('1')
          }}
        >
          事業情報
        </Text>
        <Text
          bgColor={tab === 2 ? '#E8ECEF' : ''}
          borderRadius="4px 4px 0px 0px"
          color={tab === 2 ? '' : '#0084FF'}
          cursor="pointer"
          p="12px 10px"
          textAlign="center"
          width="120px"
          onClick={() => {
            setTab(2)
            goToViolation('2')
          }}
        >
          人事情報
        </Text>
        <Text
          bgColor={tab === 3 ? '#E8ECEF' : ''}
          borderRadius="4px 4px 0px 0px"
          color={tab === 3 ? '' : '#0084FF'}
          cursor="pointer"
          p="12px 10px"
          textAlign="center"
          width="120px"
          onClick={() => {
            setTab(3)
            goToViolation('3')
          }}
        >
          財務情報
        </Text>
        <Text
          bgColor={tab === 4 ? '#E8ECEF' : ''}
          borderRadius="4px 4px 0px 0px"
          color={tab === 4 ? '' : '#0084FF'}
          cursor={size === ROLE.ADVISOR ? 'not-allowed' : 'pointer'}
          p="12px 10px"
          textAlign="center"
          width="150px"
          onClick={() => {
            if (size === ROLE.ADVISOR) return
            setTab(4)
            if (!companyDetail.data.salesforce_lightning) {
              onOpen()
            } else {
              toast({
                status: 'warning',
                description: 'この企業はすでにSFAに連携されました。',
                position: 'top',
              })
            }
          }}
        >
          SFA連携データ
        </Text>
      </Flex>
      <Box bgColor="#E8ECEF" borderBottomRadius={4} padding="24px 32px">
        <BusinessInfoDetail data={companyDetail.data} options={options} />
      </Box>
      <LinkageSourceModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={linkingSFA}
      />
    </Box>
  )
}
