import React, { FC } from 'react'
import { Avatar } from 'components/fromAdvisor/elements'
import { Box, Flex, IconButton, Text, useTheme } from '@chakra-ui/react'
import { PaperPlaneIcon } from 'components/fromAdvisor/elements/Icons'
import { RichTextarea, createRegexRenderer } from 'rich-textarea'
import { InfoIcon } from '@chakra-ui/icons'
import { ChatBoxProps } from './MainChat.types'
import './styleChatBox.css'

const InfoVariable: FC<{ variable: string; label?: string }> = ({
  variable,
  label = 'に企業名を入力してください。',
}) => {
  return (
    <Flex alignItems={'center'}>
      <InfoIcon color={'#F75555'} />
      <Text color="#F75555">
        {variable}
        {label}
      </Text>
    </Flex>
  )
}

/**
 * ChatBox component page.
 * @return {JSX.Element} ChatBox component.
 */
export const ChatBox: FC<ChatBoxProps> = ({
  question,
  onSubmit,
  setQuestion,
  onEnter,
  avatar,
}) => {
  const theme = useTheme()
  const renderer = createRegexRenderer([
    [
      /「顧客名」|ここに記事を貼り付けてください|「業界名」|「業界名」or「顧客名」|「企業名」/g,
      { color: '#c7b9b7' },
    ],
  ])

  const CUSTOMER = '「顧客名」'
  const NEWS = 'ここに記事を貼り付けてください'
  const INDUSTRY = '「業界名」'
  const CUSTOMERORINDUSTRY = '「業界名」or「顧客名」'
  const COMPANY = '「企業名」'

  const checkVariable = (nameVariable: string) =>
    question.includes(nameVariable)

  const isQuestionNotVariable = !(
    checkVariable(CUSTOMER) ||
    checkVariable(NEWS) ||
    checkVariable(INDUSTRY) ||
    checkVariable(CUSTOMERORINDUSTRY) ||
    checkVariable(COMPANY)
  )

  return (
    <Box w={'full'}>
      <Flex
        bg={'white.50'}
        borderColor={'purple.250'}
        borderRadius={'xl'}
        borderWidth={'3px'}
        boxShadow={theme.boxShadows.insetInputBase}
        gap={3.5}
        height={'fit-content'}
        mb={10}
        minH={'280px'}
        px={6}
        py={8}
        w={'full'}
      >
        <Box maxH={15} minW={15} w={15}>
          <Avatar src={avatar} />
        </Box>
        <Flex
          alignItems={'flex-end'}
          flexDirection={'column'}
          h={'full'}
          w={'full'}
        >
          <RichTextarea
            autoHeight={true}
            className="chatbox-textarea"
            placeholder="右の検索からAIへの相談内容をお選びください。自由入力もできます。"
            style={{
              width: '100%',
              minHeight: '192px',
              maxHeight: 'calc(100vh - 396px)',
              resize: 'none',
              overflow: 'auto',
            }}
            value={question}
            onChange={e => setQuestion(e.target.value)}
            onKeyDown={e => {
              if (isQuestionNotVariable) {
                onEnter(e)
              }
            }}
          >
            {renderer}
          </RichTextarea>
          <IconButton
            _hover={{
              background: 'transparent',
            }}
            aria-label="send message"
            bg={'transparent'}
            icon={<PaperPlaneIcon />}
            zIndex={theme.zIndices.docked}
            onClick={onSubmit}
          />
        </Flex>
      </Flex>
      {checkVariable(CUSTOMER) && <InfoVariable variable={CUSTOMER} />}
      {checkVariable(COMPANY) && <InfoVariable variable={COMPANY} />}
      {checkVariable(INDUSTRY) && (
        <InfoVariable
          label="に業界名を入力してください。"
          variable={INDUSTRY}
        />
      )}
      {checkVariable(CUSTOMERORINDUSTRY) && (
        <InfoVariable variable={CUSTOMERORINDUSTRY} />
      )}
      {checkVariable(NEWS) && (
        <Flex alignItems={'center'}>
          <InfoIcon color={'#F75555'} />
          <Text color="#F75555">＃対象記事に記事を貼り付けてください。</Text>
        </Flex>
      )}
    </Box>
  )
}
