import { errors, regexEmail, regexPassword } from 'constant'
import { yup } from 'libs/yup'

export const loginValidateSchema = yup.object({
  email: yup
    .string()
    .required(errors.required)
    .max(64, '64字数以下で入力してください。')
    .label('メールアドレス')
    .matches(regexEmail, errors.email),
  password: yup
    .string()
    .required(errors.required)
    .label('パスワード')
    .matches(regexPassword, errors.password),
})
