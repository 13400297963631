import { yup } from 'libs'

export const aiSettingSchema = yup.object({
  constraints: yup
    .string()
    .max(500, '500字数以下で入力してください。')
    .label('constraints')
    .trim(),
  action: yup
    .string()
    .max(500, '500字数以下で入力してください。')
    .label('action')
    .trim(),
})
