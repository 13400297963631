const ratio = 0.1
const step = 0.1

interface ILetterSpacings {
  [key: string]: string
}

export const letterSpacings: ILetterSpacings = {
  tighter: '-0.28px',
  tight: '-0.32px',
  normal: '0',
  wide: '0.4px',
  wider: '0.8px',
  widest: '0.16px',
}

for (let i = -10; i <= 10; i += step) {
  const numberToFixed = Number(i.toFixed(1))
  const letterSpacing = ratio * numberToFixed
  letterSpacings[numberToFixed] = `${letterSpacing.toFixed(2)}px`
}
