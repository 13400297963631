/**
 * PenSolidIcon icon
 * @constructor
 */
export const PenSolidIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5986 11.5289C17.7286 11.6589 17.7286 11.8711 17.5986 12.0011L7.5562 22H2V16.4438L11.999 6.40164C12.129 6.27052 12.3402 6.27052 12.4713 6.40164L17.5986 11.5289ZM21.3466 5.12044L18.8797 2.6534C18.0085 1.7822 16.5951 1.7822 15.7239 2.6534L13.6368 4.74036C13.5068 4.87037 13.5068 5.08145 13.6368 5.21146L18.7885 10.3631C18.9185 10.4931 19.1297 10.4931 19.2598 10.3631L21.3466 8.27631C22.2178 7.4051 22.2178 5.99165 21.3466 5.12044Z"
      fill="#0084FF"
    />
  </svg>
)
