import { Box, Flex, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
// import { MoreVertical } from 'components/atoms/Icons'
import React, { FC } from 'react'
import { FormikProvider } from 'formik'
import { ContentAskAI } from '../ContentAskAI'
import ModalAIAdvice from '../ModalAIAdvice'
import ModalConfirmAIAdvice from '../ModalConfirmAIAdvice'
import { AIAdviceCompanyProps, useAIAdviceCompany } from './hook'

/**
 * AIAdvice molecules
 * @constructor
 */
export const AIAdviceCompany: FC<AIAdviceCompanyProps> = props => {
  const {
    formik,
    optionPromptCompany,
    values,
    setAIAnswer,
    setConversationId,
    setFieldValue,
    AIAnswer,
    toggleExpanded,
    conversationId,
    expanded,
    setExpanded,
    setShowConfirmModal,
    handleCloseModal,
    showConfirmModal,
    setRenderPrompt,
  } = useAIAdviceCompany(props)
  return (
    <FormikProvider value={formik}>
      <Box
        bg="white.base"
        borderRadius={4}
        boxSizing="border-box"
        padding="12px 16px 16px 16px"
        w="50%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="12px"
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            <Text
              color="black.100"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
              minW="fit-content"
              p="0px 8px"
            >
              AIアドバイス
            </Text>
            <SelectField
              height="40px"
              heightListOptions="220px"
              isDisabled={!AIAnswer.done}
              maxWidth="412px"
              name="askAI"
              options={optionPromptCompany}
              placeholder="当社の最新の経営・財務・営業戦略は？ "
              valueOption={optionPromptCompany.find(
                it => it.value === values.askAI,
              )}
              onChange={e => {
                if (values.askAI !== e?.value) {
                  setAIAnswer(prev => ({
                    ...prev,
                    data: '',
                    loading: true,
                    done: false,
                  }))
                  setConversationId(null)
                  setRenderPrompt('')
                  setFieldValue('askAI', e?.value)
                }
              }}
            />
          </Flex>
          {/* <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} /> */}
        </Flex>
        <ContentAskAI
          content={AIAnswer}
          heightContent="144px"
          isCopyBtn={true}
          isDone={AIAnswer.done}
          isHidden={false}
          isOpenModal={expanded}
          isValues={Boolean(values.askAI)}
          minHeight={'192px'}
          toggleExpanded={toggleExpanded}
        />
      </Box>
      <ModalAIAdvice
        AIAnswer={AIAnswer}
        askAIOptions={optionPromptCompany}
        conversationId={conversationId}
        isOpen={expanded}
        isValues={Boolean(values.askAI)}
        setAIAnswer={setAIAnswer}
        setConversationId={setConversationId}
        setExpanded={setExpanded}
        setRenderPrompt={setRenderPrompt}
        setShowConfirmModal={setShowConfirmModal}
        setValueAskAI={value => setFieldValue('askAI', value)}
        value={values.askAI}
        onClose={handleCloseModal}
      />
      <ModalConfirmAIAdvice
        conversationId={conversationId}
        isOpen={showConfirmModal}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
      />
    </FormikProvider>
  )
}
