import React from 'react'
import { Box, Flex, useTheme } from '@chakra-ui/react'
import { InputProps, Input } from 'components/fromAdvisor/elements/Input'
import { LabelFormProps, LabelForm } from './LabelForm'
import { ErrorForm } from './ErrorForm'

export type InputFormProps = InputProps & {
  error?: string
  label: string | React.ReactNode
  id?: string
  direction?: 'horizontal' | 'vertical'
  labelWidth?: string | number
  labelProps?: Omit<
    LabelFormProps,
    'labelWidth' | 'label' | 'id' | 'isRequired'
  >
}

/**
 * InputForm component page.
 * @return {JSX.Element} InputForm component.
 */
export const InputForm: React.FC<InputFormProps> = ({
  id,
  error,
  label,
  labelProps,
  direction = 'vertical',
  labelWidth,
  isRequired,
  ...inputProps
}): React.ReactElement => {
  const theme = useTheme()

  const errorInput = error || inputProps.isInvalid

  if (direction === 'horizontal') {
    return (
      <Box w={'full'}>
        <Flex alignItems="center" w={'full'}>
          <LabelForm
            id={id}
            isRequired={isRequired}
            label={label}
            labelWidth={labelWidth}
            mt={2}
            {...labelProps}
          />
          <Input
            id={id}
            {...inputProps}
            color={errorInput ? theme.colors.red[700] : undefined}
          />
        </Flex>
        {error && <ErrorForm error={error} ml={labelWidth} />}
      </Box>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={theme.space[1]}>
      <LabelForm
        id={id}
        isRequired={isRequired}
        label={label}
        {...labelProps}
      />
      <Input
        _disabled={{
          background: theme.colors.gray[100],
        }}
        id={id}
        {...inputProps}
        color={errorInput ? theme.colors.red[700] : undefined}
      />
      {error && <ErrorForm error={error} />}
    </Flex>
  )
}
