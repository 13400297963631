import { images } from 'constant/images'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { FC, useState } from 'react'
import { loginValidateSchema } from 'libs/yup/login'
import { InputField } from 'components/atoms'
import _ from 'lodash'
import { Lock, Mail } from 'components/atoms/Icons'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { authServices, storageServices } from 'services'
import { useDispatch } from 'react-redux'
import { loginSuccess, userLogin } from 'context/Auth/auth.slice'
import { Button } from 'components/fromAdvisor/elements'

/**
 * login page
 * @constructor
 */
export const Login: FC<{ role: string }> = ({ role }) => {
  const [resError, setResError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: loginValidateSchema,
    /**
     *
     */
    async onSubmit(values: { email: string; password: string }) {
      setIsLoading(true)

      try {
        const res = await authServices.login({ ...values, role: role })
        if (res.is_success) {
          const { user_type, access_token, refresh_token } = res.data
          access_token && storageServices.setAccessToken(access_token)
          refresh_token && storageServices.setRefreshToken(refresh_token)
          user_type && storageServices.setRoleUser(role)
          dispatch(loginSuccess())
          dispatch(userLogin({ user: res.data }))
          if (user_type === ROLE.ADMIN) {
            navigate(ADMIN_ROUTES.ROOT)
          } else {
            navigate(ROUTES.DASHBOARD)
          }
        }
        setIsLoading(false)
      } catch (error: any) {
        console.log('error', error)
        setIsLoading(false)
        let errorCode = error?.response?.status
        if (errorCode === 422) {
          setResError('ユーザーネームまたはパスワードが違います')
        } else {
          setResError(
            error?.response?.data?.message ??
              'サーバーでエラーが発生しました。後ほど再度お試しください。',
          )
        }
      }
    },
  })

  const { handleSubmit, errors, setFieldValue, dirty, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Box
        bgImage={images.BACKGROUND_LOGIN}
        bgSize="cover"
        boxSizing="border-box"
        minH="100vh"
        padding="24px"
        position="relative"
        w="100%"
      >
        <Box
          bgColor="white.50"
          borderRadius="xl"
          h="calc(100vh - 48px)"
          maxW={647}
          ml={2}
          position="relative"
          w="100%"
        >
          <Flex
            alignItems="center"
            direction="column"
            h="100%"
            justifyContent="center"
          >
            {role === ROLE.ADVISOR ? (
              <Image
                alt="Logo"
                mb={resError ? '0px' : '56px'}
                src={images.LOGO_LOGIN}
              />
            ) : (
              <>
                <Image alt="Logo" mb="24px" src={images.LOGO_LOGIN} />
                <Text
                  bgColor="black.800"
                  borderRadius="24px"
                  color="white.50"
                  fontSize="lg"
                  fontWeight="bold"
                  height="33px"
                  lineHeight="short"
                  mb={resError ? '0px' : '40px'}
                  padding="0 28px"
                >
                  管理画面
                </Text>
              </>
            )}
            {resError && (
              <Text
                color="red.700"
                fontSize="sm"
                fontWeight="medium"
                lineHeight="shorter"
                mb={10}
                mt="32px"
              >
                {resError}
              </Text>
            )}
            <Flex direction="column" gap={4} maxW={327} mb="24px" w="100%">
              <InputField
                error={Boolean(resError || errors.email)}
                errorText={errors.email}
                height="56px"
                leftIcon={
                  <Mail color="blue.900" fontSize="2xl" opacity={0.5} />
                }
                name="email"
                placeholder="IDまたはメールアドレス"
                type="email"
                width="100%"
                onChange={e => setFieldValue('email', e.target.value)}
                onFocus={() => setResError('')}
              />
              <InputField
                error={Boolean(resError || errors.password)}
                errorText={errors.password}
                height="56px"
                leftIcon={
                  <Lock color="blue.900" fontSize="2xl" opacity={0.5} />
                }
                name="password"
                placeholder="パスワード"
                type="password"
                width="100%"
                onChange={e =>
                  setFieldValue('password', e.target.value.replace(/ /g, ''))
                }
                onFocus={() => setResError('')}
              />
              <Text
                color="blue.900"
                cursor="pointer"
                fontSize="sm"
                fontWeight="medium"
                letterSpacing="-0.01em"
                lineHeight="shorter"
                onClick={() => {
                  if (role === ROLE.ADVISOR) {
                    navigate(ROUTES.FORGOT_PASSWORD)
                  } else {
                    navigate(ADMIN_ROUTES.FORGOT_PASSWORD)
                  }
                }}
              >
                パスワードを忘れた方はこちら
              </Text>
            </Flex>
            <Button
              _hover={{ bgColor: 'blue.900' }}
              bgColor="blue.900"
              borderRadius="xl"
              color="white.50"
              fontSize="md"
              fontWeight="bold"
              height="56px"
              isDisabled={!_.isEmpty(errors) || !dirty || !isValid}
              isLoading={isLoading}
              letterSpacing="-0.02em"
              lineHeight="shorter"
              maxWidth={327}
              opacity={_.isEmpty(errors) ? 1 : 0.2}
              padding="12px 14px"
              text="ログイン"
              width="100%"
              onClick={() => handleSubmit()}
            />
          </Flex>
          <Flex
            bottom={0}
            justifyContent="space-between"
            padding={6}
            position="absolute"
            width="100%"
          >
            <Text
              color="blue.900"
              cursor={'pointer'}
              fontSize="sm"
              fontWeight="medium"
              letterSpacing="-0.01em"
              lineHeight="shorter"
              onClick={() => {
                if (role === ROLE.ADVISOR) {
                  navigate(ADMIN_ROUTES.LOGIN)
                } else {
                  navigate(ROUTES.LOGIN)
                }
              }}
            >
              {role === ROLE.ADVISOR
                ? '管理者ログインはこちら'
                : 'ユーザーログインはこちら'}
            </Text>
            <Text
              color="gray.800"
              fontSize="sm"
              fontWeight="normal"
              letterSpacing="-0.02em"
              lineHeight="shorter"
            >
              © MILIZE Inc.
            </Text>
          </Flex>
        </Box>
        <Flex
          bottom={6}
          gap={4}
          justifyContent="flex-end"
          position="absolute"
          width="calc(100% - 48px)"
        >
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            利用規約
          </Text>
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            プライバシーポリシー
          </Text>
          <Text
            color="white.base"
            fontSize="sm"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="shorter"
          >
            運営会社
          </Text>
        </Flex>
      </Box>
    </FormikProvider>
  )
}
