import { errors } from 'constant'
import { yup } from 'libs/yup'

export const initialValuesProduct = {
  products: [
    {
      category_id: null,
      product_name: '',
      product_short_name: '',
      product_code: '1000',
      description: '',
      url: '',
      score_target: '0',
      state_score: false,
      files: [],
    },
  ],
}

export const MESSAGE_USER = {
  CREATE_USER: 'プロンプト作成が成功されました。',
  UPDATE_USER: 'プロンプト更新が成功されました。',
  DELETE_USER: 'プロンプト削除が成功されました。',
}

export const productRegistrationSchema = yup.object({
  products: yup.array().of(
    yup.object().shape({
      category_id: yup.string().required(errors.required).label('category'),
      product_name: yup
        .string()
        .required(errors.required)
        .label('product_name')
        .max(255, '255字数以下で入力してください。')
        .trim(),
      product_short_name: yup
        .string()
        .required(errors.required)
        .label('name')
        .max(255, '255字数以下で入力してください。')
        .trim(),
      description: yup
        .string()
        .required(errors.required)
        .label('description')
        .max(255, '255字数以下で入力してください。')
        .trim(),
      url: yup
        .string()
        .label('url')
        .max(255, '255字数以下で入力してください。')
        .trim(),
      score_target: yup.number().label('score'),
    }),
  ),
})
