import React from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from '@emotion/styled'
import { range } from 'lodash'

type Props = {
  value?: Date
  onChange: (value?: Date) => void
  placeholder?: string
  isInvalid: boolean
  name?: string
  onKeyDown?: (e: any) => any
  width?: string
  height?: string
  clear?: boolean
  selectsStart?: boolean
  selectsEnd?: boolean
  startDate?: Date
  endDate?: Date
  minDate?: Date
  maxDate?: Date
}

/**
 * DatePicker component page.
 * @return {JSX.Element} DatePicker component.
 */
export const DatePicker = (props: Props) => {
  const {
    value,
    onChange,
    placeholder,
    isInvalid,
    name,
    onKeyDown,
    width,
    height,
    clear,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
    minDate,
    maxDate,
  } = props

  const years = range(1990, new Date().getFullYear() + 1, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <StyledDatePicker
      autoComplete="off"
      dateFormat="yyyy/MM/dd"
      endDate={endDate}
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
      height={height}
      isClearable={clear}
      isInvalid={isInvalid}
      maxDate={maxDate}
      minDate={minDate}
      name={name}
      placeholderText={placeholder}
      popperClassName="custom-popper"
      popperContainer={({ children }) => (
        <PopperWrapper>{children}</PopperWrapper>
      )}
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <select
            style={{
              border: '1px solid black',
            }}
            value={new Date(date).getFullYear()}
            onChange={({ target: { value } }) => changeYear(+value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            style={{
              border: '1px solid black',
            }}
            value={months[new Date(date).getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      selected={value}
      selectsEnd={selectsEnd}
      selectsStart={selectsStart}
      startDate={startDate}
      width={width}
      onChange={(value: Date) => onChange(value ? value : undefined)}
      onChangeRaw={e => e.preventDefault()}
      onKeyDown={onKeyDown}
    />
  )
}

const StyledDatePicker = styled(ReactDatePicker)<{
  isInvalid: boolean
  width: string | undefined
  height: string | undefined
}>`
  color: ${props => props.theme.colors.black[100]};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: ${props => props.theme.lineHeights.shorter};
  width: ${props => (props.width ? props.width : props.theme.sizes.full)};
  height: ${props => (props.height ? props.height : props.theme.sizes[12])};
  box-shadow: ${props => props.theme.boxShadows.passwordInput};
  background: ${props => props.theme.colors.white[50]};
  padding-left: ${props => props.theme.space[4]};
  border-radius: ${props => props.theme.radii.xl};
  outline: none;
  border: ${props =>
    props.isInvalid
      ? `${props.theme.sizes[0.5]} solid ${props.theme.colors.red[700]}`
      : `${props.theme.sizes.px} solid ${props.theme.colors.purple[250]}`};
  &::placeholder {
    color: ${props => props.theme.colors.black[100]};
    opacity: 0.3;
  }
  &:focus-visible {
    border-color: ${props => props.theme.colors.blue[900]};
  }
`

const PopperWrapper = styled.div`
  .react-datepicker__tab-loop {
    .custom-popper {
      z-index: 2 !important;
    }
  }
`
